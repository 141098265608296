import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useSeeAllExternalPlays() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid + "/seeAllExternalPlays/")
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["track", variables.trackid]);
        queryClient.invalidateQueries(["all-tracks"]);
      },
    }
  );
}
