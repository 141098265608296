import React, { useEffect, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import DotsLoader from "../../DotsLoader";

import OnboardingTextfield from "../OnboardingTextfield";
import { classNames } from "../../shared/Utils";

import SpotifyAvatar from "../../SpotifyAvatar";

const OnboardingUser1 = ({
  handleNextStep,
  current,
  percentage,
  workspaceCompanyType,
  formik,
}) => {
  const [buttonText, setButtonText] = React.useState("next");
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleNext = (e) => {
    if (!error && current) {
      e.preventDefault();
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        setButtonText("next");
        handleNextStep();
      }, 100);
    }
  };
  return (
    <div className=" flex w-full flex-row gap-12">
      {/*  */}
      <div className="hidden w-20 flex-shrink-0 md:block ">
        {/* <img className="flex h-20 w-auto " src={logoicon} alt="Trackflow" /> */}
        <CircularProgressbar
          value={percentage}
          className={" h-20 w-20 transition-all"}
          strokeWidth={18}
          // text={`${loadingPercentage}%`}
          styles={buildStyles({
            rotation: 0,

            strokeLinecap: "butt",

            pathTransitionDuration: 1,
            pathColor: "#22c55e",
            textColor: "#d1d5db",
            trailColor: `#3f3f46`,
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="w-full">
          {" "}
          <label
            htmlFor="userName"
            className="block pb-2 text-2xl text-gray-800 sm:text-3xl "
          >
            Your{" "}
            <span className="font-medium">
              {workspaceCompanyType === "label" ? "artist name" : "full name"}
            </span>
            :
          </label>
          <OnboardingTextfield
            current={current}
            setError={setError}
            autoFocus={false}
            name="userName"
            id="userName"
            helpText="Must be 8-20 characters and cannot contain special characters."
            type="text"
          />
        </div>

        <div>
          <button
            type="button"
            onClick={handleNext}
            className={classNames(
              error
                ? "my-3 inline-flex h-14 cursor-not-allowed  items-center rounded-md px-5 py-3 text-xl  font-medium shadow-sm text-white bg-gray-200  border-gray-300"
                : "my-3 inline-flex h-14 items-center  rounded-md px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
            )}
          >
            {buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />{" "}
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingUser1;
