import React, { useEffect } from "react";
import NotificationsListItem from "./NotificationsListItem";
import NotificationsListItemSeen from "./NotificationsListItemSeen";
import useAllProjects from "../hooks/useAllProjects";
import useAllTracks from "../hooks/useAllTracks";
import useAllUsers from "../hooks/useAllUsers";
import { formatDistanceToNow, formatDistanceToNowStrict } from "date-fns";
import { formatRelative } from "date-fns";
import { enGB } from "date-fns/esm/locale";
import secureLocalStorage from "react-secure-storage";

import { classNames } from "../shared/Utils";
import useAuth from "../hooks/useAuth";
import useAllNotifications from "../hooks/useAllNotifications";

import axios from "axios";
import { useInView } from "react-intersection-observer";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import Avatar from "../Avatar";

import ToolTip from "../ToolTip";

import useSeeMultipleTrackistory from "../hooks/useSeeMultipleTrackHistory";

import { serverUrl } from "../hooks/Api";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  AtSymbolIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  MusicalNoteIcon,
  PlusIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";

import { ClockIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DotsLoader from "../DotsLoader";

const formatRelativeLocale = {
  lastWeek: "'LAST WEEK'",
  yesterday: "'YESTERDAY'",
  today: "'TODAY'",
  tomorrow: "'TOMORROW'",
  other: "dd/MM",
};

const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};

// is less than 48 hours ago
const isRecent = (date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return diff < 1000 * 60 * 60 * 48;
};

const WeekTitle = (props) => {
  return (
    <div className="sticky top-[6.55rem] z-40 self-start border-y px-[1.4rem]  py-2 text-[0.8rem]  font-medium uppercase tracking-wider bg-white text-gray-500 border-gray-200">
      {isRecent(new Date(props.date))
        ? formatRelative(new Date(props.date), new Date(), { locale })
        : formatDistanceToNowStrict(new Date(props.date), {
            addSuffix: true,
          })}
    </div>
  );
};

const NotificationsList = ({ notifications, mentions, dashboard }) => {
  const { ref, inView } = useInView();

  const axiosPrivate = useAxiosPrivate();

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    ["all-notifications"],
    async ({ pageParam = 0 }) => {
      const res = await axiosPrivate.get(
        serverUrl + "/notifications/" + pageParam
      );

      return res.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
    }
  );

  console.log("notificationsdata", data);

  // React.useEffect(() => {
  //   if (inView) {
  //     fetchNextPage();
  //   }
  // }, [inView]);

  const typeToText = (type) => {
    switch (type) {
      case "comment":
        return { text: "Commented on", icon: ChatBubbleOvalLeftEllipsisIcon };
      case "mention":
        return { text: "Mentioned you in", icon: AtSymbolIcon };
      case "upload":
        return { text: "Uploaded audio for", icon: MusicalNoteIcon };
      case "status":
        return { text: "Changed status of", icon: SwatchIcon };
      case "created":
        return { text: "Created", icon: PlusIcon };
      case "externalPlay":
        return { text: "Visited a shared link", icon: PlusIcon };
      case "externalPlaylistPlay":
        return { text: "Visited a shared playlist", icon: PlusIcon };
      default:
        return { text: "", icon: InformationCircleIcon };
    }
  };

  const { auth } = useAuth();

  console.log("workspace notificationslist", auth?.workspace);

  const [seenItems, setSeenItems] = React.useState([]);

  const addItemToSeenArray = (newItem) => {
    // check if historyId already exists in data
    const isDuplicate = seenItems.some(
      (item) => item.historyId === newItem.historyId
    );

    // add item to data if historyId is not a duplicate
    if (!isDuplicate) {
      setSeenItems((seenItems) => [...seenItems, newItem]);
    }
  };

  useEffect(() => {
    console.log("seenItemsArray", seenItems);
  }, [seenItems]);

  const seeMultipleTrackHistoryQuery = useSeeMultipleTrackistory();
  console.log("seeMultipleTrackHistoryQuery", seeMultipleTrackHistoryQuery);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (seenItems.length > 0) {
        // updateMultipleSeenItems(seenItemsArray);
        seeMultipleTrackHistoryQuery.mutate(seenItems);
        setSeenItems([]);
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [seenItems]);

  return (
    <div>
      <div>
        {status === "loading" ? (
          <div className="flex h-12 items-center justify-center border-0">
            <DotsLoader />
          </div>
        ) : status === "error" ? (
          <span>Error: {error.message}</span>
        ) : (
          <>
            {data?.pages?.map((data, i) => (
              <>
                {Object.keys(data.notifications)?.map((date, Idx) => (
                  <div className={classNames(Idx === 0 ? "" : "")} key={date}>
                    <WeekTitle date={date} />
                    {Object.keys(data.notifications[date])?.map((user) => {
                      const userAvatar =
                        Object.keys(data.notifications[date][user])[0] ===
                        "externalPlay"
                          ? data.notifications[date][user]["externalPlay"][0]
                              .data.listenerPicture
                          : Object.keys(data.notifications[date][user])[0] ===
                            "externalPlaylistPlay"
                          ? data.notifications[date][user][
                              "externalPlaylistPlay"
                            ][0].data.listenerPicture
                          : serverUrl +
                            "/avatar/" +
                            auth?.workspace +
                            "/" +
                            user +
                            "/small";

                      if (
                        Object.keys(data.notifications[date][user])[0] ===
                        "externalPlay"
                      ) {
                        console.log(
                          "userAvatar",
                          data.notifications[date][user]["externalPlay"][0].data
                            .listenerPicture
                        );
                      }
                      return (
                        <div
                          className=" flex flex-row gap-3 py-3  pl-4 pr-2 divide-gray-200  "
                          key={date + "-" + user}
                        >
                          <div className="sticky top-[9.55rem] self-start">
                            <ToolTip message={user}>
                              <div
                                style={{
                                  backgroundImage: `url(${userAvatar})`,

                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                className="h-10 w-10 rounded-md bg-gray-200"
                              />
                            </ToolTip>
                          </div>
                          <div className="flex flex-grow flex-col gap-5">
                            {Object.keys(data.notifications[date][user])?.map(
                              (type) => {
                                return (
                                  <div
                                    className=" flex flex-col "
                                    key={date + "-" + user + "-" + type}
                                  >
                                    <div className="flex items-center gap-1 pb-1 pl-2 text-xs font-medium uppercase text-gray-600">
                                      <div>{typeToText(type).text}</div>
                                    </div>
                                    <div className=" flex flex-col   border-gray-200 divide-gray-100 ">
                                      {data.notifications[date][user][
                                        type
                                      ]?.map((item) => (
                                        <div className="group  ">
                                          <NotificationsListItemSeen
                                            addItemToSeenArray={
                                              addItemToSeenArray
                                            }
                                            item={item}
                                          >
                                            <NotificationsListItem
                                              item={item}
                                              key={item.id}
                                            />
                                            <div className="float-right flex h-0 flex-row  items-center gap-1 overflow-hidden rounded-b-md px-2  text-xs transition-all text-gray-400 bg-gray-50 group-hover:h-8">
                                              <ClockIcon className="h-4 w-4 text-gray-400 " />
                                              <div>
                                                {formatRelative(
                                                  new Date(item.date),
                                                  new Date()
                                                )}
                                              </div>
                                            </div>
                                          </NotificationsListItemSeen>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </>
            ))}

            <div>
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="flex h-12 w-full cursor-pointer flex-row items-center justify-center p-4 text-center uppercase text-gray-500 bg-gray-50 hover:bg-gray-100"
              >
                {isFetchingNextPage ? (
                  <>
                    <DotsLoader />
                  </>
                ) : hasNextPage ? (
                  <div className="flex flex-row items-center">Load more...</div>
                ) : (
                  "Nothing more to load"
                )}
              </button>
            </div>
            {/* <div>
              {isFetching && !isFetchingNextPage
                ? "Background Updating..."
                : null}
            </div> */}
          </>
        )}
      </div>

      {/* <div className="projecttoolbar  flex h-full w-full flex-col divide-y  border-b border-gray-200 divide-gray-200  ">
        {Object.keys(allNotifications).map((date, Idx) => (
          <div className={classNames(Idx === 0 ? "" : "")} key={date}>
            <WeekTitle date={date} />
            {Object.keys(allNotifications[date]).map((user) => (
              <div
                className=" flex flex-row gap-3 py-3  pl-4 pr-2 divide-gray-200  "
                key={date + "-" + user}
              >
                <div className="sticky top-12 self-start">
                  <div
                    style={{
                      backgroundImage: `url(${
                        serverUrl + "/avatar/" + user + "/80"
                      })`,

                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    className="h-10 w-10 rounded-md bg-gray-200"
                  />
                </div>
                <div className="flex flex-grow flex-col gap-5">
                  {Object.keys(allNotifications[date][user]).map((type) => (
                    <div
                      className=" flex flex-col "
                      key={date + "-" + user + "-" + type}
                    >
                      <div className="pb-1 pl-2 text-xs font-medium uppercase text-gray-600">
                        {typeToText(type)}
                      </div>
                      <div className=" flex flex-col   border-gray-200 divide-gray-100 ">
                        {allNotifications[date][user][type].map((item) => (
                          <div className="rounded-md hover:bg-gray-50">
                            <NotificationsListItem item={item} key={item.id} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default NotificationsList;
