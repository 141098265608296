import React from "react";
import useAuth from "../../hooks/useAuth";

const TrackListTrackOverlayNavbarShareCount = ({ track }) => {
  const { auth } = useAuth();
  const newExternalPlaysCount = track?.externalPlays
    ?.filter((play) => play.playDuration > 0)
    ?.filter(
      (externalPlay) => !externalPlay.seenBy?.includes(auth.email)
    ).length;

  if (newExternalPlaysCount > 0) {
    return (
      <div className="ml-3 hidden rounded-full py-0.5 px-2.5 text-tiny font-medium bg-white text-gray-800 md:inline-block">
        {newExternalPlaysCount}
      </div>
    );
  }
};

export default TrackListTrackOverlayNavbarShareCount;
