import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import useAuth from "../../hooks/useAuth.js";
import CustomCommentComponent from "./CustomCommentComponent.js";
import secureLocalStorage from "react-secure-storage";

export default Node.create({
  // configuration …

  name: "commentItem",
  draggable: true,
  // inline: true,
  group: "block",
  content: "text*",
  editable: false,

  parseHTML() {
    return [
      {
        tag: "comment-item",
      },
    ];
  },

  addAttributes() {
    return {
      commentBy: {
        renderHTML: (attributes) => ({
          "data-comment-by": attributes.commentBy,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["comment-item", mergeAttributes(HTMLAttributes)];
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          // let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          // state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          //   if (node.type.name === this.name) {
          //     isMention = true;
          //     tr.insertText(
          //       this.options.suggestion.char || "",
          //       pos,
          //       pos + node.nodeSize
          //     );

          //     return false;
          //   }
          // });

          // return isMention;
        }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomCommentComponent);
  },
});
