import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import useLogout from "../components/hooks/useLogout";

const AuthContext = createContext({});

import OpenReplayContext from "./OpenReplayProvider";

export const AuthProvider = ({ children }) => {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
  } = useAuth0();
  const navigate = useNavigate();
  // const logout = useLogout();

  console.log("AuthProvider isAuthenticated", isAuthenticated);
  const storedAuth = JSON.parse(secureLocalStorage.getItem("auth"));

  const [accessToken, setAccessToken] = useState(null);

  console.log("AuthProvider accessToken", accessToken);

  const setAuth = () => null;

  const [auth, setAuth2] = useState({});

  const { tracker } = useContext(OpenReplayContext);

  useEffect(() => {
    if (user) {
      tracker.setUserID(user.email);
      tracker.setMetadata("name", user.name);

      StonlyWidget("identify", user.email, {
        email: user.email,
        name: user.name,
        workspace: user.userInfo.workspaces[0].name,
        roles: user.userInfo.workspaces[0].roles,
        avatar: user.userInfo.avatarUrl,
      });

      $crisp.push([
        "set",
        "user:email",
        [user.email, user.userInfo.crispSignature],
      ]);
      if (user.userInfo.avatarUrl)
        $crisp.push(["set", "user:avatar", [user.userInfo.avatarUrl]]);
      $crisp.push(["set", "user:nickname", [user.name]]);
      $crisp.push(["set", "user:company", [user.userInfo.workspaces[0].name]]);

      if (user.userInfo.workspaces[0].roles.includes("admin")) {
        $crisp.push(["set", "session:segments", [["admin"]]]);
      }

      $crisp.push([
        "set",
        "session:data",
        [["roles", user.userInfo.workspaces[0].roles]],
      ]);
    }
  }, [user]);

  useEffect(() => {
    setAuth2({
      email: user?.email,
      name: user?.name,
      picture: user?.picture,
      workspace: user?.userInfo.workspaces[0].name,
      roles: user?.userInfo.workspaces[0].roles,
      accessToken: accessToken,
    });
  }, [user, accessToken]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      // logout();
      // window.location.href = "/login";
      // window.alert("You are not logged in", isLoading, isAuthenticated);
    }
  }, [isAuthenticated, isLoading]);

  // useEffect(() => {
  // if(!storedAuth && !isAuthenticated){
  //   navigate("/login");
  // }
  // }, [storedAuth, isAuthenticated])

  // useEffect(() => {
  //   if(!auth.email && window.location != "/login"){
  //     navigate("/callback");
  //   }
  // }, [auth])

  const [persist, setPersist] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        persist,
        setPersist,
        accessToken,
        setAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
