/* This example requires Tailwind CSS v2.0+ */

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MenuToggle({ enabled, onClick, large, medium }) {
  return (
    <div
      className={classNames(
        enabled ? "bg-gray-500" : "bg-gray-200",
        large ? "h-5 w-9" : medium ? "h-4 w-7" : "h-3 w-5",
        "relative my-auto inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          enabled
            ? large
              ? "translate-x-4"
              : medium
              ? "translate-x-3"
              : "translate-x-2"
            : "translate-x-0",
          large ? "h-4 w-4" : medium ? "h-3 w-3" : "h-2 w-2",
          "inline-block  transform  rounded-full shadow ring-0 transition duration-200 ease-in-out bg-white"
        )}
      />
    </div>
  );
}
