import React, { useEffect } from "react";
import {
  ArrowPathIcon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/outline";
import ReactDOM from "react-dom/client";
import "./index.css";
import Workspace from "./Workspace";
import Register from "./routes/Register";
import Play from "./routes/Play";
import PlayTest from "./routes/PlayTest";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Callback from "./routes/Callback";

import { Auth0Provider } from "@auth0/auth0-react";

import reportWebVitals from "./reportWebVitals.js";
import { sendToVercelAnalytics } from "./vitals.js";

import Lottie from "react-lottie-player";

import * as errorcat from "./animations/errorcat.json";

// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Helmet } from "react-helmet";
import nightwind from "nightwind/helper";

import { AuthProvider } from "./context/AuthProvider";
import { OpenReplayProvider } from "./context/OpenReplayProvider";

import RequiredAuth from "./components/RequireAuth";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  useQuery,
  QueryClient,
  QueryClientProvider,
  MutationCache,
  onlineManager,
  useIsRestoring,
} from "@tanstack/react-query";

import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import SignUp from "./routes/SignUp";
import ListeningRoom from "./routes/ListeningRoom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import IsLoaded from "./IsLoaded";
import Onboarding from "./components/Onboarding/Onboarding";
import NFT from "./routes/NFT";
import CannyRedirect from "./routes/CannyRedirect";
import ResetPassword from "./routes/ResetPassword";
import ResetPasswordRequest from "./routes/ResetPasswordRequest";

import { inject } from "@vercel/analytics";
import posthog from "posthog-js";
import OnboardingCreatedUser from "./components/Onboarding/OnboardingCreatedUser";
import LocalStorage from "./routes/LocalStorage";
import SetWebsiteLocalStorage from "./components/hooks/SetWebsiteLocalStorage";
import { toast } from "react-toastify";

import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
// Somewhere in your `index.ts`:
setChonkyDefaults({ iconComponent: ChonkyIconFA });

posthog.init("phc_AUdoHNbqo4mCtvdRBWhNFqKU6ZT28WTvsQ0Yp4KqbPE", {
  api_host: "https://app.posthog.com",
});

if (process.env.NODE_ENV === "production") {
  // setup Logrocket and Sentry if session isn't development
  // LogRocket.init("ocukap/trackflow");
  // setupLogRocketReact(LogRocket);

  Sentry.init({
    dsn: "https://9ce37b3f857043758227c1730d53773e@o4503924953710592.ingest.sentry.io/4503924959739904",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: { staleTime: Infinity },
//   },
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 60, // 1 hour
      retry: 0,
    },
  },
});

// if (process.env.NODE_ENV === "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

function FallbackComponent() {
  inject();

  useEffect(() => {
    document.body.classList.add("body-login");
    return () => {
      document.body.classList.remove("body-login");
    };
  }, []);

  return (
    <div className="flex h-screen w-screen">
      <div className="my-auto mx-auto flex flex-col justify-center text-xl text-gray-600">
        <div className=" mx-auto flex h-full w-full justify-center  opacity-80">
          <Lottie
            speed={1}
            loop={true}
            animationData={errorcat}
            play
            style={{ width: 200, height: 200 }}
          />
        </div>
        {/* <div className="m-2 mx-auto">
          <ExclamationTriangleIcon className="h-10 w-10" />
        </div> */}
        <div className="m-2 mx-auto">An error has occurred</div>
        <div className="flex flex-col justify-center text-center text-xs text-gray-400">
          <div className="mx-auto flex flex-wrap">
            Try{" "}
            <div
              onClick={() => {
                window.location.reload();
              }}
              className=" flex w-fit cursor-pointer px-2 underline  hover:text-gray-600  "
            >
              <ArrowPathIcon className="mr-2 mt-1 h-4 w-4" />
              refreshing
            </div>
            your browser
          </div>
          <div className="mt-8 flex flex-wrap">
            If this error persists, please{" "}
            <div
              onClick={() => {
                $crisp.push(["do", "chat:open"]);
              }}
              className=" flex w-fit cursor-pointer px-2 underline  hover:text-gray-600  "
            >
              <ChatBubbleBottomCenterIcon className="mr-2 mt-1 h-4 w-4" />
              Chat with us
            </div>
          </div>
        </div>
      </div>
      <IsLoaded />
    </div>
  );
}

const myFallback = <FallbackComponent />;

window.$crisp = [];
window.CRISP_WEBSITE_ID = "c3713c12-bbc5-4672-8f6f-7b4059c3df11";
(function () {
  var d = document;
  var s = d.createElement("script");
  s.src = "https://client.crisp.chat/l.js";
  s.async = 1;
  d.getElementsByTagName("head")[0].appendChild(s);
})();

window.$crisp.push(["safe", true]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="auth.trackflow.studio"
      clientId="NNhZpCNGW8jpBhUkSz6X8dmjvRsuSVs7"
      authorizationParams={{
        redirect_uri:
          process.env.NODE_ENV === "production"
            ? "https://app.trackflow.studio/callback"
            : "http://localhost:3000/callback",
      }}
    >
      <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <BrowserRouter>
          <Helmet>
            <script>{nightwind.init()}</script>
          </Helmet>

          <QueryClientProvider client={queryClient}>
            <OpenReplayProvider>
              <AuthProvider>
                <Routes>
                  {/* public */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/callback" element={<Callback />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/localstorage" element={<LocalStorage />} />
                  <Route path="/signup/:invitecode" element={<SignUp />} />
                  <Route
                    path="/requestreset"
                    element={<ResetPasswordRequest />}
                  />
                  <Route
                    path="/resetpassword/:resetPasswordToken"
                    element={<ResetPassword />}
                  />
                  <Route
                    path="/onboarding/:invitecode"
                    element={<Onboarding />}
                  />
                  <Route
                    path="/onboarding/cu/:invitecode"
                    element={<OnboardingCreatedUser />}
                  />
                  <Route path="p/:code" element={<Play />} />
                  <Route path="ptest" element={<PlayTest />} />
                  <Route path="/room" element={<ListeningRoom />} />
                  <Route path="/nft" element={<NFT />} />
                  <Route
                    element={
                      <>
                        <RequiredAuth />
                      </>
                    }
                  ></Route>
                  <Route path="/*" element={<Workspace />} />
                  <Route path="/canny" element={<CannyRedirect />} />
                </Routes>{" "}
              </AuthProvider>{" "}
            </OpenReplayProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
