import { HomeIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TrackMenu from "../../TrackMenu";

export default function TracklistTrackOverlayBreadcrumb({ setOpen, track }) {
  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  const copyPreviewLinkToClipboard = () => {
    navigator.clipboard.writeText(
      "https://p2.trackflow.studio/" + track?.previewcode
    );
    setCopied(true);
    toast.success("Copied preview link to clipboard", {
      containerId: "main",
    });
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <nav
      className="flex flex-row border-b bg-gray-100 border-gray-200 dark:bg-zinc-950"
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className="max-w-screen-xl mx-auto flex w-full flex-grow space-x-4 px-4"
      >
        <li className="flex">
          <div className="flex items-center">
            <div
              onClick={() => {
                setOpen(false);
                navigate("/");
              }}
              className="-mr-2 cursor-pointer text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </div>
        </li>

        <li key="project" className="flex">
          <div className="flex items-center">
            <svg
              className="h-full w-6 flex-shrink-0 text-gray-300"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div
              onClick={() => {
                setOpen(false);
                navigate(`/projects/${track?.project}/list`);
              }}
              className="ml-4 cursor-pointer text-sm font-medium text-gray-500 hover:text-gray-700"
              // aria-current={page.current ? "page" : undefined}
            >
              {track?.projects ? track.projects[0]?.title : ""}
            </div>
          </div>
        </li>
        <li key="track" className="flex">
          <div className="flex items-center">
            <svg
              className="h-full w-6 flex-shrink-0 text-gray-300"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div
              // href={page.href}
              className="ml-4 cursor-default text-sm font-medium text-gray-500 "
              // aria-current={page.current ? "page" : undefined}
            >
              {track?.title}
            </div>
          </div>
        </li>
      </ol>
      <div className="flex flex-row items-center gap-3">
        <div
          onClick={() => copyPreviewLinkToClipboard()}
          className="group flex h-full cursor-pointer items-center justify-center pl-3 text-gray-500 hover:text-gray-900"
        >
          <div className="hidden pr-2 group-hover:block">share</div>
          {copied ? (
            <ClipboardDocumentCheckIcon
              className=" h-5 w-5 "
              aria-hidden="true"
            />
          ) : (
            <ClipboardDocumentIcon className=" h-5 w-5 " aria-hidden="true" />
          )}
        </div>
        <div
          onClick={() => setOpen(false)}
          className="flex h-full cursor-pointer items-center justify-center px-3 text-gray-500 hover:text-gray-900"
        >
          <XMarkIcon className="h-6 w-6 flex-shrink-0 " aria-hidden="true" />
        </div>
      </div>
    </nav>
  );
}
