import React, { createContext, useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const GuidedTourContext = createContext({});

export const GuidedTourProvider = ({ children }) => {
  const [stepIndex, setStepIndex] = React.useState(0);
  const [run, setRun] = React.useState(false);

  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => (
    <div
      {...tooltipProps}
      className="rounded-md p-4 shadow-sm bg-white dark:bg-white"
    >
      <p>{step.content}</p>
      {/* <div>
        {index > 0 && <button {...backProps}>Back</button>}
        <button {...closeProps}>Close</button>
        {continuous && <button {...primaryProps}>Next</button>}
      </div> */}
    </div>
  );

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }
  };

  useEffect(() => {
    if (stepIndex === 1 && window.location.pathname === "/artists") {
      setTimeout(() => {
        setStepIndex(2);
      }, 500);
    }
  }, [stepIndex, window.location.pathname]);

  useEffect(() => {
    if (stepIndex === 4 && window.location.pathname === "/projects") {
      setTimeout(() => {
        setStepIndex(5);
      }, 500);
    }
  }, [stepIndex, window.location.pathname]);

  const steps = [
    {
      disableBeacon: true,
      target: "body",
      content: (
        <div>
          <h1>Welcome to Trackflow</h1>
          <p>Let's take a tour of the app</p>
          <button
            onClick={() => {
              setStepIndex(stepIndex + 1);
            }}
          >
            Next
          </button>
        </div>
      ),
      hideCloseButton: true,
    },
    {
      disableBeacon: true,
      target: "#nav-Artists",
      content: "Let's start by creating a new artist",
    },
    {
      disableBeacon: true,
      target: "#new-artists-button",
      content: "Create a new artist by clicking the 'New Artist' button",
    },
    {
      disableBeacon: true,
      target: "#new-artist-overlay",
      content: "Fill out the form and click 'Create Artist'",
    },
    {
      disableBeacon: true,
      target: "#nav-Projects",
      content: "Now, let's create your first project",
    },
    {
      disableBeacon: true,
      target: "#new-project-button",
      content: "Create a new project by clicking the 'New Project' button",
    },
  ];

  return (
    <GuidedTourContext.Provider
      value={{ steps, stepIndex, setStepIndex, run, setRun }}
    >
      {children}
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        run={run}
        continuous={false}
        stepIndex={stepIndex}
        hideBackButton={true}
        hideCloseButton={true}
        showSkipButton={true}
        spotlightClicks={true}
        disableOverlayClose={true}
        tooltipComponent={Tooltip}
        styles={{
          buttonClose: {
            display: "none",
          },
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            //   overlayColor: "rgba(79, 26, 0, 0.4)",
            primaryColor: "#000",
            textColor: "#004a14",
            //   width: 900,
            zIndex: 1000,
          },
        }}
      />
    </GuidedTourContext.Provider>
  );
};

export default GuidedTourContext;
