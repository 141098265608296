import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackDeadline() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ deadline, projectid, trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          deadline: deadline,
        })
        .then((res) => res.data),
    {
      onMutate: async (newTrackData) => {
        await queryClient.cancelQueries(["track", newTrackData.trackid]);
        const previousTrack = queryClient.getQueryData([
          "track",
          newTrackData.trackid,
        ]);
        queryClient.setQueryData(["track", newTrackData.trackid], {
          ...previousTrack,
          deadline: newTrackData.deadline,
        });
        return { previousTrack };
      },
      onError: (err, newTrackData, context) => {
        queryClient.setQueryData(
          ["track", newTrackData.trackid],
          context.previousTrack
        );
      },
    }
  );
}
