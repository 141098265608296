import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import nightwind from "nightwind/helper";

import DotsLoader from "../../DotsLoader";
import * as confettiAnimation from "../../../animations/confetti.json";
import Lottie from "react-lottie-player";
import secureLocalStorage from "react-secure-storage";

import useOnboarding from "../../hooks/useOnboarding";
import { Navigate, useNavigate } from "react-router-dom";
import { FaceFrownIcon } from "@heroicons/react/20/solid";

import axios from "axios";

import useAuth from "../../hooks/useAuth";

import { serverUrl } from "../../hooks/Api.js";

// import LogRocket from "logrocket";
import { useContext } from "react";
import OpenReplayContext from "../../../context/OpenReplayProvider";

const OnboardingFinished = ({
  handleNextStep,
  current,
  percentage,
  formik,
  inviteCode,
}) => {
  const { tracker } = useContext(OpenReplayContext);

  const navigate = useNavigate();
  const [buttonText, setButtonText] = React.useState("next");

  const onboardingQuery = useOnboarding();

  const [createWorkspaceFinished, setCreateWorkspaceFinished] =
    React.useState(false);

  const [errMsg, setErrMsg] = useState("");
  const { setAuth, persist, setPersist } = useAuth();
  useEffect(() => {
    if (current) {
      console.log(
        "Workspace id:",
        formik.values.workspaceName.replace(/[^a-zA-Z0-9 ]/g, "")
      );

      //TODO Last check for errors in formik
      // create user
      onboardingQuery.mutate({
        token: inviteCode,
        workspace: {
          workspaceId: formik.values.workspaceName
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .toLowerCase()
            .replace(/\s/g, "-")
            .replace(/[^a-zA-Z0-9 ]/g, ""),
          workspaceSettings: {
            companyType: formik.values.workspaceType.name,
            color: formik.values.workspaceColor,
            logo: formik.values.workspaceLogo
              ? formik.values.workspaceLogo
              : null,
            name: formik.values.workspaceName,
            statuses: formik.values.workspaceStatuses,
            createdBy: formik.values.email,
            createdAt: new Date().toISOString(),
          },

          userEmail: formik.values.userEmail,
          userPassword: formik.values.userPassword,
          userName: formik.values.userName,
          userAvatar: formik.values.userProfilePicture,
        },
      });
      console.log("onboardingQuery", onboardingQuery);
    }
  }, [current]);

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleFinish = async (e) => {
    e.preventDefault();

    try {
      const res = await axios
        .post(
          serverUrl + "/auth",
          JSON.stringify({
            email: formik.values.userEmail,
            pwd: formik.values.userPassword,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log("return from api", JSON.stringify(res?.data));

          tracker.setUserID(res?.data?.email);
          tracker.setMetadata("name", res?.data?.username);
          // tracker.setMetadata("email", res?.data?.email);
          // tracker.setMetadata("workspace", res?.data?.workspace);
          // tracker.setMetadata("roles", res?.data?.roles);
          // tracker.setMetadata("avatar", res?.data?.avatar);

          StonlyWidget("identify", res?.data?.email, {
            email: res?.data?.email,
            name: res?.data?.username,
            workspace: res?.data?.workspace,
            roles: res?.data?.roles,
            avatar: res?.data?.avatar,
          });

          $crisp.push([
            "set",
            "user:email",
            [res?.data?.email, res?.data?.crispSignature],
          ]);
          if (res?.data?.avatar)
            $crisp.push(["set", "user:avatar", [res?.data?.avatar]]);
          $crisp.push(["set", "user:nickname", [res?.data?.username]]);
          $crisp.push(["set", "user:company", [res?.data?.workspace]]);

          const userType = res?.data?.roles?.includes("admin")
            ? "admin"
            : "user";
          $crisp.push(["set", "session:data", [["roles", res?.data?.roles]]]);
          $crisp.push(["set", "session:segments", [userType]]);
          // LogRocket.identify(res?.data?.userid, {
          //   id: res?.data?.userid,
          //   name: res?.data?.username,
          //   email: res?.data?.email,
          //   workspace: res?.data?.workspace,
          //   roles: res?.data?.roles,
          //   ...(res?.data?.linkedArtist && {
          //     linkedArtist: res?.data?.linkedArtist,
          //   }),
          // });

          setAuth({
            email: formik.values.userEmail,
            username: formik.values.userName,
            userid: res?.data?.userid,
            roles: res?.data?.roles,
            accessToken: res?.data?.accessToken,
            name: res?.data?.username,
            workspace: res?.data?.workspace,
            ...(res?.data?.linkedArtist && {
              linkedArtist: res?.data?.linkedArtist,
            }),
          });

          secureLocalStorage.setItem(
            "auth",
            JSON.stringify({
              workspace: formik.values.workspaceName
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase(),
              accessToken: res?.data?.accessToken,
              userid: res?.data?.userid,
              username: formik.values.userName,
              email: res?.data?.email,
              ...(res?.data?.linkedArtist && {
                linkedArtist: res?.data?.linkedArtist,
              }),
            })
          );
          nightwind.enable(false);
          navigate("/projects", { replace: true });
        });
    } catch (err) {
      setButtonText("signin");
      if (!err?.response) {
        setErrMsg("No server response");
        console.log("No server response", err);
      } else if (err.response?.status === 400) {
        setErrMsg("Missing user or password");
      } else if (err.response?.status === 401) {
        setErrMsg("Check username and password");
      } else {
        setErrMsg("Login Failed");
      }
      console.log("Login Failed ", errMsg);
    }
    // navigate("/login");
  };
  return (
    <div className="flex  h-screen w-full ">
      <div className="my-auto flex flex-row gap-12">
        {/*  */}
        <div className="hidden w-20 flex-shrink-0 md:block ">
          {onboardingQuery.isError ? (
            <div className="flex flex-col gap-4">
              <FaceFrownIcon className="h-20 w-20 text-red-500" />
            </div>
          ) : (
            <CircularProgressbar
              value={percentage}
              className={" h-20 w-20 transition-all"}
              strokeWidth={18}
              // text={`${loadingPercentage}%`}
              styles={buildStyles({
                rotation: 0,

                strokeLinecap: "butt",

                pathTransitionDuration: 1,
                pathColor: "#22c55e",
                textColor: "#d1d5db",
                trailColor: `#3f3f46`,
                backgroundColor: "#3e98c7",
              })}
            />
          )}
        </div>

        {onboardingQuery.isError && (
          <div className="flex flex-col gap-4">
            <h1 className="text-2xl font-medium text-gray-600">
              Something went wrong
            </h1>
            <p className="text-gray-400 ">
              {onboardingQuery.error.response.data.message}
            </p>
          </div>
        )}

        {onboardingQuery.isSuccess && (
          <div className="relative flex w-full flex-col gap-6">
            <div className="w-full">
              {" "}
              <div className="block pb-2 text-2xl text-gray-800 sm:text-3xl ">
                <span className="font-medium">All done!</span>
              </div>
            </div>
            {current && (
              <>
                <div className="absolute top-2 left-0 z-0">
                  <Lottie
                    speed={0.5}
                    loop={true}
                    play
                    animationData={confettiAnimation}
                    style={{ width: 240, height: 200 }}
                  />
                </div>
                <div className="z-10">
                  <button
                    type="button"
                    onClick={handleFinish}
                    className="inline-flex h-14 items-center rounded-md  px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
                  >
                    {buttonText == "next" ? (
                      <div>Check out Trackflow</div>
                    ) : buttonText == "loading" ? (
                      <div>
                        <DotsLoader color="#ffffff" />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {onboardingQuery.isInitialLoading && (
          <div className="mt-9 scale-150">
            <DotsLoader color="#22c55d" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingFinished;
