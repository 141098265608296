import React from "react";

import trackflowicon from "../../../img/trackflow-logo.svg";

const TipTapTasksTemplateCard = ({ template }) => {
  console.log("template", template);
  if (template.isBlank === true) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center rounded-lg border-2 p-10 text-gray-700 border-gray-600">
        Start from scratch...
      </div>
    );
  } else {
    return (
      <div className="group flex  h-fit cursor-pointer flex-col shadow-2xl">
        <div
          className="rounded-lg border-2 shadow-sm"
          style={{
            borderColor: template.color + "ee",
          }}
        >
          <div
            className=" relative h-[3rem] w-full  p-4"
            style={{
              backgroundColor: template.color + "ee",
            }}
          >
            <div className=" absolute inset-0  rounded-t-lg bg-gradient-to-tr from-black/10 to-transparent" />
            {template.author && (
              <div
                className="absolute -bottom-[1rem] z-10 h-[3rem] w-[3rem] rounded-full border-2 shadow-lg border-white bg-green-700 dark:border-white"
                style={{
                  backgroundImage: `url(${template.author.avatarSrc})`,
                  backgroundSize: "cover",
                }}
              />
            )}
          </div>
          <div className="flex flex-col rounded-b-md pt-4  bg-white group-hover:bg-gray-50">
            <div className=" px-6  pt-2 pb-1 text-2xl font-medium text-gray-800">
              {template.title}
            </div>

            <div className="px-6 pt-4 pb-2 text-gray-700">
              {template.description}
            </div>
            <div className="my-2 h-[1px] w-full bg-gray-200" />
            {template.author && (
              <div className=" mb-4 px-6 text-tiny font-medium text-gray-500 ">
                {template.author.name} <br />
                <span className="font-normal text-gray-400">
                  {template.author.companyName}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TipTapTasksTemplateCard;
