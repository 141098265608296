import { BoltIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useState, useMemo } from "react";
import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { classNames } from "../../shared/Utils";

import { fakeTemplates } from "./FakeTemplates";
import TipTapTasksTemplateCard from "./TipTapTasksTemplateCard";

const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
});

const TipTapTasksTemplateButton = () => {
  return (
    <>
      {" "}
      <PopoverPrimitive.Root
      // onOpenChange={(open) => openChange(open)}
      >
        <PopoverPrimitive.Trigger className="">
          <div
            className={classNames(
              "cursor-pointer opacity-100 hover:bg-white",
              "text-gray-700 border-gray-300 flex select-none flex-row rounded-md border px-2 py-1"
            )}
          >
            <div className="my-auto select-none pr-1 text-xs font-medium">
              templates
            </div>{" "}
            <BoltIcon className="my-auto h-4 w-4" />
          </div>{" "}
        </PopoverPrimitive.Trigger>

        <StyledContent className=" bg-white border-white rounded-md  border dark:border-zinc-600  dark:bg-zinc-950 ">
          {/* <PopoverPrimitive.Close> */}
          <div className="p-6">
            {" "}
            <div className=" grid w-full grid-cols-2 gap-6  text-sm">
              {fakeTemplates.map((template) => (
                <TipTapTasksTemplateCard template={template} />
              ))}
            </div>
          </div>
          {/* </PopoverPrimitive.Close> */}

          <PopoverPrimitive.Arrow
            width={11}
            height={5}
            offset={80}
            className="fill-gray-50 dark:fill-gray-600"
          />
        </StyledContent>
      </PopoverPrimitive.Root>
    </>
  );
};

export default TipTapTasksTemplateButton;
