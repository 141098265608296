import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";
import { serverUrl } from "./Api.js";

export default function useAllTracks() {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(['all-tracks'], () =>
    axiosPrivate.get(serverUrl + "/tracks/").then((res) => res.data));
}
