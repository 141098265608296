import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const { accessToken, setAccessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const authorizationParams = {
    audience: `https://api.trackflow.studio`,
    scope: "read:current_user",
    issuer: "https://auth.trackflow.studio",
  };

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const token =
          process.env.NODE_ENV === "development"
            ? await getAccessTokenWithPopup({ authorizationParams })
            : await getAccessTokenSilently({ authorizationParams });

        setAccessToken(token);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };
    if (getAccessTokenSilently && !accessToken && !isLoading) {
      initAuth0();
    }
  }, [getAccessTokenSilently, accessToken]);

  const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });

  const refreshToken = async () => {
    try {
      const token =
        process.env.NODE_ENV === "development"
          ? await getAccessTokenWithPopup({ authorizationParams })
          : await getAccessTokenSilently({ authorizationParams });

      setAccessToken(token);
    } catch (error) {
      console.error(error);
    }
  };

  axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const accessToken =
            process.env.NODE_ENV === "development"
              ? await getAccessTokenWithPopup({ authorizationParams })
              : await getAccessTokenSilently({ authorizationParams });

          setAccessToken(accessToken);

          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosPrivate(originalRequest);
        } catch (error) {
          console.error(error);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosPrivate;
};

export default useAxiosPrivate;
