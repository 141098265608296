export const fakeTemplates = [
  {
    id: 0,
    title: "Blank",
    isBlank: true,
    color: "#F9A826",
    description: "Start from scratch with a blank canvas. ",
  },
  {
    id: 1,
    title: "Music Release",

    color: "#F9A826",
    author: {
      name: "Rosie Foster",
      companyName: "100 management / Netsky",
      avatarSrc:
        "https://firebasestorage.googleapis.com/v0/b/trackflow-5762f.appspot.com/o/users%2F100mgmt%2Favatars%2Fnetsky.jpg?alt=media&token=574ae4de-6829-4a07-8d99-e5e127043c04",
    },
    description:
      "A guide through music production. This template features your music creation strategy, mastering schedule, pre-release marketing, release day marketing and post-release engagement strategies. ",
  },
  {
    id: 1,
    title: "Fan engagement",
    color: "#10b981",
    author: {
      name: "Luke Mitzman",
      companyName: "100 management / Netsky",
      avatarSrc:
        "https://firebasestorage.googleapis.com/v0/b/trackflow-5762f.appspot.com/o/users%2F100mgmt%2Favatars%2Fluke_200x200.png?alt=media",
    },
    description:
      "Plan your marketing strategy using this templates which focuses on scheduling social media posts, advertising campaigns and story posting to maximize fan engagement. You can use this template alongside Marketing Cloud to improve your conversions from listeners to fans. ",
  },
  {
    id: 2,
    title: "Release rollout",
    color: "#8b5cf6",
    author: {
      name: "Mikkel Eriksen",
      companyName: "Stargate",
      avatarSrc: "https://secondhandsongs.com/picture/107067",
    },
    description:
      "  This plan focuses on the execution of putting out music through streaming platforms, social media and email sendouts.",
  },
  {
    id: 3,
    title: "Release rollout 2",
    color: "#8b5cf6",
    author: {
      name: "Mikkel Eriksen",
      companyName: "Stargate",
      avatarSrc: "https://secondhandsongs.com/picture/107067",
    },
    description:
      "  This plan focuses on the execution of putting out music through streaming platforms, social media and email sendouts.",
  },
];
