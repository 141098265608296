import React, { Suspense } from "react";
import TipTapTasks from "../../TipTapTasks/TipTapTasks";
import TipTapTasksTemplateSwiper from "../../TipTapTasks/TipTapTasksTemplates/TipTapTasksTemplateSwiper";

const TrackListTrackOverlayTasks = ({ track, setUnfinishedTasksCount }) => {
  return (
    <div className="flex h-full flex-col border-gray-300 ">
      {/* <div className="">
        <TipTapTasksTemplateSwiper />
      </div> */}
      <TipTapTasks
        setUnfinishedTasksCount={setUnfinishedTasksCount}
        track={track}
      />
    </div>
  );
};

export default TrackListTrackOverlayTasks;
