/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";

import useCreatePlaylist from "../hooks/useCreatePlaylist";

import { Dialog, Transition } from "@headlessui/react";

import { forwardRef, useImperativeHandle } from "react";

import useAuth from "../hooks/useAuth";

import ShortUniqueId from "short-unique-id";

import DotsLoader from "../DotsLoader";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import MyMemo from "../MyMemo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NewPlaylistOverlay = forwardRef((props, ref) => {
  const createPlaylistQuery = useCreatePlaylist();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { auth } = useAuth();
  console.log("auth", auth);

  //   const isAdmin = auth?.roles.includes("admin");

  const [projectvalues, setProjectValues] = useState([]);

  const setValue = (field, value) => {
    console.log("Setting value", field, value);
    setProjectValues((old) => ({ ...old, [field]: value }));
  };

  console.log("projectvalues", projectvalues);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting", projectvalues);
    createPlaylistQuery.mutate(projectvalues);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    openDialog() {
      createPlaylistQuery.reset();

      handleOpen();
    },
    openDialogWithInitTracks({ trackIds }) {
      createPlaylistQuery.reset();

      setProjectValues({ trackIds });

      handleOpen();
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-500 dark:bg-zinc-700/50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="block  min-h-screen items-end  justify-center text-center ">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className=" inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block w-full  max-w-md transform  rounded-lg p-12 text-left  align-middle shadow-xl  transition-all bg-white sm:my-8">
                <div className=" flex w-full flex-col md:flex md:gap-4">
                  <div className="pb-6 text-xl font-medium text-gray-700">
                    {/* <UsersIcon className="text-gray-700 mr-2 inline h-7 w-7" /> */}
                    New playlist
                  </div>
                  {/* content */}

                  <main className="mx-auto w-full ">
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-6">
                        <div className="new-playlist-title">
                          <label
                            htmlFor="playlist-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Playlist name
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="playlist-name"
                              id="playlist-name"
                              className="block w-full rounded-md py-3 text-lg font-medium shadow-sm bg-white text-gray-800 border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              required
                              autoComplete="off"
                              onChange={(e) =>
                                setValue("title", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="flex justify-end pt-4">
                          <button
                            type="button"
                            onClick={() => {
                              handleClose();
                            }}
                            className="rounded-md border py-2 px-4 text-sm font-medium shadow-sm bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:focus:ring-offset-zinc-900"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                            className={
                              "ml-3 inline-flex justify-center rounded-md border py-2 px-4 text-sm font-medium shadow-sm border-transparent text-white bg-green-500 hover:bg-green-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:focus:ring-offset-zinc-900"
                            }
                          >
                            {createPlaylistQuery.isInitialLoading
                              ? "Saving..."
                              : createPlaylistQuery.isError
                              ? "Error..."
                              : createPlaylistQuery.isSuccess
                              ? "Saved!"
                              : "Create playlist"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </main>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default NewPlaylistOverlay;
