import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useState, useEffect } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import useLogout from "../components/hooks/useLogout";

import useAuth from "../components/hooks/useAuth";

const Callback = () => {
  const { auth, setAuth } = useAuth();
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
  } = useAuth0();
  const navigate = useNavigate();

  const logout = useLogout();

  console.log("callback isAuthenticated", isAuthenticated);
  console.log("callback user", user);

  console.log("callback auth", auth);

  useEffect(() => {
    if (isAuthenticated) {
      const getAuthData = async () => {
        console.log("callback getAuthData");
        const domain = "dev-8e7ztyu9.us.auth0.com";
        try {
          const authorizationParams = {
            audience: `https://api.trackflow.studio`,
            scope: "read:current_user",
            issuer: "https://auth.trackflow.studio",
          };

          const accessToken =
            process.env.NODE_ENV === "development"
              ? await getAccessTokenWithPopup({ authorizationParams })
              : await getAccessTokenSilently({ authorizationParams });

          console.log("callback accessToken", accessToken);
          console.log("callback user", user);

          const { email, name, picture } = user;
          const workspace = user.userInfo.workspaces[0].name;
          const roles = user.userInfo.workspaces[0].roles;

          const authData = {
            accessToken,
            email,
            name,
            picture,
            workspace,
            roles,
          };

          console.log("callback authData", authData);

          setAuth(authData);
          secureLocalStorage.setItem("auth", JSON.stringify(authData));

          useEffect(() => {
            if (auth.email) {
              console.log("callback redirect to projects");
              navigate("/projects");
            }
          }, [auth]);
        } catch (e) {
          console.log(e.message);
        }
      };

      if (!auth.email) {
        getAuthData();
      } else {
        console.log("callback redirect to projects");
        navigate("/projects");
      }
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      const errorDescription = searchParams.get("error_description");
      const error = searchParams.get("error");

      if (error) {
        window.alert(errorDescription);
        navigate("/logout");
      }
    }
  }, [isAuthenticated, auth]);
};

export default Callback;
