import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyAGMXn1bT5PgpOQeKuaucyQyEoLnrPZnCQ",
  authDomain: "trackflow-5762f.firebaseapp.com",
  projectId: "trackflow-5762f",
  storageBucket: "trackflow-5762f.appspot.com",
  messagingSenderId: "482128047477",
  appId: "1:482128047477:web:da656a6efd0fb19141773c",
  measurementId: "G-ZF6Q6TTGH8",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const perf = getPerformance(app);
