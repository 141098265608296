import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Flipper, Flipped } from "react-flip-toolkit";
import { useMediaQuery } from "react-responsive";
import { fakeTracks } from "./FakeTracks";
import "./ListeningRoom.css";
import ListeningRoomCard from "./ListeningRoomCard";
import ListeningRoomListeners from "./ListeningRoomListeners";

import useWindowDimensions from "../hooks/useWindowDimensions";
import IsLoaded from "../../IsLoaded";
import { Fade } from "react-awesome-reveal";

const ListeningRoomRoot = () => {
  const [fullScreen, setFullScreen] = useState("");
  const toggleFullScreen = (trackId) => {
    if (fullScreen === trackId) {
      setFullScreen("");
      setShowReactionSelector(false);
    } else {
      setFullScreen(trackId);
    }
  };

  const tracks = fakeTracks;

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const [showReactionSelector, setShowReactionSelector] = useState(false);
  const [color, setColor] = useState("#ef4444");
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <IsLoaded />
      <Flipper applyTransformOrigin={true} flipKey={fullScreen}>
        <div className="flex flex-col px-6 py-8 md:px-6">
          <div className="text-gray-600 mx-auto pb-12 pt-2 text-4xl">
            Project title
          </div>
          <Fade>
            <div className="flex h-full w-full flex-wrap justify-evenly gap-6 md:gap-10">
              {tracks.map((track, index) => (
                <>
                  {fullScreen === track.trackId && (
                    <div
                      className={
                        "square h-[9.4rem] w-[9.4rem]  md:h-[14rem] md:w-[14rem]"
                      }
                    />
                  )}
                  <Flipped flipId={track.trackId} key={track.trackId}>
                    <div
                      className={
                        fullScreen === track.trackId
                          ? "full-screen-square"
                          : "square h-[9.4rem] w-[9.4rem]  md:h-[14rem] md:w-[14rem]"
                      }
                      style={{
                        zIndex: fullScreen === track.trackId ? 60 : "auto",
                      }}
                      onClick={() => {
                        setColor(track.color);
                        toggleFullScreen(track.trackId);
                      }}
                    >
                      <ListeningRoomCard
                        track={track}
                        isMobile={isMobile}
                        fullScreen={fullScreen === track.trackId}
                        setShowReactionSelector={setShowReactionSelector}
                      />
                    </div>
                  </Flipped>
                </>
              ))}
            </div>
          </Fade>
        </div>
      </Flipper>
      <div
        className="fixed bottom-12 z-[70] w-full"
        // style={{
        //   width: width,
        // }}
      >
        <ListeningRoomListeners
          viewPortWidth={width}
          color={color}
          showReactionSelector={showReactionSelector}
        />
      </div>
    </div>
  );
};

export default ListeningRoomRoot;
