import React, { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { forwardRef, useImperativeHandle } from "react";

import { classNames } from "../../shared/Utils";
import { fakeTemplates } from "./FakeTemplates";
import TipTapTasksTemplateCard from "./TipTapTasksTemplateCard";

const TipTapTasksTemplatesOverlay = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    openDialog(track) {
      handleOpen();
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 backdrop-blur transition-opacity bg-gray-800 dark:bg-zinc-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 z-10  ">
          <div className="flex h-screen items-end justify-center  px-6 text-center sm:block">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "sm:my-8 sm:h-[calc(100vh-60px)] sm:w-full sm:max-w-[60rem]",
                  "  relative inline-block  w-full transform overflow-hidden  rounded-xl text-left     align-bottom transition-all  border-gray-300  sm:align-middle"
                )}
              >
                <div className="flex h-full flex-col ">
                  <div className="p-6">
                    {" "}
                    <div className=" grid w-full grid-cols-2 gap-12  text-sm">
                      {fakeTemplates.map((template) => (
                        <TipTapTasksTemplateCard
                          key={template.id}
                          template={template}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default TipTapTasksTemplatesOverlay;
