import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackArtists() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({ artists, collaborators, projectid, trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          artists: artists,
          collaborators: collaborators,
        })
        .then((res) => res.data),
    {
      onMutate: async (newTrackArtists) => {
        await queryClient.cancelQueries(["track", newTrackArtists.trackid]);
        const previousTrack = queryClient.getQueryData([
          "track",
          newTrackArtists.trackid,
        ]);
        queryClient.setQueryData(["track", newTrackArtists.trackid], {
          ...previousTrack,
          artists: newTrackArtists.artists,
          collaborators: newTrackArtists.collaborators,
        });
        return { previousTrack };
      },
      onError: (err, newTrackArtists, context) => {
        queryClient.setQueryData(
          ["track", newTrackArtists.trackid],
          context.previousTrack
        );
      },
    }
  );
}
