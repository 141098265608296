import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";
import { toast } from "react-toastify";

export default function useUpdateWorkspace() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ workspaceid, workspace }) =>
      axiosPrivate
        .put(serverUrl + "/workspaces/" + workspaceid, workspace)
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "workspace",
          variables.workspaceid.toString(),
        ]);
        toast.success("Workspace settings updated.", { containerId: "main" });
      },
    }
  );
}
