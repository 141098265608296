import React from "react";
import { styled, keyframes } from "@stitches/react";

import { Menu, Transition } from "@headlessui/react";
import { classNames } from "./shared/Utils";

import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import SpotifyAvatar from "./SpotifyAvatar";

import ArtistHoverCardContent from "./ArtistHoverCardContent";

import {
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  StarIcon,
  ChevronDownIcon,
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import ArtistAvatar from "./ArtistAvatar";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(HoverCardPrimitive.Content, {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  borderRadius: 6,
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(HoverCardPrimitive.Arrow, {
  fill: "white",
});

function Content({ children, ...props }) {
  return (
    <HoverCardPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow className="fill-white dark:fill-zinc-600" />
      </StyledContent>
    </HoverCardPrimitive.Portal>
  );
}

// Exports
export const HoverCard = HoverCardPrimitive.Root;
export const HoverCardTrigger = HoverCardPrimitive.Trigger;
export const HoverCardContent = Content;

export default class ArtistHoverCard extends React.PureComponent {
  render() {
    let hoverCardOpen = false;
    let expanded = false;

    const artistTypes = [
      {
        id: "artist",
        name: "Main artist",
        icon: StarIcon,
      },
      {
        id: "writer",
        name: "Writer",
        icon: PencilIcon,
      },
      {
        id: "producer",
        name: "Producer",
        icon: AdjustmentsHorizontalIcon,
      },
      {
        id: "remixer",
        name: "Remixer",
        icon: AdjustmentsVerticalIcon,
      },
      {
        id: "other",
        name: "Other",
        icon: EllipsisHorizontalIcon,
      },
    ];
    const tagType = this.props.tags.find(
      (t) => t.id === this.props.tag.id
    )?.type;

    return (
      <HoverCard
        onOpenChange={(open) => (hoverCardOpen = open)}
        openDelay="300"
        closeDelay="300"
      >
        <HoverCardTrigger
          asChild
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            expanded = true;
          }}
        >
          {this.props.children}
        </HoverCardTrigger>
        <HoverCardContent sideOffset={5} className="z-50 flex flex-col gap-3">
          <div className="z-20 flex flex-col rounded-md border p-4 bg-white border-white dark:bg-zinc-950 dark:border-zinc-600">
            <div className="flex flex-row">
              <div className="flex h-16 w-16">
                <ArtistAvatar artistId={this.props.artistId} />
              </div>
              <div className="ml-4  flex  flex-col gap-2">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.navigateToArtist();
                  }}
                  className="cursor-pointer pl-2 text-lg text-gray-700 hover:underline"
                >
                  {this.props.artistName}
                </div>
                <Menu
                  as="div"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="relative  text-left"
                >
                  {({ open }) => {
                    // setMenuOpen(open);
                    const Icon = artistTypes.find(
                      (t) => t.id === tagType
                    )?.icon;
                    return (
                      <>
                        <Menu.Button className="inline-flex w-full justify-start   text-sm font-medium  text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white">
                          <div
                            className={classNames(
                              open ? "bg-gray-100" : "",
                              " my-auto  flex flex-row flex-nowrap rounded-md border px-1.5 py-1 border-transparent hover:bg-gray-100 hover:border-gray-200"
                            )}
                            // onClick={() => handleChangeTagType(tag.id)}
                          >
                            {artistTypes.find((t) => t.id === tagType) && (
                              <Icon className="my-auto h-4 w-4 text-gray-700" />
                            )}
                            <div className="my-auto whitespace-nowrap px-2 text-gray-700">
                              {
                                artistTypes.find(
                                  (t) => t.id === this.props.tag.type
                                )?.name
                              }
                            </div>
                            <ChevronUpDownIcon className="my-auto h-4 w-4 text-gray-700 " />
                          </div>
                        </Menu.Button>

                        <Menu.Items
                          as="div"
                          className="absolute  right-0  z-50 mt-2  w-36 origin-top-right divide-y overflow-hidden rounded-md shadow-lg ring-1 ring-opacity-5 ring-black bg-white border-gray-200 divide-gray-100 focus:outline-none dark:border"
                        >
                          {artistTypes.map((type) => (
                            <Menu.Item key={type.id}>
                              {({ active }) => (
                                <>
                                  <div
                                    key={type.id}
                                    className={classNames(
                                      active ? "bg-gray-100" : "bg-white",
                                      tagType === type.id
                                        ? "bg-gray-100"
                                        : " hover:bg-gray-100",
                                      "flex w-36 cursor-pointer flex-row items-center justify-between px-3 py-2 text-xs text-gray-700"
                                    )}
                                    onClick={() =>
                                      this.props.handleChangeTagType(
                                        this.props.tag.id,
                                        type.id
                                      )
                                    }
                                  >
                                    <div className="flex w-32 flex-grow flex-row">
                                      <type.icon className="mr-2 mt-0.5 h-4 w-4" />
                                      {type.name}
                                    </div>
                                    {tagType === type.id && (
                                      <div>
                                        <CheckIcon className="h-4 w-4" />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            <div
                              className={classNames(
                                // active ? "bg-gray-100" : "bg-white",

                                " flex w-36 cursor-pointer flex-row items-center px-3  py-2 text-xs text-red-600  hover:bg-gray-100"
                              )}
                              onClick={() =>
                                this.props.handleRemoveTag(this.props.tag.id)
                              }
                            >
                              <XMarkIcon className="mr-2 h-4 w-4" />
                              Remove
                            </div>
                          </Menu.Item>
                        </Menu.Items>
                      </>
                    );
                  }}
                </Menu>
              </div>
            </div>
          </div>
        </HoverCardContent>
      </HoverCard>
    );
  }
}
