import React, { useEffect, useRef, useState, useMemo } from "react";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import WaveSurfer from "wavesurfer.js";
import { motion } from "framer-motion";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { classNames } from "./shared/Utils";

export default function ListeningRoomPlayer({
  url,
  height,
  setStartedPlaying,
}) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);

  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "rgba(255, 255, 255, 0.8)",
    progressColor: "rgba(255, 255, 255, 1)",
    cursorColor: "rgba(255, 255, 255, 1)",
    // waveColor: "#ccc",
    // progressColor: "#eee",
    // cursorColor: "#ddd",
    barWidth: 4,
    barRadius: 6,
    responsive: true,
    height: height || 50,
    fillParent: true,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
  });

  // create new WaveSurfer instance
  // On component mount and when url changes

  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("loading", (data) => {
      setLoadingPercentage(data);
    });
    wavesurfer.current.on("finish", () => {
      setPlay(false);
    });

    wavesurfer.current.on("ready", function () {
      setLoadingAnimation(false);
      setPlay(true);
      setStartedPlaying(true);
      wavesurfer.current.play();

      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  // useEffect(() => {
  //   // re draw

  //   wavesurfer.current.drawBuffer();
  // }, [height]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const handlePlay = () => {
    setPlay(true);
    wavesurfer.current.play();
  };

  const variantsPlay = {
    loaded: { opacity: 1, delay: 1 },
    loading: { opacity: 0 },
  };
  const variantsProgress = {
    loaded: { opacity: 0 },
    loading: { opacity: 1 },
  };
  const variantsWaveForm = {
    loaded: { opacity: 1 },
    loading: { opacity: 0 },
  };

  return (
    <div className="flex py-3 align-middle ">
      <div className="mr-4 flex " style={{ width: height }}>
        <div className="controls  z-50 my-auto mx-auto w-full flex-none">
          {!playing ? (
            <motion.div
              variants={variantsPlay}
              animate={loadingAnimation ? "loading" : "loaded"}
            >
              <PlayCircleIcon
                className=" nightwind-prevent text-white  cursor-pointer hover:text-white/80"
                onClick={handlePlayPause}
                aria-hidden="true"
              />
            </motion.div>
          ) : (
            <PauseCircleIcon
              className=" hover:white/80 nightwind-prevent text-white  cursor-pointer"
              onClick={handlePlayPause}
              aria-hidden="true"
            />
          )}
        </div>
        <div className="absolute left-[calc(50%-50px)]">
          <motion.div
            variants={variantsProgress}
            animate={loadingAnimation ? "loading" : "loaded"}
            style={{
              width: height,
              height: height,
            }}
          >
            <CircularProgressbar
              value={loadingPercentage}
              // text={`${loadingPercentage}%`}

              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: "#eee",
                textColor: "#eee",
                trailColor: `rgba(0, 0, 0, 0)`,
                backgroundColor: "#3e98c7",
              })}
            />
          </motion.div>
        </div>
      </div>

      <motion.div
        variants={variantsWaveForm}
        animate={loadingAnimation ? "loading" : "loaded"}
        className={classNames(
          loadingAnimation ? "" : "",
          "w-full overflow-hidden transition-all duration-500"
        )}
      >
        <div
          id="waveform"
          style={{
            maxHeight: height || "50px",
          }}
          className="z-0  w-full cursor-pointer overflow-hidden"
          onClick={handlePlay}
          ref={waveformRef}
        />
      </motion.div>
    </div>
  );
}
