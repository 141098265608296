import React, { useEffect, useState } from "react";
import Avatar from "boring-avatars";

import { Fade } from "react-awesome-reveal";

import { createAvatar } from "@dicebear/avatars";
import UserAvatar from "react-user-avatar";
import * as style from "@dicebear/avatars-avataaars-sprites";

import {
  FaceSmileIcon,
  FireIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  PlusIcon,
  RocketLaunchIcon,
  ShareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import { LinkIcon } from "@heroicons/react/20/solid";

import { useMediaQuery } from "react-responsive";
import ListeningRoomReactionSelector from "./ListeningRoomReactionSelector";
import { classNames } from "../shared/Utils";
import useAllUsers from "../hooks/useAllUsers";
import useAuth from "../hooks/useAuth";

const availableReactions = [
  {
    label: "Rocket",
    node: (
      <div>
        <RocketLaunchIcon className=" h-12 w-12" />
      </div>
    ),
    key: "rocket",
  },
  {
    label: "Smile",
    node: (
      <div>
        <FaceSmileIcon className=" h-12 w-12" />
      </div>
    ),
    key: "smile",
  },
  {
    label: "Love",
    node: (
      <div>
        <HeartIcon className=" h-12 w-12" />
      </div>
    ),
    key: "love",
  },
  {
    label: "Like",
    node: (
      <div>
        <HandThumbUpIcon className=" h-12 w-12" />
      </div>
    ),
    key: "like",
  },
  {
    label: "Dislike",
    node: (
      <div>
        <HandThumbDownIcon className=" h-12 w-12" />
      </div>
    ),
    key: "dislike",
  },
];

const ListeningRoomListeners = ({
  color,
  viewPortWidth,
  showReactionSelector,
  admin,
}) => {
  const avatarColors = ["#ef4444", "#eab308", "#22c55e", "#0ea5e9"];

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const allUsersQuery = useAllUsers();

  const [listeners, setListeners] = useState([]);
  const listenersAdmin = admin
    ? [...listeners, { addListener: true }, { inviteLink: true }]
    : listeners;

  // let listeners = [
  //   { listenerName: "Lilian", reaction: "love" },
  //   { listenerName: "Georgie", reaction: "rocket" },
  //   { listenerName: "Boris", reaction: "rocket", me: true },
  //   { listenerName: "Rosie", reaction: "rocket" },
  //   { listenerName: "Luke", reaction: "rocket" },
  // ];

  const { auth } = useAuth();

  useEffect(() => {
    if (allUsersQuery.isSuccess) {
      const listeners = allUsersQuery.data.map((user) => {
        return {
          listenerName: user.name.split(" ")[0],
          reaction: "none",
          me: user._id === auth.userid,
        };
      });
      setListeners(listeners);
    }
  }, [allUsersQuery.isSuccess]);

  const iconYConst = 36;

  const circleWidth =
    viewPortWidth - 300 < listenersAdmin.length * 78
      ? isMobile
        ? viewPortWidth - 120
        : viewPortWidth - 300
      : listenersAdmin.length * 78;

  const iconSize = isMobile ? (listeners.length > 4 ? 50 : 60) : 60;

  return (
    <div
      className="mx-auto -mb-[150px] flex transition-all"
      style={{
        width: isMobile ? circleWidth + 100 : circleWidth + 140,
        height: 200,
      }}
    >
      <div
        className="mx-auto flex border shadow transition-all bg-white border-gray-200  dark:bg-zinc-950  dark:shadow-zinc-500 "
        style={{
          width: isMobile ? circleWidth + 100 : circleWidth + 140,
          height: 200,
          borderRadius: "50%",
        }}
      >
        <div
          className="relative mx-auto"
          style={{
            width: circleWidth,
          }}
        >
          {listenersAdmin.map((listener, i) => {
            const left =
              (circleWidth - 40) * (i / (listenersAdmin.length - 1)) + 20;
            const top =
              Math.sin((i / (listenersAdmin.length - 1) - 1) * Math.PI) *
              iconYConst;

            if (!listener.addListener && !listener.inviteLink) {
              return (
                <div
                  className="absolute transition-all"
                  style={{
                    left: left,
                    top: top - iconSize + 60,
                    marginLeft: "-" + iconSize / 2 + "px",
                  }}
                  key={i}
                >
                  {" "}
                  <Fade direction="up" delay={i * 50} duration="200">
                    <div
                      style={{
                        width: iconSize,
                        height: iconSize,
                      }}
                      className={classNames(
                        showReactionSelector && listener.me
                          ? "bottom-3  shadow-xl "
                          : "",
                        admin ? "cursor-pointer" : "",
                        "avatar-parent relative bottom-0 rounded-full border-2 text-center   text-xs shadow-lg transition-all text-black  bg-white border-white  dark:border-white "
                      )}
                    >
                      {admin && (
                        <div
                          className="absolute rounded-full bg-black/20 p-3 opacity-0 backdrop-blur text-white hover:opacity-100 dark:bg-black/40   dark:text-white"
                          style={{
                            width: iconSize - 4,
                            height: iconSize - 4,
                          }}
                        >
                          <LinkIcon />
                        </div>
                      )}
                      <Avatar
                        title={true}
                        size={iconSize - 4}
                        name={listener.listenerName}
                        variant="beam"
                        colors={avatarColors}
                      />

                      <div
                        className={classNames(
                          isMobile && listeners.length > 4
                            ? "text-xs"
                            : "text-sm ",
                          "py-2 font-medium text-gray-600"
                        )}
                      >
                        {listener.listenerName}
                      </div>

                      {listener.me && showReactionSelector && (
                        <Fade direction="up" delay="30" duration="200">
                          <ListeningRoomReactionSelector
                            color={color}
                            iconSize={38}
                          />
                        </Fade>
                      )}

                      {/* <div
                    className="bg-white absolute  rounded-full  p-1"
                    style={{
                      color: color,
                      left: iconSize / 1.8,
                      top: iconSize / 1.8,
                    }}
                  >
                    <RocketLaunchIcon className=" h-5 w-5" />
                  </div> */}
                    </div>
                  </Fade>
                </div>
              );
            } else if (listener.addListener) {
              return (
                <div
                  className="absolute"
                  style={{
                    left: left,
                    top: top - iconSize + 60,
                    marginLeft: "-" + iconSize / 2 + "px",
                  }}
                  key="addIcon"
                >
                  {" "}
                  <Fade direction="up" delay={i * 50} duration="200">
                    <div
                      style={{
                        width: iconSize,
                        height: iconSize,
                      }}
                      className="relative  rounded-full border-2 text-center text-xs shadow-lg bg-white text-gray-600  border-gray-400  dark:bg-zinc-950 "
                    >
                      <div
                        style={{
                          width: iconSize - 4,
                          height: iconSize - 4,
                        }}
                        className="p-3"
                      >
                        <PlusIcon />
                      </div>

                      <div className="mt-2">Add</div>
                    </div>{" "}
                  </Fade>
                </div>
              );
            } else if (listener.inviteLink) {
              return (
                <div
                  className="absolute"
                  style={{
                    left: left,
                    top: top - iconSize + 60,
                    marginLeft: "-" + iconSize / 2 + "px",
                  }}
                  key="shareIcon"
                >
                  {" "}
                  <Fade direction="up" delay={i * 50} duration="200">
                    <div
                      style={{
                        width: iconSize,
                        height: iconSize,
                      }}
                      className="relative  rounded-full border-2 text-center text-xs shadow-lg bg-white text-gray-600  border-gray-400  dark:bg-zinc-950 "
                    >
                      <div
                        style={{
                          width: iconSize - 4,
                          height: iconSize - 4,
                        }}
                        className="p-3"
                      >
                        <LinkIcon />
                      </div>

                      <div className="mt-2">Invite</div>
                    </div>{" "}
                  </Fade>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ListeningRoomListeners;
