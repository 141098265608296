import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { ArrowsUpDownIcon, FlagIcon } from "@heroicons/react/24/outline";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import React from "react";
import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";

import ColorPicker from "../../ColorPicker";
import Prioritypicker from "../../PriorityPicker";
import { classNames } from "../../shared/Utils";
import UserPicker from "../../UserPicker";

export default (props) => {
  const currentTaskId = props.node.attrs.id;

  // check if the task has nested tasks
  const hasNestedTasks = props.node.content.content.reduce((acc, curr) => {
    if (curr.type.name === "taskList") {
      return true;
    }
    return acc;
  }, false);

  // check if task has nested tasks but isn't a nested task itself
  const isNestedTask = props.node.content.content.reduce((acc, curr) => {
    if (curr.type.name === "taskList") {
      return false;
    }
    return acc;
  }, true);

  console.log("hasNestedTasks", hasNestedTasks);

  const onCheckClick = () => {
    props.updateAttributes({
      checked: !props.node.attrs.checked,
    });
  };

  const setColor = (color) => {
    props.updateAttributes({
      color: color.color,
    });

    // set color of nested tasks
    props.node.content.content.forEach((node) => {
      if (node.type.name === "taskList") {
        console.log("taskListnode", node.content.content);
        node.content.content.forEach((task) => {
          task.attrs.color = color.color;
        });
      }
    });
    props.editor.chain().focus().run();
  };

  // array of nested tasks with their id and checked value
  const nestedTasks = props.node.content.content.reduce((acc, curr) => {
    if (curr.type.name === "taskList") {
      curr.content.forEach((task) => {
        acc.push({
          id: task.attrs.id,
          checked: task.attrs.checked,
        });
      });
    }

    return acc;
  }, []);

  console.log("nestedTasks", nestedTasks);

  const setAssignedTo = (assignedTo) => {
    props.updateAttributes({
      assignedTo,
    });
    props.editor.chain().focus().run();
  };

  const setPriority = (priority) => {
    props.updateAttributes({
      priority: priority,
    });
    props.editor.chain().focus().run();
  };

  const [open, setOpen] = React.useState(false);

  const [collapsed, setCollapsed] = React.useState(false);

  const docJSON = props.editor.getJSON();

  const color = props.node.attrs.color;

  console.log("props.node", props);
  return (
    <NodeViewWrapper
      // as="p"
      className={classNames(
        collapsed ? "h-[3.5rem] overflow-hidden" : "",
        "react-component-with-content  group peer relative flex w-full  flex-row rounded-md transition-all border-gray-200    "
      )}
      data-task-id={props.node.attrs.id}
    >
      {/* <div
        data-drag-handle
        className="block cursor-pointer"
        contentEditable={false}
      >
        drag
      </div> */}
      <div
        id={"task-" + props.node.attrs.id}
        className={classNames(
          hasNestedTasks ? "pt-[0.6rem]" : "pt-[0.14rem]",
          "pl-2 "
        )}
        contentEditable={false}
      >
        <input
          className="inp-cbx cursor-pointer"
          readOnly
          id={props.node.attrs.id}
          type="checkbox"
          value={props.node.attrs.checked}
          checked={props.node.attrs.checked}
          onChange={() => {
            onCheckClick();
          }}
          style={{ display: "none" }}
          contentEditable={false}
        />
        <label
          contentEditable={false}
          className="cbx mt-1.5 flex cursor-pointer flex-row"
          htmlFor={props.node.attrs.id}
        >
          <span
            contentEditable={false}
            style={{
              backgroundColor: props.node.attrs.checked
                ? color
                  ? color
                  : "#6b7280"
                : "",
              borderColor: color ? color : "#6b7280",
            }}
          >
            <svg
              contentEditable={false}
              width="12px"
              height="9px"
              viewBox="0 0 12 9"
            >
              <polyline
                contentEditable={false}
                points="1 5 4 8 11 1"
              ></polyline>
            </svg>
          </span>
          <span contentEditable={false} className="w-[0rem]"></span>
        </label>
      </div>
      <div
        className={classNames(
          hasNestedTasks ? "" : "",
          "peer  flex w-full flex-grow flex-row justify-between "
        )}
      >
        <NodeViewContent
          className={classNames(
            hasNestedTasks ? "task-has-nested-tasks " : "",
            " my-auto w-full"
          )}
          data-header={hasNestedTasks ? "true" : "false"}
          data-header-color={hasNestedTasks ? color : ""}
          // style={{
          //   color: hasNestedTasks ? color : "",
          // }}

          style={{
            "--header-color": hasNestedTasks ? color : "",
          }}
        />
        {hasNestedTasks && (
          <div
            className="absolute right-0 top-[0.65rem] z-20  flex h-8 w-8 cursor-pointer rounded-full p-1 hover:bg-gray-100"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            contentEditable={false}
          >
            <ChevronUpIcon
              style={{ color: color }}
              className={classNames(
                collapsed ? "rotate-180" : "",
                " my-auto mx-auto h-6 w-6 transition-all text-gray-400"
              )}
              contentEditable={false}
            />
          </div>
        )}
        {hasNestedTasks && collapsed && (
          <div
            className="absolute right-9 top-[0.65rem] z-20 flex     rounded-md p-1 py-1 px-2 text-base font-medium text-gray-500 bg-gray-100 hover:bg-gray-100"
            contentEditable={false}
          >
            {nestedTasks.filter((task) => task.checked).length} /{" "}
            {nestedTasks.length}
          </div>
        )}
        <div
          contentEditable={false}
          className={classNames(
            hasNestedTasks
              ? collapsed
                ? "top-[0.6rem] right-[5.6rem]"
                : "top-[0.6rem] right-[2.6rem]"
              : "hidden",
            open ? "" : "",
            " absolute z-50  flex flex-row   gap-2 transition-all hover:opacity-100 group-hover:opacity-100"
          )}
        >
          <div className="-mt-[0.1rem]" contentEditable={false}>
            <ColorPicker
              setOpen={setOpen}
              open={open}
              setColor={setColor}
              color={props.node.attrs.color || "#ccc"}
              contentEditable={false}
            />
          </div>
        </div>
        <div
          contentEditable={false}
          className={classNames(
            hasNestedTasks
              ? collapsed
                ? "top-[0.46rem] right-[7.6rem] "
                : "top-[0.46rem] right-[4.6rem]"
              : "top-0.5 right-2 ",
            open ? "" : "",
            props.node.attrs.assignedTo?.length > 0 ||
              props.node.attrs.priority != "Low"
              ? ""
              : "opacity-0",
            " absolute z-50 flex flex-row gap-2  transition-all group-hover:opacity-100"
          )}
        >
          <div className="-mt-[0.1rem]" contentEditable={false}>
            <Prioritypicker
              selectedPriority={props.node.attrs.priority}
              setSelectedPriority={setPriority}
              setExternalOpen={setOpen}
              contentEditable="false"
            />
          </div>
        </div>
        <div
          contentEditable={false}
          className={classNames(
            hasNestedTasks
              ? collapsed
                ? "top-[0.85rem] right-[9.7rem] "
                : "top-[0.85rem] right-[6.7rem] "
              : "top-[0.51rem] right-[2.6rem]",
            open ? "" : "",
            props.node.attrs.assignedTo?.length > 0 ? "" : "opacity-0",
            " absolute  z-50 flex flex-row gap-2  transition-all group-hover:opacity-100"
          )}
        >
          <div className="-mt-[0.1rem]" contentEditable={false}>
            <UserPicker
              selectedUsers={props.node.attrs.assignedTo}
              setSelectedUsers={setAssignedTo}
              setExternalOpen={setOpen}
              contentEditable="false"
            />
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
