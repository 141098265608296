import React, { useEffect } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import axios from "axios";
import qs from "qs";

export const getAuth = async () => {
  const clientId = "c307bb17266f4bad89de9502fcb4bef8";
  const clientSecret = "bfe89f6570d84cd5ba2192ec87facf8b";

  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: clientId,
      password: clientSecret,
    },
  };
  const data = {
    grant_type: "client_credentials",
  };

  try {
    const response = await axios.post(
      "https://accounts.spotify.com/api/token",
      qs.stringify(data),
      headers
    );

    return response.data.access_token;
  } catch (error) {
    console.log("spotify error", error);
  }
};

const GetSpotifyAvatars = async ({ artists, setImages }) => {
  var spotifyApi = new SpotifyWebApi();
  useEffect(() => {
    if (artists) {
      try {
        getAuth().then((token) => {
          spotifyApi.setAccessToken(token);
          const urls = artists.map((artist) => {
            return new Promise((resolve, reject) => {
              spotifyApi.searchArtists(artist.name).then(function (data) {
                resolve(data.artists.items[0].images[0].url);
              });
            });
          });
          Promise.all(urls).then((urls) => {
            setImages(urls);
          });
        });
      } catch (error) {
        console.log("spotify error", error);
      }
    }
  }, [artists]);
};

export default GetSpotifyAvatars;
