import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import React from "react";

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <label
      className=" z-10 my-auto flex cursor-pointer 
    "
    >
      {/* p-[7px] pl-[10px] */}
      <input
        type="checkbox"
        className="h-4 w-4 cursor-pointer ring-0 bg-white border-gray-300 text-blue-600 checked:bg-gray-400 checked:border-gray-400 indeterminate:bg-gray-300 checked:hover:bg-gray-300 hover:indeterminate:bg-gray-400 focus:ring-0 checked:focus:ring-0 focus:checked:bg-gray-400 focus:checked:hover:bg-gray-300 group-hover:border-gray-400 dark:ring-offset-zinc-800 dark:bg-zinc-900 dark:border-zinc-700 dark:focus:ring-0 dark:group-hover:border-zinc-500"
        ref={ref}
        {...rest}
      />
    </label>
  );
}

export const PageButtons = ({ table }) => {
  if (table?.getPageCount() > 0) {
    return (
      <div className=" flex w-full justify-end">
        <div className="flex  items-center gap-2 px-3 pt-2 pb-3">
          <span className="flex items-center gap-1 pr-2 text-gray-400">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            className="rounded border p-1 border-gray-300"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" />
          </button>
          <button
            className="rounded border p-1 border-gray-300"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
          </button>
          <button
            className="rounded border p-1 border-gray-300"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
          </button>
          <button
            className="rounded border p-1 border-gray-300"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" />
          </button>

          {/* <span className="flex items-center gap-1">
        | Go to page:
        <input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          className="w-16 rounded border p-1"
        />
      </span>
      <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[20, 50, 100, 200].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
        </div>
      </div>
    );
  }
};
