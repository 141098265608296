import React from "react";
import LoaderAnimation from "./LoaderAnimation";
import Lottie from "react-lottie-player";

import wipAnimation from "../animations/wip.json";
import wipAnimationDark from "../animations/wip-dark.json";

const WorkingOnThis = () => {
  return (
    <div className="text-gray-600 flex w-full flex-col p-2 text-lg">
      <div className="my-auto mx-auto -mt-12 block dark:hidden">
        <Lottie
          speed={1}
          loop={true}
          animationData={wipAnimation}
          style={{ width: 400, height: 340 }}
          play
        />
      </div>
      <div className="my-auto mx-auto -mt-12 hidden dark:block">
        <Lottie
          speed={1}
          loop={true}
          animationData={wipAnimationDark}
          style={{ width: 400, height: 340 }}
          play
        />
      </div>
      <div className="my-auto mx-auto -mt-4 p-1 font-medium">
        Still working on this function..
      </div>
      <div className="my-auto mx-auto -mt-2 p-1 text-base">
        Please check again soon.
      </div>
    </div>
  );
};

export default WorkingOnThis;
