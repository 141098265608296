import React from "react";
import { formatDistanceStrict } from "date-fns";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/solid";

import Avatar from "../../Avatar";

import { classNames } from "../../shared/Utils";

const TrackListTrackOverlaySidebarHistoryReply = ({
  activityItem,
  reply,
  replyIdx,
}) => {
  console.log("[reply] ", reply);
  return (
    <div className="group relative flex  h-full  ">
      <div
        className={classNames(
          replyIdx == activityItem.replies.length - 1 ? "h-10" : "h-full ",
          " bg-gray-200  absolute my-auto ml-[31px] w-[2px] flex-shrink"
        )}
      />
      <div className="bg-gray-200 ml-8 mt-[2.4rem] h-[2px] w-4 flex-shrink-0" />
      <div className="relative mt-6 mr-3 flex h-8 w-8 flex-shrink-0">
        <Avatar size="32" userid={reply.by} />
      </div>
      <div className=" chat-bubble relative   z-10 mt-4 ml-1 mr-8  ">
        <div className="absolute -right-8 -z-0 flex h-full opacity-0 group-hover:opacity-100 ">
          <div className="my-auto">
            <PencilIcon className="text-gray-600 bg-gray-50 border-gray-300 -right-8 top-[0.4rem] h-8 w-8 cursor-pointer rounded-tr-lg  border  p-1.5 hover:bg-gray-100" />
            <XMarkIcon className="text-gray-600 bg-gray-50 border-gray-300 -right-8 top-[2.4rem] h-8 w-8 cursor-pointer rounded-br-lg border-x  border-b  p-1.5 hover:bg-gray-100" />
          </div>{" "}
        </div>
        <div className="flex items-center  ">
          <div className="relative  ">
            <div className=" text-gray-700 px-3 pt-3  pb-2">
              <p>{reply.message}</p>
            </div>
            <div className="text-gray-400 float-right flex flex-row gap-3 px-3 pb-2 text-xs">
              <div>
                {formatDistanceStrict(new Date(reply.date), new Date(), {
                  addSuffix: true,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackListTrackOverlaySidebarHistoryReply;
