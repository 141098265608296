import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import secureLocalStorage from "react-secure-storage";
import axios from "./Api";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const queryClient = useQueryClient();

  const { logout } = useAuth0();

  const logoutFn = async () => {

    try {
      const response = await axios("/logout/", {
        withCredentials: true,
      });

      setAuth({});
      secureLocalStorage.clear();
      queryClient.clear();
      $crisp.push(["do", "session:reset"])

      logout({ logoutParams: { returnTo: process.env.NODE_ENV === "development" ? "http://localhost:3000/login" : "https://app.trackflow.studio/login" } })
  
    } catch (err) {
      console.error(err);
    }
  };

  
  return logoutFn;
};

export default useLogout;
