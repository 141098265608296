import "./TipTapTasksMentionList.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useAllUsers from "../hooks/useAllUsers";

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [allUsers, setAllUsers] = useState(["loading"]);

  console.log("ALL PROPS", props.query);
  const allUsersQuery = useAllUsers();

  useEffect(() => {
    if (allUsersQuery.isSuccess) {
      setAllUsers(allUsersQuery.data);
    }
  }, [allUsersQuery.isSuccess]);

  function filteredItems() {
    return allUsers
      .filter((item) =>
        item.name?.toLowerCase().startsWith(props.query?.toLowerCase())
      )
      .slice(0, 5);
  }

  console.log("filteredItems", filteredItems());

  const selectItem = (index) => {
    console.log("SELECTED INDEX", index);
    const item = filteredItems()[index];

    if (item) {
      props.command({ id: item });
      console.log("props", props);
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + filteredItems().length - 1) % filteredItems().length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % filteredItems().length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [filteredItems()]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {filteredItems().length ? (
        filteredItems().map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? "is-selected" : ""}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.name}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
