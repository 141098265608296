import React from "react";
import useAllUsers from "./hooks/useAllUsers";

import ReactAvatar from "react-avatar";
import uniqolor from "uniqolor";

import { styled, keyframes } from "@stitches/react";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { classNames } from "./shared/Utils";
import {
  ArrowRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { serverUrl } from "./hooks/Api";
import MyMemo from "./MyMemo";

import { useAuth0 } from "@auth0/auth0-react";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: 15,
  lineHeight: 1,
  color: "black",
  backgroundColor: "white",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
});

// Exports
export const Provider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = StyledContent;

const Avatar = ({
  email,

  size,
  hidetooltip,
  withName,
  square,
  className,
}) => {
  const usersQuery = useAllUsers();

  const encodedEmail = email?.replace("@", "%40");

  console.log("usersQuery", usersQuery);

  const { user } = useAuth0();

  const workspace = user?.userInfo?.workspaces[0].name;

  const name = usersQuery?.data?.find((u) => u.email === email)?.name;
  const initials = name
    ? name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase()
    : "";

  return (
    <div className="inline-flex select-none rounded-full ">
      <Provider>
        <Tooltip delayDuration={0}>
          <TooltipPrimitive.Trigger asChild>
            <div
              style={{
                width: size ? size + "px" : 32,
                height: size ? size + "px" : 32,
                borderRadius: square ? 0 : 9999,
                backgroundColor: uniqolor(email, {
                  lightness: 50,
                  differencePoint: 50,
                }).color,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
                flexGrow: 0,
                overflow: "hidden",

                fontSize: size ? size / 2 : 16,
                ...(user?.userInfo.avatarUrl
                  ? {
                      backgroundImage: `url(${serverUrl}/avatar/${workspace}/${encodedEmail}/small)`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }
                  : []),
              }}
              className={className}
            >
              {initials}
            </div>
          </TooltipPrimitive.Trigger>
          <StyledContent side="bottom" sideOffset={5}>
            {name}
            <StyledArrow />
          </StyledContent>
        </Tooltip>
      </Provider>
    </div>
  );
};

export default MyMemo(Avatar, [
  "email",
  "userid",
  "artistid",
  "size",
  "hidetooltip",
  "withName",
  "square",
  "className",
]);
