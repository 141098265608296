import React from "react";
import { styled, keyframes } from "@stitches/react";
import * as Tooltip from "@radix-ui/react-tooltip";

const scaleIn = keyframes({
  "0%": { opacity: 0, transform: "scale(0)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

const TooltipContent = styled(Tooltip.Content, {
  backgroundColor: "white",
  borderRadius: 3,
  fontSize: "0.8rem",
  padding: "0.5rem",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  border: "1px solid #eaeaea",
  transformOrigin: "var(--radix-tooltip-content-transform-origin)",
  animation: `${scaleIn} 0.1s ease-out forwards`,
});

const StyledArrow = styled(Tooltip.Arrow, {
  fill: "white",
});
function TrackListNotification(props) {
  return (
    <>
      <Tooltip.Provider delayDuration={200}>
        <Tooltip.Root>
          <Tooltip.Trigger>{props.children}</Tooltip.Trigger>
          <TooltipContent>
            {props.message}
            <StyledArrow />
          </TooltipContent>
        </Tooltip.Root>
      </Tooltip.Provider>
    </>
  );
}

export default TrackListNotification;
