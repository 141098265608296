import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";
import { toast } from "react-toastify";

export default function useUpdateUser() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, data }) =>
      axiosPrivate
        .patch(serverUrl + "/users/" + userId, { data })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["user", variables.userId.toString()]);
        queryClient.invalidateQueries(['all-users']);
        // toast.success("User updated successfully", { containerId: "main" });
      },
    }
  );
}
