import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useUpdateTrackInfo() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ trackid, projectid, trackInfo }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid + "/info", {
          trackInfo,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["track", variables.trackid.toString()]);
        queryClient.invalidateQueries(["recent-tracks"]);
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
      },
    }
  );
}
