import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";
import { serverUrl } from "./Api.js";

export default function useAllUsers() {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(['all-users'], () =>
    axiosPrivate.get(serverUrl + "/users/").then((res) => res.data));
}
