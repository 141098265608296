import { useEditor, EditorContent, FloatingMenu } from "@tiptap/react";

import JSONPretty from "react-json-pretty";

import { classNames } from "../shared/Utils";

import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";

// import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";

import Dblock from "./CustomDblockExtension/CustomDblockExtension";

import Image from "@tiptap/extension-image";

import Youtube from "@tiptap/extension-youtube";

import Paragraph from "@tiptap/extension-paragraph";
import Heading from "@tiptap/extension-heading";
import { Link } from "./CustomLinkExtension/CustomLinkExtension";
import Focus from "@tiptap/extension-focus";
import Placeholder from "@tiptap/extension-placeholder";
import CustomMentionExtension from "./CustomMentionExtension/CustomMentionExtension";

import Gapcursor from "@tiptap/extension-gapcursor";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";

import { TrailingNode } from "./CustomTrailingNodePlugin/trailing-node";

import CustomGlobalHandleExtension from "./CustomGlobalHandleExtension/CustomGlobalHandleExtension";

import Suggestion from "@tiptap/suggestion";
import UniqueID from "@tiptap-pro/extension-unique-id";

import TaskItem from "./CustomTaskItem/TaskItem";
import TaskList from "@tiptap/extension-task-list";
import { SmilieReplacer } from "./CustomSmilieReplacer/SmilieReplacer";

import CustomHeaderComponent from "./CustomHeaderExtension/CustomHeaderExtension";
import CustomSpotifyComponent from "./CustomSpotifyComponent/CustomSpotifyExtension";

import { hocuspocusServerUrl } from "../hooks/Api";

// import Commands from "./CustomSlashCommands/commands";
// import getSuggestionItems from "./CustomSlashCommands/items";
// import renderItems from "./CustomSlashCommands/renderItems";

import "./TipTapTasks.css";

import suggestion from "./TipTapTasksSuggestion";
import {
  CheckCircleIcon,
  ClipboardDocumentIcon,
  DocumentTextIcon,
  FolderPlusIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React, { createRef, useEffect, useState } from "react";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { BoltIcon } from "@heroicons/react/24/solid";
import TipTapTasksTemplateButton from "./TipTapTasksTemplates/TipTapTasksTemplateButton";
import TipTapTasksTemplatesOverlay from "./TipTapTasksTemplates/TipTapTasksTemplatesOverlay";
import Dropcursor from "@tiptap/extension-dropcursor";
import useUpdateTrackTasks from "../hooks/useUpdateTrackTasks";
import { initContent } from "./initContent";
import CustomCommentExtension from "./CustomCommentExtension/CustomCommentExtension";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import TipTapBubbleMenu from "./TipTapBubbleMenu";
import { BiCheckCircle, BiCommentDots } from "react-icons/bi";
import History from "@tiptap/extension-history";
import TipTapTasksTemplateSwiper from "./TipTapTasksTemplates/TipTapTasksTemplateSwiper";
import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ydoc = new Y.Doc();

String.prototype.toColor = function () {
  var colors = [
    "#e51c23",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#5677fc",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#259b24",
    "#8bc34a",
    "#afb42b",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

const TipTapTasks = ({
  track,
  trackId,
  trackTitle,
  setUnfinishedTasksCount,
}) => {
  const { auth } = useAuth();

  const content = track?.tiptapJSON ? track.tiptapJSON : initContent;

  const provider = new HocuspocusProvider({
    url: hocuspocusServerUrl,
    name: "trackTasks-" + track?.id,
    document: ydoc,
  });

  const taskToHighlightId = useParams().taskid || null;

  const [updated, setUpdated] = useState(false);
  const updateTrackTasksQuery = useUpdateTrackTasks();

  const updateServerData = (editor) => {
    const json = editor?.getJSON();
    const allTasks = json?.content
      ? json.content
          .filter((node) => node.type === "taskList")
          .map((taskList) => taskList.content)
          .flat()
          .filter((node) => node.type === "taskItem")
          .reduce((acc, task) => {
            const taskName = task?.content[0]?.content
              ? task.content[0].content[0].text
                ? task.content[0].content[0].text
                : ""
              : "";
            const taskContent = task?.content[0]?.content
              ? task.content[0].content
              : "";
            const taskChecked = task.attrs.checked;
            const taskID = task.attrs.id;
            const taskSubtasks = task.content
              .filter((node) => node.type === "taskList")
              .map((taskList) => taskList.content)
              .flat()
              .filter((node) => node.type === "taskItem")
              .reduce((acc, taskItem) => {
                const taskName = taskItem?.content[0]?.content
                  ? taskItem.content[0].content[0].text
                    ? taskItem.content[0].content[0].text
                    : ""
                  : "";
                const subTaskContent = taskItem?.content[0]?.content
                  ? taskItem.content[0].content
                  : "";

                const taskChecked = taskItem.attrs.checked;
                const taskID = taskItem.attrs.id;
                return [
                  ...acc,
                  {
                    id: taskID,
                    name: taskName,
                    content: subTaskContent,
                    checked: taskChecked,
                    color: taskItem.attrs.color,
                    priority: taskItem.attrs.priority,
                    assignedTo: taskItem.attrs.assignedTo,
                  },
                ];
              }, []);

            return [
              ...acc,
              {
                id: taskID,
                name: taskName,
                content: taskContent,
                checked: taskChecked,
                subTasks: taskSubtasks,
                color: task.attrs.color,
                assignedTo: task.attrs.assignedTo,
                priority: task.attrs.priority,
              },
            ];
          }, [])
      : [];

    console.log("allTasks", allTasks);

    let allTasksArray = [];
    allTasks.forEach((task) => {
      allTasksArray.push(task);
      if (task.subTasks) {
        task.subTasks.forEach((subTask) => {
          allTasksArray.push(subTask);
          if (subTask.subTasks) {
            subTask.subTasks.forEach((subSubTask) => {
              allTasksArray.push(subSubTask);
            });
          }
        });
      }
    });

    const allTasksArrayReduced = allTasks.reduce((acc, task) => {
      acc.push({ ...task, subTasks: [] });

      if (task.subTasks) {
        task.subTasks.forEach((subTask) => {
          acc.push({ ...subTask, subTasks: [] });
          if (subTask.subTasks) {
            subTask.subTasks.forEach((subSubTask) => {
              acc.push({ ...subSubTask, subTasks: [] });
            });
          }
        });
      }
      return acc;
    }, []);

    setUnfinishedTasksCount(
      allTasksArrayReduced?.filter((t) => t.checked === false).length
    );

    console.log("allTasksArrayReduced", allTasksArrayReduced);

    updateTrackTasksQuery.mutate(
      {
        trackid: track.id,
        tasks: allTasksArrayReduced,
        tiptapJSON: editor.getJSON(),
      },
      (data) => {
        console.log("[updated track tasks] ", data);
      }
    );
  };

  console.log("TipTapTasks content", content);
  useEffect(() => {
    if (track?.tiptapJSON) {
      console.log("track.tiptapJSON", track.tiptapJSON);
      // find task attributes that need updates from track.tasks
      // update task attributes
      const tasks = track?.tasks;
      const content = track?.tiptapJSON;

      console.log("tasks", tasks);
      console.log("content", content);

      let allTasksArray = [];
      tasks?.forEach((task) => {
        allTasksArray.push(task);
        if (task.subTasks) {
          task.subTasks.forEach((subTask) => {
            allTasksArray.push(subTask);
            if (subTask.subTasks) {
              subTask.subTasks.forEach((subSubTask) => {
                allTasksArray.push(subSubTask);
              });
            }
          });
        }
      });

      console.log("allTestTasks", allTasksArray);

      const newContent = content.content?.map((node) => {
        if (node.type === "taskList") {
          const updatedNodeContent = node.content?.map((subnode) => {
            if (subnode.type === "taskItem") {
              const task = allTasksArray.find(
                (task) => task.id === subnode.attrs.id
              );
              if (task) {
                console.log("found first level taskItem", task);
                subnode.attrs = {
                  ...subnode.attrs,
                  checked: task.checked,
                  assignedTo: task.assignedTo,
                  priority: task.priority,
                  dueDate: task.dueDate,
                  color: task.color,
                };
              }

              const subTasks = subnode.content?.map((subTask) => {
                console.log("subTask", subTask);
                if (subTask.type === "taskList") {
                  const subTask2 = subTask.content?.map((subSubTask) => {
                    console.log("subSubTask", subSubTask);
                    if (subSubTask.type === "taskItem") {
                      console.log("subSubTask", subSubTask);

                      const task = allTasksArray.find(
                        (task) => task.id === subSubTask.attrs.id
                      );

                      if (task) {
                        subSubTask.attrs = {
                          ...subSubTask.attrs,
                          checked: task.checked,
                          assignedTo: task.assignedTo,
                          priority: task.priority,
                          dueDate: task.dueDate,

                          color: task.color,
                        };
                      }
                    }
                    return subSubTask;
                  });

                  subTask.content = subTask2;
                }
              });
              return { ...subnode, content: subTasks };
            }
            return subnode;
          });
          return { ...node, content: updatedNodeContent };
        } else {
          return node;
        }
      });
      console.log("newContent", newContent);
    }
  }, [track]);

  const editor = useEditor({
    onCreate: () => {
      if (taskToHighlightId) {
        setTimeout(() => {
          const task = document.querySelector(
            `[data-task-id="${taskToHighlightId}"]`
          );

          if (task) {
            task.scrollIntoView();
            task.classList.add("highlight-task");
            task.addEventListener("click", () => {
              task.classList.remove("highlight-task");
            });
          }
        }, 1200);
      }
    },

    editorProps: {
      attributes: {
        class:
          "text-gray-800 prose prose-sm sm:prose lg:prose-lg xl:prose-2xl my-0 h-full  py-0 focus:outline-none ",
      },
    },
    extensions: [
      // Collaboration.configure({
      //   document: ydoc,
      // }),
      // CollaborationCursor.configure({
      //   provider,
      //   user: { name: auth.name, color: auth.name.toColor() },
      // }),
      Dblock,
      Document,
      HardBreak,
      HorizontalRule,
      ListItem,
      OrderedList,
      Text,
      Bold,
      Italic,
      Strike,
      SmilieReplacer,
      History,
      Suggestion,
      TrailingNode,
      Gapcursor,
      Underline,
      TextStyle,
      Color,
      Heading.configure({
        HTMLAttributes: {
          class: "tiptap-heading",
        },
        levels: [1],
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),

      // Commands.configure({
      //   suggestion: {
      //     items: getSuggestionItems,
      //     render: renderItems,
      //   },
      // }),
      Highlight.configure({
        HTMLAttributes: {
          class: "tiptap-highlight",
        },
      }),

      Link.configure({
        HTMLAttributes: {
          class: "tiptap-link",
        },
      }),
      CustomCommentExtension,
      CustomSpotifyComponent,
      Dropcursor.configure({
        class: "tiptap-dropcursor",
        width: 2,
      }),
      Image,
      CustomHeaderComponent.extend({
        addAttributes() {
          return {
            ...this.parent?.(),
            color: {
              // …
            },
            allTasks: "",
          };
        },
      }),
      ,
      UniqueID.configure({
        types: ["taskHeader", "taskItem"],
      }),
      Focus.configure({
        className: "task-list-has-focus",
        mode: "deepest",
      }),

      Paragraph.extend({
        // addKeyboardShortcuts() {
        //   return {
        //     Tab: () => {
        //       if (!this.editor.isActive("taskItem")) {
        //         // this.editor.commands.toggleTaskList();
        //         // this.editor.commands.focus();
        //         this.editor.chain().toggleTaskList().focus().run();
        //       }
        //     },
        //   };
        // },
      }),

      TaskList,

      TaskItem.configure({
        nested: true,

        HTMLAttributes: {
          className: "task-list-item",
        },
      }).extend({
        addAttributes() {
          return {
            ...this.parent?.(),
            color: {
              // …
            },
            assignedTo: {
              // …
            },
          };
        },
      }),
      Youtube.configure({
        controls: false,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "paragraph") {
            return "Insert text or paste youtube, spotify or url link";
          }
          if (node.type.name === "taskItem") {
            return "Insert task";
          }
          // if (node.type.name === "taskHeader") {
          //   return "Category";
          // } else {
          //   return "Insert text";
          // }

          // return "Can you add some further context?";
        },
        includeChildren: true,
        emptyNodeClass: "my-custom-is-empty-class",
        showOnlyCurrent: true,
      }),
      CustomMentionExtension.configure({
        suggestion,
        HTMLAttributes: {
          class: "mention",
        },
        renderLabel({ options, node }) {
          return `${node.attrs.id.name.split(" ")[0]}`;
        },
      }),
    ],
    // content: initContent,
    content: content,
    // onUpdate: ({ editor }) => {
    //   console.log("onUpdate updated");
    //   setUpdated(true);
    // },
    // onDestroy: () => {
    //   console.log("onDestroy");
    // },
    // onFocus: ({ editor, event }) => {
    //   console.log("onFocus", event);
    // },
    onBlur({ editor, event }) {
      updateServerData(editor);
    },
  });
  useEffect(() => {
    if (editor) {
      editor.commands.focus("end");
    }
  }, [editor]);

  useEffect(() => {
    return () => editor?.destroy();
  }, []);

  const TipTapTasksTemplatesOverlayRef = createRef();
  const openTemplatesOverlay = () => {
    TipTapTasksTemplatesOverlayRef.current.openDialog();
  };

  const currentemail = auth.email;
  if (editor)
    return (
      <div className="">
        <TipTapTasksTemplatesOverlay ref={TipTapTasksTemplatesOverlayRef} />
        {!editor && loading}
        <div className="top-0   block rounded-t-md bg-white ">
          <div className="border-b border-gray-200">
            <div className="flex items-center  bg-gray-50">
              <div className="flex w-full justify-between ">
                <div className="flex flex-row px-3 py-1 ">
                  <div
                    type="button"
                    className="mr-3 inline-flex items-center rounded-md border px-3 py-3  text-sm  font-medium border-transparent text-gray-400  hover:text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:focus:ring-offset-zinc-900"
                    onClick={() =>
                      editor.chain().toggleTaskList().focus().run()
                    }
                  >
                    <BiCheckCircle className="my-auto h-5 w-5" />
                    <div className="my-auto cursor-pointer select-none whitespace-nowrap pl-1 text-xs font-medium">
                      Make task
                    </div>{" "}
                  </div>{" "}
                  <div
                    type="button"
                    className="mr-3 inline-flex items-center rounded-md border px-3 py-3  text-sm  font-medium border-transparent text-gray-400  hover:text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:focus:ring-offset-zinc-900"
                    onClick={() =>
                      editor
                        .chain()
                        .insertContent({
                          type: "commentItem",
                          attrs: { commentBy: currentemail },
                        })
                        .run()
                    }
                  >
                    <BiCommentDots className="my-auto h-5 w-5" />
                    <div className="my-auto cursor-pointer select-none whitespace-nowrap pl-1 text-xs font-medium">
                      Add comment
                    </div>{" "}
                  </div>
                </div>
                <div className="hidden flex-row px-3 py-1  lg:flex ">
                  <div
                    className={classNames(
                      editor.can().undo()
                        ? "cursor-pointer opacity-100 hover:bg-white"
                        : "opacity-50",
                      "flex select-none flex-row px-3 py-3 text-gray-700 border-gray-300"
                    )}
                    onClick={() => editor.chain().focus().undo().run()}
                  >
                    {" "}
                    <ArrowUturnLeftIcon className="my-auto h-5 w-5" />
                    <div className="my-auto select-none pl-1 text-xs font-medium">
                      undo
                    </div>{" "}
                  </div>
                  <div
                    className={classNames(
                      editor.can().redo()
                        ? "cursor-pointer opacity-100 hover:bg-white"
                        : "opacity-50",
                      "flex select-none flex-row px-3 py-3  text-gray-700 border-gray-300"
                    )}
                    onClick={() => editor.chain().focus().redo().run()}
                  >
                    {" "}
                    <ArrowUturnRightIcon className="my-auto h-5 w-5" />
                    <div className="my-auto select-none pl-1 text-xs font-medium">
                      redo
                    </div>{" "}
                  </div>

                  <div
                    onClick={() => openTemplatesOverlay()}
                    className={classNames(
                      "cursor-pointer opacity-100 hover:bg-white",
                      "flex select-none flex-row rounded-tr-md px-3 py-3  text-gray-700 border-gray-300"
                    )}
                  >
                    {" "}
                    <BoltIcon className="my-auto h-5 w-5" />
                    <div className="my-auto select-none pl-1 text-xs font-medium">
                      templates
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col  rounded-b-md py-0 px-4  pb-6 bg-white">
          <TipTapBubbleMenu editor={editor} />
          <EditorContent editor={editor} />
          {/* {editor && (
          <FloatingMenu
            editor={editor}
            tippyOptions={{
              duration: 100,
              animateFill: true,
              inlinePositioning: true,
              offset: [170, 20],
              placement: "bottom",
            }}
            className="group flex flex-row rounded-md border shadow-2xl   bg-gray-50 border-gray-300 divide-gray-200"
          >
            {getSuggestionItems("").filter((item) => item.category === "block")
              .length > 0 && (
              <div className=" flex flex-col divide-y text-gray-800 divide-gray-200 ">
                <div className="flex flex-col divide-y border-b border-gray-200 divide-gray-200">
                  <div className="px-5 py-2 text-[0.77rem] font-medium uppercase tracking-wide text-gray-600">
                    Insert
                  </div>
                  {getSuggestionItems("")
                    .filter((item) => item.category === "block")
                    .map((item, index) => {
                      return (
                        <div
                          className={classNames(
                            "my-auto flex cursor-pointer select-none flex-row gap-3 py-3 px-4 hover:bg-gray-100"
                          )}
                          key={index}
                          onClick={() => item.floatCommand({ editor })}
                        >
                          <div
                            style={{
                              backgroundColor: item.color,
                              borderColor: item.color,
                            }}
                            className="mt-1 flex h-9 w-9 flex-shrink-0 rounded-md border p-1 bg-gray-50 border-gray-200"
                          >
                            {item.icon ? (
                              <item.icon
                                style={{
                                  color: "white",
                                }}
                                className="my-auto mx-auto h-5 w-5 text-gray-600  "
                              />
                            ) : null}
                          </div>
                          <div
                            style={{
                              // backgroundColor: item.color + "11",
                              color: item.color,
                            }}
                            className="my-auto  flex flex-col text-sm font-medium"
                          >
                            <div className="brightness-90 dark:brightness-100">
                              {item.element || item.title}
                            </div>
                            <div className="text-xs font-normal text-gray-600">
                              {item.description}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </FloatingMenu>
        )} */}
        </div>

        {/* <div className="grid grid-cols-2">
        <div className="code json mt-4 h-72 overflow-scroll p-3 text-xs text-gray-700 bg-gray-50">
          allTasks {allTasks?.length}{" "}
          <JSONPretty id="json-pretty" data={allTasks}></JSONPretty>
        </div>
        <div className="code json mt-4 h-72 overflow-scroll p-3 text-xs text-gray-700 bg-gray-50">
          allTasksArrayReduced {allTasksArrayReduced?.length}
          <JSONPretty id="json-pretty" data={allTasksArrayReduced}></JSONPretty>
        </div>
      </div> */}
        {/* <div
        className="rounded-md border px-2 py-1 text-gray-700 border-gray-300"
        onClick={() => {
          editor.commands.setImage({
            src: "https://source.unsplash.com/8xznAGy4HcY/800x400",
          });
        }}
      >
        add image
      </div> */}
        {/* <div
        className="rounded-md border px-2 py-1 text-gray-700 border-gray-300"
        onClick={() => {
          editor.commands.setDBlock();
        }}
      >
        add dblock
      </div> */}

        {/* <div className="flex gap-3">
        <button
          onClick={() => {
            editor.commands.doCommit();
          }}
          className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-md border text-gray-700 border-gray-200 hover:border-gray-300"
        >
          Do commit
        </button>
        <button
          onClick={() => {
            window.alert(editor.commands.getCommits());
          }}
          className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-md border text-gray-700 border-gray-200 hover:border-gray-300"
        >
          Get commit
        </button>
        <button
          onClick={() => {
            editor.commands.highlightCommit();
          }}
          className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-md border text-gray-700 border-gray-200 hover:border-gray-300"
        >
          Highlight commit
        </button>
      </div> */}
      </div>
    );
};

export default TipTapTasks;
