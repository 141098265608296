import React, { useState } from "react";
import ArtistAvatar from "../../ArtistAvatar";
import ArtistContactCard from "../../Artist/ArtistContactCard";
import {
  ChevronDownIcon,
  PaperClipIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../../shared/Utils";

import { LightenDarkenColor } from "lighten-darken-color";
import TipTapTasksTemplateSwiper from "../../TipTapTasks/TipTapTasksTemplates/TipTapTasksTemplateSwiper";
import WorkingOnThis from "../../WorkingOnThis";

const TrackListTrackOverlaySplits = ({ track }) => {
  console.log("TrackListTrackOverlaySplits track", track);
  return (
    <div>
      <div className="p-6 text-xl text-gray-700">Splits</div>
      <div className="flex flex-col  divide-y border-y border-gray-300 divide-gray-300">
        {track.artists?.map((artist) => {
          console.log("artist", artist);
          const [contactExpanded, setContactExpanded] = React.useState(false);
          return (
            <div className="flex flex-col">
              <div className="flex flex-row gap-6 py-4 px-6 text-gray-700 hover:bg-gray-50">
                <div className="my-auto h-20 w-20">
                  <ArtistAvatar
                    setColors={(e) => console.log(e)}
                    artistId={artist.id}
                  />
                </div>
                <div className="my-auto flex flex-grow flex-col gap-1">
                  <div className="pb-0.5 text-xl font-medium">
                    {artist.name}
                  </div>
                  {/* <div className="pb-1 text-xs font-medium uppercase text-gray-400">
                    {artist.type[0]}
                  </div> */}
                  <div className="-ml-1 w-fit rounded-md border px-2 py-1 text-base border-gray-200">
                    {(100 / track.artists.length).toFixed(2)} %
                  </div>
                </div>

                <div className="flex flex-col justify-center gap-2">
                  {artist.contact?.length > 0 && (
                    <div
                      onClick={() => setContactExpanded(!contactExpanded)}
                      className="flex cursor-pointer flex-row rounded-md border py-1.5 px-3 border-transparent hover:bg-gray-50 hover:border-gray-200"
                    >
                      <div className="my-auto">
                        {artist.contact?.length} contacts
                      </div>{" "}
                      <ChevronDownIcon
                        className={classNames(
                          contactExpanded ? "rotate-180" : "",
                          "my-auto ml-2 h-4 w-4 transition-all"
                        )}
                      />
                    </div>
                  )}{" "}
                  <div
                    // onClick={() => setContactExpanded(!contactExpanded)}
                    className="flex cursor-pointer flex-row rounded-md border py-1.5 px-3 border-transparent hover:bg-gray-50 hover:border-gray-200"
                  >
                    <div className="my-auto">Agreement</div>{" "}
                    <PaperClipIcon
                      className={classNames(
                        "my-auto ml-2 h-4 w-4 transition-all"
                      )}
                    />
                  </div>
                </div>
              </div>
              {artist.contact?.length > 0 && contactExpanded && (
                <div className="flex flex-wrap p-6  shadow-inner bg-zinc-100 border-zinc-200   dark:bg-zinc-800">
                  <div className="  flex flex-wrap  gap-4">
                    {artist.contact.map((contact) => {
                      return (
                        <ArtistContactCard border bggray dbcontact={contact} />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="flex flex-col">
          <div className="flex flex-row gap-6 py-4 px-6 text-gray-700 hover:bg-gray-50">
            <div className="my-auto h-20 w-20">
              <div className="flex h-full w-full overflow-hidden rounded-full border-4 border-dashed border-gray-300">
                <PlusIcon className="my-auto mx-auto  h-10 w-10 text-gray-400" />
              </div>
            </div>
            <div className="my-auto flex flex-grow flex-col gap-1">
              <div className="flex pb-0.5 text-xl font-medium text-gray-400">
                <div className="my-auto">Add contribution</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-2 w-full self-start p-6">
        <div className="flex flex-row  gap-1 overflow-hidden rounded-md ">
          {track.artists.map((artist) => {
            const [colors, setColors] = useState([]);
            return (
              <div
                style={{
                  backgroundColor: colors[0]
                    ? LightenDarkenColor(colors[0], -30) + "cc"
                    : "#ccc",
                  backgroundBlendMode: "multiply",
                  width: `${(100 / track.artists.length).toFixed(2)}%`,
                }}
                className={classNames(
                  "flex flex-row flex-col overflow-hidden py-3  px-4 align-middle text-xs font-medium uppercase text-white/100 hover:bg-gray-50"
                )}
              >
                {artist.name}{" "}
                <span className="ml-2 ">
                  {(100 / track.artists.length).toFixed(2)} %
                </span>
                <div className="hidden">
                  <ArtistAvatar setColors={setColors} artistId={artist.id} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <WorkingOnThis /> */}
    </div>
  );
};

export default TrackListTrackOverlaySplits;
