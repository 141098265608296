import React from "react";

const isFunction = (value) => typeof value === "function";
export default (WrappedComponent, cb) => {
  class MyMemo extends React.Component {
    shouldComponentUpdate(nextProps) {
      if (!cb) {
        return Object.keys(nextProps).length !== Object.keys(this.props).length;
      }

      if (!isFunction(cb)) {
        return (
          cb.filter((prop) => nextProps[prop] !== this.props[prop]).length > 0
        );
      }

      return cb(nextProps, this.props);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return MyMemo;
};
