import React, { useEffect } from "react";
import template from "./template";
import { Formik } from "formik";
import { classNames } from "../shared/Utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import DotsLoader from "../DotsLoader";
import useUpdateTrackInfo from "../hooks/useUpdateTrackInfo";
import ToolTip from "../ToolTip";
import InputMask from "react-input-mask";

const TrackInfo = ({ track }) => {
  console.log("TrackInfo track", track);

  const updateTrackInfoQuery = useUpdateTrackInfo();

  // const initialValues = template.reduce((acc, item) => {
  //   acc[item.id] = item.initialValue;
  //   return acc;
  // }, {});

  const initialValues = template.reduce((acc, item) => {
    if (track.trackInfo && track.trackInfo[item.id]) {
      acc[item.id] = track.trackInfo[item.id];
    } else {
      acc[item.id] = item.initialValue;
    }
    return acc;
  }, {});

  const [hints, setHints] = React.useState({});
  console.log("hints", hints);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          console.log("values", values);
          const errors = {};
          for (const key in values) {
            console.log("key", key);
            if (
              template.find((item) => item.id === key).validate(values[key])
            ) {
              errors[key] = template
                .find((item) => item.id === key)
                .validate(values[key]);
            }
          }
          setHints(errors);
          return null;
        }}
        onSubmit={(values, actions) => {
          updateTrackInfoQuery
            .mutate({
              trackid: track?.id,
              projectid: track?.project,
              trackInfo: values,
            })
            .then((res) => {
              console.log("res", res);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid  md:grid-cols-2   ">
              {template.map((item) => {
                const error =
                  template
                    .find((templateItem) => templateItem.id === item.id)
                    .validate(values[item.id]) || null;
                return (
                  <div className=" grid grid-cols-1 divide-y  border-b border-gray-300 divide-gray-300  dark:divide-zinc-700 md:grid-cols-2   ">
                    <div
                      className="group flex flex-shrink-0  md:col-span-2 "
                      key={"div-" + item.id}
                    >
                      <div className="my-auto w-28 flex-shrink-0 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
                        <label htmlFor={item.id}>{item.title}</label>
                      </div>
                      <div className="flex w-full flex-grow flex-col">
                        {item.type === "text" ? (
                          <div className="relative m-1 ml-2 flex h-12 flex-grow ">
                            <input
                              type="text"
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder || ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                handleSubmit();
                              }}
                              className={classNames(
                                error
                                  ? "block w-full rounded-md border py-1.5 bg-white  text-red-900 border-red-300  placeholder:text-gray-400  focus:border-red-300  sm:text-sm sm:leading-6"
                                  : "block w-full rounded-md border py-1.5 border-transparent  bg-white text-gray-900  placeholder:text-gray-400  focus:border-gray-300 group-hover:border-gray-300 sm:text-sm sm:leading-6"
                              )}
                              value={values[item.id]}
                            />
                            {error && (
                              <div className=" absolute inset-y-0 right-0 z-30 m-1 flex items-center pr-3 text-sm bg-white text-red-600">
                                <ToolTip message={hints[item.id]}>
                                  <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                  />
                                </ToolTip>
                              </div>
                            )}
                          </div>
                        ) : item.type === "date" ? (
                          <div className="relative m-1 ml-2 flex h-12 flex-grow ">
                            <InputMask
                              mask="99/99/9999"
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder || ""}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                handleSubmit();
                              }}
                              className={classNames(
                                error
                                  ? "block w-full rounded-md border py-1.5 ring-0 bg-white text-red-900 border-red-300 placeholder:text-gray-400  focus:ring-0  focus:border-red-300  sm:text-sm sm:leading-6"
                                  : "block w-full rounded-md border py-1.5 ring-0 border-transparent bg-white  text-gray-900 placeholder:text-gray-400  focus:ring-0  focus:border-gray-300 group-hover:border-gray-300 sm:text-sm sm:leading-6"
                              )}
                              style={{ paddingLeft: "6px", boxShadow: "none" }}
                              value={values[item.id]}
                            />
                            {error && (
                              <div className=" absolute inset-y-0 right-0 z-30 m-1 flex items-center pr-3 text-sm bg-white text-red-600">
                                <ToolTip message={hints[item.id]}>
                                  <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                  />
                                </ToolTip>
                              </div>
                            )}
                          </div>
                        ) : item.type === "select" ? (
                          <> </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>{" "}
                  </div>
                );
              })}
            </div>
            {isSubmitting && <DotsLoader big />}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TrackInfo;
