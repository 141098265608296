import { createContext, useState, useEffect, createRef, useRef } from "react";
import TrackListTrackOverlay from "../components/Tracklist/TrackOverlay/TrackListTrackOverlay";

const TrackOverlayContext = createContext({});

export const TrackOverlayProvider = ({ children }) => {
  const TrackOverlayRef = useRef(null);

  return (
    <TrackOverlayContext.Provider value={{ TrackOverlayRef }}>
      <TrackListTrackOverlay ref={TrackOverlayRef} />
      {children}
    </TrackOverlayContext.Provider>
  );
};

export default TrackOverlayContext;
