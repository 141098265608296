import React, { useEffect, useRef, useState } from "react";
import {
  PlayCircleIcon,
  PauseCircleIcon,
  ChatIcon,
} from "@heroicons/react/24/solid";
import useSettings from "./hooks/useSettings";

import Lottie from "react-lottie-player";

import * as newTrackBackground from "../animations/363-animated-background-14-lineal-edited.json";
import { serverUrl } from "./hooks/Api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MiniPlayerMobile = ({ track, dark, alert }) => {
  const {
    bottomPlayerOpen,
    setBottomPlayerOpen,
    bottomPlayerSetTrack,
    bottomPlayerUrl,
    bottomPlayerSetUrl,
    bottomPlayerIsPlaying,
    bottomPlayerSetIsPlaying,
  } = useSettings();

  const [click, setClick] = useState(false);
  const [goTo, setGoTo] = useState(0);

  const handleClick = () => {
    setGoTo(0);
    setClick(true);
    setBottomPlayerOpen(true);
    if (bottomPlayerIsPlaying) {
      if (bottomPlayerUrl === track.previewurl) {
        bottomPlayerSetIsPlaying(false);
      } else {
        bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
        bottomPlayerSetTrack(track);
        bottomPlayerSetIsPlaying(true);
      }
    } else {
      bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
      bottomPlayerSetTrack(track);
      bottomPlayerSetIsPlaying(true);
    }
  };

  return (
    <div className="z-50 my-auto flex h-[4.2rem] w-full ">
      <div className=" relative h-full w-full ">
        <div
          className={classNames(
            dark
              ? "text-gray-400 hover:text-gray-500"
              : "text-gray-300 hover:text-gray-400",
            " controls z-10 float-left my-auto ml-0.5 flex h-full   "
          )}
          onClick={handleClick}
        >
          {!bottomPlayerIsPlaying && bottomPlayerUrl === track.previewurl && (
            <PlayCircleIcon
              className="z-10 my-auto mx-auto mr-1 h-[3.7rem] w-[3.7rem]  cursor-pointer pl-1  pr-4"
              aria-hidden="true"
            />
          )}
          {!bottomPlayerIsPlaying && bottomPlayerUrl != track.previewurl && (
            <PlayCircleIcon
              className="z-10 my-auto mx-auto mr-1 h-[3.7rem] w-[3.7rem]  cursor-pointer pl-1 pr-4"
              aria-hidden="true"
            />
          )}
          {bottomPlayerIsPlaying && bottomPlayerUrl != track.previewurl && (
            <PlayCircleIcon
              className="z-10 my-auto mx-auto mr-1 h-[3.7rem] w-[3.7rem]  cursor-pointer pl-1 pr-4"
              aria-hidden="true"
            />
          )}
          {bottomPlayerIsPlaying && bottomPlayerUrl === track.previewurl && (
            <PauseCircleIcon
              className="z-10 my-auto mx-auto mr-1 h-[3.7rem] w-[3.7rem] cursor-pointer pl-1   pr-4 text-gray-400  hover:text-gray-500"
              aria-hidden="true"
            />
          )}
        </div>

        <div className="absolute top-[0.2rem] left-0.5 z-0 -ml-[0.4rem] h-full w-full">
          <Lottie
            speed={1}
            loop={false}
            goTo={goTo}
            onComplete={() => setClick(false)}
            animationData={newTrackBackground}
            play={click}
            style={{ width: 60, height: 60 }}
          />
        </div>
        {/* <div className=" h-[1.9rem] w-[1.9rem] left-[0.33rem] top-[0.6rem] border-green-500/50 border-2 absolute rounded-full z-0" /> */}
      </div>
    </div>
  );
};

export default MiniPlayerMobile;
