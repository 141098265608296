import { Fragment, useEffect, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  DocumentPlusIcon,
  DocumentIcon,
  FolderPlusIcon,
  FolderIcon,
  UserPlusIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";

import useAllProjects from "./hooks/useAllProjects";
import useAllTracks from "./hooks/useAllTracks";
import useAllArtists from "./hooks/useAllArtists";

// const projects = [{ id: 1, name: "Netsky 2022", url: "#" }];

// const tracks = [
//   { id: 1, name: "Netsky -Track 1", url: "#" },
//   { id: 2, name: "Track 2", url: "#" },
// ];

// const artists = [
//   { id: 1, name: "Artist 1", url: "#" },
//   { id: 2, name: "Netsky 2", url: "#" },
// ];

const quickActions = [
  { name: "Add new track...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  {
    name: "Create new project...",
    icon: FolderPlusIcon,
    shortcut: "F",
    url: "#",
  },
  {
    name: "Invite team member...",
    icon: UserPlusIcon,
    shortcut: "H",
    url: "#",
  },
  { name: "Settings...", icon: Cog6ToothIcon, shortcut: "L", url: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CommandPalette({ open, setOpen }) {
  const navigate = useNavigate();

  const allProjectsQuery = useAllProjects();
  const allTracksQuery = useAllTracks();
  const allArtistsQuery = useAllArtists();

  const [projects, setProjects] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [artists, setArtists] = useState([]);

  const [query, setQuery] = useState("");

  const recent = ["test"];

  const handleChoice = (choice) => {
    navigate(choice.url);
    setOpen(false);
  };

  useEffect(() => {
    console.log("COMMANDPALETTE allProjectsQuery", allProjectsQuery);
    if (allProjectsQuery.isSuccess) {
      const newProjects = allProjectsQuery.data.map((project) => {
        return {
          id: project.id,
          title: project.title,
          url: `/projects/${project.id}`,
        };
      });
      setProjects(newProjects);
    }
  }, [allProjectsQuery.isSuccess]);

  useEffect(() => {
    console.log("COMMANDPALETTE allTracksQuery", allTracksQuery);
    if (allTracksQuery.isSuccess) {
      const newTracks = allTracksQuery.data.map((track) => {
        return {
          id: track.id,
          title: track.title,
          url: `/projects/${track.project}/track/${track.id}`,
        };
      });
      setTracks(newTracks);
    }
  }, [allTracksQuery.isSuccess]);

  useEffect(() => {
    console.log("COMMANDPALETTE allArtistsQuery", allArtistsQuery);
    if (allArtistsQuery.isSuccess && allArtistsQuery.data?.length > 0) {
      const newArtists = allArtistsQuery.data?.map((artist) => {
        return {
          id: artist.id,
          name: artist.name,
          url: `/artists/${artist.id}`,
        };
      });
      setArtists(newArtists);

      console.log("COMMAND PALETTE artists", artists);
    }
  }, [allArtistsQuery.isSuccess]);

  const filteredProjects =
    query === ""
      ? []
      : projects.filter((project) => {
          return project.title.toLowerCase().includes(query.toLowerCase());
        });
  const filteredTracks =
    query === ""
      ? []
      : tracks.filter((track) => {
          return track.title.toLowerCase().includes(query.toLowerCase());
        });
  const filteredArtists =
    query === ""
      ? []
      : artists.filter((artist) => {
          return artist.name.toLowerCase().includes(query.toLowerCase());
        });

  // const handleClick = ({ type, id }) => {
  //   console.log("handleClick", type, id);
  //   // e.preventDefault();
  //   // navigate(e.target.value);
  // };

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-black fixed inset-0 bg-opacity-50 backdrop-blur-sm transition-opacity dark:bg-zinc-800 dark:bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="bg-white ring-black divide-gray-500 mx-auto max-w-2xl transform divide-y divide-opacity-10 overflow-hidden rounded-xl bg-opacity-80 shadow-2xl ring-1 ring-opacity-5 backdrop-blur backdrop-filter transition-all">
              <Combobox onChange={(item) => handleChoice(item)}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="text-gray-900 pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-opacity-40"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="text-gray-900 placeholder-gray-500 h-12 w-full border-0 pl-11 pr-4 bg-transparent focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {(query === "" ||
                  filteredProjects.length > 0 ||
                  filteredTracks.length > 0 ||
                  filteredArtists.length > 0) && (
                  <Combobox.Options
                    static
                    className="divide-gray-500 max-h-80 scroll-py-2 divide-y divide-opacity-10 overflow-y-auto"
                  >
                    {filteredProjects.length > 0 && (
                      <li className="p-2">
                        <h2 className=" text-gray-500 px-3 pt-2 pb-2  text-tiny font-medium uppercase  tracking-wider">
                          Projects
                        </h2>

                        <ul className="text-gray-700 text-sm">
                          {(query === "" ? recent : filteredProjects).map(
                            (project) => (
                              <Combobox.Option
                                key={project.id}
                                value={project}
                                url={`/projects/${project.id}`}
                                className={({ active }) =>
                                  classNames(
                                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                    active &&
                                      "text-gray-900 bg-gray-900 bg-opacity-5"
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <FolderIcon
                                      className={classNames(
                                        "text-gray-900 h-6 w-6 flex-none text-opacity-40",
                                        active && "text-opacity-100"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="ml-3 flex-auto truncate">
                                      {project.title}
                                    </span>
                                    {active && (
                                      <span className="text-gray-500 ml-3 flex-none">
                                        Jump to...
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            )
                          )}
                        </ul>
                      </li>
                    )}

                    {filteredTracks.length > 0 && (
                      <li className="p-2">
                        <h2 className=" text-gray-500 px-3 pt-2 pb-2  text-tiny font-medium uppercase  tracking-wider">
                          Tracks
                        </h2>
                        <ul className="text-gray-700 text-sm">
                          {filteredTracks.map((track) => (
                            <Combobox.Option
                              key={track.id}
                              value={track}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "text-gray-900 bg-gray-900 bg-opacity-5"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <DocumentIcon
                                    className={classNames(
                                      "text-gray-900 h-6 w-6 flex-none text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {track.title}
                                  </span>
                                  {active && (
                                    <span className="text-gray-500 ml-3 flex-none">
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}

                    {filteredArtists.length > 0 && (
                      <li className="p-2">
                        <h2 className=" text-gray-500 px-3 pt-2 pb-2  text-tiny font-medium uppercase  tracking-wider">
                          Artists
                        </h2>
                        <ul className="text-gray-700 text-sm">
                          {filteredArtists.map((artist) => (
                            <Combobox.Option
                              key={artist.id}
                              value={artist}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "text-gray-900 bg-gray-900 bg-opacity-5"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <DocumentIcon
                                    className={classNames(
                                      "text-gray-900 h-6 w-6 flex-none text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {artist.name}
                                  </span>
                                  {active && (
                                    <span className="text-gray-500 ml-3 flex-none">
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}

                    {query === "" && (
                      <li className="p-2">
                        <h2 className="sr-only">Quick actions</h2>
                        <ul className="text-gray-700 text-sm">
                          {quickActions.map((action) => (
                            <Combobox.Option
                              key={action.shortcut}
                              value={action}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "text-gray-900 bg-gray-900 bg-opacity-5"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <action.icon
                                    className={classNames(
                                      "text-gray-900 h-6 w-6 flex-none text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {action.name}
                                  </span>
                                  <span className="text-gray-500 ml-3 flex-none text-xs font-semibold">
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">
                                      {action.shortcut}
                                    </kbd>
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                  </Combobox.Options>
                )}

                {query !== "" &&
                  filteredProjects.length === 0 &&
                  filteredArtists.length === 0 &&
                  filteredTracks.length === 0 && (
                    <div className="py-14 px-6 text-center sm:px-14">
                      <FolderIcon
                        className="text-gray-900 mx-auto h-6 w-6 text-opacity-40"
                        aria-hidden="true"
                      />
                      <p className="text-gray-900 mt-4 text-sm">
                        We couldn't find any projects with that term. Please try
                        again.
                      </p>
                    </div>
                  )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
