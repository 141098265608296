import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useDeleteTrackHistory() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectid, trackid, historyid }) =>
      axiosPrivate
        .delete(serverUrl + "/tracks/" + trackid + "/history/" + historyid)
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("variables ", variables);
        queryClient.invalidateQueries([
          "track-history",
          variables.trackid.toString(),
        ]);
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
      },
    }
  );
}
