import React from "react";
import ListeningRoomPlayer from "../../ListeningRoomPlayer";
import ScaleText from "react-scale-text";

import MiddleEllipsis from "react-middle-ellipsis";
import { classNames } from "../../shared/Utils";

const ListeningRoomCardFullscreen = ({
  track,
  setShowReactionSelector,
  isMobile,
}) => {
  return (
    <div className="flex w-full flex-col items-center ">
      <div
        className={classNames(
          track.title.length > 30 ? "text-3xl" : "text-5xl",
          "ellipsis w-full overflow-hidden whitespace-nowrap px-6  pb-12 pt-4  text-center  md:w-[800px]"
        )}
      >
        <MiddleEllipsis>
          <span>{track.title}</span>
        </MiddleEllipsis>{" "}
        <div className="py-2 text-2xl opacity-70">
          {track.artists.map((artist, artistIdx) => (
            <span key={artist.name}>
              {artistIdx > 0 && ", "}
              {artist.name}
            </span>
          ))}
        </div>
      </div>
      <div className="w-full px-6  md:w-[800px]">
        <ListeningRoomPlayer
          setStartedPlaying={() => {
            setShowReactionSelector(true);
          }}
          url={track.previewurl}
          height={100}
        />
      </div>
    </div>
  );
};

export default ListeningRoomCardFullscreen;
