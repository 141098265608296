import React, { useEffect, useRef, useState, useMemo } from "react";
import WaveSurfer from "wavesurfer.js";

import "react-circular-progressbar/dist/styles.css";
import { classNames } from "./shared/Utils";

import LargePlayerAnalyticsTable from "./LargePlayerAnalyticsTable";
import axios from "axios";

import CursorPlugin from "./Wavesurfer/CursorPlugin";
import PauseCircleIcon from "@heroicons/react/24/solid/PauseCircleIcon";
import PlayCircleIcon from "@heroicons/react/24/solid/PlayCircleIcon";
import { serverUrl } from "./hooks/Api";

// import { unmute } from "./unmute";

export default function LargePlayerAnalytics({ track, url, height }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const [trackDuration, setTrackDuration] = useState(0);
  const formWaveSurferOptions = (ref) => ({
    container: ref,

    waveColor: "#dddddddd",
    progressColor: "#dddddddd",

    cursorColor: "#ccc",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: height || 70,
    fillParent: true,
    normalize: true,
    hideScrollbar: true,
    hideCursor: false,

    cursorWidth: 2,

    partialRender: true,
    backend: "MediaElement",
    ignoreSilenceMode: true,

    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    plugins: [
      CursorPlugin.create({
        deferInit: true,
        showTime: true,
        opacity: 0.7,
        style: {
          width: "4px",
        },
        customShowTimeStyle: {
          "background-color": "#fff",
          color: "#333",
          padding: "4px",
          marginLeft: "4px",
          // marginRight: "8px",
          borderRadius: "4px",
          "font-size": "12px",
          "font-weight": "bold",
        },
      }),
    ],
  });

  // create new WaveSurfer instance
  // On component mount and when url changes

  useEffect(() => {
    if (url) {
      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);

      axios.get(serverUrl + "/files/waveforms/" + track.id).then((res) => {
        wavesurfer.current.load(url, res.data);
      });

      wavesurfer.current.on("loading", (data) => {
        setLoadingPercentage(data);
      });

      wavesurfer.current.on("ready", function () {
        wavesurfer.current.initPlugin("cursor");
        // wavesurfer.current.play();
        setLoadingAnimation(false);
        setTrackDuration(wavesurfer.current.getDuration());

        if (wavesurfer.current) {
          wavesurfer.current.setVolume(1);
        }
      });

      wavesurfer.current.on("play", () => {
        setIsPlaying(true);
      });

      wavesurfer.current.on("pause", () => {
        setIsPlaying(false);
      });

      // Removes events, elements and disconnects Web Audio nodes.
      // when component unmount
      return () => wavesurfer.current.destroy();
    }
  }, [url]);

  const handlePlay = () => {
    wavesurfer.current.play();
  };

  const handlePause = () => {
    wavesurfer.current.pause();
  };

  const variantsWaveForm = {
    loaded: { opacity: 1 },
    loading: { opacity: 0 },
  };

  const externalPlays = track?.externalPlays?.filter(
    (play) => play.playDuration > 0
  );

  return (
    <div className="flex flex-col ">
      <div className="sticky top-0 z-50  h-[138px]  w-full flex-shrink-0 self-start px-6 py-6  align-middle bg-white ">
        <div className="flex flex-row items-center">
          {/* <div className="controls z-50 my-auto   w-24  flex-grow-0 ">
            {!isPlaying ? (
              <PlayCircleIcon
                className="nightwind-prevent h-16 w-16 cursor-pointer text-gray-300  hover:text-gray-300/80 "
                onClick={handlePlay}
                aria-hidden="true"
              />
            ) : (
              <PauseCircleIcon
                className="nightwind-prevent h-16 w-16 cursor-pointer text-gray-300  hover:text-gray-300/80 "
                onClick={handlePause}
                aria-hidden="true"
              />
            )}
          </div> */}

          <div className="relative w-full flex-grow transition-all duration-500   bg-white ">
            <div
              style={{
                height: height || "90px",
              }}
              id="seekPoints-container"
              className="pointer-events-none absolute top-0 h-20 w-full "
            >
              {/* mix-blend-color-burn dark:mix-blend-darken */}
              {externalPlays?.map((play, index) => {
                console.log("play.date", play.date);
                return (
                  <div
                    key={"seekPoints-" + play.date}
                    id={"seekPoints-" + play.date}
                    className="pointer-events-none absolute top-0 h-full w-full transition-all duration-100"
                  >
                    <ul className="pointer-events-none relative top-0 h-full w-full rounded-md ">
                      {play.seekPoints?.map((point, index) => {
                        const startPoint =
                          point.start / wavesurfer.current?.getDuration();
                        const endPoint = point.end
                          ? point.end / wavesurfer.current?.getDuration()
                          : point.start / wavesurfer.current?.getDuration();
                        return (
                          <li
                            key={index}
                            className={classNames(
                              externalPlays.length === 1
                                ? "bg-green-800/60"
                                : externalPlays.length === 2
                                ? "bg-green-800/50"
                                : externalPlays.length === 3
                                ? "bg-green-800/40"
                                : "bg-green-800/20",
                              "pointer-events-none absolute h-full rounded-sm  "
                            )}
                            style={{
                              width: `${(endPoint - startPoint) * 100}%`,
                              left: `${startPoint * 100}%`,
                            }}
                          ></li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>{" "}
            <div
              id="waveform"
              style={{
                maxHeight: height || "50px",
              }}
              className="z-[60]  my-[10px] w-full cursor-pointer   "
              onClick={handlePlay}
              ref={waveformRef}
            />
          </div>
        </div>
      </div>
      {track.externalPlays && (
        <div className="w-full ">
          <LargePlayerAnalyticsTable
            trackDuration={trackDuration}
            data={externalPlays}
            trackid={track.id}
          />
        </div>
      )}
    </div>
  );
}
