import React, { Fragment, useEffect, useRef, useState } from "react";
import { encode } from "blurhash";

import { toast } from "react-toastify";

import { useParams } from "react-router-dom";

import { classNames } from "../shared/Utils";

import { WithContext as ReactTags } from "../ArtistTags/ReactTags";

import useAllArtists from "../hooks/useAllArtists";

import short from "short-uuid";
import { useNavigate } from "react-router-dom";

import useCreateArtist from "../hooks/useCreateArtist";
import useAuth from "../hooks/useAuth";
import useUpdateTrackArtists from "../hooks/useUpdateTrackArtists";
import ArtistHoverCard from "../ArtistHoverCard";
import GetSpotifyAvatarFunction from "../GetSpotifyAvatarFunction";
import { serverUrl } from "../hooks/Api";
import { XMarkIcon } from "@heroicons/react/20/solid";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const TracklistArtists = ({ row }) => {
  console.log("TracklistArtists row", row.original.artists);
  const { projectid } = useParams();

  const [allartistnames, setAllArtistNames] = useState([]);

  const [suggestions, setSuggestions] = useState([]);

  const allArtistsQuery = useAllArtists();

  useEffect(() => {
    if (allArtistsQuery.data) {
      const suggestions = allArtistsQuery.data.map((artist) => {
        return {
          id: artist.id,
          name: artist.name,
        };
      });
      setSuggestions(suggestions);
      setAllArtistNames(allArtistsQuery.data);
    }
  }, [allArtistsQuery.isSuccess]);

  console.log("row.original.artists", row.original.artists);

  const initArtists = row.original.artists?.map((artist, i) => {
    return {
      id: artist.id,
      name: artist.name,
      type: artist.type
        ? Array.isArray(artist.type)
          ? artist.type[i]
          : artist.type
        : "artist",
      position: artist.postition ? artist.position : i,
    };
  });

  console.log("initArtists", initArtists);
  const [tags, setTags] = React.useState(initArtists);

  const handleDelete = (id) => {
    console.log("handleDelete", id, tags);
    setTags(
      tags
        .filter((tag) => tag.id !== id)
        .map((tag, index) => {
          tag.position = index;
          return tag;
        })
    );
  };

  const createArtistQuery = useCreateArtist();

  const { auth } = useAuth();
  const navigate = useNavigate();

  const currentWorkspace = auth.workspace;

  console.log("createArtistQuery", createArtistQuery);

  const handleAddition = async (tag) => {
    if (allArtistsQuery.isSuccess) {
      console.log("handleAddition", tag);

      // check if artist already exists
      const existingArtist = allartistnames.find(
        (artist) => artist.name === tag.name
      );

      if (existingArtist) {
        const newTag = {
          id: existingArtist.id,
          name: existingArtist.name,
          type: "artist",
          position: tags.length,
        };
        setTags([...tags, newTag]);
      } else {
        console.log("CREATE NEW ARTIST");
        let id = short.generate();
        const newTag = {
          ...tag,
          id: id,
          position: tags.length,
          type: "artist",
        };
        setTags([...tags, newTag]);

        const avatarUrl = await GetSpotifyAvatarFunction(tag.name);

        // create blurhash of image
        const loadImage = async (src) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => resolve(img);
            img.onerror = (...args) => reject(args);
            img.src = src;
          });

        const getImageData = (image) => {
          image.crossOrigin = "Anonymous";
          console.log("image", image);
          console.log("image width 1", image.width);
          console.log("image height 1", image.height);
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0);
          console.log("context", context);
          console.log("image width", image.width);
          console.log("image height", image.height);
          return context.getImageData(0, 0, image.width, image.height);
        };

        const encodeImageToBlurhash = async (imageUrl) => {
          const image = await loadImage(imageUrl);
          const imageData = getImageData(image);
          return encode(
            imageData.data,
            imageData.width,
            imageData.height,
            4,
            4
          );
        };

        const avatarBlurHash = avatarUrl
          ? await encodeImageToBlurhash(avatarUrl)
          : "";

        console.log("GetSpotifyAvatarsFunction avatarUrl", avatarUrl);

        const confirmSpotifyArtist = () => {
          createArtistQuery.mutate({
            id: id,
            avatarUrl: avatarUrl,
            avatarBlurHash: avatarBlurHash,
            name: tag.name,
            workspace: currentWorkspace,
            projectid: projectid,
          });
        };

        const declineSpotifyArtist = () => {
          createArtistQuery.mutate({
            id: id,
            avatarUrl: "",
            avatarBlurHash: "",
            name: tag.name,
            workspace: currentWorkspace,
            projectid: projectid,
          });
        };

        if (avatarUrl) {
          toast(
            <div className="flex flex-col bg-white">
              <div className="flex flex-grow  flex-row">
                <div className="flex flex-col">
                  <div className="px-4 pb-3 font-medium">Artist added!</div>
                  <div className="px-4">
                    We found "{tag.name}" on Spotify. Is this the artist you
                    meant?
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div
                    style={{
                      backgroundImage: `url(${avatarUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "78px",
                      height: "78px",
                    }}
                    className="mx-auto h-16 w-16 flex-shrink-0 rounded-full bg-gray-400"
                  ></div>
                </div>
              </div>
              <div className="flex flex-row justify-end gap-3 pt-4">
                <div
                  onClick={() => {
                    declineSpotifyArtist();
                    toast.dismiss();
                  }}
                  className="text-900 mt-3 w-fit cursor-pointer rounded-md  border px-3 py-2 bg-gray-100 border-gray-300 hover:bg-gray-200"
                >
                  Not correct
                </div>
                <div
                  onClick={() => {
                    confirmSpotifyArtist();
                    toast.dismiss();
                  }}
                  className="mt-3 w-fit cursor-pointer rounded-md border px-3 py-2 text-white  bg-green-600 border-green-600 hover:bg-green-700 dark:text-white dark:bg-green-600  dark:hover:bg-green-700"
                >
                  Link artist
                </div>
              </div>
              {/* <Blurhash
              hash={avatarBlurHash}
              width={400}
              height={300}
              resolutionX={32}
              resolutionY={32}
              punch={1}
            /> */}
            </div>,
            { containerId: "createArtist" }
          );
        }
      }
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render with new positions
    setTags(newTags.map((tag, index) => ({ ...tag, position: index })));
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const handleChangeTagType = (id, tag) => {
    console.log("handleChangeTagType", id, tag);
    const newTags = tags.slice();
    // find tag index by id
    const index = newTags.findIndex((tag) => tag.id === id);

    //update the tag type
    newTags[index].type = tag;

    setTags(newTags);
  };

  const handleRemoveTag = (id) => {
    // alert("remove tag " + tags.find((tag) => tag.id === id).name);

    setTags(tags.filter((item) => item.id !== id));
  };

  const TagComponent = ({ tag, index, onDelete, onTagClick }) => {
    return (
      <ArtistHoverCard
        tags={tags}
        tag={tag}
        artistName={tag.name}
        artistId={tag.id}
        handleChangeTagType={handleChangeTagType}
        handleRemoveTag={handleRemoveTag}
        navigateToArtist={() => navigate(`/artists/${tag.id}`)}
      >
        <div
          className={classNames(
            tag.type === "artist"
              ? "border-gray-200 hover:border-gray-300"
              : " bg-gray-100 border-gray-200  hover:border-gray-300",
            "group/artist my-auto flex h-9 cursor-pointer flex-wrap items-center rounded-md border  py-1 pr-2 pl-1 text-xs "
          )}
        >
          <div
            className="mr-1.5  rounded-full bg-gray-200"
            style={{
              backgroundImage: `url(${serverUrl}/avatar/artist/${tag.id}/small)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundOrigin: "content-box",
              backgroundRepeat: "no-repeat",
              width: "22px",
              height: "22px",
            }}
          />
          {tag.name || tag}
          <div
            onClick={() => {
              handleDelete(tag.id);
            }}
            className="w-0 overflow-hidden rounded-md transition-all text-gray-500 hover:text-red-600 group-hover/artist:w-5"
          >
            <XMarkIcon className="ml-1.5 h-4 w-4 " />
          </div>
        </div>
      </ArtistHoverCard>
    );
  };

  const updateTrackArtistsQuery = useUpdateTrackArtists();

  const updateArtists = () => {
    console.log("updateArtists", tags);

    updateTrackArtistsQuery.mutate({
      trackid: row.original.id,
      projectid: projectid,
      artists: tags,
    });
  };

  console.log("init tags vs tags", initArtists, tags);

  useEffect(() => {
    if (JSON.stringify(initArtists) != JSON.stringify(tags)) {
      updateArtists(tags);
    }
  }, [tags]);

  return (
    <div className="relative  my-auto mx-2 ">
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        // handleTagClick={handleTagClick}
        inputFieldPosition="inline"
        autocomplete
        placeholder="Add artist"
        tagComponent={TagComponent}
        labelField="name"
        inputProps={{
          as: "1",
          position: 0,
        }}
        hand
      />
    </div>
  );
};

export default TracklistArtists;
