import {
  BiHeading,
  BiCheckCircle,
  BiBold,
  BiItalic,
  BiText,
  BiUnderline,
  BiCommentDots,
} from "react-icons/bi";

export const nodeTypes = [
  {
    type: "taskItem",
    title: "Task",
    description: "You can assign these to a team member.",
    icon: BiCheckCircle,
    color: "#22c55e",
    category: "block",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
  },
  {
    type: "taskHeader",
    title: "Header",
    description: "Use headers to organize tasks",
    color: "#0ea5e9",
    icon: BiHeading,
    category: "block",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode("taskHeader").run();
    },
  },
  {
    type: "H1",
    title: "Sub-header",
    description: "Large text",
    color: "#F9A826",
    icon: BiText,
    category: "block",
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })
        .run();
    },
  },
  {
    type: "commentItem",
    title: "Comment",
    description: "Add a comment",
    color: "#d946ef",
    icon: BiCommentDots,
    category: "block",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode("commentItem").run();
    },
  },
];
