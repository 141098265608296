import React from "react";
import { styled, keyframes } from "@stitches/react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { classNames } from "./shared/Utils";

const scaleIn = keyframes({
  "0%": { opacity: 0, transform: "scale(0)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

const TooltipContent = styled(Tooltip.Content, {
  borderRadius: 5,
  fontSize: "0.8rem",
  padding: "0.5rem",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  transformOrigin: "var(--radix-tooltip-content-transform-origin)",
  animation: `${scaleIn} 0.1s ease-out forwards`,
});

const TooltipTrigger = styled(Tooltip.Trigger, {
  position: "relative",
});
const StyledArrow = styled(Tooltip.Arrow, {});
function ToolTip(props) {
  return (
    <Tooltip.Provider delayDuration={20} className={props.className}>
      <Tooltip.Root className={props.className}>
        <TooltipTrigger className={props.className}>
          <div
            // style={{
            //   width: props.width,
            //   height: props.height,
            // }}
            className={props.className}
          >
            {props.children}
          </div>
        </TooltipTrigger>

        <TooltipContent
          alignOffset={props.alignOffset}
          sideOffset={props.sideOffset}
          side={props.side}
          className={classNames(
            props.hide ? "hidden" : "",
            "border bg-white text-gray-800 border-gray-300"
          )}
        >
          {props.message}
          <StyledArrow className="fill-gray-300 dark:fill-zinc-600" />
        </TooltipContent>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default ToolTip;
