import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useArtistById(artistid) {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(["artist", artistid], () =>
    axiosPrivate.get(serverUrl + "/artists/" + artistid).then((res) => res.data)
  );
}
