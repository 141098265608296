import React from "react";
import LargePlayer from "../components/LargePlayer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ArrowDownOnSquareIcon, XCircleIcon } from "@heroicons/react/24/solid";
import fileDownload from "js-file-download";
import { LightenDarkenColor } from "lighten-darken-color";
import SafeArea from "react-safe-area-component";

import { ColorExtractor } from "react-color-extractor";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import IsLoaded from "../IsLoaded";
const storage = getStorage();

import LoaderAnimation from "../components/LoaderAnimation";

import usePlay from "../components/hooks/usePlay";
import useAddPlayVisit from "../components/hooks/useAddPlayVisit";

import useGeoLocation from "react-ipgeolocation";

import logo from "../img/trackflow-logo-with-text-black.svg";
import { useMediaQuery } from "react-responsive";
import GetSpotifyAvatars from "../components/GetSpotifyAvatars";

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

function handleDownload(url, filename) {
  axios
    .get(url, {
      headers: {
        "Content-type": "Content-Type: audio/mpeg",
      },
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

const Play = () => {
  const { code } = useParams();
  const playQuery = usePlay({ code });
  const addPlaylistVisitQuery = useAddPlayVisit({ code });
  const visitorLocation = useGeoLocation();

  const [track, setTrack] = useState([]);
  const [error, setError] = useState("");

  const [images, setImages] = useState(null);
  const [colors, setColors] = useState([]);

  const [trackVisitRecorded, setTrackVisitRecorded] = useState(false);

  console.log("get spotify avatars for", track.artists);
  GetSpotifyAvatars({ artists: track.artists, setImages });

  useEffect(() => {
    if (visitorLocation.country && !trackVisitRecorded) {
      setTrackVisitRecorded(true);
      addPlaylistVisitQuery.mutate({ country_code: visitorLocation.country });
    }
  }, [visitorLocation]);

  useEffect(() => {
    if (playQuery.isSuccess) {
      if (playQuery.data.length == 0) {
        setError("This playlist does not exist anymore");
      } else {
        setTrack({
          id: playQuery.data[0].id,
          title: playQuery.data[0].title,
          artists: playQuery.data[0].artists,

          previewurl: playQuery.data[0].previewurl,
          downloadurl: playQuery.data[0].downloadurl,
        });
        console.log("playQuery", playQuery.data);
      }
    } else if (playQuery.isError) {
      setError("Couldn't download playlist data");
    }
  }, [playQuery.isSuccess]);

  console.log("track", track);

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  if (error) {
    return (
      <>
        <div className="flex h-full w-full items-center justify-center">
          <div className="rounded-md p-4 bg-red-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  There was an error retrieving this playlist
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    <li>{error}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IsLoaded />
      </>
    );
  }

  var LightenColor = function (color, percent) {
    var num = parseInt(color.replace("", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1);
  };

  const [mainColor, setMainColor] = useState(null);
  const [mainColorLight, setMainColorLight] = useState(null);
  const [textColor, setTextColor] = useState(null);
  useEffect(() => {
    if (colors.length > 0) {
      setMainColor(LightenDarkenColor(colors[2], -20));
      setMainColorLight(LightenDarkenColor(colors[2], 30));
      setTextColor(LightenDarkenColor(colors[4], 120));
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", colors[3]);
    }
  }, [colors]);

  return (
    <>
      <SafeArea top />
      {track.previewurl ? (
        <>
          <div
            style={{
              backgroundColor: isMobile ? mainColorLight : mainColorLight,
            }}
            className=" fixed top-0 left-0 flex h-full w-full flex-col items-center justify-between overflow-hidden md:p-12 "
          >
            {" "}
            <div
              className="relative  flex h-full w-full max-w-4xl flex-grow flex-row justify-between overflow-hidden md:rounded-t-lg"
              // style={{
              //   backgroundColor: mainColor,
              // }}
            >
              {mainColor &&
                images?.map((image, index) => {
                  if (index === 0) {
                    return (
                      <div
                        key={image}
                        className="  h-full w-full  overflow-hidden "
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                          }}
                          className=" top-0 left-0 h-full w-full "
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={image}
                        style={{
                          backgroundColor: colors[0],
                        }}
                        className="h-full w-full overflow-hidden"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat",
                            // filter:
                            //   " hue-rotate(" +
                            //   getHueRotate(mainColor) +
                            //   "deg) saturate(1) contrast(1.2)",
                          }}
                          className="h-full w-full opacity-80 bg-blend-screen contrast-125"
                        />
                      </div>
                    );
                  }
                })}
              <div
                style={{
                  backgroundImage: isMobile
                    ? `linear-gradient(180deg, rgba(0,0,0,0) 14%, ${mainColor} 94%)`
                    : `linear-gradient(180deg, rgba(0,0,0,0) 14%, ${mainColor} 94%)`,
                  // boxShadow: "inset 20px 0 20px -20px rgba(0,0,0,0.5)",
                }}
                className="absolute bottom-0 left-0 z-10   h-full w-full "
              ></div>
            </div>{" "}
            {images?.length > 0 && (
              <ColorExtractor
                src={images[0]}
                getColors={(colors) => setColors(colors)}
              />
            )}
            <div className="mx-auto flex  w-full md:mb-12   md:rounded-b-lg ">
              <div
                style={{
                  backgroundColor: isMobile ? mainColor : mainColor,
                }}
                className=" mx-auto -mt-3 flex w-full max-w-4xl flex-col justify-between rounded-b-lg p-6  pb-20 backdrop-blur-xl md:bg-black/20 md:p-12  md:pb-10   "
              >
                <div className="mx-auto w-full   ">
                  <div className="flex flex-row gap-6">
                    <div className="my-auto flex-grow">
                      {textColor && (
                        <LargePlayer
                          buttonColor={textColor}
                          cursorColor={textColor}
                          progressColor={textColor}
                          url={track.previewurl}
                          height={90}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row gap-6">
                    <div className="flex-grow">
                      <div
                        // style={{
                        //   color: textColor ? textColor : "white",
                        // }}
                        className="pt-6 text-4xl text-white dark:text-white"
                      >
                        {track.title}
                      </div>
                      <div className="py-2 text-2xl text-white dark:text-white/80">
                        {track.artists.map((artist, artistIdx) => (
                          <span key={artist.name}>
                            {artistIdx > 0 && ", "}
                            {artist.name}
                          </span>
                        ))}
                      </div>
                      <div className="py-2 text-2xl text-white dark:text-white/80">
                        Looking for feature
                      </div>
                    </div>
                    {track.downloadurl && (
                      <div className="flex items-end justify-end">
                        <div
                          className="cursor-pointer select-none rounded-sm border border-white/30 px-2 py-1.5 text-sm font-medium uppercase text-white/60 hover:bg-white/5 hover:text-white/80"
                          onClick={() => {
                            handleDownload(
                              track.downloadurl,
                              track.artists
                                .map((artist, i) => artist.name)
                                .join(", ") +
                                " - " +
                                track.title +
                                ".mp3"
                            );
                          }}
                        >
                          {" "}
                          download
                        </div>
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed right-0 bottom-0 -mr-3 flex scale-75 flex-col gap-2 p-3">
            {/* <a
              href="https://www.laampmusic.com/"
              className="ml-auto h-10 py-2 pl-2 opacity-50 invert hover:opacity-80"
            >
              <img
                className="h-full "
                src="https://firebasestorage.googleapis.com/v0/b/trackflow-5762f.appspot.com/o/workspaces%2Flogos%2F620441bc6b5ecbfc73e3d178.png?alt=media&token=a6e8c0ba-3385-4a60-baa6-62075961e426"
              />
            </a> */}
            <div className="flex flex-row">
              {/* <div className="my-auto text-xs opacity-30 text-white">
                Powered by
              </div> */}
              <div className="my-auto">
                {" "}
                <img src={logo} className="ml-3 w-24 opacity-30 invert" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-screen w-screen">
          <div className="my-auto mx-auto ">
            <LoaderAnimation />
          </div>
        </div>
      )}
      <IsLoaded />

      <SafeArea bottom />
    </>
  );
};

export default Play;
