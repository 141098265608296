import React, { createRef, useEffect, useRef } from "react";
import { CirclePicker } from "react-color";
import logoicon from "../../../img/trackflow-logo.svg";
import { encode } from "blurhash";
import DotsLoader from "../../DotsLoader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useField } from "formik";

import { app, storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { nanoid } from "nanoid";
import { classNames } from "../../shared/Utils";
import SpotifyAvatar from "../../SpotifyAvatar";

const OnboardingUser3Artist = ({
  handleNextStep,
  current,
  percentage,
  formik,
  workspaceCompanyType,
}) => {
  const [buttonText, setButtonText] = React.useState("later");
  const [progresspercent, setProgresspercent] = React.useState(null);
  const [avatarURL, setAvatarURL] = React.useState("");

  const userProfilePictureRef = React.createRef();

  const [field, meta, helpers] = useField({ name: "userProfilePicture" });

  useEffect(() => {
    if (current) {
      setButtonText("later");
    }
  }, [current]);

  const handleSubmit = (e) => {
    if (current) {
      e.preventDefault();
      console.log("submitted");
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        handleNextStep();
      }, 100);
    }
  };

  const handleUploadAvatar = (e) => {
    // e.preventDefault();
    console.log("e", e);
    const file = e.target?.files[0];
    console.log("file", file);

    if (!file) return;

    const extention = file.name.split(".").pop();

    const storageRef = ref(storage, `users/avatars/${nanoid()}.${extention}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          formik.setFieldValue("userProfilePicture", downloadURL);
          setAvatarURL(downloadURL);
          setButtonText("next");
        });
      }
    );
  };

  const [found, setFound] = React.useState(false);
  const [spotifyAvatarUrl, setSpotifyAvatarUrl] = React.useState("");
  const [blurHash, setBlurHash] = React.useState("");

  useEffect(() => {
    if (found && !avatarURL) {
      setButtonText("spotify");
      formik.setFieldValue("userProfilePicture", spotifyAvatarUrl);
    }
  }, [found]);

  const loadImage = async (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = (...args) => reject(args);
      img.src = src;
    });

  const getImageData = (image) => {
    image.crossOrigin = "Anonymous";
    console.log("image", image);
    console.log("image width 1", image.width);
    console.log("image height 1", image.height);
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0);
    console.log("context", context);
    console.log("image width", image.width);
    console.log("image height", image.height);
    return context.getImageData(0, 0, image.width, image.height);
  };

  const encodeImageToBlurhash = async (imageUrl) => {
    console.log("encodeImageToBlurhash", imageUrl);
    const image = await loadImage(imageUrl);
    const imageData = getImageData(image);
    return encode(imageData.data, imageData.width, imageData.height, 4, 4);
  };

  useEffect(() => {
    if (avatarURL) {
      async function calculateBlurHash() {
        const avatarBlurHash = await encodeImageToBlurhash(avatarURL);
        setBlurHash(avatarBlurHash);
        formik.setFieldValue("userProfilePictureBlurhash", avatarBlurHash);
      }
      calculateBlurHash();
    }
    if (!avatarURL && spotifyAvatarUrl) {
      async function calculateBlurHash() {
        const avatarBlurHash = await encodeImageToBlurhash(spotifyAvatarUrl);
        setBlurHash(avatarBlurHash);
        formik.setFieldValue("userProfilePictureBlurhash", avatarBlurHash);
      }
      calculateBlurHash();
    }
  }, [avatarURL, spotifyAvatarUrl]);

  return (
    <div className=" flex w-full flex-row gap-12">
      <div className="hidden w-20 flex-shrink-0 md:block ">
        <CircularProgressbar
          value={percentage}
          className={" h-20 w-20 transition-all"}
          strokeWidth={18}
          // text={`${loadingPercentage}%`}
          styles={buildStyles({
            rotation: 0,

            strokeLinecap: "butt",

            pathTransitionDuration: 1,
            pathColor: "#22c55e",
            textColor: "#d1d5db",
            trailColor: `#3f3f46`,
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className=" w-full ">
          <div className="block pb-2 text-2xl text-gray-800 sm:text-3xl ">
            Choose profile picture
          </div>

          <div
            className={classNames(
              "mt-4 mb-6 flex w-full flex-row items-start gap-10"
            )}
          >
            {!avatarURL && (
              <>
                <div
                  className={classNames(
                    found ? "flex" : "hidden",
                    " justify-center rounded-md p-6 text-center bg-gray-100"
                  )}
                >
                  {workspaceCompanyType === "label" && current && !avatarURL && (
                    <div className="flex flex-col justify-center">
                      <div className="mx-auto pb-8 text-gray-600">
                        We found this on spotify:
                      </div>
                      <div className="mx-auto h-32 w-32">
                        <SpotifyAvatar
                          setFound={setFound}
                          setImageUrl={setSpotifyAvatarUrl}
                          artistName={formik.values.userName}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-auto font-medium text-gray-600"> or</div>{" "}
              </>
            )}
            <div className="flex flex-col justify-center rounded-md p-6 text-center bg-gray-100">
              <div className="mx-auto pb-8 text-gray-600">
                Upload your own picture:
              </div>
              <div
                style={{
                  backgroundImage: `url(${avatarURL})`,
                  backgroundSize: "cover",
                }}
                className={classNames(
                  avatarURL ? "flex h-32 items-center" : "",
                  "mx-auto flex max-w-lg  cursor-pointer justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6 transition-all border-gray-300 hover:bg-gray-50 "
                )}
                onClick={() => userProfilePictureRef.current.click()}
              >
                <div className="flex w-full flex-col space-y-1 text-center">
                  {!avatarURL && (
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <div className="flex text-sm text-gray-600">
                    {progresspercent != null ? (
                      <div>
                        <div
                          className={classNames(
                            progresspercent === 100 ? "" : "h-2.5",
                            "mt-2 w-32  rounded-full bg-gray-200 dark:bg-gray-700"
                          )}
                        >
                          <div
                            className={classNames(
                              progresspercent === 100 ? "p-1" : "h-2.5",
                              "rounded-full font-medium transition-all text-white  bg-green-500 dark:text-white"
                            )}
                            style={{
                              width: `${progresspercent}%`,
                            }}
                          >
                            {progresspercent === 100 && "Done!"}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" relative cursor-pointer rounded-md font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500 hover:text-green-500">
                        <span>Upload your profile picture</span>
                      </div>
                    )}
                    <input
                      ref={userProfilePictureRef}
                      autoFocus={false}
                      id="userProfilePicture"
                      name="userProfilePicture"
                      type="file"
                      className="sr-only"
                      onChange={(e) => handleUploadAvatar(e)}
                      accept="image/png, image/jpeg, image/gif, image/svg"
                    />

                    {/* <p className="pl-1">or drag and drop</p> */}
                  </div>
                  {/* <p className="text-gray-500 text-xs">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-6">
          {/* <button
            type="button"
            onClick={handleSubmit}
            className="text-white bg-green-500  border-gray-300 inline-flex  h-14 items-center rounded-md border px-5 py-3 text-xl font-medium shadow-sm hover:bg-green-600 focus:ring-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:ring-offset-backgrounddark"
          >
            {buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />{" "}
              </div>
            ) : (
              ""
            )}
          </button> */}
          <button
            type="button"
            onClick={handleSubmit}
            className={classNames(
              avatarURL || found
                ? "my-3 inline-flex h-14 items-center  rounded-md px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
                : "inline-flex h-14  items-center rounded-md  px-5 py-3  text-xl font-medium shadow-sm bg-gray-200 border-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark dark:text-zinc-400 dark:border-zinc-600"
            )}
          >
            {buttonText == "later" ? (
              <div>Do it later</div>
            ) : buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "spotify" ? (
              <div>Use Spotify avatar</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />{" "}
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingUser3Artist;
