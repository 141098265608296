import React from "react";

class IsLoaded extends React.Component {
  componentDidMount() {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      // fade out
      ele.classList.add("available");
      setTimeout(() => {
        // remove from DOM
        ele.outerHTML = "";
      }, 2000);
    }
  }
  render() {
    return null;
  }
}

export default IsLoaded;
