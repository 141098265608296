import { statusOptionColors } from "../../shared/Utils";

import { getCode, getName } from "country-list";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import IsVisible from "react-is-visible";

import ReactCountryFlag from "react-country-flag";

import useSettings from "../../hooks/useSettings";

import "./TrackListTrackOverlaySidebarHistory.css";
import {
  ChatBubbleLeftIcon,
  TagIcon,
  ArrowUpOnSquareIcon,
  PlusCircleIcon,
  CalendarIcon,
  SortAscendingIcon,
  ChevronDownIcon,
  ArrowDownOnSquareIcon,
  ArrowUturnLeftIcon,
  HandThumbUpIcon,
  PencilIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
  LinkIcon,
  RefreshIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

import useTrackHistory from "../../hooks/useTrackHistory";
import useDeleteTrackHistory from "../../hooks/useDeleteTrackHistory";

import { format, formatDistanceToNow, formatDistanceStrict } from "date-fns";
import {
  DocumentIcon,
  InboXMarkIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Avatar from "../../Avatar";
import TrackListTrackOverlaySidebarHistoryMessage from "./TrackListTrackOverlaySidebarHistoryMessage";

const tabs = [
  { name: "All updates", href: "#", count: "", current: true },
  { name: "Uploads", href: "#", count: "2", current: false },
  { name: "Status", href: "#", count: "1", current: false },
  { name: "Deadline", href: "#", count: "1", current: false },

  // { name: "Completed", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TrackListTrackOverlaySidebarHistory({
  trackid,
  projectid,
  showAll,
}) {
  const trackHistoryQuery = useTrackHistory(trackid);
  const deleteTrackHistory = useDeleteTrackHistory();

  const { workspaceSettings } = useSettings();
  const availableStatuses = workspaceSettings?.statuses;

  console.log("trackHistoryQuery", trackHistoryQuery);

  let activity = [];
  if (trackHistoryQuery.isSuccess && trackHistoryQuery.data.length > 0) {
    activity = trackHistoryQuery.data[0].history;
    if (showAll) {
      activity = trackHistoryQuery.data[0].history;
    } else {
      activity = trackHistoryQuery.data[0].history.filter(
        (item) => item.action === "comment" || item.action === "visit"
      );
    }
  }
  const handleDelete = (historyid) => {
    console.log("delete history", historyid);
    deleteTrackHistory.mutate({ trackid, historyid });
    console.log("deleteTrackHistory", deleteTrackHistory);
  };

  return (
    <main>
      <div className=" ">
        <ul role="list" className="grid divide-y divide-gray-300 ">
          <div className="flow-root ">
            {trackHistoryQuery.isInitialLoading && (
              <>
                <div className="my-2 flex w-full animate-pulse flex-col">
                  <div className=" w-full   overflow-hidden rounded-lg border-2 border-gray-200 ">
                    <div className="p-3">
                      <div className="flex items-center">
                        <div className="relative flex w-full ">
                          <div className="h-[40px] w-[40px] rounded-full bg-gray-200" />
                          <div className="flex flex-col gap-2 pl-4">
                            <div className="h-4 w-48 rounded-full bg-gray-200" />
                            <div className="h-4 w-72 rounded-full bg-gray-200" />
                            <div className="h-4 w-12 rounded-full bg-gray-200" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 flex w-full animate-pulse flex-col">
                  <div className=" w-full   overflow-hidden rounded-lg border-2 border-gray-200 ">
                    <div className="p-3">
                      <div className="flex items-center">
                        <div className="relative flex w-full ">
                          <div className="h-[40px] w-[40px] rounded-full bg-gray-200" />
                          <div className="flex flex-col gap-2 pl-4">
                            <div className="h-4 w-56 rounded-full bg-gray-200" />
                            <div className="h-4 w-36 rounded-full bg-gray-200" />
                            <div className="h-4 w-24 rounded-full bg-gray-200" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {trackHistoryQuery.isSuccess && activity.length == 0 && (
              <div className="m-4 flex flex-col text-center text-gray-500">
                <svg
                  className="mx-auto mb-4 h-12 w-16 text-gray-400"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 74 57.15"
                  stroke="currentColor"
                  fill="none"
                >
                  <path
                    d="M24.65,1.5H6.63C3.8,1.5,1.5,3.8,1.5,6.63V50.51c0,2.83,2.3,5.13,5.13,5.13h60.74c2.83,0,5.13-2.3,5.13-5.13V14.78c0-2.83-2.3-5.13-5.13-5.13H35.1c-.71,0-1.39-.26-1.92-.74l-6.61-6.67c-.53-.47-1.21-.74-1.92-.74Z"
                    strokeWidth={2}
                  />
                  <circle cx={49} cy="30.52" r="3.07" fill="currentColor" />
                  <circle cx="36.6" cy="30.52" r="3.07" fill="currentColor" />
                  <circle cx="23.98" cy="30.52" r="3.07" fill="currentColor" />
                </svg>
                No activity yet
              </div>
            )}

            <TransitionGroup component="ul">
              {activity.length > 0 &&
                activity.map((activityItem, activityItemIdx) => (
                  <CSSTransition
                    key={trackid + " - " + activityItem._id}
                    timeout={200}
                    classNames="history-item"
                  >
                    <li>
                      <div
                        className={classNames(
                          activityItem.action === "comment"
                            ? " "
                            : "group hover:bg-gray-50",
                          " relative  rounded-md  "
                        )}
                      >
                        {activityItemIdx !== activity.length - 1 ? (
                          <span
                            className="absolute top-5 left-8 z-0 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex items-start   ">
                          {activityItem.action === "comment" ? (
                            <TrackListTrackOverlaySidebarHistoryMessage
                              activityItem={activityItem}
                              trackid={trackid}
                              projectid={projectid}
                            />
                          ) : activityItem.action === "visit" ? (
                            <>
                              {" "}
                              <div className="px-3 py-2">
                                <div className="relative flex px-1">
                                  <div className="flex h-8 w-8 items-center justify-center rounded-full ring-8  bg-white ring-white group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950 ">
                                    <LinkIcon
                                      className="h-5 w-5 text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className=" group min-w-0 flex-1 py-2">
                                <div className="text-xs  leading-8 text-gray-500">
                                  <span>
                                    link visited from
                                    <div className="ml-2 inline rounded-md p-2 bg-gray-100">
                                      <ReactCountryFlag
                                        svg
                                        countryCode={activityItem.countryCode}
                                        className="mr-2"
                                      />
                                      {activityItem.countryCode}
                                      {/* {getName(activityItem.country_code)} */}
                                    </div>
                                  </span>

                                  <div className="float-right  text-gray-400">
                                    {formatDistanceStrict(
                                      new Date(activityItem.date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                </div>
                                {/* <div className="h-0 cursor-pointer overflow-hidden pt-3 text-xs transition-all delay-300 text-gray-600 hover:underline group-hover:h-10">
                                  {" "}
                                  <ExclamationCircleIcon className=" mr-2 inline h-4 w-4" />
                                  reset link?{" "}
                                </div> */}
                              </div>
                            </>
                          ) : activityItem.action === "version" ? (
                            <>
                              <div>
                                <div className="relative px-1">
                                  <div className="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white bg-gray-100 group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950">
                                    <ArrowUpOnSquareIcon
                                      className="h-5 w-5 text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1 py-1.5">
                                <div className="text-xs text-gray-500">
                                  <a
                                    href={activityItem.person.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {activityItem.person.name}
                                  </a>{" "}
                                  uploaded a new version:{" "}
                                  <a
                                    href={activityItem.version.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {activityItem.version.name}
                                  </a>{" "}
                                  <span className="whitespace-nowrap">
                                    {activityItem.date}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : activityItem.action === "status" ? (
                            <>
                              <div className="py-3 px-3">
                                <div className="relative my-auto px-1">
                                  <div
                                    style={{
                                      backgroundColor: availableStatuses.find(
                                        (color) =>
                                          color.title ===
                                          activityItem.data.status
                                      )
                                        ? availableStatuses.find(
                                            (color) =>
                                              color.title ===
                                              activityItem.data.status
                                          ).color
                                        : "#cccccc",
                                    }}
                                    className="ml-1 mt-1 flex h-6 w-6  items-center justify-center rounded-full p-1   ring-8 ring-white group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950"
                                  >
                                    <TagIcon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1  py-3 ">
                                <div className="text-xs leading-8 text-gray-500">
                                  <span className="mr-0.5">
                                    {/* <a
                            href={activityItem.person.href}
                            className="font-medium text-gray-900"
                          >
                            {activityItem.person.name}
                          </a>{" "} */}
                                    <i>status</i> to
                                  </span>{" "}
                                  <span className="mr-0.5">
                                    {/* <Fragment key={status.name}>
                              <a
                                href={status.href}
                                className="relative inline-flex items-center  border border-gray-300 px-3  text-xs"
                              > */}
                                    <span className="inline-flex  ">
                                      <span
                                        style={{
                                          backgroundColor:
                                            statusOptionColors.find(
                                              (color) =>
                                                color.title ===
                                                activityItem.data.status
                                            )
                                              ? statusOptionColors.find(
                                                  (color) =>
                                                    color.title ===
                                                    activityItem.data.status
                                                ).color
                                              : "#cccccc",
                                        }}
                                        className="h-2 w-2 "
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="ml-2 mr-1 font-medium text-gray-900">
                                      {activityItem.data.status}
                                    </span>
                                    {/* </a>{" "}
                            </Fragment> */}
                                  </span>
                                  <div className="float-right text-gray-400">
                                    {formatDistanceStrict(
                                      new Date(activityItem.date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : activityItem.action === "upload" ? (
                            <>
                              <div className="px-3 py-3">
                                <div className="relative px-1">
                                  <div className="flex h-8 w-8  items-center justify-center rounded-full ring-8  ring-white bg-gray-500 group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950">
                                    <ArrowUpOnSquareIcon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1 py-3 ">
                                <div className="text-xs leading-8 text-gray-500">
                                  <span className="mr-0.5">new upload</span>
                                  <div className="float-right text-gray-400">
                                    {formatDistanceStrict(
                                      new Date(activityItem.date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                  <div className="my-2 flex ">
                                    <div className="mt-1 h-5 w-5 text-white">
                                      <DocumentIcon className="h-5 w-5 text-gray-700" />
                                    </div>
                                    <a
                                      href={activityItem.data.url}
                                      className="hover:underline"
                                    >
                                      <div className="my-auto w-[19rem]  truncate overflow-ellipsis pl-2 font-medium text-gray-700">
                                        {activityItem.data.url
                                          .split("/")
                                          .pop()
                                          .substring(10)}
                                        <ArrowDownOnSquareIcon className="ml-1 inline-flex h-4 w-4 text-gray-500" />
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : activityItem.action === "created" ? (
                            <>
                              <div className="px-3 py-3">
                                <div className="relative flex px-1">
                                  <div className="flex h-8 w-8 items-center justify-center rounded-full ring-8  ring-white bg-gray-100 group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950">
                                    <PlusCircleIcon
                                      className="h-5 w-5 text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-2 inline-flex ">
                                    <Avatar
                                      // bandw
                                      size={32}
                                      email={activityItem.actionBy}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1 py-3 ">
                                <div className="text-xs leading-8 text-gray-500">
                                  <span className="mr-0.5">
                                    {/* <a
                            href={activityItem.person.href}
                            className="font-medium text-gray-900"
                          >
                            {activityItem.person.name}
                          </a>{" "} */}
                                    added the track
                                  </span>{" "}
                                  <div className="float-right text-gray-400">
                                    {formatDistanceStrict(
                                      new Date(activityItem.date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : activityItem.action === "deadline" ? (
                            <>
                              <div className="py-3 pr-2 pl-3">
                                <div className="relative flex px-1">
                                  <div className="flex h-8 w-8 items-center justify-center rounded-full ring-8  bg-white ring-white group-hover:ring-gray-50 dark:ring-zinc-900 dark:group-hover:ring-zinc-950 ">
                                    <CalendarIcon
                                      className="h-5 w-5 text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  {/* <div className="inline-flex ml-2 ">
                                  <Avatar
                                    size={32}
                                    bandw
                                    userid={activityItem.actionBy}
                                  />
                                </div> */}
                                </div>
                              </div>
                              <div className=" min-w-0 flex-1 py-3">
                                <div className="text-xs leading-8 text-gray-500">
                                  <span>
                                    changed the deadline to{" "}
                                    <strong className="font-medium text-gray-900">
                                      {" "}
                                      {activityItem.data.deadline == "/"
                                        ? "no deadline"
                                        : format(
                                            new Date(
                                              activityItem.data.deadline
                                            ),
                                            "dd MMM"
                                          )}
                                    </strong>
                                  </span>

                                  {/* </a>{" "}
                            </Fragment> */}
                                  <div className="float-right  text-gray-400">
                                    {formatDistanceStrict(
                                      new Date(activityItem.date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {activityItem.action != "comment" && (
                            <div
                              className="float-right w-0 cursor-pointer overflow-hidden py-3 transition-all text-gray-500 hover:text-gray-600 group-hover:w-10 "
                              onClick={() => handleDelete(activityItem._id)}
                            >
                              <TrashIcon className=" mx-3 mt-1.5 h-5 w-5" />
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  </CSSTransition>
                ))}
            </TransitionGroup>
          </div>
        </ul>
      </div>
      {/* End main area */}
    </main>
  );
}
