import { useContext } from "react";
import SettingsContext from "../../context/SettingsProvider";

const useSettings = () => {
  const { context } = useContext(SettingsContext);

  return useContext(SettingsContext);
};

export default useSettings;
