import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BubbleMenu } from "@tiptap/react";
import React, { useState } from "react";

import {
  BiHeading,
  BiCheckCircle,
  BiBold,
  BiItalic,
  BiText,
  BiUnderline,
  BiCommentDots,
  BiStrikethrough,
  BiAlignLeft,
  BiAlignMiddle,
  BiAlignRight,
  BiHighlight,
} from "react-icons/bi";
import { classNames } from "../shared/Utils";

import { nodeTypes } from "./TipTapBubbleMenuNodeTypes";
const TipTapBubbleMenu = ({ editor }) => {
  const availableColors = [
    "#ef4444",
    "#f97316",
    "#eab308",
    "#22c55e",
    "#0ea5e9",
    "#1f2937",
  ];

  const [nodeMenuOpen, setNodeMenuOpen] = useState(false);
  return (
    <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      <div className="group bg-gray-50 border-gray-300 flex w-fit  select-none   flex-row overflow-hidden rounded-md   border  shadow-2xl">
        {/* {nodeTypes.map((node) => {
          if (editor.isActive(node.type)) {
            return (
              <div
                key={node.title}
                className="bg-gray-100 border-gray-300  w-20 border-r"
              >
                <div className="text-gray-800 bg-gray-50 border-gray-200  flex h-full w-full flex-shrink-0  flex-col items-center justify-center p-2 p-1 text-tiny  hover:bg-gray-100">
                  <div className="">
                    {node.icon ? (
                      <node.icon
                   
                        className="text-gray-600 my-auto mx-auto h-6 w-6  "
                      />
                    ) : null}
                  </div>
                  <div className="text-gray-700 px-3 pt-1"> {node.title}</div>
             
                </div>{" "}
              </div>
            );
          }
        })} */}

        <div className="flex flex-col">
          <div className="flex flex-row">
            {" "}
            <div className="border-gray-200 divide-gray-200 flex flex-row divide-x border-r">
              <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={
                  editor.isActive("bold") ? "bg-gray-200" : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiBold className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={
                  editor.isActive("italic")
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiItalic className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={
                  editor.isActive("underline")
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiUnderline className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={
                  editor.isActive("strike")
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiStrikethrough className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
            </div>
            <div className="bg-gray-200 w-[1px]" />
            <div className="border-gray-200 divide-gray-200 flex flex-row divide-x border-x">
              <button
                onClick={() =>
                  editor.chain().focus().setTextAlign("left").run()
                }
                className={
                  editor.isActive({ textAlign: "left" })
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiAlignLeft className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() =>
                  editor.chain().focus().setTextAlign("center").run()
                }
                className={
                  editor.isActive({ textAlign: "center" })
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiAlignMiddle className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() =>
                  editor.chain().focus().setTextAlign("right").run()
                }
                className={
                  editor.isActive({ textAlign: "right" })
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiAlignRight className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
                className={
                  editor.isActive("heading", { level: 1 })
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiHeading className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
              <button
                onClick={() => editor.chain().focus().toggleHighlight().run()}
                className={
                  editor.isActive("highlight")
                    ? "bg-gray-200"
                    : "hover:bg-gray-100"
                }
              >
                <div className=" flex h-10 w-10  p-1">
                  <BiHighlight className="text-gray-600 my-auto mx-auto h-5 w-5 " />
                </div>
              </button>
            </div>{" "}
          </div>
          {/* COLORS */}
          <div className="border-gray-300 h-10 border-t ">
            <div className="border-gray-200 divide-gray-200 my-auto flex h-full w-full  flex-row  ">
              {availableColors.map((color) => (
                <button
                  key={"color-" + color}
                  onClick={() => {
                    if (editor.isActive("textStyle", { color: color })) {
                      editor.chain().focus().unsetColor().run();
                    } else {
                      editor.chain().focus().setColor(color).run();
                    }
                  }}
                  className={classNames(
                    editor.isActive("textStyle", { color: color })
                      ? "ring-gray-700 ring-2 ring-inherit ring-offset-1 ring-offset-gray-50 dark:ring-offset-zinc-950 "
                      : "ring-gray-300 ring-inherit ring-offset-1 ring-offset-gray-50 hover:ring-2 dark:ring-offset-zinc-950 ",
                    "my-auto  mx-1.5  h-6 w-6 rounded-md p-0.5"
                  )}
                >
                  <div
                    className="my-auto mx-auto h-full w-full rounded-md"
                    style={{
                      backgroundColor: color,
                    }}
                  ></div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </BubbleMenu>
  );
};

export default TipTapBubbleMenu;
