import {
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
  RocketLaunchIcon,
  ArrowPathIcon,
  BeakerIcon,
  BellIcon,
  BoltIcon,
  BookOpenIcon,
  ChartBarIcon,
  CameraIcon,
  ChartPieIcon,
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleLeftIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyDollarIcon,
  FingerPrintIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  LockClosedIcon,
  LockOpenIcon,
  PhoneIcon,
  TvIcon,
  XMarkIcon,
  AcademicCapIcon,
  ArrowRightCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";

import {
  PencilIcon as PencilIconOutline,
  AdjustmentsVerticalIcon as AdjustmentsVerticalIconOutline,
  StarIcon as StarIconOutline,
  MicrophoneIcon as MicrophoneIconOutline,
  RocketLaunchIcon as RocketLaunchIconOutline,
  ArrowPathIcon as ArrowPathIconOutline,
  BeakerIcon as BeakerIconOutline,
  BellIcon as BellIconOutline,
  BoltIcon as BoltIconOutline,
  BookOpenIcon as BookOpenIconOutline,
  ChartBarIcon as ChartBarIconOutline,
  CameraIcon as CameraIconOutline,
  ChartPieIcon as ChartPieIconOutline,
  CalendarDaysIcon as CalendarDaysIconOutline,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconOutline,
  ChatBubbleLeftIcon as ChatBubbleLeftIconOutline,
  CloudArrowDownIcon as CloudArrowDownIconOutline,
  CloudArrowUpIcon as CloudArrowUpIconOutline,
  CurrencyEuroIcon as CurrencyEuroIconOutline,
  CurrencyPoundIcon as CurrencyPoundIconOutline,
  CurrencyDollarIcon as CurrencyDollarIconOutline,
  FingerPrintIcon as FingerPrintIconOutline,
  HandThumbDownIcon as HandThumbDownIconOutline,
  HandThumbUpIcon as HandThumbUpIconOutline,
  HeartIcon as HeartIconOutline,
  LockClosedIcon as LockClosedIconOutline,
  LockOpenIcon as LockOpenIconOutline,
  PhoneIcon as PhoneIconOutline,
  TvIcon as TvIconOutline,
  XMarkIcon as XMarkIconOutline,
  AcademicCapIcon as AcademicCapIconOutline,
  ArrowRightCircleIcon as ArrowRightCircleIconOutline,
  QuestionMarkCircleIcon as QuestionMarkCircleIconOutline,
} from "@heroicons/react/24/outline";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const statusOptions = [
  {
    id: 1,
    title: "Draft",
    rows: [
      {
        title: "New",
        description: "New Track",
        icon: "PencilIcon",
        color: "#bbbbbb",
        secondarycolor: "#bbbbbb",
      },
      {
        title: "Writing",
        description: "Demo track",
        icon: "PencilIcon",
        color: "#ef4444",
        secondarycolor: "#224422",
      },
    ],
  },
  {
    id: 2,
    title: "Recording",
    rows: [
      {
        title: "Feature",
        description: "Looking for feature",
        icon: "MicrophoneIcon",
        color: "#eab308",
        secondarycolor: "#d49600",
      },
      {
        title: "Vocals",
        description: "Waiting for vocal",
        icon: "MicrophoneIcon",
        color: "#eab308",
        secondarycolor: "#d49600",
      },
    ],
  },
  {
    id: 3,
    title: "Finalizing",
    rows: [
      {
        title: "Mixing",
        description: "Mixing track",
        icon: "AdjustmentsVerticalIcon",
        color: "#0ea5e9",
        secondarycolor: "#0ea5e9",
      },
      {
        title: "Mastering",
        description: "Mastering track",
        icon: "AdjustmentsVerticalIcon",
        color: "#0ea5e9",
        secondarycolor: "#0ea5e9",
      },
    ],
  },
  {
    id: 4,
    title: "Finalizing",
    rows: [
      {
        title: "Assets",
        description: "Collecting assets",
        icon: "StarIcon",
        color: "#22c55e",
        secondarycolor: "#22c55e",
      },
      {
        title: "Finished",
        description: "All done!",
        icon: "StarIcon",
        color: "#22c55e",
        secondarycolor: "#22c55e",
      },
    ],
  },
];

export const statusOptionColors = [
  {
    id: 1,
    title: "New",
    icon: "PencilIcon",
    statusIcon: PencilIcon,
    color: "#bbbbbb",
    progress: 30,
  },
  {
    id: 2,

    title: "Writing",
    icon: "PencilIcon",
    statusIcon: PencilIcon,
    color: "#ef4444",
    progress: 30,
  },
  {
    id: 3,
    title: "Feature",
    icon: "MicrophoneIcon",
    statusIcon: MicrophoneIcon,
    color: "#eab308",
    progress: 40,
  },
  {
    id: 4,
    title: "Vocals",
    icon: "MicrophoneIcon",
    statusIcon: MicrophoneIcon,
    color: "#eab308",
    progress: 60,
  },
  {
    id: 5,
    title: "Mixing",
    icon: "AdjustmentsVerticalIcon",
    statusIcon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
    progress: 80,
  },
  {
    id: 6,

    title: "Mastering",
    icon: "AdjustmentsVerticalIcon",
    statusIcon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
    progress: 90,
  },
  {
    id: 7,
    title: "Assets",
    icon: "StarIcon",
    statusIcon: StarIcon,
    color: "#22c55e",
    progress: 95,
  },
  {
    id: 8,
    title: "Finished",
    icon: "StarIcon",
    statusIcon: StarIcon,
    color: "#22c55e",
    progress: 100,
  },
];

export const getStatusIcon = (iconName) => {
  switch (iconName) {
    case "PencilIcon":
      return <PencilIcon />;
    case "MicrophoneIcon":
      return <MicrophoneIcon />;
    case "AdjustmentsVerticalIcon":
      return <AdjustmentsVerticalIcon />;
    case "StarIcon":
      return <StarIcon />;
    case "AcademicCapIcon":
      return <AcademicCapIcon />;
    case "ArrowRightCircleIcon":
      return <ArrowRightCircleIcon />;
    case "RocketLaunchIcon":
      return <RocketLaunchIcon />;
    case "ArrowPathIcon":
      return <ArrowPathIcon />;
    case "BeakerIcon":
      return <BeakerIcon />;
    case "BellIcon":
      return <BellIcon />;
    case "BoltIcon":
      return <BoltIcon />;
    case "BookOpenIcon":
      return <BookOpenIcon />;
    case "ChartBarIcon":
      return <ChartBarIcon />;
    case "CameraIcon":
      return <CameraIcon />;
    case "ChartPieIcon":
      return <ChartPieIcon />;
    case "CalendarDaysIcon":
      return <CalendarDaysIcon />;
    case "ChatBubbleLeftRightIcon":
      return <ChatBubbleLeftRightIcon />;
    case "ChatBubbleLeftIcon":
      return <ChatBubbleLeftIcon />;
    case "CloudArrowDownIcon":
      return <CloudArrowDownIcon />;
    case "CloudArrowUpIcon":
      return <CloudArrowUpIcon />;
    case "CurrencyEuroIcon":
      return <CurrencyEuroIcon />;
    case "CurrencyPoundIcon":
      return <CurrencyPoundIcon />;
    case "CurrencyDollarIcon":
      return <CurrencyDollarIcon />;
    case "FingerPrintIcon":
      return <FingerPrintIcon />;
    case "HandThumbDownIcon":
      return <HandThumbDownIcon />;
    case "HandThumbUpIcon":
      return <HandThumbUpIcon />;
    case "HeartIcon":
      return <HeartIcon />;
    case "LockClosedIcon":
      return <LockClosedIcon />;
    case "LockOpenIcon":
      return <LockOpenIcon />;
    case "PhoneIcon":
      return <PhoneIcon />;
    case "TvIcon":
      return <TvIcon />;
    case "XMarkIcon":
      return <XMarkIcon />;

    default:
      return <QuestionMarkCircleIcon />;
  }
};

export const getStatusIconOutline = (iconName) => {
  switch (iconName) {
    case "PencilIcon":
      return <PencilIconOutline />;
    case "MicrophoneIcon":
      return <MicrophoneIconOutline />;
    case "AdjustmentsVerticalIcon":
      return <AdjustmentsVerticalIconOutline />;
    case "StarIcon":
      return <StarIconOutline />;
    case "AcademicCapIcon":
      return <AcademicCapIconOutline />;
    case "ArrowRightCircleIcon":
      return <ArrowRightCircleIconOutline />;
    case "RocketLaunchIcon":
      return <RocketLaunchIconOutline />;
    case "ArrowPathIcon":
      return <ArrowPathIconOutline />;
    case "BeakerIcon":
      return <BeakerIconOutline />;
    case "BellIcon":
      return <BellIconOutline />;
    case "BoltIcon":
      return <BoltIconOutline />;
    case "BookOpenIcon":
      return <BookOpenIconOutline />;
    case "ChartBarIcon":
      return <ChartBarIconOutline />;
    case "CameraIcon":
      return <CameraIconOutline />;
    case "ChartPieIcon":
      return <ChartPieIconOutline />;
    case "CalendarDaysIcon":
      return <CalendarDaysIconOutline />;
    case "ChatBubbleLeftRightIcon":
      return <ChatBubbleLeftRightIconOutline />;
    case "ChatBubbleLeftIcon":
      return <ChatBubbleLeftIconOutline />;
    case "CloudArrowDownIcon":
      return <CloudArrowDownIconOutline />;
    case "CloudArrowUpIcon":
      return <CloudArrowUpIconOutline />;
    case "CurrencyEuroIcon":
      return <CurrencyEuroIconOutline />;
    case "CurrencyPoundIcon":
      return <CurrencyPoundIconOutline />;
    case "CurrencyDollarIcon":
      return <CurrencyDollarIconOutline />;
    case "FingerPrintIcon":
      return <FingerPrintIconOutline />;
    case "HandThumbDownIcon":
      return <HandThumbDownIconOutline />;
    case "HandThumbUpIcon":
      return <HandThumbUpIconOutline />;
    case "HeartIcon":
      return <HeartIconOutline />;
    case "LockClosedIcon":
      return <LockClosedIconOutline />;
    case "LockOpenIcon":
      return <LockOpenIconOutline />;
    case "PhoneIcon":
      return <PhoneIconOutline />;
    case "TvIcon":
      return <TvIconOutline />;
    case "XMarkIcon":
      return <XMarkIconOutline />;

    default:
      return <QuestionMarkCircleIconOutline />;
  }
};

export default {
  statusOptions,
  statusOptionColors,
  getStatusIcon,
  getStatusIconOutline,
};

export function blendColors(colorA, colorB, amount) {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round(rA + (rB - rA) * amount)
    .toString(16)
    .padStart(2, "0");
  const g = Math.round(gA + (gB - gA) * amount)
    .toString(16)
    .padStart(2, "0");
  const b = Math.round(bA + (bB - bA) * amount)
    .toString(16)
    .padStart(2, "0");
  return "#" + r + g + b;
}
