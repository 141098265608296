import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { serverUrl } from "./Api.js";
import useAxiosPrivate from "./useAxiosPrivate.js";

export default function useCreatePlaylist() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    (newPlaylist) =>
      axiosPrivate
        .post(serverUrl + "/playlists/", newPlaylist)
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("onSuccessdata", data, variables);
        queryClient.invalidateQueries(['all-playlists']);

        toast(
          <div className=" w-[35] ">
            Created playlist
            <a
              onClick={() => {
                navigate("/playlists/" + data.id);
              }}
              className=" pl-2 text-gray-400 hover:underline hover:text-gray-600"
            >
              {data.title}
            </a>
          </div>,
          {
            containerId: "main",
            type: "success",
          }
        );
      },
    }
  );
}
