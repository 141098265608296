import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";

import axios from "axios";

export default function usePlay({ code }) {
  return useQuery(
    ["p", code],
    () => axios.get(serverUrl + "/play/" + code).then((res) => res.data),
    { keepPreviousData: true }
  );
}
