import React, { useEffect, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useField } from "formik";
import DotsLoader from "../../DotsLoader";

import OnboardingTextfield from "../OnboardingTextfield";
import { classNames } from "../../shared/Utils";

const OnboardingStep0 = ({ handleNextStep, current, percentage, formik }) => {
  const [buttonText, setButtonText] = React.useState("next");
  const [error, setError] = React.useState("");

  const [field, meta] = useField({ name: "workspaceType" });

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleNext = (e) => {
    if (!meta.error && current) {
      e.preventDefault();
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        setButtonText("next");
        handleNextStep();
      }, 100);
    }
  };

  const [selectedTag, setSelectedTag] = React.useState({ name: "" });

  const availableWorkspaceTypes = [
    {
      name: "artist",
      description: "Artist",
      nameDescription: "What is your artist name?",
      color: "#F56565",
      bgcolor: "bg-green-500",
    },
    {
      name: "producer",
      description: "Producer",
      nameDescription: "What is your name?",
      color: "#F56565",
      bgcolor: "bg-green-500",
    },
    {
      name: "label",
      description: "Label",
      nameDescription: "What is the name of your label?",
      color: "#ED8936",
      bgcolor: "bg-yellow-500",
    },
    {
      name: "publisher",
      description: "Publisher",
      nameDescription: "What is the name of your publishing company?",
      color: "#ECC94B",
      bgcolor: "bg-sky-500",
    },

    {
      name: "management",
      description: "Management",
      nameDescription: "What is the name of your management company?",
      color: "#48BB78",
      bgcolor: "bg-red-500",
    },
    {
      name: "school",
      description: "School",
      nameDescription: "What is the name of your school?",
      color: "#48BB78",
      bgcolor: "bg-amber-500",
    },
    {
      name: formik.values.workspaceTypeOther,
      description: "Other",
      nameDescription: "Name your workspace:",
      color: "#4299E1",
      bgcolor: "bg-purple-500",
    },
  ];

  useEffect(() => {
    if (selectedTag.description === "Other") {
      setSelectedTag({
        ...selectedTag,
        name: formik.values.workspaceTypeOther,
      });
    }
  }, [formik.values.workspaceTypeOther]);

  useEffect(() => {
    formik.setFieldValue("workspaceType", selectedTag);
  }, [selectedTag]);

  return (
    <div className=" flex w-full flex-row gap-12">
      {/* mt-[calc(50vh-10rem)]  */}
      <div className="hidden w-20 flex-shrink-0 md:block ">
        {/* <img className="flex h-20 w-auto " src={logoicon} alt="Trackflow" /> */}
        <CircularProgressbar
          value={percentage}
          className={" h-20 w-20 transition-all"}
          strokeWidth={18}
          // text={`${loadingPercentage}%`}
          styles={buildStyles({
            rotation: 0,

            strokeLinecap: "butt",

            pathTransitionDuration: 1,
            pathColor: "#22c55e",
            textColor: "#d1d5db",
            trailColor: `#3f3f46`,
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="w-full">
          {" "}
          <label
            htmlFor="workspaceName"
            className="block pb-2 text-2xl text-gray-800 sm:text-3xl "
          >
            Describe yourself or your business
          </label>
          <div className="flex flex-wrap gap-4 py-6">
            {availableWorkspaceTypes.map((workspaceType, index) => (
              <div
                key={index}
                onClick={() => setSelectedTag(workspaceType)}
                className={classNames(
                  selectedTag &&
                    selectedTag?.description == workspaceType.description
                    ? "ring-2 ring-offset-2 ring-offset-backgrounddark text-white bg-green-400 ring-green-400"
                    : "text-gray-800 bg-gray-100 hover:bg-gray-200",

                  " flex cursor-pointer flex-row items-center gap-4  rounded-full px-5  py-3 text-xl font-medium transition-all  "
                )}
              >
                {workspaceType.description}
              </div>
            ))}
          </div>
          {selectedTag.description == "Other" && (
            <>
              <OnboardingTextfield
                current={current}
                setError={setError}
                autoFocus={false}
                name="workspaceTypeOther"
                id="workspaceTypeOther"
                helpText="Must be 8-20 characters and cannot contain special characters."
                type="text"
              />

              {/* <div className="pt-3 text-lg text-gray-400">
                This can be your artist name or company name.
              </div> */}
            </>
          )}
        </div>
        {/* {meta.error} */}
        <div>
          <button
            type="button"
            onClick={handleNext}
            className={classNames(
              meta.error
                ? "my-3 inline-flex h-14 cursor-not-allowed  items-center rounded-md px-5 py-3 text-xl  font-medium shadow-sm text-white bg-gray-200  border-gray-300"
                : "my-3 inline-flex h-14 items-center  rounded-md px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
            )}
          >
            {buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep0;
