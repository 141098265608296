import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";
import useAxiosPrivate from "./useAxiosPrivate.js";

export default function useCheckResetPasswordToken({ token }) {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["reset-password", token],
    () =>
      axiosPrivate
        .get(serverUrl + "/resetpassword/" + token)
        .then((res) => res.data),
    { keepPreviousData: true }
  );
}
