/* This example requires Tailwind CSS v2.0+ */
import {
  ExclamationCircleIcon,
  SpeakerWaveIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function Banner({ message, action, dismiss }) {
  return (
    <div className="top-0 z-[60] bg-green-100  md:sticky">
      <div className="mx-auto w-full py-3 px-3 sm:px-6 ">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg p-2 bg-green-600">
              <ExclamationCircleIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 truncate font-medium text-gray-800">
              <span className="cursor-pointer md:hidden" onClick={action}>
                {message}
              </span>
              <span
                className="hidden cursor-pointer md:inline"
                onClick={action}
              >
                {message}
              </span>
            </p>
          </div>
          <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="#"
              onClick={action}
              className="flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm border-transparent bg-white text-green-600 hover:bg-green-50 dark:hover:bg-green-900"
            >
              Continue{" "}
            </a>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only" onClick={dismiss}>
                Dismiss
              </span>
              <XMarkIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
                onClick={dismiss}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
