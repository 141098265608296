import React from "react";
import { classNames } from "./shared/Utils";

const DotsLoader = ({ color, dark, big }) => {
  return (
    <div className={big ? "scale-150" : ""}>
      <svg
        id="dots"
        width="50px"
        height="16px"
        viewBox="0 0 132 58"
        version="1.1"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="dots"
            className={classNames(
              dark
                ? "fill-gray-400 dark:fill-zinc-600"
                : "fill-gray-200 dark:fill-zinc-600"
            )}
            style={{
              fill: color && color,
            }}
          >
            <circle id="dot1" cx="25" cy="30" r="13"></circle>
            <circle id="dot2" cx="65" cy="30" r="13"></circle>
            <circle id="dot3" cx="105" cy="30" r="13"></circle>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default DotsLoader;
