import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";
import { serverUrl } from "./Api.js";
import { useAuth0 } from "@auth0/auth0-react";

export default function useNotificationCount() {
  const axiosPrivate = useAxiosPrivate();

  const { user } = useAuth0();

  const userSignUpDate = user.userInfo.signedUpAt;
  console.log("userSignUpDate", userSignUpDate);

  return useQuery(["notification-count"], () =>
    axiosPrivate
      .get(serverUrl + "/notifications/count/" + userSignUpDate)
      .then((res) => res.data)
  );
}
