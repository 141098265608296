import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackUrl() {
  const axiosPrivate = useAxiosPrivate();
  console.log("useUpdateTrackArtists");
  const queryClient = useQueryClient();
  return useMutation(
    ({
      previewurl,
      waveformurl,
      originalurl,
      downloadurl,
      trackid,
      projectid,
      playedBy,
    }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          previewurl: previewurl,
          waveformurl: waveformurl,
          originalurl: originalurl,
          downloadurl: downloadurl,
          playedBy: playedBy,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("variables ", variables);
        queryClient.invalidateQueries(['alltracks']);
        queryClient.invalidateQueries(["tracksbyproject", variables.projectid]);
        queryClient.invalidateQueries(["track", variables.trackid]);
        queryClient.invalidateQueries(["track-genre", variables.trackid]);
        queryClient.invalidateQueries(["track-tags", variables.trackid]);
        queryClient.invalidateQueries(["trtack-history", variables.trackid]);
      },
    }
  );
}
