import { Fragment, useState, useRef } from "react";

import useDeleteTrack from "./hooks/useDeleteTrack";

import { forwardRef, useImperativeHandle } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Navigate, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DeleteOverlay = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedtracks, setSelectedTracks] = useState([]);
  const [selectedproject, setSelectedProject] = useState(null);
  const deleteTrackQuery = useDeleteTrack();

  useImperativeHandle(ref, () => ({
    openDialog(selectedrows, projectid) {
      handleOpen();
      setSelectedTracks(selectedrows);
      setSelectedProject(projectid);
      console.log("selected project", projectid);
      console.log("selectedtracks", selectedrows);
    },
  }));

  const navigate = useNavigate();
  const handleDelete = (selectedtracks) => {
    if (selectedtracks.length > 0) {
      const ids = selectedtracks.map((row) => row.id);
      const deleteAll = ids.map((trackid) =>
        deleteTrackQuery.mutate({
          trackid: trackid,
          projectid: selectedproject,
        })
      );

      Promise.all(deleteAll).then((result) => {
        setOpen(false);
      });
    } else if (selectedtracks) {
      deleteTrackQuery.mutate({
        trackid: selectedtracks.id,
        projectid: selectedproject,
      });

      setOpen(false);
      // navigate("/projects/" + selectedproject + "/list");
    }
  };

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-500 dark:bg-zinc-800/75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all bg-white sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete{" "}
                        {selectedtracks?.length > 1
                          ? "these tracks?"
                          : "this track?"}{" "}
                        All of your data will be permanently removed from our
                        servers forever. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => {
                      handleDelete(selectedtracks);
                    }}
                    className="inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm ring-offset-white border-transparent text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-offset-zinc-900 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm ring-offset-white bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:focus:ring-offset-zinc-900 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default DeleteOverlay;
