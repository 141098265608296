import { ChevronUpIcon, PlayCircleIcon } from "@heroicons/react/20/solid";

import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import React from "react";
import { classNames } from "../../shared/Utils";

import Spotify from "react-spotify-embed";

export default (props) => {
  return (
    <NodeViewWrapper
      // as="p"
      className={classNames(
        "my-3 flex items-center rounded-md  py-2 text-xs font-medium text-white dark:text-white    "
      )}
      contentEditable={false}
      data-drag-handle
      draggable="true"
    >
      {/* <Spotify height={80} width="400" link={props.node.attrs.src} /> */}
    </NodeViewWrapper>
  );
};
