import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";
import useAuth from "./useAuth.js";

export default function useSeeMultipleTrackHistory() {
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axiosPrivate
        .post(serverUrl + "/history/see-multiple-track-history", data)
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("useSeeMultipleTrackHistory onSuccess");
        queryClient.invalidateQueries(["notification-count"]);
      },
    }
  );
}
