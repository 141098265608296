import { useContext } from "react";
import TrackOverlayContext from "../../context/TrackOverlayProvider";

const useTrackOverlay = () => {
  const { context } = useContext(TrackOverlayContext);

  return useContext(TrackOverlayContext);
};

export default useTrackOverlay;
