import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import IsLoaded from "../IsLoaded";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  console.log("login isAuthenticated", isAuthenticated);
  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/");
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, navigate, user]);

  return null;
};

export default Login;
