import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";

import TopNavBarUserMenu from "./TopNavBarUserMenu";

import {
  UsersIcon,
  XMarkIcon,
  WalletIcon,
  ClipboardDocumentCheckIcon,
  PuzzlePieceIcon,
  QueueListIcon,
  Bars3Icon,
  BellIcon,
} from "@heroicons/react/24/outline";

import {
  UsersIcon as UsersIconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  BellIcon as BellIconSolid,
  Bars3Icon as Bars3IconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  ArrowLeftOnRectangleIcon as ArrowLeftOnRectangleIconSolid,
  XMarkIcon as XMarkIconSolid,
  WalletIcon as WalletIconSolid,
  ArrowsRightLeftIcon as ArrowsRightLeftIconSolid,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
  PuzzlePieceIcon as PuzzlePieceIconSolid,
  QueueListIcon as QueueListIconSolid,
} from "@heroicons/react/24/solid";

const navigation = [
  {
    name: "Projects",
    href: "/projects",
    icon: WalletIcon,
    solidIcon: WalletIconSolid,
    current: true,
  },
  {
    name: "Playlists",
    href: "/playlists",
    icon: QueueListIcon,
    solidIcon: QueueListIconSolid,
    current: false,
  },

  {
    name: "Artists",
    href: "/artists",
    icon: UsersIcon,
    solidIcon: UsersIconSolid,
    current: false,
    roles: [5150],
  },

  {
    name: "Tasks",
    href: "/tasks",
    icon: ClipboardDocumentCheckIcon,
    solidIcon: ClipboardDocumentCheckIconSolid,
    current: false,
  },
  //   {
  //     name: "Extensions",
  //     href: "/extensions",
  //     icon: PuzzlePieceIcon,
  //     solidIcon: PuzzlePieceIconSolid,
  //     current: false,
  //   },
  //   {
  //     name: "Settings",
  //     href: "/settings",
  //     icon: Cog6ToothIcon,
  //     solidIcon: Cog6ToothIconSolid,
  //     current: false,
  //     roles: [1984],
  //   },
];

import logoicon from "../../img/trackflow-logo.svg";
import { useNavigation } from "react-day-picker";
import Avatar from "../Avatar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TopNavBar({ userRoles }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const navigate = useNavigate();
  return (
    <>
      <Disclosure
        as="nav"
        className={classNames(
          visible ? "" : "-top-16",
          "fixed top-0 left-0 z-[100] w-screen border-b bg-background transition-all border-zinc-300 data-[headlessui-state=open]:shadow-xl dark:bg-backgrounddark dark:border-zinc-700"
        )}
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button
                    className="inline-flex items-center justify-center rounded-md p-2 
                    text-gray-600 hover:bg-white hover:text-gray-600  focus:outline-none focus:ring-2 
                    focus:ring-inset focus:ring-white dark:text-gray-400 dark:hover:bg-zinc-900 dark:focus:ring-zinc-600"
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div
                    onClick={() => navigate("/")}
                    className="flex flex-shrink-0 cursor-pointer items-center"
                  >
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={logoicon}
                      alt="Trackflow"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={logoicon}
                      alt="Trackflow"
                    />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => {
                        if (item.roles) {
                          if (
                            !userRoles?.some((role) =>
                              item.roles.includes(role)
                            )
                          ) {
                            return null;
                          }
                        }
                        return (
                          <div
                            key={item.name}
                            onClick={() => navigate(item.href)}
                            className={classNames(
                              window.location.pathname.startsWith(item.href)
                                ? "bg-white text-gray-800 dark:text-white dark:bg-zinc-800 "
                                : "text-gray-600 hover:bg-white hover:text-gray-800 dark:text-gray-300 dark:hover:text-white dark:hover:bg-zinc-800",
                              "cursor-pointer rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={
                              window.location.pathname.startsWith(item.href)
                                ? "page"
                                : undefined
                            }
                          >
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="relative mx-3 flex rounded-full text-sm bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <TopNavBarUserMenu />
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="flex flex-col gap-1 space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => {
                  if (item.roles) {
                    if (!userRoles?.some((role) => item.roles.includes(role))) {
                      return null;
                    }
                  }
                  return (
                    <Disclosure.Button
                      key={item.name}
                      as="div"
                      onClick={() => navigate(item.href)}
                      className={classNames(
                        window.location.pathname.startsWith(item.href)
                          ? "bg-white text-gray-800 dark:bg-zinc-950 dark:text-white"
                          : "text-gray-600 hover:bg-white hover:text-gray-800 dark:text-gray-300 dark:hover:bg-zinc-950 dark:hover:text-white",
                        "ml-2 mr-4 flex cursor-pointer items-center rounded-md px-4 py-3 text-lg font-medium"
                      )}
                      aria-current={
                        window.location.pathname.startsWith(item.href)
                          ? "page"
                          : undefined
                      }
                    >
                      <item.icon
                        className="my-auto mr-3 h-5 w-5"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Disclosure.Button>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="h-16 bg-transparent"></div>
    </>
  );
}
