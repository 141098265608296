import React from 'react'
import useLogout from '../components/hooks/useLogout'

const Logout = () => {
  const logout = useLogout();
  logout();
  return (
    <div>Logout</div>
  )
}

export default Logout