import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";
import { toast } from "react-toastify";

export default function useUpdateTrackProject() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({ project, projectid, trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          project: project,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("variables ", variables);
        queryClient.invalidateQueries(['all-tracks']);
        queryClient.invalidateQueries(['recent-tracks']);
        queryClient.invalidateQueries(['all-artists']);
        queryClient.invalidateQueries(["tracksbyproject"]);
        queryClient.invalidateQueries(["project"]);
        queryClient.invalidateQueries(["tracksbyproject"]);
        queryClient.invalidateQueries(["project"]);
        queryClient.invalidateQueries(["track", variables.trackid]);
        queryClient.invalidateQueries(["project-pie-data"]);

        toast.success("Track moved", { containerId: "main" });
      },
    }
  );
}
