import React from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { storage as storage } from "../hooks/firebase";
import axios from "axios";
import fileDownload from "js-file-download";

const handleDownload = ({ track, type }) => {
  if (type == "mp3") {
    getDownloadURL(
      ref(storage, "tracks/" + track.downloadurl.split("/tracks/").pop())
    )
      .then((url) => {
        axios
          .get(url, {
            headers: {
              "Content-type": "Content-Type: audio/mpeg",
            },
            responseType: "blob",
          })
          .then((res) => {
            const filename =
              track.artists.map((artist) => artist.name).join(", ") +
              " - " +
              track.title +
              ".mp3";

            fileDownload(res.data, filename);
          });
      })
      .catch((error) => {
        console.log("firebase download error", error);
      });
  } else if (type == "original") {
    getDownloadURL(
      ref(storage, "tracks/" + track.originalurl.split("/tracks/").pop())
    )
      .then((url) => {
        axios
          .get(url, {
            headers: {
              "Content-type": "Content-Type: audio/mpeg",
            },
            responseType: "blob",
          })
          .then((res) => {
            fileDownload(res.data, track.originalurl.split(":original-").pop());
          });
      })
      .catch((error) => {
        console.log("firebase download error", error);
      });
  }
  return <div>handleDownload</div>;
};

export default handleDownload;
