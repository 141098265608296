import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import React from "react";
import { serverUrl } from "../../hooks/Api";
import useSettings from "../../hooks/useSettings";

const TrackListTrackOverlayNavbarPlaybutton = ({ track }) => {
  const {
    bottomPlayerOpen,
    setBottomPlayerOpen,
    bottomPlayerSetTrack,
    bottomPlayerUrl,
    bottomPlayerSetUrl,
    bottomPlayerIsPlaying,
    bottomPlayerSetIsPlaying,
    workspaceSettings,
  } = useSettings();

  const handleClick = () => {
    setBottomPlayerOpen(true);
    if (bottomPlayerIsPlaying) {
      if (bottomPlayerUrl === track.previewurl) {
        bottomPlayerSetIsPlaying(false);
      } else {
        bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
        bottomPlayerSetTrack(track);
        bottomPlayerSetIsPlaying(true);
      }
    } else {
      bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
      bottomPlayerSetTrack(track);
      bottomPlayerSetIsPlaying(true);
    }
  };

  return (
    <>
      {track.previewurl && (
        <div
          className="absolute top-0 left-0 z-10 flex h-full w-full cursor-pointer items-center justify-center opacity-0 transition-all duration-150 group-hover:bg-black/30 group-hover:opacity-100"
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {!bottomPlayerIsPlaying && bottomPlayerUrl === track.previewurl && (
            <PlayIcon className="h-20 w-20 text-white/70" />
          )}
          {!bottomPlayerIsPlaying && bottomPlayerUrl != track.previewurl && (
            <PlayIcon className="h-20 w-20 text-white/70" />
          )}
          {bottomPlayerIsPlaying && bottomPlayerUrl != track.previewurl && (
            <PlayIcon className="h-20 w-20 text-white/70" />
          )}
          {bottomPlayerIsPlaying && bottomPlayerUrl === track.previewurl && (
            <PauseIcon className="h-20 w-20 text-white/70" />
          )}
        </div>
      )}
    </>
  );
};

export default TrackListTrackOverlayNavbarPlaybutton;
