import React, { createContext, useEffect } from "react";

const DocumentTitleContext = createContext({});

export const DocumentTitleProvider = ({ children }) => {
  const [documentTitle, setDocumentTitle] = React.useState(0);

  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle + " | Trackflow";
    } else {
      document.title = "Trackflow";
    }
  }, [documentTitle]);

  return (
    <DocumentTitleContext.Provider value={{ documentTitle, setDocumentTitle }}>
      {children}
    </DocumentTitleContext.Provider>
  );
};

export default DocumentTitleContext;
