import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import useAllArtists from "./hooks/useAllArtists";
import useArtistById from "./hooks/useArtistById";
import SpotifyAvatar from "./SpotifyAvatar";

import { ColorExtractor } from "react-color-extractor";

const ArtistAvatar = ({ artistName, artistId, size, setColors }) => {
  const allArtistsQuery = useAllArtists();
  const [artist, setArtist] = useState(null);

  console.log("allArtistsQuery", allArtistsQuery);

  useEffect(() => {
    if (allArtistsQuery.data) {
      setArtist(allArtistsQuery.data?.find((artist) => artist.id === artistId));
    }
  }, [allArtistsQuery.data]);

  return (
    <div
      style={{
        // width: size ? size : 64,
        // height: size ? size : 64,
        borderRadius: "50%",
        overflow: "hidden",
        ...(artist?.avatarUrl
          ? {
              backgroundImage: `url(${artist.avatarUrl})`,
            }
          : {
              backgroundImage: `url("./images/empty-avatar-green.jpg")`,
            }),
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="h-full w-full "
    >
      {artist?.avatarUrl && (
        <ColorExtractor
          getColors={(colors) => {
            setColors(colors);
            console.log("setColors", colors);
          }}
          src={
            artist?.avatarUrl
              ? artist.avatarUrl
              : "./images/empty-avatar-green.jpg"
          }
        />
      )}
    </div>
  );
};

export default ArtistAvatar;
