import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import React from "react";
import Avatar from "../../Avatar";
import useAuth from "../../hooks/useAuth";
import { classNames } from "../../shared/Utils";

const CustomCommentComponent = (props) => {
  const { auth } = useAuth();
  const currentemail = auth.email;

  console.log("props.node.attrs.commentBy", props.node.attrs.commentBy);
  return (
    <NodeViewWrapper
      className={classNames(
        currentemail === props.node.attrs.commentBy
          ? "cursor-text"
          : "cursor-default",
        "react-component relative my-2 flex  w-fit flex-row items-center rounded-lg px-3  py-[3px] bg-gray-200 border-gray-300"
      )}
    >
      <div
        className="absolute -top-1.5 left-3 h-3 w-3 rotate-45 bg-gray-200"
        contentEditable={false}
      />
      <span
        className="label z-40  -mt-1.5 -ml-0.5 pr-2"
        contentEditable={false}
      >
        <Avatar
          email={props.node.attrs.commentBy}
          size={20}
          contentEditable={false}
        />
      </span>
      <NodeViewContent
        contentEditable={currentemail === props.node.attrs.commentBy}
        className={classNames(
          "content tiptap-comment my-auto pb-0.5 text-xs no-underline"
        )}
      />
    </NodeViewWrapper>
  );
};

export default CustomCommentComponent;
