import React, { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";

import Avatar from "boring-avatars";

const columnDetails = [
  {
    id: "select",
    title: "Selection",
    icon: CheckCircleIcon,
  },
  {
    id: "checked",
    title: "Checked",
    description: "These tasks have been marked as done",
    icon: CheckIcon,
  },
  {
    id: "text",
    title: "Task",
    description: "The content of the task",
    icon: ClipboardDocumentCheckIcon,
  },
  {
    id: "color",
    title: "Color",
    description: "The color of the task",
    icon: SwatchIcon,
  },
  {
    id: "trackId",
    title: "Track",
    description: "The track the task belongs to",
    icon: DocumentIcon,
  },
  {
    id: "depth",
    title: "Type",
    description: "The user the task is assigned to",
    icon: UserIcon,
  },
  {
    id: "assignedTo",
    title: "Assigned to",
    description: "The user the task is assigned to",
    icon: UserIcon,
  },
  {
    id: "priority",
    title: "Priority",
    description: "The priority of the task",
    icon: RiFlag2Fill,
  },
];

import {
  GroupingState,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  Column,
  Table,
  ColumnFiltersState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  ColumnResizeMode,
  SortingState,
  FilterFn,
  SortingFn,
  FilterFns,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  DebouncedInput,
  IndeterminateCheckbox,
  PageButtons,
} from "./Projects/ProjectsList/ProjectsListFunctions";

import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  DocumentIcon,
  SwatchIcon,
  TableCellsIcon,
  BoltIcon,
} from "@heroicons/react/24/solid";

import { UserIcon as UserIconOutline } from "@heroicons/react/24/outline";

import {
  BoltIcon as BoltIconOutline,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconOutline,
} from "@heroicons/react/24/outline";

import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

import { CheckCircleIcon as CheckCircleIconOutline } from "@heroicons/react/24/outline";
import { classNames } from "./shared/Utils";
import MenuToggle from "./MenuToggle";
import {
  SortDownIcon,
  SortIcon,
  SortUpIcon,
} from "./shared/TrackListTableIcons";
import { formatDistanceToNow } from "date-fns";
import { RiFlag2Fill } from "react-icons/ri";
import useSeeAllExternalPlays from "./hooks/useSeeAllExternalPlays";
import useAuth from "./hooks/useAuth";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
function LargePlayerAnalyticsTable({
  miniHeader,
  data,
  trackDuration,
  trackid,
}) {
  const rerender = React.useReducer(() => ({}), {})[1];
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnResizeMode, setColumnResizeMode] = React.useState("onChange");
  const [grouping, setGrouping] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});

  const columns = React.useMemo(
    () => [
      // {
      //   id: "select",
      //   header: ({ table }) => (
      //     <IndeterminateCheckbox
      //       {...{
      //         checked: table.getIsAllRowsSelected(),
      //         indeterminate: table.getIsSomeRowsSelected(),
      //         onChange: table.getToggleAllRowsSelectedHandler(),
      //       }}
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <div className="my-auto px-1">
      //       <IndeterminateCheckbox
      //         {...{
      //           checked: row.getIsSelected(),
      //           indeterminate: row.getIsSomeSelected(),
      //           onChange: row.getToggleSelectedHandler(),
      //         }}
      //       />
      //     </div>
      //   ),
      //   size: 33,
      //   minSize: 33,
      //   maxSize: 33,
      //   sortable: false,
      //   groupable: false,
      //   type: "Display Columns",
      //   title: "Selection",
      //   icon: CheckCircleIcon,
      // },

      {
        accessorKey: "listenerName",
        header: <span className="pl-4">Listener</span>,
        size: 300,
        minSize: 300,
        maxSize: 600,
        cell: (props) => (
          <div className="flex items-center pl-4">
            {props.row.original.listenerName ? (
              <div className="flex flex-row items-center gap-2 text-xs font-normal text-gray-800">
                <div className="h-5 w-5 rounded-full p-0.5 bg-white">
                  <UserIcon
                    style={{
                      color: stringToColour(props.row.original.listenerName),
                    }}
                    className="h-4 w-4 text-gray-400"
                  />
                </div>
                <div>{props.row.original.listenerName}</div>
              </div>
            ) : (
              <div className="flex flex-row items-center gap-3">
                <UserIcon className="h-5 w-5 text-gray-400" />
              </div>
            )}
          </div>
        ),
        sortable: true,
        groupable: true,
      },

      {
        accessorKey: "countryCode",
        header: <span className="pl-2">From</span>,
        size: 200,
        minSize: 200,
        maxSize: 400,

        cell: (props) => (
          <div className="mx-2 flex items-center pl-2 text-xs font-normal text-gray-700">
            {props.row.original.countryCode && (
              <>
                <ReactCountryFlag
                  svg
                  style={{
                    width: "1.2em",
                    height: "1em",
                    marginRight: "0.5em",
                  }}
                  countryCode={props.row.original.countryCode}
                />
                {countries[props.row.original.countryCode].name}
              </>
            )}
          </div>
        ),
        sortable: true,
        groupable: true,

        icon: DocumentIcon,
      },

      {
        id: "date",
        accessorKey: "date",
        header: "When",
        size: 300,
        minSize: 200,
        maxSize: 400,

        cell: (props) => (
          <div className="mx-2 flex items-center text-xs  text-gray-600">
            {formatDistanceToNow(new Date(props.row.original.date), {
              addSuffix: true,
            })}
          </div>
        ),
        sortable: true,
        groupable: false,
        title: "Date",
        icon: ClipboardDocumentCheckIcon,
      },
      {
        accessorKey: "deviceType",
        id: "deviceType",
        header: "Device",
        size: 100,
        minSize: 100,
        maxSize: 200,

        cell: (props) => (
          <div className="mx-2 flex items-center text-xs font-normal text-gray-500">
            {props.row.original.deviceType ? (
              props.row.original.deviceType === "mobile" ? (
                <>
                  <DevicePhoneMobileIcon className="mr-1 h-4 w-4 text-gray-500" />{" "}
                  mobile
                </>
              ) : (
                <>
                  <ComputerDesktopIcon className="mr-1 h-4 w-4 text-gray-500" />{" "}
                  desktop
                </>
              )
            ) : (
              <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />
            )}
          </div>
        ),
        sortable: true,
        groupable: true,

        icon: DocumentIcon,
      },
      {
        id: "playDuration",
        accessorKey: "playDuration",
        header: "Duration",
        enableColumnFilter: true,
        size: 150,
        minSize: 150,
        maxSize: 300,

        cell: (props) => (
          <div className="mx-2 flex w-full items-center pr-4 text-tiny  text-gray-600">
            <div className="mr-1.5 h-3 flex-grow overflow-hidden  rounded-sm border border-gray-500">
              <div
                className="h-full bg-gray-400"
                style={{
                  width: `${
                    props.row.original.playDuration
                      ? (props.row.original.playDuration / trackDuration) * 100
                      : 0
                  }%`,
                }}
              />
            </div>
            <div className="w-8 flex-shrink-0 flex-grow-0 text-right">
              {props.row.original.playDuration
                ? Math.round(props.row.original.playDuration) + "s"
                : ""}
            </div>
          </div>
        ),
        sortable: true,
        groupable: false,
        title: "Tracks",
        icon: ClipboardDocumentCheckIcon,
      },
    ],
    [trackDuration]
  );

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    miniHeader,

    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      // expanded: true,
      // sorting: [{ id: "date", desc: true }],
    },
    state: {
      grouping,
      columnFilters,
      globalFilter,
      sorting,
      columnVisibility,
      rowSelection,
    },

    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugHeaders: true,
    debugColumns: false,
    onSortingChange: setSorting,

    getSortedRowModel: getSortedRowModel(),
  });

  console.log("table data", data);

  const [firstLoad, setFirstLoad] = React.useState(true);
  useEffect(() => {
    if (firstLoad) {
      console.log("SET PAGE SIZE ON LOAD");
      table?.setPageSize(200);
      setFirstLoad(false);
    }
  }, []);

  const handleMouseOver = (date) => (e) => {
    console.log("handleMouseOver", date);

    data.map((play, i) => {
      console.log("play.date", `seekPoints-${play.date}`);
      const el = document.getElementById(`seekPoints-${play.date}`);
      console.log("element", el);
      if (play.date === date) {
        el.style.opacity = 1;
        el.classList.remove("seekPoints");
        el.classList.add("seekPoints-hover");
      } else {
        el.style.opacity = 0;
        el.classList.remove("seekPoints-hover");
        el.classList.add("seekPoints");
      }
    });
  };

  const handleMouseOut = (date) => (e) => {
    data.map((play, i) => {
      document.getElementById(`seekPoints-${play.date}`).style.opacity = 1;
      document
        .getElementById(`seekPoints-${play.date}`)
        .classList.remove("seekPoints-hover");
      document
        .getElementById(`seekPoints-${play.date}`)
        .classList.add("seekPoints");
    });
  };

  const [showEmpty, setShowEmpty] = React.useState(false);

  const toggleEmpty = () => {
    if (!showEmpty) {
      setShowEmpty(true);
      table.getColumn("playDuration").setFilterValue("");
    }
    if (showEmpty) {
      setShowEmpty(false);
      table.getColumn("playDuration").setFilterValue([1, 100000]);
    }
  };

  const seeAllExternalPlaysQuery = useSeeAllExternalPlays();

  useEffect(() => {
    if (table) {
      table.setSorting([
        {
          id: "date",
          desc: true,
        },
      ]);
      table.getColumn("playDuration").setFilterValue([1, 100000]);

      seeAllExternalPlaysQuery.mutate({ trackid: trackid });
    }
  }, [table]);

  const { auth } = useAuth();

  return (
    <div className="flex w-full flex-col">
      <div>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0",
          }}
          className="my-0 w-full  "
        >
          <thead className="rounded-t-md">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0",
                }}
                key={headerGroup.id}
                className={classNames(
                  "sticky   top-[8.64rem] z-40 self-start  text-left text-base  font-medium    uppercase tracking-wider bg-white text-gray-500 border-gray-200 hover:bg-gray-50 "
                )}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize(),
                        },
                      }}
                      className={classNames(
                        "group z-40 border-y  px-2  text-left  text-xs   font-medium uppercase tracking-wider text-gray-500 border-gray-200 hover:bg-gray-50 "
                      )}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          className={classNames(
                            header.column.columnDef.id === "select" ? "" : "",
                            " my-auto flex cursor-pointer select-none flex-row justify-between py-2 "
                          )}
                        >
                          <div
                            className={classNames(
                              header.column.columnDef.sortable
                                ? header.column.columnDef.size < 50
                                  ? header.column.getIsSorted()
                                    ? "hidden "
                                    : "group-hover:hidden"
                                  : ""
                                : " w-full "
                            )}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                          {!header.column.columnDef.sortable ? (
                            ""
                          ) : header.column.getIsSorted() === "asc" ? (
                            <div>
                              <SortUpIcon className="mx-auto h-5   w-5  rounded-full  p-1 text-gray-500 " />
                            </div>
                          ) : header.column.getIsSorted() === "desc" ? (
                            <div>
                              <SortDownIcon className="mx-auto h-5  w-5  rounded-full  p-1 text-gray-500 " />
                            </div>
                          ) : (
                            <div>
                              <SortIcon
                                className={classNames(
                                  header.column.columnDef.size < 50
                                    ? "hidden group-hover:block"
                                    : "opacity-0 group-hover:opacity-100",
                                  "mx-auto -mt-0  h-5 w-5   rounded-full p-1 text-gray-400 "
                                )}
                              />
                            </div>
                          )}
                          {header.column.getCanGroup() ? (
                            <></>
                          ) : // <button
                          //   {...{
                          //     onClick:
                          //       header.column.getToggleGroupingHandler(),
                          //     style: {
                          //       cursor: "pointer",
                          //     },
                          //   }}
                          // >
                          //   {header.column.getIsGrouped()
                          //     ? `🛑(${header.column.getGroupedIndex()}) `
                          //     : `👊 `}
                          // </button>
                          null}{" "}
                        </div>
                      )}
                    </th>
                  );
                })}
                {/* <div className="h-[1px bg-gray-300 w-full" /> */}
              </tr>
            ))}
          </thead>
          {/* {table.getRowModel().rows.length === 0 && (
                  <tbody className="relative divide-x border-b  border-gray-200 divide-gray-200">
                    <tr>
                      <td colSpan={100}>
                        <div className="flex h-full w-full p-6 text-gray-500">
                          No projects found
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )} */}
          <tbody className="relative divide-x  border-b  border-gray-200 divide-gray-200">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={classNames(
                    row.getIsSelected()
                      ? "bg-gray-100 hover:bg-gray-100"
                      : "hover:bg-gray-50",
                    "group divide-x bg-white"
                  )}
                  onMouseOver={handleMouseOver(row.original.date)}
                  onMouseOut={handleMouseOut(row.original.date)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize(),
                          },
                        }}
                        {...{
                          key: cell.id,
                          // style: {
                          //   background: cell.getIsGrouped()
                          //     ? "#0aff0082" // light green
                          //     : cell.getIsAggregated()
                          //     ? "#ffa50078" // light orange
                          //     : cell.getIsPlaceholder()
                          //     ? "#ff000042" // light red
                          //     : "white",
                          // },
                        }}
                        className={classNames(
                          cell.getIsGrouped()
                            ? "h-16 bg-gray-50  "
                            : cell.getIsAggregated()
                            ? "bg-gray-50   "
                            : cell.getIsPlaceholder()
                            ? "bg-white  "
                            : "h-[2.6rem]  ",
                          row.original.seenBy?.includes(auth.email)
                            ? ""
                            : " bg-green-100 dark:bg-green-700/40 ",
                          "relative h-full divide-y border-b  border-gray-200  dark:border-zinc-800/80  "
                        )}
                      >
                        {cell.getIsGrouped() ? (
                          // If it's a grouped cell, add an expander and row count

                          <div
                            {...{
                              onClick: row.getToggleExpandedHandler(),
                              style: {
                                cursor: row.getCanExpand()
                                  ? "pointer"
                                  : "normal",
                              },
                            }}
                            className=" absolute z-20 -mt-[31px] ml-2 flex h-[62px] w-96 flex-row  bg-gray-50  border-gray-200   "
                          >
                            <div className="my-auto h-8 w-8 cursor-pointer rounded-full p-1.5 text-gray-700  border-gray-400 hover:bg-gray-100">
                              {row.getIsExpanded() ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronRightIcon />
                              )}
                            </div>
                            <div className="my-auto  ">
                              {flexRender(
                                cell.column.columnDef.groupCell,
                                cell.getContext()
                              )}
                            </div>
                            {/* <div className="my-auto text-gray-500">
                                    ({row.subRows.length})
                                  </div> */}
                          </div>
                        ) : cell.getIsAggregated() ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          flexRender(
                            cell.column.columnDef.aggregatedCell ??
                              cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          <div className={classNames("flex h-full ")}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                            {/* {JSON.stringify(row.original.seenBy)} */}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
        {table.getPageCount() > 1 && (
          <div className="rounded-b-md  bg-white ">
            <PageButtons table={table} />
          </div>
        )}
      </div>
      {/* <DebouncedInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
              className="font-lg border-block border p-2 shadow"
              placeholder="Search all columns..."
            /> */}
      {/* <div className="relative flex w-full justify-end py-3 px-6 ">
        <div className="mr-3 text-sm">
          <label htmlFor="showEmpty" className=" text-tiny text-gray-600">
            Show visitors that didn't listen
          </label>
          <span id="show-empty-description" className="text-gray-500">
            <span className="sr-only">Show visitors that didn't listen </span>
          </span>
        </div>
        <div className="flex h-5 items-center">
          <input
            id="showEmpty"
            aria-describedby="show-empty-description"
            name="showEmpty"
            type="checkbox"
            checked={showEmpty}
            onChange={toggleEmpty}
            className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
          />
        </div>
      </div> */}
    </div>
  );
}

export default LargePlayerAnalyticsTable;
