import React, { useEffect } from "react";
import Lottie from "react-lottie-player";

import * as notificationsBellAnimation from "../../animations/notifications-bell.json";
import { classNames } from "../shared/Utils";

import useNotificationCount from "../hooks/useNotificationCount";

const NavBarNotifictionsButton = ({
  notificationsBarOpen,
  toggleNotificationsBarOpen,
}) => {
  const [notificationCount, setNotificationCount] = React.useState(0);

  const notificationCountQuery = useNotificationCount();

  useEffect(() => {
    if (notificationCountQuery.data || notificationCountQuery.data === 0) {
      setNotificationCount(notificationCountQuery.data);
    }
  }, [notificationCountQuery.data]);

  const [play, setPlay] = React.useState(false);
  useEffect(() => {
    if (document.getElementById("notifications-bubble")) {
      document
        .getElementById("notifications-bubble")
        .classList.add("notifications-bubble-animation");
    }
    setTimeout(function () {
      if (document.getElementById("notifications-bubble")) {
        document
          .getElementById("notifications-bubble")
          .classList.remove("notifications-bubble-animation");
      }
    }, 1000);
    if (notificationCount) {
      setPlay(true);
    }
  }, [notificationCount]);

  // to string

  return (
    <div
      onClick={toggleNotificationsBarOpen}
      className={classNames(
        notificationsBarOpen
          ? "text-zinc-300 bg-zinc-700 dark:text-zinc-300 dark:bg-zinc-800"
          : "",
        "m-3 flex h-10 w-10 cursor-pointer rounded-md  p-1 hover:bg-zinc-700 dark:hover:bg-zinc-800"
      )}
    >
      <div className="relative -left-0.5 my-auto mx-auto">
        {notificationCount > 0 ? (
          <div className="">
            <Lottie
              play={play}
              //   loop={false}
              onLoopComplete={() => {
                setPlay(false);
              }}
              animationData={notificationsBellAnimation}
              style={{
                width: 36,
                height: 36,
              }}
            />
          </div>
        ) : (
          <div className="ml-1 h-5 w-5 text-zinc-400">
            <svg
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22.55 24.05"
              fill="none"
            >
              <path
                d="M11.39,20.13c-2.78,0-5.57,0-8.35,0-.74,0-1.5,0-1.88-.8-.4-.84,.04-1.46,.6-2.03,1.53-1.56,2.06-3.51,2.15-5.6,.08-1.83,.22-3.62,1.32-5.2,.96-1.38,2.23-2.33,3.83-2.82,.59-.18,.85-.43,.85-1.09,0-.96,.62-1.58,1.42-1.58,.8,0,1.44,.62,1.42,1.56-.02,.72,.28,.94,.91,1.14,2.95,.93,4.84,3.45,5,6.57,.12,2.29,.24,4.57,1.77,6.47,.21,.26,.5,.48,.62,.77,.22,.54,.54,1.16,.45,1.69-.12,.71-.83,.94-1.56,.94-2.85,0-5.7,0-8.55,0Z"
                style={{
                  stroke: "currentColor",
                  strokeMiterlimit: 10,
                  strokeWidth: "1.2px",
                }}
              />
              <path
                d="M11.43,20.13c-2.78,0-5.57,0-8.35,0-.74,0-1.5,0-1.88-.8-.4-.84,.04-1.46,.6-2.03,1.53-1.56,2.06-3.51,2.15-5.6,.08-1.83,.22-3.62,1.32-5.2,.96-1.38,2.23-2.33,3.83-2.82,.59-.18,.85-.43,.85-1.09,0-.96,.62-1.58,1.42-1.58,.8,0,1.44,.62,1.42,1.56-.02,.72,.28,.94,.91,1.14,2.95,.93,4.84,3.45,5,6.57,.12,2.29,.24,4.57,1.77,6.47,.21,.26,.5,.48,.62,.77,.22,.54,.54,1.16,.45,1.69-.12,.71-.83,.94-1.56,.94-2.85,0-5.7,0-8.55,0Z"
                style={{
                  stroke: "currentColor",
                  strokeMiterlimit: 10,
                  strokeWidth: "1.2px",
                }}
              />
              <path
                d="M8.4,20.13h5.7c.15,1.49-1.06,2.81-2.66,2.91-1.63,.1-2.92-1.1-3.04-2.91Z"
                style={{
                  stroke: "currentColor",
                  strokeMiterlimit: 10,
                  strokeWidth: "1.2px",
                }}
              />
            </svg>
          </div>
        )}

        <div
          id="notifications-bubble"
          className={classNames(
            notificationCount > 0 ? "absolute" : "invisible",
            "absolute top-3 left-4 h-4 min-w-[1rem] rounded-full px-0.5 text-center text-[0.65rem] font-bold text-zinc-800 bg-green-500 dark:text-zinc-800"
          )}
        >
          {notificationCount}
        </div>
      </div>
    </div>
  );
};

export default NavBarNotifictionsButton;
