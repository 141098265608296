// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGMXn1bT5PgpOQeKuaucyQyEoLnrPZnCQ",
  authDomain: "trackflow-5762f.firebaseapp.com",
  projectId: "trackflow-5762f",
  storageBucket: "trackflow-5762f.appspot.com",
  messagingSenderId: "482128047477",
  appId: "1:482128047477:web:da656a6efd0fb19141773c",
  measurementId: "G-ZF6Q6TTGH8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);

// logEvent(analytics, "firebase analytics initialized");

export const storage = getStorage(app);
