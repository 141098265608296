/* This example requires Tailwind CSS v2.0+ */
import { useState, useMemo, useEffect } from "react";
import React, { CSSProperties } from "react";
import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { classNames, getStatusIcon } from ".././shared/Utils";

import { useQueryClient } from "@tanstack/react-query";

import Select, { StylesConfig } from "react-select";

import { defaultTheme } from "react-select";
import { availableGenres } from "../shared/Genres";

const { colors } = defaultTheme;
import useUpdateTrackGenre from "../hooks/useUpdateTrackGenre";

import "./TrackListStatus.css";
import { useMutation } from "@tanstack/react-query";
import { Cog6ToothIcon, QueueListIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import useTrackGenre from "../hooks/useTrackGenre";

String.prototype.toColor = function () {
  var colors = [
    "#ef4444",
    "#f97316",
    "#f59e0b",
    "#eab308",
    "#84cc16",
    "#22c55e",
    "#03a9f4",
    "#10b981",
    "#14b8a6",
    "#06b6d4",
    "#0ea5e9",
    "#3b82f6",
    "#6366f1",
    "#d946ef",
    "#ec4899",
    "#f43f5e",
  ];

  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    zIndex: 100,
    backgroundColor: "white",
  }),
  menu: () => ({
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",

    zIndex: 100,
  }),
  container: () => ({
    zIndex: 100,
  }),
  //   menuList: () => ({
  //     zIndex: 100,
  //   }),
};

const Option = (props) => {
  console.log("props", props);

  const genre = props.data.value.split("---")[0];
  const subGenre = props.data.value.split("---")[1];
  return (
    <div
      className="mx flex cursor-pointer py-2  hover:bg-gray-100"
      onClick={() => {
        console.log("click");
        props.setValue(
          { value: props.children, label: props.children },
          "clear"
        );
      }}
    >
      <div className=" my-auto  flex flex-row whitespace-nowrap  text-[0.82rem] text-gray-700 border-gray-300    ">
        <ul className="breadcrumb my-auto mx-3 flex flex-row ">
          <li
            className="breadcrumb__item border bg-gray-50 border-gray-200"
            // style={{
            //   backgroundColor: genre ? genre.toColor() : "#00000000",
            // }}
          >
            <span className="breadcrumb__inner transition-all ">
              <span className="breadcrumb__title whitespace-pre  text-xs text-gray-500   ">
                {genre}
              </span>
            </span>
          </li>
          <li
            className="breadcrumb__item border border-gray-300"
            // style={{
            //   backgroundColor: subGenre ? subGenre.toColor() : "#00000000",
            // }}
          >
            <span className="breadcrumb__inner">
              <span
                className="breadcrumb__title text-xs brightness-75 dark:brightness-100"
                style={{
                  color: subGenre ? subGenre.toColor() : "#00000000",
                }}
              >
                {subGenre}
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
});

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span className="mb-1">{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
);

const TrackListGenre = ({ row }) => {
  console.log("render TrackListStatus");

  const [value, setValue] = useState("");

  useEffect(() => {
    if (row.original.genre) setValue(row.original.genre);
  }, [row.original.genre]);

  const genre = row.original.genre
    ? row.original.genre?.split("---")[0]
    : row.original.predictedGenres
    ? row.original.predictedGenres[0]?.name?.split("---")[0]
      ? row.original.predictedGenres[0]?.name?.split("---")[0]
      : ""
    : "";
  const genreShort = genre?.split(" ")[0].slice(0, 3);
  const subGenre = row.original.genre
    ? row.original.genre?.split("---")[1]
    : row.original.predictedGenres
    ? row.original.predictedGenres[0]?.name?.split("---")[1]
      ? row.original.predictedGenres[0]?.name?.split("---")[1]
      : ""
    : "";

  const updateTrackGenreQuery = useUpdateTrackGenre();

  const stateOptions = row.original.predictedGenres?.map((genre) => {
    return {
      value: genre.name,
      label: genre.name,
    };
  });

  const queueStatus = row.original.predictionStatus
    ? row.original.predictionStatus
    : "loading";

  console.log("genrevalue", value);
  if (queueStatus === "finished") {
    return (
      <>
        <PopoverPrimitive.Root>
          <PopoverPrimitive.Trigger className="h-full w-full text-black">
            <div
              id={"genre-" + row.original.id}
              className={classNames(
                " group/trigger relative flex h-full w-full   cursor-pointer select-none  overflow-hidden border-0 text-center hover:bg-gray-100   md:px-2"
              )}
            >
              <ul className="breadcrumb my-auto mx-1 flex flex-row items-center ">
                {/* <li
                  className="breadcrumb__item border bg-gray-50 border-gray-200"
                  // style={{
                  //   backgroundColor: genre ? genre.toColor() : "#00000000",
                  // }}
                >
                  <span className="breadcrumb__inner transition-all duration-150 ">
                    <span className="breadcrumb__title whitespace-pre  text-[0.7rem] uppercase text-gray-500 group-hover/trigger:text-gray-600  ">
                      {genreShort}
                    </span>
                  </span>
                </li> */}
                <li
                  className="breadcrumb__item border  border-gray-300"
                  style={{
                    backgroundColor: subGenre
                      ? subGenre.toColor() + "09"
                      : "#00000000",
                    // borderColor: subGenre ? subGenre.toColor() : "#00000000",
                  }}
                >
                  <span className="breadcrumb__inner">
                    <span
                      className="breadcrumb__title text-xs brightness-75 dark:brightness-95"
                      style={{
                        color: subGenre ? subGenre.toColor() : "#00000000",
                      }}
                    >
                      {subGenre}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </PopoverPrimitive.Trigger>

          <StyledContent className="  rounded-md border bg-white  border-white dark:bg-zinc-950  dark:border-zinc-600 ">
            <PopoverPrimitive.Close>
              <div className="">
                <Select
                  className="artist-select-container"
                  classNamePrefix="my-react-select"
                  formatGroupLabel={formatGroupLabel}
                  autoFocus
                  backspaceRemovesValue={false}
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Option,
                  }}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  onChange={(newValue) => {
                    setValue(newValue.value);
                    updateTrackGenreQuery.mutate({
                      trackid: row.original.id,
                      projectid: row.original.projectid,
                      genre: newValue.value,
                      predictedGenres: row.original.predictedGenres,
                    });
                  }}
                  options={stateOptions}
                  placeholder="Search..."
                  styles={selectStyles}
                  tabSelectsValue={false}
                  value={value}
                />
              </div>
            </PopoverPrimitive.Close>

            <PopoverPrimitive.Arrow
              width={11}
              height={5}
              offset={80}
              className="fill-gray-50 dark:fill-gray-600"
            />
          </StyledContent>
        </PopoverPrimitive.Root>
      </>
    );
  } else {
    return (
      <div
        className={classNames(
          queueStatus === "processing" && "barberpole",
          " flex h-full w-full overflow-hidden text-sm    "
        )}
      >
        <div className=" relative my-auto  h-full w-full  items-center whitespace-nowrap">
          <div className=" flex h-full w-full   overflow-hidden ">
            <div className="my-auto mx-2 flex h-full ">
              <div
                className={classNames(
                  "my-auto  rounded-full  px-2  py-1 transition-all  bg-gray-100   "
                )}
              >
                {queueStatus !== "finished" && (
                  <>
                    {queueStatus === "queued" ? (
                      <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                        <QueueListIcon className="mr-2 h-4 w-4" />
                        {queueStatus}
                      </div>
                    ) : queueStatus === "processing" ? (
                      <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                        <Cog6ToothIcon className="mr-2 h-4 w-4 animate-spin" />
                        {queueStatus}
                      </div>
                    ) : (
                      <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                        {queueStatus}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const Svg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    // {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export default TrackListGenre;
