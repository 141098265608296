import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import React from "react";
import useAuth from "./hooks/useAuth";
import Iubenda from "react-iubenda-policy";
import useSettings from "./hooks/useSettings";
import { classNames } from "./shared/Utils";

const Footer = () => {
  const myPolicy = 74223795;
  const { bottomPlayerOpen, notificationsBarFixed, sideOffset } = useSettings();
  return (
    <div
      style={{
        paddingRight: sideOffset,
      }}
      className={classNames(
        bottomPlayerOpen ? "bottom-[7.5rem]" : "bottom-[3rem]",

        "absolute  w-full transition-all "
      )}
    >
      <footer className="-mb-6  w-auto pt-6">
        <div className="mx-auto flex max-w-7xl flex-row items-center justify-center gap-6 p-6 text-xs border-slate-300 text-gray-500 ">
          <div className="flex flex-shrink-0 flex-row flex-nowrap gap-6">
            <Iubenda id={myPolicy} type="privacy" styling="nostyle">
              <span className="hover:underline">Privacy Policy</span>
            </Iubenda>

            <Iubenda id={myPolicy} type="cookie" styling="nostyle">
              <span className="hover:underline"> Cookie Policy</span>
            </Iubenda>

            {/* <div>
              <a href="#" class="iubenda-cs-preferences-link hover:underline">
                Your Privacy Choices
              </a>
            </div>

            <div>
              <a href="#" class="iubenda-cs-uspr-link hover:underline">
                Notice at Collection
              </a>
            </div> */}
          </div>
          <div className="flex flex-shrink-0 flex-row flex-nowrap items-center">
            <div
              onClick={() => {
                $crisp.push(["do", "chat:open"]);
              }}
              className="mx-3 flex cursor-pointer items-center rounded-md px-2 py-1.5 bg-white text-gray-600 border-gray-300 hover:bg-gray-50 dark:border dark:bg-backgrounddark"
            >
              <ChatBubbleBottomCenterIcon className="mr-2 h-4 w-4" />
              Chat with us
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
