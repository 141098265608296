import React from "react";
import IsLoaded from "../IsLoaded";

import Avatar from "boring-avatars";

const NFT = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const avatarColors = ["#ef4444", "#eab308", "#22c55e", "#0ea5e9"];

  return (
    <div className="flex h-screen w-screen">
      <IsLoaded />

      <div className="bg-gray-50 my-auto mx-auto flex w-full max-w-xl flex-col gap-6 rounded-lg p-12 shadow">
        {" "}
        <div className="mx-auto ">
          <Avatar
            title={false}
            size={260}
            name={name}
            variant="beam"
            colors={avatarColors}
          />
        </div>
        <div className="">
          <div className="isolate -space-y-px rounded-md shadow-sm">
            <div className="border-gray-300 relative rounded-md rounded-b-none border px-3 py-2 focus-within:border-green-600 focus-within:ring-green-600 focus-within:z-10 focus-within:ring-1">
              <label
                htmlFor="name"
                className="text-gray-900 block text-xs font-medium"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                id="name"
                className="text-gray-900 bg-gray-50 placeholder-gray-500 block w-full border-0 p-0 focus:ring-0 sm:text-sm"
                placeholder="Tom Vroemans"
              />
            </div>
            <div className="border-gray-300 relative rounded-md rounded-t-none border px-3 py-2 focus-within:border-green-600 focus-within:ring-green-600 focus-within:z-10 focus-within:ring-1">
              <label
                htmlFor="email"
                className="text-gray-900 block text-xs font-medium"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="text-gray-900 bg-gray-50 placeholder-gray-500 block w-full border-0 p-0 focus:ring-0 sm:text-sm"
                placeholder="my@email.com"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFT;
