import React, { useEffect } from "react";
import useAuth from "./useAuth";

const SetWebsiteLocalStorage = () => {
  const { auth } = useAuth;

  // useEffect(() => {
  //   if (auth) {
  //     var iframe = document.getElementById("localStorageIframe");
  //     var win;

  //     console.log("localstorage auth", auth);
  //     const data = {
  //       workspace: auth?.workspace,
  //       name: auth?.username,
  //     };

  //     if (iframe) {
  //       win = iframe.contentWindow;
  //       win.postMessage(data, "*");
  //     }
  //   }
  // }, [auth]);

  return <div></div>;
};

export default SetWebsiteLocalStorage;
