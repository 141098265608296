import React from "react";
import { format } from "date-fns";
import Avatar from "../../Avatar";
import VersionPlayer from "../../VersionPlayer";

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WorkingOnThis from "../../WorkingOnThis";
import { serverUrl } from "../../hooks/Api";

const TrackListTrackOverlayVersions = ({ track }) => {
  return (
    <div>
      <div className=" m-6 flex flex-row  overflow-hidden divide-gray-200  ">
        <div className="   flex  flex-col px-6 py-4 ">
          <div className="my-auto flex flex-col gap-1">
            <div className="flex flex-row justify-between font-medium uppercase tracking-wider text-gray-700">
              Latest
            </div>
            <div className="text-sm text-gray-500">
              Uploaded on{" "}
              {track?.createdAt &&
                format(new Date(track?.createdAt), "dd/MM/yyyy")}{" "}
            </div>
            <div className="text-sm text-gray-500">
              <Avatar userid={track?.createdBy} size="16" withName />
            </div>{" "}
          </div>
        </div>
        <div className="flex  flex-grow flex-row p-4">
          <div className="my-auto   flex-grow flex-col pr-3">
            {track?.previewurl && (
              <VersionPlayer
                url={serverUrl + "/files/preview/" + track?.previewcode}
              />
            )}
          </div>
          <div className="my-auto flex h-16 w-36">
            <div className="my-auto  mx-auto flex  flex-row rounded-md border border-green-500 ">
              <div className="border-r px-3 py-2 text-base font-medium uppercase  tracking-wide text-green-500 border-green-500">
                Master
              </div>
              <div className="my-auto px-2 py-2">
                <ChevronDownIcon className="h-5 w-5 text-green-500" />
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <WorkingOnThis />
    </div>
  );
};

export default TrackListTrackOverlayVersions;
