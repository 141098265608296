import React, { useEffect } from "react";
import logoicon from "../../../img/trackflow-logo.svg";
import DotsLoader from "../../DotsLoader";
import ProjectEditStatuses from "../../Project/ProjectEditStatuses";
import StatusList from "./components/StatusList";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import StatusGroups from "./components/StatusGroups";
import StatusGroupsSimple from "./components/StatusGroupsSimple";

const OnboardingStep3 = ({ handleNextStep, current, percentage, formik }) => {
  const [buttonText, setButtonText] = React.useState("next");

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleSubmit = (e) => {
    if (current) {
      e.preventDefault();
      console.log("submitted");
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        handleNextStep();
      }, 100);
    }
  };

  const handleSetStatuses = (statuses) => {
    formik.setFieldValue("workspaceStatuses", statuses);
  };

  return (
    <div className=" flex w-full flex-row gap-12">
      {/*  */}
      <div className="hidden w-20 flex-shrink-0 md:block ">
        <CircularProgressbar
          value={percentage}
          className={" h-20 w-20 transition-all"}
          strokeWidth={18}
          // text={`${loadingPercentage}%`}
          styles={buildStyles({
            rotation: 0,

            strokeLinecap: "butt",

            pathTransitionDuration: 1,
            pathColor: "#22c55e",
            textColor: "#d1d5db",
            trailColor: `#3f3f46`,
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="w-full">
          <div className="block pb-2 text-2xl text-gray-800 sm:text-3xl ">
            Choose your <span className="font-medium">Trackflow</span> or make
            your own.
          </div>{" "}
          <div className="py-4 text-lg text-gray-600">
            Think of these as the steps from a demo to a finished track.
            <br />
            (You can always change this later)
          </div>
          <div>
            <StatusGroupsSimple setStatuses={handleSetStatuses} />
          </div>
        </div>

        <div>
          <button
            type="button"
            onClick={handleSubmit}
            className="inline-flex h-14 items-center rounded-md  px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
          >
            {buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />{" "}
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep3;
