import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useOnboardingCreatedUser() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(({ token, password, email }) => {
    console.log("useMutation", token);
    axiosPrivate
      .post(serverUrl + "/onboarding/cu/" + token, { password, email })
      .then((res) => res.data),
      {
        onSuccess: (data, variables) => {
          queryClient.invalidateQueries(['allworkspaces']);
        },
        return(data, variables) {
          return data;
        },
      };
  });
}
