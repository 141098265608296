import React, { useState, useEffect, useRef } from "react";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import useAllUsers from "../../hooks/useAllUsers";
import { classNames } from "../../shared/Utils";

import useAddTrackHistory from "../../hooks/useAddTrackHistory";

import Avatar from "../../Avatar";
import { nanoid } from "nanoid";

import "./TrackListChatSlideoverCommentForm.css";
import useAuth from "../../hooks/useAuth";

const dueDates = [
  { name: "No due date", value: null },
  { name: "Today", value: "today" },
  // More items...
];

const TrackListChatSlideoverCommentForm = ({ trackid, projectid }) => {
  const [focus, setFocus] = useState(false);
  const allUsersQuery = useAllUsers();
  console.log("allUsersQuery", allUsersQuery);
  const [assignees, setAssignees] = useState([
    { name: "Unassigned", value: null },
  ]);
  const [assigned, setAssigned] = useState(assignees[0]);
  const [dated, setDated] = useState(dueDates[0]);
  const [message, setMessage] = useState("");
  const [mentions, setMentions] = useState([]);

  useEffect(() => {
    setFocus(false);
    setAssigned(assignees[0]);
    setDated(dueDates[0]);
  }, [trackid]);

  useEffect(() => {
    if (allUsersQuery.data) {
      let newAssignees = [{ name: "Unassigned", value: null }];
      allUsersQuery.data.map((user, index) => {
        newAssignees[index + 1] = {
          name: user.name,
          avatar: user.avatar,
          userid: user._id,
        };
      });
      console.count("newAssignees");
      setAssignees(newAssignees);
    }
  }, [allUsersQuery.isSuccess]);

  useEffect(() => {
    const handleClick = (e) => {
      if (
        (document.getElementById("clickbox").contains(e.target) &&
          document.getElementById("clickbox").contains(e.target)) ||
        document.getElementById("editor").contains(e.target)
      ) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const [users, setUsers] = useState([]);

  const CustomButton = () => <span className="octicon octicon-star">@</span>;

  useEffect(() => {
    if (allUsersQuery.data) {
      const usersdownload = allUsersQuery.data?.map((user) => {
        return {
          id: user._id,
          value: user.name,
        };
      });
      setUsers(usersdownload);
      console.log("downloaded users", usersdownload);
    }
  }, [allUsersQuery.data]);

  async function suggestPeople(searchTerm) {
    return users.filter((person) => person.value.includes(searchTerm));
  }

  const handleChange = (value) => {
    setMessage(value);

    const regex = /<span class="mention"(.*?)<\/span>/g;
    const matches = value.match(regex);
    let id = [];
    if (matches) {
      matches.map((match, i) => {
        id[i] = match.match(/id="(.*?)"/)[1];
      });
    }

    setMentions(id);
  };

  const CustomToolbar = () => (
    <div id="customtoolbar" className=" flex justify-end">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>

      <select className="ql-color">
        <option value="red"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="orange"></option>
        <option value="violet"></option>
        <option value="#d0d1d2"></option>
        <option selected></option>
      </select>
      <button className="ql-list" value="check"></button>
    </div>
  );
  const modules = {
    toolbar: {
      container: "#customtoolbar",
      handlers: {
        insertStar: insertStar,
      },
    },
    magicUrl: true,

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      showDenotationChar: false,
      source: function (searchTerm, renderList, mentionChar) {
        let values;

        if (mentionChar === "@") {
          values = users;
        } else {
          values = hashValues;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
  };

  const addTrackHistoryQuery = useAddTrackHistory();

  const { auth } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentUserId = auth?.userid;
    const history = {
      id: nanoid(),
      action: "comment",
      message: message,
      mentions: mentions,
      notify: "true",
      actionBy: currentUserId,
      assignedTo: assigned.userid,
      replies: [],
    };

    console.log("history added", history);

    addTrackHistoryQuery.mutate({
      trackid: trackid,
      projectid: projectid,
      history,
    });
  };

  if (addTrackHistoryQuery.isSuccess) {
    setMessage("");
    setAssigned(assignees[0]);
    setDated(dueDates[0]);
    addTrackHistoryQuery.reset();
    setFocus(false);
  }

  return (
    <form action="#" className="relative">
      <div
        id="clickbox"
        className={classNames(
          focus ? " ring-1 border-green-500 ring-green-500" : " ",
          "mb-4  rounded-lg border  transition-all  bg-white border-gray-300"
        )}
      >
        <label htmlFor="message" className="sr-only">
          Message
        </label>

        <div
          className={classNames(
            focus ? "pb-0" : "pb-0",
            "flex flex-col pl-[2.2rem] transition-all "
          )}
        >
          {/* <ReactQuill
            ref={quillRef}
            theme="snow"
            id="editor"
            placeholder={"Write something.."}
            onChange={(e) => handleChange(e)}
            modules={modules}
          /> */}
        </div>
        <div className="absolute top-3 left-3">
          <Avatar email="netskymusic@me.com" hidetooltip size={28} />
        </div>
        {/* Spacer element to match the height of the toolbar */}

        <div
          className={classNames(focus ? "h-[3.6rem]" : "h-0", "transition-all")}
        />

        <div
          className={classNames(
            focus ? " opacity-100" : "opacity-0",
            " absolute inset-x-px bottom-0 transition-all delay-200 "
          )}
        >
          <div
            className={classNames(
              focus ? "flex" : "hidden",
              "items-center justify-between space-x-3 border-t px-2 py-2 border-gray-200 sm:px-3"
            )}
          >
            <div className="flex">
              <CustomToolbar />
              <button
                type="button"
                className="group -my-2 -ml-3 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
              >
                <PaperClipIcon
                  className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {/* <span className="text-gray-500 text-sm italic group-hover:text-gray-600">
                  Attach a file
                </span> */}
              </button>
            </div>
            <div className="flex-shrink-0">
              <button
                type="submit"
                onClick={handleSubmit}
                className="inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm border-transparent text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TrackListChatSlideoverCommentForm;
