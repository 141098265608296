import React, { useState, useEffect } from "react";
import Waveform from "react-audio-waveform";

const NotificationListWaveForm = ({ data, waveformUrl }) => {
  console.log("NotificationListWaveForm", data);

  // download waveform data into array
  const [peaks, setPeaks] = useState([]);
  useEffect(() => {
    fetch(waveformUrl)
      .then((response) => response.json())
      .then((data) => {
        setPeaks(data);
      });
  }, []);

  const seekPoints = data.seekPoints ? data.seekPoints : [];
  const trackDuration = data.trackDuration ? data.trackDuration : 0;
  const playDuration = data.playDuration ? data.playDuration : 0;

  return (
    <div className="relative w-full">
      <div className="opacity-70">
        <Waveform
          barWidth={1}
          peaks={peaks}
          height={30}
          // pos={this.props.pos}
          duration={trackDuration}
          color="#999"
        />
      </div>
      {/* {JSON.stringify(seekPoints)} */}
      {seekPoints.map((seekPoint, i) => {
        return (
          <div
            key={"seekpoint-" + i}
            className="absolute top-0   bg-green-500/50"
            style={{
              left: `${(seekPoint.start / trackDuration) * 100}%`,
              width: `${
                ((seekPoint.end - seekPoint.start) / trackDuration) * 100
              }%`,
              height: "100%",
            }}
          />
        );
      })}
    </div>
  );
};

export default NotificationListWaveForm;
