export const fakeTracks = [
  {
    _id: "628b81c0402ad5082dd7cd73",
    trackId: "5CCpT2JrXJritjaDpaoz1S",
    title: "Barricades",
    project: "ksLeWc1tvHMRCAxULeYndz",
    color: "#22c55e",
    artists: ["Netsky", "Rita Ora"],
    status: "Finished",
    url: "",
    createdAt: "2022-05-23T12:44:48.309Z",
    __v: 0,
    originalurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/5CCpT2JrXJritjaDpaoz1S/:original-BARRICADES-SPIKE-REF-5-LIM.wav",
    previewurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/5CCpT2JrXJritjaDpaoz1S/preview-BARRICADES-SPIKE-REF-5-LIM.mp3",
    waveformurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/5CCpT2JrXJritjaDpaoz1S/waveform-BARRICADES-SPIKE-REF-5-LIM.json",
  },
  {
    _id: "628b81c0402ad5082dd7cd",
    trackId: "5CCpT2JrXJritDpaoz1S",
    title: "Just like me",
    project: "ksLeWc1tvHMRCAxULeYndz",
    color: "#ef4444",
    artists: ["Jenna G", "MemoriaXI"],
    status: "Writing",
    url: "",
    createdAt: "2022-05-23T12:44:48.309Z",
    __v: 0,
    originalurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/m34zfoWKs7MeGASEhmA1qB/:original-Just-Like-Me-v25.wav",
    previewurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/m34zfoWKs7MeGASEhmA1qB/preview-Just-Like-Me-v25.mp3",
    waveformurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/5CCpT2JrXJritjaDpaoz1S/waveform-BARRICADES-SPIKE-REF-5-LIM.json",
  },
  {
    _id: "628b81c0402ad5082dd7cd",
    trackId: "5CCpT2JfdJritDpaoz1S",
    title: "Gold",
    project: "ksLeWc1tvHMRCAxULeYndz",
    color: "#0ea5e9",
    artists: ["Netsky"],
    status: "Writing",
    url: "",
    createdAt: "2022-05-23T12:44:48.309Z",
    __v: 0,
    originalurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/rKVs7bes5g54qCcw9gSXcA/:original-Gold-feat.-Dermot-Kennedy.mp3",
    previewurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/rKVs7bes5g54qCcw9gSXcA/preview-Gold-feat.-Dermot-Kennedy.mp3",
    waveformurl:
      "https://storage.googleapis.com/trackflow-5762f.appspot.com/tracks/100mgmt/ksLeWc1tvHMRCAxULeYndz/rKVs7bes5g54qCcw9gSXcA/waveform-Gold-feat.-Dermot-Kennedy.json",
  },
];
