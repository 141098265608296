import React from "react";

import { classNames } from "../shared/Utils";
import ListeningRoomCardFullscreen from "./ListeningRoomCard/ListeningRoomCardFullScreen";
import ListeningRoomCardSquare from "./ListeningRoomCard/ListeningRoomCardSquare";

const ListeningRoomCard = ({
  track,
  isMobile,
  fullScreen,
  setShowReactionSelector,
}) => {
  return (
    <div
      className={classNames(
        isMobile ? "p-3" : "p-4",
        fullScreen ? "" : "rounded-md",
        "text-white flex h-full w-full  font-medium hover:shadow-2xl dark:text-white"
      )}
      style={{
        backgroundColor: track.color,
      }}
    >
      {fullScreen ? (
        <ListeningRoomCardFullscreen
          track={track}
          isMobile={isMobile}
          setShowReactionSelector={setShowReactionSelector}
        />
      ) : (
        <ListeningRoomCardSquare track={track} isMobile={isMobile} />
      )}
    </div>
  );
};

export default ListeningRoomCard;
