import React from "react";
import App from "./App";
import { SettingsProvider } from "./context/SettingsProvider";

const Workspace = () => {
  return (
    <SettingsProvider>
      <App />
    </SettingsProvider>
  );
};

export default Workspace;
