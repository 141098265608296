import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useTrackHistory(trackid) {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(["track-history", trackid], () =>
    axiosPrivate
      .get(serverUrl + "/tracks/" + trackid + "/history/")
      .then((res) => res.data)
  );
}
