import React, { useEffect, useRef, useState } from "react";
import IsLoaded from "../IsLoaded";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import FullDonut from "../components/FullDonutAnimation";
import FullDonutDark from "../components/FullDonutAnimationDark";
import {
  ArrowRightOnRectangleIcon,
  LockOpenIcon,
} from "@heroicons/react/24/outline";
import DotsLoader from "../components/DotsLoader";

import logo from "../img/trackflow-logo-with-text-white.svg";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useCheckResetPasswordToken from "../components/hooks/useCheckResetPasswordToken";
import ErrorMessage from "../components/ErrorMessage";
import ErrorMessageFullScreen from "../components/ErrorMessageFullScreen";
import LoaderAnimation from "../components/LoaderAnimation";
import useResetPassword from "../components/hooks/useResetPassword";

import Lottie from "react-lottie-player";

import checkAnimation from "../animations/check.json";
import { LockClosedIcon } from "@radix-ui/react-icons";

const ResetPassword = () => {
  const resetPasswordToken = useParams().resetPasswordToken;
  const [resetSuccess, setResetSuccess] = useState(false);

  const checkResetPasswordTokenQuery = useCheckResetPasswordToken({
    token: resetPasswordToken || "",
  });

  const resetPasswordQuery = useResetPassword();

  console.log("checkResetPasswordTokenQuery", checkResetPasswordTokenQuery);

  const errRef = useRef();

  useEffect(() => {
    document.body.classList.add("body-login");
    return () => {
      document.body.classList.remove("body-login");
    };
  }, []);

  const formik = useFormik({
    onSubmit: (values) => {
      resetPasswordQuery.mutate({
        token: resetPasswordToken,
        password: values.password,
      });
    },
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .max(80, "Must be less  than 80 characters")
        .required("Is required")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
          "Must contain at least one letter and one number."
        ),
    }),
  });

  const navigate = useNavigate();
  useEffect(() => {
    console.log("resetPasswordQuery.isSuccess", resetPasswordQuery.isSuccess);
    if (resetPasswordQuery.isSuccess) {
      setResetSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    }
  }, [resetPasswordQuery.isSuccess]);

  const [buttonText, setButtonText] = useState("signin");
  if (!resetPasswordToken) {
    return (
      <ErrorMessageFullScreen title="Error" message="No token specified" />
    );
  }

  if (checkResetPasswordTokenQuery.isError) {
    return <ErrorMessageFullScreen title="Error" message="Invalid token" />;
  }

  if (resetPasswordQuery.isError) {
    return (
      <ErrorMessageFullScreen
        title="Error"
        message="Something went wrong. Please try again."
      />
    );
  }

  return (
    <div>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Ld7ev8iAAAAAIfCd9-W7P6KKYoeBE5F-GPu4Chv"
        language="englesh"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
        container={{
          parameters: {
            badge: "bottomleft", // optional, default undefined
            theme: "dark", // optional, default undefined
          },
        }}
      >
        {" "}
        {!resetSuccess ? (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="h-screen p-4">
                <div className="absolute -z-10 flex h-screen w-screen justify-center align-middle dark:hidden">
                  <FullDonut />
                </div>
                <div className="absolute -z-10 hidden h-screen w-screen justify-center align-middle dark:flex">
                  <FullDonutDark />
                </div>
                {checkResetPasswordTokenQuery.isInitialLoading ? (
                  <div className="">Loading</div>
                ) : checkResetPasswordTokenQuery.isError ? (
                  <>
                    {" "}
                    <IsLoaded />
                    <ErrorMessageFullScreen
                      title="Error"
                      message={
                        checkResetPasswordTokenQuery.error.response.data.message
                      }
                    />
                  </>
                ) : checkResetPasswordTokenQuery.isSuccess ? (
                  <>
                    <IsLoaded />
                    <div
                      className="form flex min-h-full flex-col items-center justify-center
        py-12 opacity-0 transition delay-200 duration-300 sm:px-6 lg:px-8"
                    >
                      <div className="mx-auto pb-3">
                        <img
                          src={logo}
                          alt="Trackflow"
                          className="h-14 dark:opacity-60 dark:invert"
                        />
                      </div>
                      <p className="inline-flex py-4 px-6 text-center text-sm font-bold text-white md:hidden">
                        {" "}
                        Trackflow is a desktop-first app, you'll have limited
                        functionality on a smartphone
                      </p>

                      <div className="mt-8 w-full sm:max-w-sm lg:max-w-md xl:max-w-md">
                        <div className="rounded-lg py-8 px-4 shadow-md bg-white dark:bg-[#134f26] sm:px-10">
                          <div>
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Your new password
                            </label>
                            <div className="mt-1">
                              <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="off"
                                required
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                className="block  w-full appearance-none rounded-md border px-3 py-2 shadow-sm bg-white text-gray-800 border-gray-300 placeholder-gray-400 focus:outline-none focus:border-green-500 focus:ring-green-500 dark:border-white/30 dark:bg-black/40 sm:text-sm"
                              />
                            </div>{" "}
                            <p
                              className="py-2 text-red-500"
                              ref={errRef}
                              aria-live="assertive"
                            >
                              {formik.errors.password &&
                              formik.touched.password ? (
                                <>{formik.errors.password}</>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="mt-4 flex h-12 w-full justify-center rounded-md border py-2 px-4 text-base font-medium  shadow-sm border-transparent text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:hover:bg-green-600"
                            >
                              <div className="my-auto mx-auto">
                                {buttonText == "signin" ? (
                                  <div className="flex flex-row">
                                    <LockClosedIcon className="my-auto mr-1 inline-flex h-5 w-5" />
                                    <div className="my-auto">
                                      Change password
                                    </div>
                                  </div>
                                ) : buttonText == "loading" ? (
                                  <div>
                                    <DotsLoader color="#ffffff" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>

                      <p className="my-2 pt-6 text-center text-sm text-white/70 dark:text-black/50 md:my-6">
                        dev / beta
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <IsLoaded />
                    <ErrorMessageFullScreen
                      title="Error"
                      message={
                        checkResetPasswordTokenQuery.error.response.data.message
                      }
                    />
                  </>
                )}
              </div>
            </form>
          </FormikProvider>
        ) : (
          <div className="flex h-screen w-screen items-center justify-center bg-green-500">
            <IsLoaded />

            <div className="">
              <div className="mt-8 w-full sm:max-w-sm lg:max-w-md xl:max-w-md">
                <div className="flex flex-col rounded-lg py-8 px-4 shadow-md bg-white dark:bg-[#134f26] sm:px-10">
                  <div className="mx-auto">
                    <Lottie
                      speed={1}
                      loop={false}
                      animationData={checkAnimation}
                      style={{ width: 100, height: 100 }}
                      play={true}
                    />
                  </div>
                  <div className="pt-4 text-center text-lg text-gray-800">
                    Password reset successful!
                  </div>
                  <div className="pt-1 text-center text-xs opacity-50 text-gray-800">
                    redirecting you to the login page
                    <br /> click{" "}
                    <a
                      href="https://app.trackflow.studio/login"
                      className="underline"
                    >
                      here
                    </a>{" "}
                    if you're not
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default ResetPassword;
