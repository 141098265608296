import React, { useState, useEffect } from "react";

import nightwind from "nightwind/helper";

import OnboardingStep1 from "./Steps/OnboardingStep1";
import OnboardingStep2 from "./Steps/OnboardingStep2";
import OnboardingStep3 from "./Steps/OnboardingStep3";
import IsLoaded from "../../IsLoaded";
import logoicon from "../../img/trackflow-logo.svg";

import { serverUrl } from "../hooks/Api";

import { classNames } from "../shared/Utils";
import OnboardingWelcome from "./Steps/OnboardingWelcome";
import OnboardingStep4 from "./Steps/OnboardingStep4";
import OnboardingFinished from "./Steps/OnboardingFinished";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import OnboardingUser1 from "./Steps/OnboardingUser1";
import OnboardingUser2 from "./Steps/OnboardingUser2";
import OnboardingUser3 from "./Steps/OnboardingUser3";
import { useMediaQuery } from "react-responsive";
import Lottie from "react-lottie-player";

import { useFormik, FormikProvider, Form, useField } from "formik";
import * as Yup from "yup";

import circularLinesAnimation from "../../animations/donut-pie-chart.json";
import { useParams } from "react-router-dom";
import useOnboardingCheckCode from "../hooks/useOnboardingCheckCode";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import OnboardingStep0 from "./Steps/OnboardingStep0";

const Onboarding = () => {
  useEffect(() => {
    nightwind.enable(true);
  }, []);

  const inviteCode = "onboarding-" + useParams().invitecode;
  const [codeValid, setCodeValid] = useState("pending");
  const [workspaceCompanyType, setWorkspaceCompanyType] = useState("");

  console.log("invitecode", inviteCode);

  const onboardingCheckCodeQuery = useOnboardingCheckCode(inviteCode);

  const [currentStep, setCurrentStep] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const formik = useFormik({
    initialValues: {
      userName: "",
      userProfilePicture: "",
      workspaceType: "",
      workspaceName: "",
      workspaceColor: "#22c55e",
    },
    initialErrors: {
      userName: "Name is required",

      userPassword: "Password is required",
      workspaceName: "Name is required",
    },
    onSubmit: async (values) => {
      await sleep(500);
      alert(JSON.stringify(values, null, 2));
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .max(80, "Must be less  than 80 characters")
        .required("Is required")
        .matches(
          /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
          "Cannot contain special characters or more than one space between words"
        ),
      userPassword: Yup.string()
        .min(8, "Must be at least 8 characters")
        .max(80, "Must be less  than 80 characters")
        .required("Is required")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
          "Must contain at least one letter and one number."
        ),
      workspaceType: Yup.object({
        name: Yup.string()
          .min(1, "Must be at least 1 character")
          .required("Is required"),
      }),

      workspaceName: Yup.string()
        .test("checkWorkspaceUnique", "This name is already used.", (value) => {
          if (value)
            return new Promise((resolve, reject) => {
              const testName = value
                .toLowerCase()
                .replace(/\s/g, "-")
                .replace(/[^a-zA-Z0-9 ]/g, "");
              fetch(
                `${serverUrl}/onboarding/is-workspace-unique/${testName}`
              ).then(async (res) => {
                const { isWorkspaceUnique } = await res.json();
                console.log("checkWorkspaceUnique RES", isWorkspaceUnique);
                resolve(isWorkspaceUnique);
              });
            });
        })
        .min(3, "Must be at least 3 characters")
        .max(80, "Must be less  than 80 characters")
        .required("Is required"),

      workspaceInvites: Yup.string().matches(
        /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
        "Comma separated list of valid email addresses (e.g. bill@example.com, chris@example.com)"
      ),
    }),
  });

  useEffect(() => {
    if (onboardingCheckCodeQuery.isSuccess) {
      if (onboardingCheckCodeQuery.data.email) {
        if (onboardingCheckCodeQuery.data.setWorkspaceCompanyType) {
          setWorkspaceCompanyType(
            onboardingCheckCodeQuery.data.setWorkspaceCompanyType
          );
        }
        setCodeValid(true);
        formik.setFieldValue("userEmail", onboardingCheckCodeQuery.data.email);
      } else {
        setCodeValid(false);
      }
      console.log("onboardingCheckCodeQuery", onboardingCheckCodeQuery.data);
    }
  }, [onboardingCheckCodeQuery.isSuccess]);

  const steps = [
    {
      id: 0,
      name: "Step 0",
      description: "Create your account",
      content: OnboardingWelcome,
    },
    {
      id: 1,
      name: "Step 1",
      description: "User name",
      content: OnboardingUser1,
    },
    {
      id: 2,
      name: "Step 2",
      description: "User profile",
      content: OnboardingUser2,
    },
    {
      id: 3,
      name: "Step 3",
      description: "User profile",
      content: OnboardingUser3,
    },
    {
      id: 4,
      name: "Step 4",
      description: "Add your first project",
      content: OnboardingStep0,
    },
    {
      id: 5,
      name: "Step 5",
      description: "Add your first project",
      content: OnboardingStep1,
    },
    {
      id: 6,
      name: "Step 6",
      description: "Add your first project",
      content: OnboardingStep2,
    },
    {
      id: 7,
      name: "Step 7",
      description: "Add your first task",
      content: OnboardingStep3,
    },
    // {
    //   id: 6,
    //   name: "Step 5",
    //   description: "Invite other users",
    //   content: OnboardingStep4,
    // },
    {
      id: 8,
      name: "Finished",
      description: "Done!",
      content: OnboardingFinished,
    },
  ];

  const handleNextStep = () => {
    gotoStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    gotoStep(currentStep - 1);
  };

  const [gotoActive, setGotoActive] = useState(false);

  const gotoStep = (step) => {
    console.log("gotoStep", step);
    const currentStepDiv = document.getElementById("step-" + step);
    currentStepDiv.scrollIntoView({
      behavior: "smooth",
      block: isMobile ? "start" : "center",
    });
    setGotoActive(true);
    setTimeout(() => {
      setGotoActive(false);
    }, 1000);
    setCurrentStep(step);
  };

  console.log("Values", formik.values);

  function waitForScrollEnd() {
    var last_changed_frame = 0;
    var last_x = window.scrollX;
    var last_y = window.scrollY;
    return new Promise((resolve, reject) => {
      function tick(frames) {
        // We requestAnimationFrame either for 500 frames or until 20 frames with
        // no change have been observed.
        if (frames >= 500 || frames - last_changed_frame > 20) {
          resolve();
        } else {
          if (window.scrollX != last_x || window.scrollY != last_y) {
            last_changed_frame = frames;
            last_x = window.scrollX;
            last_y = window.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }
      tick(0);
    });
  }

  // window.addEventListener("scroll", function () {
  //   waitForScrollEnd().then(() => {
  //     if (document.getElementById("step-" + currentStep)) {
  //       const currentStepDiv = document.getElementById("step-" + currentStep);

  //       currentStepDiv.scrollIntoView({
  //         behavior: "smooth",
  //         block: isMobile ? "start" : "center",
  //       });
  //     }
  //   });
  // });

  return (
    <>
      <div
        className={classNames(
          currentStep === "finished" ? "bg-green-500" : "bg-white  ",
          " flex  w-full flex-row   transition-all"
        )}
      >
        <div
          className={classNames(
            currentStep === 0 ? "opacity-100" : "opacity-[0.5]",
            "fixed inset-0 h-screen w-screen overflow-hidden transition-all"
          )}
        >
          <div className="absolute -top-[10rem] -right-[40rem] rotate-[-9deg]  opacity-[0.08]  invert transition-all  duration-1000  dark:opacity-[0.08] dark:invert-0 md:-right-[24rem] md:-top-[20rem] ">
            <Lottie
              speed={1}
              loop={false}
              animationData={circularLinesAnimation}
              style={{
                width: isMobile ? 1000 : 1400,
                height: isMobile ? 1000 : 1400,
              }}
              play
            />
          </div>
        </div>
        {onboardingCheckCodeQuery.isError && (
          <div className="fixed inset-0 flex h-screen w-screen overflow-hidden transition-all bg-white">
            <div className="my-auto mx-auto flex flex-col text-center text-4xl text-gray-800">
              <ExclamationTriangleIcon className="mx-auto h-12 w-12 text-red-500" />
              Error with invite code: <br />
              {onboardingCheckCodeQuery.error}
            </div>
          </div>
        )}
        {codeValid === false && (
          <div className="fixed inset-0 flex h-screen w-screen overflow-hidden transition-all bg-white">
            <div className="my-auto mx-auto flex flex-col text-center text-3xl text-gray-800">
              <ExclamationTriangleIcon className="mx-auto mb-2 h-12 w-12 text-red-500" />
              This invitation is not valid
            </div>
          </div>
        )}

        {codeValid === true && (
          <>
            <div className="z-10 w-full pl-0 lg:pl-8">
              <div className="flex flex-col gap-[6rem]">
                <FormikProvider value={formik}>
                  <Form>
                    {steps.map((step, stepIdx) => (
                      <div
                        key={step.id}
                        id={"step-" + step.id}
                        className=" mx-auto flex  w-full p-10"
                      >
                        <div className="my-auto w-full">
                          <div
                            className={classNames(
                              step.id === currentStep
                                ? "opacity-100 blur-0"
                                : "opacity-[0.1] ",
                              "transition-all "
                            )}
                          >
                            <step.content
                              inviteCode={inviteCode}
                              handleNextStep={handleNextStep}
                              current={step.id === currentStep}
                              percentage={
                                ((currentStep - 1) / (steps.length - 2)) * 100
                              }
                              formik={formik}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Form>
                </FormikProvider>
              </div>
            </div>
            <div className="fixed right-0 bottom-4 z-20 flex h-full w-20 flex-col   ">
              <div className="bottom-0   mx-auto flex h-full flex-col justify-end gap-2 self-start p-2">
                {currentStep > 1 && (
                  <button
                    onClick={handlePreviousStep}
                    className="h-10 w-10 rounded-md  border p-2 bg-white text-gray-800 border-gray-200 hover:bg-gray-300"
                  >
                    <ChevronUpIcon />
                  </button>
                )}
                {/* <button
              onClick={handleNextStep}
              className="bg-white text-gray-800 border-gray-200  h-10 w-10 rounded-md border p-2 hover:bg-gray-300"
            >
              <ChevronDownIcon />
            </button> */}
              </div>
            </div>{" "}
          </>
        )}
      </div>
      <IsLoaded />
    </>
  );
};

export default Onboarding;
