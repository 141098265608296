import { createContext, useEffect, useState } from "react";
import useWorkspace from "../components/hooks/useWorkspace";
import useWorkspaceSettings from "../components/hooks/useWorkspaceSettings";
import secureLocalStorage from "react-secure-storage";
import useAuth from "../components/hooks/useAuth";

const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
  const { auth } = useAuth();

  const currentWorkspace = auth?.workspace;

  const [bottomPlayerOpen, setBottomPlayerOpen] = useState(false);
  const [bottomPlayerTrack, bottomPlayerSetTrack] = useState(null);
  const [bottomPlayerIsPlaying, bottomPlayerSetIsPlaying] = useState(false);
  const [bottomPlayerUrl, bottomPlayerSetUrl] = useState("");

  const [notificationsBarFixed, setNotificationsBarFixed] = useState(false);
  const [sideOffset, setSideOffset] = useState(0);
  const [workspaceSettings, setWorkspaceSettings] = useState(false);

  const workspaceSettingsQuery = useWorkspaceSettings(currentWorkspace);

  useEffect(() => {
    if (workspaceSettingsQuery.data) {
      setWorkspaceSettings(workspaceSettingsQuery.data);
    }
  }, [workspaceSettingsQuery.isSuccess]);

  return (
    <SettingsContext.Provider
      value={{
        bottomPlayerOpen,
        setBottomPlayerOpen,
        bottomPlayerIsPlaying,
        bottomPlayerSetIsPlaying,
        bottomPlayerTrack,
        bottomPlayerSetTrack,
        bottomPlayerUrl,
        bottomPlayerSetUrl,
        notificationsBarFixed,
        setNotificationsBarFixed,
        workspaceSettings,
        sideOffset,
        setSideOffset,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
