import { useEffect, useState } from "react";
import {
  Cog6ToothIcon,
  EnvelopeOpenIcon,
  FaceFrownIcon,
  PlayIcon,
  WrenchIcon,
  XMarkIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useTrackTags from "../hooks/useTrackTags";
import useUpdateTrackTags from "../hooks/useUpdateTrackTags";
import { classNames } from "../shared/Utils";

import ToolTip from "../ToolTip";
import useAddTrackToMLQueue from "../hooks/useAddTrackToMLQueue";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

String.prototype.toColor = function () {
  var colors = [
    "#e51c23",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#5677fc",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#259b24",
    "#8bc34a",
    "#afb42b",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

const TrackListTags = ({ row }) => {
  console.log("render TrackListTags row", row);

  const queueStatus = row.original.predictionStatus
    ? row.original.predictionStatus
    : "loading";

  const addTrackToMLQueueQuery = useAddTrackToMLQueue();

  return (
    <div
      className={classNames(
        (queueStatus === "processing" || queueStatus === "started") &&
          "barberpole",
        " flex h-full w-full overflow-hidden  text-sm  "
      )}
    >
      <div className=" relative my-auto inline-flex h-full w-full flex-wrap items-center whitespace-nowrap">
        <div className=" flex w-full flex-wrap gap-0.5 overflow-hidden px-2 py-2">
          {queueStatus === "finished" &&
            row.original.tags?.map((tag) => {
              if (tag.name != "techno") {
                return (
                  <div
                    key={tag.name}
                    className="brightness-60 m-0.5 flex whitespace-nowrap rounded-full border px-1.5 py-[0.2rem] text-[0.82rem]  text-gray-700  border-gray-200 dark:brightness-95 "
                    style={
                      {
                        //   borderColor: tag.color + "33",
                        //   backgroundColor: tag.color + "22",
                        //   color: tag.color,
                      }
                    }
                  >
                    <div
                      className="my-auto inline-flex h-2 w-2 rounded-full"
                      style={{
                        backgroundColor: tag.name.toColor() + "cc",
                      }}
                    />
                    <ToolTip
                      message={"(" + Math.round(tag.probability * 100) + "%)"}
                    >
                      <div className="my-auto cursor-default select-none pl-1">
                        {tag.name}{" "}
                      </div>
                    </ToolTip>
                  </div>
                );
              }
            })}
          {queueStatus !== "finished" && (
            <div className="rounded-full px-2 py-1  bg-gray-100">
              {queueStatus === "queued" ? (
                <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                  <QueueListIcon className="mr-2 h-4 w-4" />
                  {queueStatus}
                </div>
              ) : queueStatus === "processing" ? (
                <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                  <Cog6ToothIcon className="mr-2 h-4 w-4 animate-spin" />
                  {queueStatus}
                </div>
              ) : queueStatus === "started" ? (
                <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                  <Cog6ToothIcon className="mr-2 h-4 w-4 animate-spin" />
                  {queueStatus}
                </div>
              ) : (
                <div className="my-auto mx-2 flex h-full items-center text-xs font-medium text-gray-700 ">
                  {queueStatus}
                </div>
              )}
            </div>
          )}

          <div
            onClick={() => {
              addTrackToMLQueueQuery.mutate({ trackId: row.original.id });
            }}
            className="mx-2 my-auto hidden cursor-pointer rounded-full border px-2 py-1 bg-white text-gray-800 border-gray-300 hover:bg-gray-100 group-hover:flex"
          >
            <ArrowPathIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackListTags;
