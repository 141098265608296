import useAxiosPrivate from "./useAxiosPrivate.js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function useAddTracksToPlaylist() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation(
    ({ playlistId, trackIds, playlistTitle }) => {
      axiosPrivate
        .put(serverUrl + "/playlists/" + playlistId + "/add-tracks", {
          trackIds,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["all-playlists"]);
        queryClient.invalidateQueries(["playlist", variables.playlistId]);

        toast.success(
          <div className="flex ">
            track{variables.trackIds?.length > 1 && "s"} added to {"  "}
            <span
              onClick={() => {
                navigate("/playlists/" + variables.playlistId);
              }}
              className="pl-2 text-gray-400 hover:underline hover:text-gray-600"
            >
              {variables.playlistTitle}
            </span>
          </div>,
          {
            containerId: "main",
          }
        );
      },
    }
  );
}
