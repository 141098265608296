import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import { classNames } from "./components/shared/Utils";
import "./App.css";
import { registerLicense } from "@syncfusion/ej2-base";

// Lazy-load components
const Team = lazy(() => import("./routes/Team"));
const TeamMember = lazy(() => import("./routes/TeamMember"));
const Projects = lazy(() => import("./routes/Projects"));
const Tasks = lazy(() => import("./routes/Tasks"));
const Project = lazy(() => import("./routes/Project"));
const Sync = lazy(() => import("./routes/Sync"));
const Shared = lazy(() => import("./routes/Shared"));
const Calendar = lazy(() => import("./routes/Calendar"));
const Reports = lazy(() => import("./routes/Reports"));
const Profile = lazy(() => import("./routes/Profile"));
const Track = lazy(() => import("./routes/Track"));
const Settings = lazy(() => import("./routes/Settings"));
const Missing = lazy(() => import("./routes/Missing"));
const ConnectSpotify = lazy(() => import("./routes/ConnectSpotify"));
const Artists = lazy(() => import("./routes/Artists"));
const Artist = lazy(() => import("./routes/Artist"));
const Dashboard = lazy(() => import("./routes/Dashboard"));
const Test = lazy(() => import("./routes/Test"));
const Extensions = lazy(() => import("./routes/Extensions"));
const Admin = lazy(() => import("./routes/Admin"));
const Playlists = lazy(() => import("./routes/Playlists"));
const Playlist = lazy(() => import("./routes/Playlist"));

import GuidedTour from "./components/GuidedTour";

import Layout from "./components/Layout";

import NavBar from "./components/NavBar/NavBar";

import BottomPlayer from "./components/BottomPlayer";
import RequiredAuth from "./components/RequireAuth";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/ScrollToTop";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UploaderProvider } from "./context/UploaderProvider";
import useSettings from "./components/hooks/useSettings";
import IsLoaded from "./IsLoaded";
import Footer from "./components/Footer";
import SetWebsiteLocalStorage from "./components/hooks/SetWebsiteLocalStorage";
import { TrackOverlayProvider } from "./context/TrackOverlayProvider";
import { NewPlaylistOverlayProvider } from "./context/NewPlaylistOverlayProvider";
import { GuidedTourProvider } from "./context/GuidedTourProvider";
import { DocumentTitleProvider } from "./context/DocumentTitleProvider";

import DotsLoader from "./components/DotsLoader";
import KeyboardShortcuts from "./components/KeyboardShortcuts";
import PushTokenChecker from "./components/PushTokenChecker";
import { useAuth0 } from "@auth0/auth0-react";

const contextClass = {
  success: "bg-white",
  error: "bg-white",
  info: "bg-white",
  warning: "bg-white",
  default: "bg-white",
  dark: "bg-white font-gray-300",
};

registerLicense(
  "Mgo+DSMBaFt+QHFqVkFrWE5GfkBAXWFKblJ8RWZTfVxgFChNYlxTR3ZcQFRiTnpSckdgXHdY;Mgo+DSMBPh8sVXJ1S0d+X1ZPckBAVXxLflF1VWJTf1t6dldWACFaRnZdQV1gS3dScUFiXX1dd3dT;ORg4AjUWIQA/Gnt2VFhhQlJDfVtdX2tWfFN0RnNYdV51fldEcDwsT3RfQF5jSnxad0JnWHtcc3xTQA==;MTU0MTgyNEAzMjMxMmUzMTJlMzMzN2srd3BMZkN2MG1Xbkd0eFAwY3A2Qy85d0k5SlVMS2M0UlJEZ0IxVXZrQVk9;MTU0MTgyNUAzMjMxMmUzMTJlMzMzN2ttYmNCZEVmTEJobUNGbFFxTi9adnc4UEQ1OWdmNDdJWVgrZnNkWmxaWWs9;NRAiBiAaIQQuGjN/V0d+XU9HcVRGQmFBYVF2R2BJflRwcV9EYkwxOX1dQl9gSX1Rf0RkXX5acnBRQGY=;MTU0MTgyN0AzMjMxMmUzMTJlMzMzN21NWTI3SGI1ZXRycGt6ZDM4LytmK0MvWFFyWVQwY29PRnF2a2ZabjdyS1E9;MTU0MTgyOEAzMjMxMmUzMTJlMzMzN0hoekZmUXlwOHFJK29LdzZybU9sbmJBUjB1SXhVVDViR1M0dXZZOXloWG89;Mgo+DSMBMAY9C3t2VFhhQlJDfVtdX2tWfFN0RnNYdV51fldEcDwsT3RfQF5jSnxad0JnWHtcdXRRQA==;MTU0MTgzMEAzMjMxMmUzMTJlMzMzN2s2clpNVng2TlJ2UUszbDVsTWJaa0ZESWFueUFTS0RJWlYzSU82aS82d2M9;MTU0MTgzMUAzMjMxMmUzMTJlMzMzN2VVL3ZNZlZPNHJhZ0ROWVlUNWtXTWN5aGJaYUN5d0s3MWdKR09qOGt6bnM9;MTU0MTgzMkAzMjMxMmUzMTJlMzMzN21NWTI3SGI1ZXRycGt6ZDM4LytmK0MvWFFyWVQwY29PRnF2a2ZabjdyS1E9"
);

const App = () => {
  const { bottomPlayerOpen, notificationsBarFixed, workspaceSettings } =
    useSettings();

  // change ios theme color
  if (document.documentElement.classList.contains("dark")) {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#2b2b2f");
  }

  const { user } = useAuth0();

  return (
    <>
      <Fragment>
        {user && <NavBar />}

        <div
          className={classNames(
            bottomPlayerOpen ? "pb-[12rem]" : "pb-[7.5rem]",
            notificationsBarFixed ? "md:ml-[32rem]" : "md:ml-[4rem]",
            " relative min-h-screen transition-all"
          )}
        >
          {/* <ScrollToTop /> */}
          <KeyboardShortcuts />
          <UploaderProvider>
            <TrackOverlayProvider>
              <NewPlaylistOverlayProvider>
                <GuidedTourProvider>
                  <DocumentTitleProvider>
                    <SetWebsiteLocalStorage />
                    <Suspense
                      fallback={
                        <div className="fixed z-10 -mb-[8rem] flex h-screen w-screen items-center justify-center text-xs font-medium text-gray-500 md:-ml-[5rem] ">
                          <DotsLoader dark big />
                        </div>
                      }
                    >
                      <Routes>
                        <Route path="" element={<Layout />}>
                          <Route
                            element={
                              <>
                                <ToastContainer
                                  position="top-center"
                                  autoClose={4000}
                                  closeButton={false}
                                  toastClassName={({ type }) =>
                                    contextClass[type || "default"] +
                                    " relative flex p-2 mx-2 my-3 text-gray-800 text-sm min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border border-gray-300 shadow-xl dark:shadow dark:shadow-zinc-600/30 "
                                  }
                                  // bodyClassName={() =>
                                  //   "text-sm font-white font-med block p-3"
                                  // }

                                  enableMultiContainer
                                  containerId="main"
                                  progressClassName="toastProgress"
                                  className="toast"
                                />
                                <ToastContainer
                                  position="bottom-center"
                                  autoClose={false}
                                  closeButton={false}
                                  toastClassName={({ type }) =>
                                    contextClass[type || "default"] +
                                    "w-96 bg-white relative flex p-2 mx-2 my-2 text-gray-800 text-sm min-h-10 rounded-md justify-between overflow-hidden  border border-gray-300 shadow-xl dark:shadow dark:shadow-zinc-600/30 "
                                  }
                                  // bodyClassName={() =>
                                  //   "text-sm font-white font-med block p-3"
                                  // }
                                  style={{
                                    width: "430px",
                                  }}
                                  enableMultiContainer
                                  containerId="createArtist"
                                  progressClassName="toastProgress"
                                  className="toast"
                                  closeOnClick={false}
                                  draggable={false}
                                  newestOnTop
                                />
                                <ToastContainer
                                  autoClose={4000}
                                  closeButton={false}
                                  toastClassName={({ type }) =>
                                    contextClass[type || "default"] +
                                    " relative flex px-2 pt-2 mx-2 my-3 text-gray-800 text-xs min-h-10 rounded-md justify-between overflow-hidden cursor-pointer border border-gray-300 shadow-xl dark:shadow dark:shadow-zinc-600/30 "
                                  }
                                  style={
                                    bottomPlayerOpen
                                      ? {
                                          marginBottom: "60px",
                                          marginRight: "80px",
                                          width: "500px",
                                        }
                                      : {
                                          marginBottom: "0px",
                                          marginRight: "80px",
                                          width: "500px",
                                        }
                                  }
                                  enableMultiContainer
                                  containerId="uploads"
                                  progressClassName="toastProgress"
                                  className="toast"
                                  newestOnTop
                                />

                                <RequiredAuth />
                              </>
                            }
                          >
                            <Route path="team" element={<Team />} />
                            <Route path="extensions" element={<Extensions />} />
                            <Route path="test" element={<Test />} />
                            <Route
                              path="team/:teammemberid"
                              element={<TeamMember />}
                            />
                            <Route path="/" element={<Projects />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route
                              path="/projects/:projectsview"
                              element={<Projects />}
                            />

                            <Route
                              path="projects/:projectid"
                              element={<Project />}
                            />
                            <Route
                              path="projects/:projectid/:projectview/"
                              element={<Project />}
                            />
                            <Route
                              path="projects/:projectid/:projectview/track/:trackid"
                              element={<Project />}
                            />
                            <Route
                              path="projects/:projectid/:projectview/track/:trackid/task/:taskid"
                              element={<Project />}
                            />

                            {/* <Route
                      path="projects/:projectid/:projectview/track/:trackid"
                      element={<Project />}
                    /> */}
                            <Route
                              path="projects/:projectid/track/:trackid"
                              element={<Track />}
                            />

                            <Route
                              path="projects/:projectid/track/:trackid/:trackview"
                              element={<Track />}
                            />
                            <Route path="playlists" element={<Playlists />} />
                            <Route
                              path="/playlists/:playlistid"
                              element={<Playlist />}
                            />
                            <Route path="tasks" element={<Tasks />} />

                            <Route path="/artists" element={<Artists />} />
                            <Route
                              path="/projects/:projectid/artists/:artistid"
                              element={<Project />}
                            />
                            <Route
                              path="/projects/:projectid/track/:trackid/artists/:artistid"
                              element={<Project />}
                            />
                            <Route path="/shared" element={<Shared />} />
                            <Route
                              path="/artists/:artistid"
                              element={<Artist />}
                            />
                            <Route path="/calendar" element={<Calendar />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route
                              path="/settings/:settingstab"
                              element={<Settings />}
                            />
                            <Route
                              path="/spotify"
                              element={<ConnectSpotify />}
                            />
                          </Route>
                          {/* catch all */}
                          <Route path="*" element={<Missing />} />
                        </Route>
                      </Routes>
                    </Suspense>
                  </DocumentTitleProvider>
                </GuidedTourProvider>
              </NewPlaylistOverlayProvider>
            </TrackOverlayProvider>
          </UploaderProvider>
          <BottomPlayer />

          <ReactQueryDevtools initialIsOpen={false} />

          <div className=" mt-auto">
            <Footer />
          </div>
        </div>
      </Fragment>
      {/* {workspaceSettings && <IsLoaded />} */}
      <IsLoaded />
    </>
  );
};

export default App;
