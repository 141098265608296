import axios from "axios";

let serverurl;
let hocuspocusserverurl;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  serverurl = "http://localhost:3500";
  hocuspocusserverurl = "ws://0.0.0.0:3505";
} else {
  serverurl = "https://api.trackflow.studio";
  hocuspocusserverurl = "wss://trackflow-collaboration.herokuapp.com/";
}

export const serverUrl = serverurl;
export const hocuspocusServerUrl = hocuspocusserverurl;

export default axios.create({ baseURL: serverUrl });

export const axiosPrivate = axios.create({
  baseURL: serverUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
