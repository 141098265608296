import React, { useEffect, useRef, useState } from "react";
import useSettings from "./hooks/useSettings";

import { Icon } from "@iconify/react";

import Lottie from "react-lottie-player";

import * as newTrackBackground from "../animations/363-animated-background-14-lineal-edited.json";
import {
  PauseCircleIcon,
  PlayCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import ToolTip from "./ToolTip";
import { serverUrl } from "./hooks/Api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MiniPlayer = ({ track, mini, alert, light }) => {
  const {
    bottomPlayerOpen,
    setBottomPlayerOpen,
    bottomPlayerSetTrack,
    bottomPlayerUrl,
    bottomPlayerSetUrl,
    bottomPlayerIsPlaying,
    bottomPlayerSetIsPlaying,
  } = useSettings();

  const handleClick = () => {
    // window.alert("track: " + track.title + " clicked");

    setBottomPlayerOpen(true);
    if (bottomPlayerIsPlaying) {
      if (
        bottomPlayerUrl ===
        serverUrl + "/files/preview/" + track.previewcode
      ) {
        bottomPlayerSetIsPlaying(false);
      } else {
        bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
        bottomPlayerSetTrack(track);
        bottomPlayerSetIsPlaying(true);
      }
    } else {
      bottomPlayerSetUrl(serverUrl + "/files/preview/" + track.previewcode);
      bottomPlayerSetTrack(track);
      bottomPlayerSetIsPlaying(true);
    }
  };

  return (
    <div className="z-30 my-auto flex h-full w-full ">
      <div className=" relative flex h-full w-full">
        {track?.previewurl ? (
          <div
            className={classNames(
              light
                ? "text-gray-100 hover:text-gray-200 dark:text-gray-100 dark:hover:text-gray-200"
                : "text-gray-300 hover:text-gray-400",
              " controls z-10  float-left my-auto flex  pb-[0rem]  "
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            {!bottomPlayerIsPlaying &&
              bottomPlayerUrl ===
                serverUrl + "/files/preview/" + track.previewcode && (
                <PlayCircleIcon
                  className={classNames(
                    mini
                      ? "mr-2 h-7 w-7"
                      : " mr-1  h-[2.9rem] w-[2.9rem] p-2   ",
                    "z-10 my-auto mx-auto cursor-pointer"
                  )}
                />
              )}
            {!bottomPlayerIsPlaying &&
              bottomPlayerUrl !=
                serverUrl + "/files/preview/" + track.previewcode && (
                <PlayCircleIcon
                  className={classNames(
                    mini
                      ? "mr-2 h-7 w-7"
                      : " mr-1  h-[2.9rem] w-[2.9rem] p-2   ",
                    "z-10 my-auto mx-auto cursor-pointer"
                  )}
                />
              )}
            {bottomPlayerIsPlaying &&
              bottomPlayerUrl !=
                serverUrl + "/files/preview/" + track.previewcode && (
                <PlayCircleIcon
                  className={classNames(
                    mini
                      ? "mr-2 h-7 w-7"
                      : " mr-1  h-[2.9rem] w-[2.9rem] p-2   ",
                    "z-10 my-auto mx-auto cursor-pointer"
                  )}
                />
              )}
            {bottomPlayerIsPlaying &&
              bottomPlayerUrl ===
                serverUrl + "/files/preview/" + track.previewcode && (
                <PauseCircleIcon
                  className={classNames(
                    mini
                      ? "mr-2 h-7 w-7"
                      : " mr-1  h-[2.9rem] w-[2.9rem] p-2   ",
                    "z-10 my-auto mx-auto cursor-pointer"
                  )}
                />
              )}
          </div>
        ) : (
          <div className=" controls z-10  float-left my-auto flex  pb-[0rem] text-gray-300 hover:text-gray-400 ">
            <ToolTip
              message="No audio preview available"
              alignOffset={15}
              sideOffset={5}
            >
              <QuestionMarkCircleIcon
                className={classNames(
                  mini ? "mr-2 h-7 w-7" : " mr-1  h-[2.9rem] w-[2.9rem] p-2   ",
                  "z-10 my-auto mx-auto "
                )}
              />
            </ToolTip>
          </div>
        )}

        {/* <div
          className={classNames(
            mini
              ? "absolute top-0 left-0 z-0 -ml-[0.4rem] -mt-[0.36rem] h-full w-full opacity-80"
              : "absolute top-0 left-0 z-0 -ml-[0.15rem] -mt-[0.08rem] h-full w-full opacity-80"
          )}
        >
          <Lottie
            speed={0.9}
            loop={false}
            goTo={goTo}
            onComplete={() => setClick(false)}
            animationData={newTrackBackground}
            play={click}
            style={{ width: mini ? 40 : 48, height: mini ? 40 : 46 }}
          />
        </div> */}
        {/* <div className=" h-[1.9rem] w-[1.9rem] left-[0.33rem] top-[0.6rem] border-green-500/50 border-2 absolute rounded-full z-0" /> */}
      </div>
    </div>
  );
};

export default MiniPlayer;
