import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useWorkspace(workspaceid) {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["workspace", workspaceid],
    () =>
      axiosPrivate
        .get(serverUrl + "/workspaces/" + workspaceid)
        .then((res) => res.data),
    {
      // refetchInterval: 60000,
    }
  );
}
