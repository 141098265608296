import React, { useContext, useEffect, useState } from "react";
import nightwind from "nightwind/helper";
import { useNavigate, useParams } from "react-router-dom";
import IsLoaded from "../../IsLoaded";
import { useFormik, useField, Formik } from "formik";
import useOnboardingCheckCode from "../hooks/useOnboardingCheckCode";
import useOnboardingCreatedUser from "../hooks/useOnboardingCreatedUser";
import * as Yup from "yup";
import { Blurhash } from "react-blurhash";
import useWindowDimensions from "../hooks/useWindowDimensions";
import DotsLoader from "../DotsLoader";
import {
  ArrowRightOnRectangleIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../shared/Utils";
import axios from "axios";
import { serverUrl } from "../hooks/Api";
import secureLocalStorage from "react-secure-storage";

import useAuth from "../hooks/useAuth";

// import LogRocket from "logrocket";

import OpenReplayContext from "../../context/OpenReplayProvider";

const OnboardingCreatedUser = () => {
  useEffect(() => {
    nightwind.enable(true);
  }, []);

  const { tracker } = useContext(OpenReplayContext);

  const inviteCode = useParams().invitecode;
  const [codeValid, setCodeValid] = useState("pending");

  const navigate = useNavigate();

  const onboardingCheckCodeQuery = useOnboardingCheckCode(inviteCode);
  const onboardingCreatedUserQuery = useOnboardingCreatedUser();

  console.log("onboardingCheckCodeQuery", onboardingCheckCodeQuery);

  const [userAvatar, setUserAvatar] = React.useState(null);
  const [userAvatarBlurHash, setUserAvatarBlurHash] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState(null);
  const [workspaceName, setWorkspaceName] = React.useState(null);

  useEffect(() => {
    if (onboardingCheckCodeQuery.isSuccess) {
      if (onboardingCheckCodeQuery.data.email) {
        setUserAvatar(onboardingCheckCodeQuery.data.userInfo?.avatarUrl);
        setUserAvatarBlurHash(
          onboardingCheckCodeQuery.data.userInfo?.avatarBlurHash
        );
        setUserName(onboardingCheckCodeQuery.data.userInfo?.name);
        setUserEmail(onboardingCheckCodeQuery.data.email);
        setWorkspaceName(onboardingCheckCodeQuery.data.workspace);
        setCodeValid(true);
      } else {
        setCodeValid(false);
      }
    }
  }, [onboardingCheckCodeQuery.isSuccess]);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    document.body.classList.add("body-login");
    return () => {
      document.body.classList.remove("body-login");
    };
  }, []);

  const [userPassword, setUserPassword] = useState("");

  const submitPassword = (password) => {
    console.log("submitPassword", password);
    setUserPassword(password);
    onboardingCreatedUserQuery.mutate({
      token: inviteCode,
      password: password,
      email: userEmail,
    });
  };

  const [errMsg, setErrMsg] = useState("");
  const { setAuth } = useAuth();

  const handleLogin = async () => {
    try {
      const res = await axios
        .post(
          serverUrl + "/auth",
          JSON.stringify({
            email: userEmail,
            pwd: userPassword,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log("return from api", JSON.stringify(res?.data));

          // tracker.setUserID(res?.data?.email);
          // tracker.setMetadata("name", res?.data?.username);

          // StonlyWidget("identify", res?.data?.email, {
          //   email: res?.data?.email,
          //   name: res?.data?.username,
          //   workspace: res?.data?.workspace,
          //   roles: res?.data?.roles,
          //   avatar: res?.data?.avatar,
          // });

          // $crisp.push([
          //   "set",
          //   "user:email",
          //   [res?.data?.email, res?.data?.crispSignature],
          // ]);
          // if (res?.data?.avatar)
          //   $crisp.push(["set", "user:avatar", [res?.data?.avatar]]);
          // $crisp.push(["set", "user:nickname", [res?.data?.username]]);
          // $crisp.push(["set", "user:company", [res?.data?.workspace]]);

          // const userType = res?.data?.roles?.includes("admin")
          //   ? "admin"
          //   : "user";
          // $crisp.push(["set", "session:data", [["roles", res?.data?.roles]]]);
          // $crisp.push(["set", "session:segments", [userType]]);

          setAuth({
            email: res?.data?.email,
            username: res?.data?.username,
            userid: res?.data?.userid,
            roles: res?.data?.roles,
            accessToken: res?.data?.accessToken,
            name: res?.data?.username,
            workspace: res?.data?.workspace,
            ...(res?.data?.linkedArtist && {
              linkedArtist: res?.data?.linkedArtist,
            }),
          });

          secureLocalStorage.setItem(
            "auth",
            JSON.stringify({
              workspace: res?.data?.workspace,
              accessToken: res?.data?.accessToken,
              userid: res?.data?.userid,
              email: res?.data?.email,
              username: res?.data?.username,
              ...(res?.data?.linkedArtist && {
                linkedArtist: res?.data?.linkedArtist,
              }),
            })
          );
          nightwind.enable(false);
          navigate("/projects", { replace: true });
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No server response");
        console.log("No server response", err);
      } else if (err.response?.status === 400) {
        setErrMsg("Missing user or password");
      } else if (err.response?.status === 401) {
        setErrMsg("Check username and password");
      } else {
        setErrMsg("Login Failed");
      }
      console.log("Login Failed ", errMsg);
    }
  };

  useEffect(() => {
    if (onboardingCreatedUserQuery.isSuccess) {
      console.log("onboardingCreatedUserQuery isSuccess");
      setTimeout(() => {
        handleLogin();
      }, 2000);
    } else if (onboardingCreatedUserQuery.isError) {
      console.log("onboardingCreatedUserQuery isError");
    }
  }, [
    onboardingCreatedUserQuery.isSuccess,
    onboardingCreatedUserQuery.isError,
  ]);

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Required";
          } else if (
            !/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/i.test(values.password)
          ) {
            errors.password =
              "Must be at least 8 characters long and contain at least one letter and one number.";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submitPassword(values.password);
            // setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            {onboardingCheckCodeQuery.isError && (
              <div className="fixed inset-0 flex h-screen w-screen overflow-hidden transition-all bg-white">
                <div className="my-auto mx-auto flex flex-col text-center text-4xl text-gray-800">
                  <ExclamationTriangleIcon className="mx-auto h-12 w-12 text-red-500" />
                  Error with invite code: <br />
                  {onboardingCheckCodeQuery.error}
                </div>
              </div>
            )}
            {codeValid === false && (
              <div className="fixed inset-0 flex h-screen w-screen overflow-hidden transition-all bg-white">
                <div className="my-auto mx-auto flex flex-col text-center text-3xl text-gray-800">
                  <ExclamationTriangleIcon className="mx-auto mb-2 h-12 w-12 text-red-500" />
                  This invitation is not valid
                </div>
              </div>
            )}
            {codeValid === true && (
              <>
                <form onSubmit={handleSubmit}>
                  {userAvatarBlurHash && (
                    <div className="absolute top-0 left-0 -z-10 h-full w-full  opacity-80">
                      <Blurhash
                        hash={userAvatarBlurHash}
                        width={width}
                        height={height}
                        resolutionX={width}
                        resolutionY={height}
                        punch={2}
                      />
                    </div>
                  )}
                  <div className="flex h-screen w-screen p-6">
                    <div className="mx-auto my-auto w-full max-w-lg  rounded-lg p-6 transition-all bg-gray-100">
                      <div
                        style={{
                          backgroundImage: `url(${userAvatar})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "50%",
                        }}
                        className="mx-auto -mt-20 aspect-square h-40 border-8 shadow bg-gray-100 border-gray-200 md:-mt-20  md:h-48"
                      />
                      {/* <img
            src="https://app.trackflow.studio/images/trackflow-logo-with-text-white.png"
            className="mt-6 inline-flex h-6 "
          /> */}
                      <div className="pt-6 text-3xl font-medium text-gray-800">
                        Welcome, {userName}!
                      </div>{" "}
                      <div className="pb-10 pt-8 text-lg text-gray-800">
                        Everything is set up for you by{" "}
                        <b className=" capitalize">
                          {workspaceName?.replace("-", " ")}
                        </b>
                        , all you need to do is choose a password
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email:
                        </label>
                        <div className="mt-3">
                          <input
                            name="email"
                            id="email"
                            type="email"
                            value={userEmail}
                            disabled
                            className="block w-full rounded-md shadow-sm  text-gray-800 bg-gray-100 border-gray-300 focus:border-green-500 focus:ring-green-500 dark:bg-zinc-800 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="pt-3">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Choose a password:
                        </label>
                        <div className="mt-3">
                          <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            className="block w-full rounded-md shadow-sm  bg-white text-gray-800 border-gray-300 focus:border-green-500 focus:ring-green-500 dark:bg-zinc-900 sm:text-sm"
                            aria-describedby="password-description"
                          />
                        </div>
                        <p
                          className="mt-2 text-sm text-gray-500"
                          id="password-description"
                        >
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>
                      </div>
                      <div className="pt-8">
                        <button
                          type="submit"
                          disabled={
                            isSubmitting || !values.password || errors.password
                          }
                          className={classNames(
                            !isSubmitting && values.password && !errors.password
                              ? "bg-green-600 hover:bg-green-500 dark:hover:bg-green-600"
                              : "bg-gray-300",
                            "flex h-12 w-full justify-center rounded-md border py-2 px-4 text-base font-medium shadow-sm  transition-all border-transparent text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 "
                          )}
                        >
                          <div className="my-auto mx-auto">
                            {!isSubmitting &&
                            values.password &&
                            !errors.password ? (
                              <>
                                <ArrowRightOnRectangleIcon className="mr-1 inline-flex h-5 w-5" />
                                Continue
                              </>
                            ) : isSubmitting &&
                              !onboardingCreatedUserQuery.isSuccess ? (
                              <div>
                                <DotsLoader color="#ffffff" />
                              </div>
                            ) : isSubmitting &&
                              onboardingCreatedUserQuery.isSuccess ? (
                              <div>Redirecting...</div>
                            ) : (
                              "Please choose a password"
                            )}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </Formik>
      <IsLoaded />
    </>
  );
};

export default OnboardingCreatedUser;
