import React, { createRef } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  HamburgerMenuIcon,
  DotFilledIcon,
  CheckIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import "./TrackMenu.css";

import {
  ArrowDownOnSquareIcon,
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
  ArrowUpOnSquareIcon,
  ArrowUpTrayIcon,
  EllipsisHorizontalIcon,
  FolderIcon,
  FolderOpenIcon,
  LinkIcon,
  PlayCircleIcon,
  PlayIcon,
  QueueListIcon,
  ShareIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import useAllProjects from "./hooks/useAllProjects";
import useAllPlaylists from "./hooks/useAllPlaylists";

import { useNavigate } from "react-router-dom";
import useSettings from "./hooks/useSettings";
import useUpdateTrack from "./hooks/useUpdateTrack";
import { classNames } from "./shared/Utils";
import useUpdateTrackProject from "./hooks/useUpdateTrackProject";
import useUpdateTrackStatus from "./hooks/useUpdateTrackStatus";
import { toast } from "react-toastify";
import { getAnalytics, logEvent } from "firebase/analytics";
import ToolTip from "./ToolTip";
import DeleteOverlay from "./DeleteOverlay";
import axios from "axios";
import fileDownload from "js-file-download";

import useResetTrackPreviewCode from "./hooks/useResetTrackPreviewCode";
import useAddTracksToPlaylist from "./hooks/useAddTracksToPlaylist";
import useNewPlaylistOverlay from "./hooks/useNewPlaylistOverlay";

const TrackMenu = (props) => {
  const analytics = getAnalytics();
  const allProjectsQuery = useAllProjects();
  const allPlaylistsQuery = useAllPlaylists();
  const { workspaceSettings } = useSettings();
  const availableStatuses = workspaceSettings?.statuses;

  const updateTrackProjectQuery = useUpdateTrackProject();
  const updateTrackStatusQuery = useUpdateTrackStatus();

  const addTracksToPlaylistQuery = useAddTracksToPlaylist();

  const resetTrackPreviewCodeQuery = useResetTrackPreviewCode();

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleUpdateStatus = (status) => {
    updateTrackStatusQuery.mutate({
      trackid: props.track.id,
      projectid: props.track.project,
      status: status,
    });
  };

  const handleUpdateProject = (project) => {
    updateTrackProjectQuery.mutate({
      trackid: props.track.id,
      projectid: props.track.project,
      project: project,
    });
  };

  const handleOpenTrack = () => {
    navigate(`/projects/${props.track.project}/list/track/${props.track.id}`);
  };

  const handleCopyPreviewLink = (track) => {
    if (track.previewcode) {
      const previewUrl = "https://p2.trackflow.studio/" + track.previewcode;
      navigator.clipboard.writeText(previewUrl);

      toast.success("Preview link copied to clipboard", {
        containerId: "main",
      });
    } else {
      resetTrackPreviewCodeQuery.mutate({
        trackid: track.id,
        projectid: track.project,
      });

      toast.error("No preview link in db. Created one now. Copy link again.", {
        containerId: "main",
      });
    }
  };

  const handleCopyDownloadLink = (track) => {
    if (track.downloadcode) {
      const previewUrl = "https://p2.trackflow.studio/" + track.downloadcode;
      navigator.clipboard.writeText(previewUrl);

      toast.success("Download link copied to clipboard", {
        containerId: "main",
      });
    } else {
      resetTrackPreviewCodeQuery.mutate({
        trackid: track.id,
        projectid: track.project,
      });

      toast.error("No download link in db. Created one now. Copy link again.", {
        containerId: "main",
      });
    }
  };

  const deleteOverlayRef = createRef();
  const handleDeleteTrack = (track) => {
    deleteOverlayRef.current.openDialog(track, track.project);
  };

  const handleDownloadOriginal = (track) => {
    axios
      .get(track.originalurl, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, track.originalurl.split(":original-").pop());
      });
  };

  const handleAddToPlaylist = ({ playlistId, playlistTitle }) => {
    console.log("add to playlist", playlistId);
    addTracksToPlaylistQuery.mutate({
      playlistId: playlistId,
      playlistTitle: playlistTitle,
      trackIds: [props.track.id],
    });
  };

  const { NewPlaylistOverlayRef } = useNewPlaylistOverlay();

  const handleAddToNewPlaylist = () => {
    NewPlaylistOverlayRef.current.openDialogWithInitTracks({
      trackIds: [props.track.id],
    });
  };

  return (
    <DropdownMenu.Root
      onOpenChange={(open) => {
        setOpen(open);
      }}
      modal={false}
      // open={open}
    >
      <DeleteOverlay ref={deleteOverlayRef} />
      {/* <ToolTip hide={open} message="Track actions"> */}
      <DropdownMenu.Trigger
        asChild
        // onClick={() => {
        //   if (props.recentTracks && !open) {
        //     setOpen(true);
        //   }
        // }}
      >
        {props.children}
      </DropdownMenu.Trigger>
      {/* </ToolTip> */}
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          <DropdownMenu.Item
            onSelect={() => {
              if (props.openTrack) {
                props.openTrack();
              } else {
                handleOpenTrack();
              }
            }}
            className="DropdownMenuItem font-medium"
          >
            <ArrowsPointingOutIcon className="mr-3  inline h-4 w-4 " />
            Open track
          </DropdownMenu.Item>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
              <div
                style={{
                  backgroundColor: availableStatuses?.find(
                    (status) => status.title === props.track.status
                  )?.color,
                }}
                className="mr-3 inline h-4 w-4  rounded-sm border-gray-800 dark:border-gray-800 "
              />
              {props.track.status}

              <div className="RightSlot">
                <ChevronRightIcon />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent
                className="DropdownMenuSubContent"
                sideOffset={2}
                alignOffset={-5}
              >
                {availableStatuses?.map((status) => (
                  <DropdownMenu.Item
                    key={status.id}
                    className={classNames(
                      status.title === props.track?.status ? "font-medium" : "",
                      "DropdownMenuItem flex flex-row items-center "
                    )}
                    onSelect={() => handleUpdateStatus(status.title)}
                  >
                    <div
                      style={{
                        backgroundColor:
                          status.title === props.track?.status
                            ? status.color
                            : "",
                        borderColor: status.color,
                      }}
                      c
                      className="mr-3 inline h-3 w-3 rounded-sm  border-2  "
                    />
                    <div>{status.title}</div>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
              <QueueListIcon className="mr-3  inline h-4 w-4 " />
              Add to playlist
              <div className="RightSlot">
                <ChevronRightIcon />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent
                className="DropdownMenuSubContent"
                sideOffset={2}
                alignOffset={-5}
              >
                {allPlaylistsQuery.data?.map((playlist) => {
                  const isTrackInPlaylist = playlist.tracks.find(
                    (track) => track.id === props.track.id
                  );
                  return (
                    <DropdownMenu.Item
                      key={playlist.id}
                      className={classNames(
                        isTrackInPlaylist ? "font-medium" : "",
                        "DropdownMenuItem"
                      )}
                      onSelect={() =>
                        handleAddToPlaylist({
                          playlistId: playlist.id,
                          playlistTitle: playlist.title,
                        })
                      }
                    >
                      {isTrackInPlaylist ? (
                        <CheckIcon className="mr-2 -ml-1  inline h-4 w-4 " />
                      ) : null}

                      {playlist.title}
                    </DropdownMenu.Item>
                  );
                })}

                <DropdownMenu.Separator className="DropdownMenuSeparator" />

                <DropdownMenu.Item
                  onSelect={() => handleAddToNewPlaylist()}
                  className="DropdownMenuItem"
                >
                  New playlist{" "}
                  <div className="RightSlot">
                    <EllipsisHorizontalIcon className="  inline h-4 w-4 " />
                  </div>
                </DropdownMenu.Item>
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
              <FolderIcon className="mr-3  inline h-4 w-4 " />
              Move to
              <div className="RightSlot">
                <ChevronRightIcon />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent
                className="DropdownMenuSubContent"
                sideOffset={2}
                alignOffset={-5}
              >
                {allProjectsQuery.data?.map((project) => (
                  <DropdownMenu.Item
                    key={project.id}
                    className={classNames(
                      props.track.project === project.id ? "font-medium" : "",
                      "DropdownMenuItem"
                    )}
                    onSelect={() => handleUpdateProject(project.id)}
                  >
                    {props.track.project === project.id ? (
                      <CheckIcon className="mr-2 -ml-1  inline h-4 w-4 " />
                    ) : null}

                    {project.title}
                  </DropdownMenu.Item>
                ))}

                {/* <DropdownMenu.Separator className="DropdownMenuSeparator" />

                <DropdownMenu.Item className="DropdownMenuItem">
                  New project{" "}
                  <div className="RightSlot">
                    <EllipsisHorizontalIcon className="  inline h-4 w-4 " />
                  </div>
                </DropdownMenu.Item> */}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Separator className="DropdownMenuSeparator" />

          <DropdownMenu.Label className="DropdownMenuLabel">
            Audio
          </DropdownMenu.Label>
          <DropdownMenu.Item
            onSelect={() => handleDownloadOriginal(props.track)}
            className="DropdownMenuItem"
          >
            <ArrowDownOnSquareIcon className="mr-3  inline h-4 w-4 " />
            Download original
          </DropdownMenu.Item>

          <DropdownMenu.Separator className="DropdownMenuSeparator" />

          <DropdownMenu.Label className="DropdownMenuLabel">
            Sharing
          </DropdownMenu.Label>
          <DropdownMenu.Item disabled className="DropdownMenuItem">
            <ShareIcon className="mr-3  inline h-4 w-4 " />
            Share
            <div className="RightSlot">
              <EllipsisHorizontalIcon className="  inline h-4 w-4 " />
            </div>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => handleCopyPreviewLink(props.track)}
            className="DropdownMenuItem"
          >
            <LinkIcon className="mr-3  inline h-4 w-4 " />
            Copy share link
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => handleCopyDownloadLink(props.track)}
            className="DropdownMenuItem"
          >
            <ArrowDownOnSquareIcon className="mr-3  inline h-4 w-4 " />
            Copy download link
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item
            onSelect={() => handleDeleteTrack(props.track)}
            className="DropdownMenuItem"
          >
            <XMarkIcon className="mr-3  inline h-4 w-4 " />
            Delete track
          </DropdownMenu.Item>

          <DropdownMenu.Arrow className="DropdownMenuArrow" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default TrackMenu;
