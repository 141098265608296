import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import useSettings from "./hooks/useSettings";

import useAuth from "./hooks/useAuth";
import { useAuth0 } from "@auth0/auth0-react";

import DotsLoader from "./DotsLoader";

const RequiredAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const { bottomPlayerOpen, setBottomPlayerOpen } = useSettings();
  const { logout } = useAuth();

  const { isAuthenticated, isLoading } = useAuth0();

  console.log("RequiredAuth isAuthenticated", !isAuthenticated && !isLoading);

  if (!isAuthenticated && !isLoading) {
    // setBottomPlayerOpen(false);
    // logout();
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return auth?.accessToken ? (
      <Outlet />
    ) : (
      <div className="-mb-[8rem]  flex h-screen w-screen items-center justify-center md:-ml-[5rem]">
        <DotsLoader dark big />
      </div>
    );
  }
};

export default RequiredAuth;
