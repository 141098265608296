import React, { Fragment } from "react";

import { useState, useEffect } from "react";
import { Reorder } from "framer-motion";

import useUpdateWorkspace from "../hooks/useUpdateWorkspace";
import useSettings from "../hooks/useSettings";
import secureLocalStorage from "react-secure-storage";

import _ from "lodash";
import shortUUID from "short-uuid";

import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/solid";

import useAuth from "../hooks/useAuth";

import ColorPicker from "../ColorPicker";
import IconPicker from "../IconPicker";
import { PlusIcon } from "@heroicons/react/24/solid";

export const initStatuses = [
  {
    id: 1,
    title: "New",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#bbbbbb",
  },
  {
    id: 2,
    title: "Writing",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#ef4444",
  },
  {
    id: 3,
    title: "Feature",
    iconName: "MicrophoneIcon",
    icon: MicrophoneIcon,
    color: "#eab308",
  },
  {
    id: 4,
    title: "Vocals",
    iconName: "MicrophoneIcon",
    icon: MicrophoneIcon,
    color: "#eab308",
  },
  {
    id: 5,
    title: "Mixing",
    iconName: "AdjustmentsVerticalIcon",
    icon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
  },
  {
    id: 6,

    title: "Mastering",
    iconName: "AdjustmentsVerticalIcon",
    icon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
  },
  {
    id: 7,
    title: "Assets",
    iconName: "StarIcon",
    icon: StarIcon,
    color: "#22c55e",
  },
  {
    id: 8,
    title: "Finished",
    iconName: "StarIcon",
    icon: StarIcon,
    color: "#22c55e",
  },
];

export default function ProjectEditStatuses({ setFullWidth, open, setOpen }) {
  const { workspaceSettings } = useSettings();
  const availableStatuses = workspaceSettings.statuses;

  const [statuses, setStatuses] = useState(availableStatuses);

  const { auth } = useAuth();

  const updateWorkspaceQuery = useUpdateWorkspace();
  const currentWorkspace = auth?.workspace;

  const setColor = ({ color, id }) => {
    setStatuses(
      statuses.map((item) =>
        item.id === id ? { ...item, color: color } : item
      )
    );
  };

  const setIcon = ({ iconName, id }) => {
    setStatuses(
      statuses.map((item) =>
        item.id === id ? { ...item, iconName: iconName } : item
      )
    );
  };

  const [error, setError] = useState(null);
  const [addButton, setAddButton] = useState(
    <>
      <PlusIcon className="mx-auto my-auto mr-2 h-5  w-5 text-gray-500 " />
      Add status
    </>
  );

  const handleAddStatus = () => {
    if (statuses[statuses.length - 1]?.title || statuses.length == 0) {
      setStatuses((oldStatuses) => [
        ...oldStatuses,
        {
          id: shortUUID.generate(),
          title: "",
          iconName: "PencilIcon",
          icon: PencilIcon,
          color: "#bbbbbb",
          progress: 30,
        },
      ]);
    } else {
      setAddButton("Please name previous status first");
    }
  };

  const handleDeleteStatus = (id) => {
    setStatuses((oldStatuses) => oldStatuses.filter((s) => s.id !== id));
  };

  const handleSaveStatuses = () => {
    console.log("save statuses to workspace settings");
    updateWorkspaceQuery.mutate({
      workspaceid: currentWorkspace,
      workspace: {
        settings: {
          statuses: statuses,
        },
      },
    });

    setOpen(false);
  };

  console.log("statuses", statuses);
  return (
    <div className="w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Statuses</h1>
          <p className="mt-2 text-sm text-gray-700">
            These statuses are used in the whole workspace.
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4  sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border shadow ring-1 ring-opacity-5 ring-black border-gray-300 md:rounded-lg">
              <div className="min-w-full divide-y rounded-md bg-white  divide-gray-300">
                <div className=" divide-y overflow-hidden divide-gray-200">
                  <Reorder.Group
                    axis="y"
                    values={statuses}
                    onReorder={setStatuses}
                  >
                    {statuses.map((status) => (
                      <Reorder.Item key={status.id} value={status}>
                        <div
                          key={status.id}
                          className="group relative flex h-12 flex-row border-b bg-white border-gray-300 hover:bg-gray-50"
                        >
                          <div className="absolute left-0 cursor-grab px-1 opacity-0 text-gray-500 group-hover:opacity-100">
                            <svg
                              className="svg-icon"
                              style={{
                                width: "1.6em",
                                height: "2.8em",
                                verticalAlign: "middle",
                                fill: "currentColor",
                                overflow: "hidden",
                              }}
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M384 128h85.333333v85.333333H384V128m170.666667 0h85.333333v85.333333h-85.333333V128M384 298.666667h85.333333v85.333333H384V298.666667m170.666667 0h85.333333v85.333333h-85.333333V298.666667m-170.666667 170.666666h85.333333v85.333334H384v-85.333334m170.666667 0h85.333333v85.333334h-85.333333v-85.333334m-170.666667 170.666667h85.333333v85.333333H384v-85.333333m170.666667 0h85.333333v85.333333h-85.333333v-85.333333m-170.666667 170.666667h85.333333v85.333333H384v-85.333333m170.666667 0h85.333333v85.333333h-85.333333v-85.333333z" />
                            </svg>
                          </div>
                          <div className="my-auto ml-5 w-12 flex-shrink-0 whitespace-nowrap   pl-3  pr-3 text-sm ">
                            <div
                              style={{
                                color: status.color,
                                // borderColor: status.color,
                              }}
                              className="flex h-8 w-8 "
                            >
                              <IconPicker
                                iconName={status.iconName}
                                setIcon={setIcon}
                                id={status.id}
                              />
                            </div>
                          </div>
                          <div className=" my-auto ml-3 w-12 flex-shrink-0 rounded-md">
                            <div className="flex h-8 w-8 ">
                              <ColorPicker
                                setColor={setColor}
                                id={status.id}
                                color={status.color}
                              />
                            </div>
                          </div>

                          <div className="my-auto whitespace-nowrap text-sm text-gray-500">
                            <div className="-ml-2">
                              <input
                                type="text"
                                className="dark:placeholder:zinc-700 w-[11.8rem] select-none border font-medium  border-transparent text-gray-800 focus:border-transparent group-hover:bg-gray-50 dark:bg-zinc-900"
                                value={status.title}
                                placeholder="Status title"
                                onChange={(e) => {
                                  setStatuses(
                                    statuses.map((item) =>
                                      item.id === status.id
                                        ? { ...item, title: e.target.value }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="relative ml-1  mr-1 flex w-8  cursor-pointer opacity-0 text-gray-400 hover:text-gray-600 group-hover:opacity-100"
                            onClick={() => {
                              handleDeleteStatus(status.id);
                            }}
                          >
                            <TrashIcon className=" mx-auto my-auto mr-2 h-5 w-5" />
                          </div>
                        </div>
                      </Reorder.Item>
                    ))}
                  </Reorder.Group>
                </div>
              </div>
              <div
                onClick={() => handleAddStatus()}
                className="flex cursor-pointer rounded-b-md border-t p-4 bg-gray-50 border-gray-200 hover:bg-gray-100"
              >
                <div className="my-auto mx-auto flex text-xs uppercase tracking-wider ">
                  <div className="flex  w-full  select-none font-medium uppercase text-gray-500 border-gray-300">
                    {addButton}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  justify-between pt-8">
        <button
          type="button"
          onClick={() => {
            setOpen(false);
          }}
          className="inline-flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm border-transparent text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto"
        >
          Cancel{" "}
        </button>
        <button
          type="button"
          onClick={() => handleSaveStatuses()}
          className="inline-flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm border-transparent text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto"
        >
          Save settings{" "}
        </button>
      </div>
    </div>
  );
}
