import React, { useEffect } from "react";

import useAxiosPrivate from "../components/hooks/useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "../components/hooks/Api.js";
import IsLoaded from "../IsLoaded.js";

const CannyRedirect = () => {
  const axiosPrivate = useAxiosPrivate();

  const tokenQuery = useQuery(["canny-token"], () =>
    axiosPrivate.get(serverUrl + "/canny/token").then((res) => res.data)
  );

  function getQueryParameterByName(name) {
    var pairStrings = window.location.search.slice(1).split("&");
    var pairs = pairStrings.map(function (pair) {
      return pair.split("=");
    });
    return pairs.reduce(function (value, pair) {
      if (value) return value;
      return pair[0] === name ? decodeURIComponent(pair[1]) : null;
    }, null);
  }

  function getRedirectURL(ssoToken) {
    var redirectURL = getQueryParameterByName("redirect");
    var companyID = getQueryParameterByName("companyID");
    if (redirectURL.indexOf("https://") !== 0 || !companyID) {
      return null;
    }

    return (
      "https://canny.io/api/redirects/sso?companyID=" +
      companyID +
      "&ssoToken=" +
      ssoToken +
      "&redirect=" +
      redirectURL
    );
  }
  const [token, setToken] = React.useState("");

  useEffect(() => {
    if (tokenQuery.data) {
      setToken(tokenQuery.data);
    }
  }, [tokenQuery.isSuccess]);

  if (token) {
    var redirectURL = getRedirectURL(token);
    if (redirectURL) {
      window.location.assign(redirectURL);
    }
  }
  return <div>{/* <IsLoaded /> token: {token} */}</div>;
};

export default CannyRedirect;
