import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useResetTrackPreviewCode() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectid, trackid }) =>
      axiosPrivate
        .post(serverUrl + "/tracks/" + trackid + "/resetpreviewcode")
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
        queryClient.invalidateQueries(['all-tracks']);
        queryClient.invalidateQueries(["track", variables.trackid]);
        return data;
      },
    }
  );
}
