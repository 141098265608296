import { createContext, useRef } from "react";
import NewPlaylistOverlay from "../components/PlayList/NewPlaylistOverlay";

const NewPlaylistOverlayContext = createContext({});

export const NewPlaylistOverlayProvider = ({ children }) => {
  const NewPlaylistOverlayRef = useRef(null);

  return (
    <NewPlaylistOverlayContext.Provider value={{ NewPlaylistOverlayRef }}>
      <NewPlaylistOverlay ref={NewPlaylistOverlayRef} />
      {children}
    </NewPlaylistOverlayContext.Provider>
  );
};

export default NewPlaylistOverlayContext;
