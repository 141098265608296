import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { toast } from "react-toastify";

import short from "short-uuid";

import useCreateInvitation from "../../hooks/useCreateInvitation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/20/solid";

const AdminInviteWorkspaceContent = ({ close }) => {
  const createInvitationQuery = useCreateInvitation();

  const [email, setEmail] = React.useState("");
  const [copied, setCopied] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    createInvitationQuery.mutateAsync({
      email: email,
      name: "",
      invitecode: "onboarding-" + short.generate(),
      sendEmail: false,
      type: "workspace",
    });
    // .then((res) => {
    //   toast.success("Invitation created!", {
    //     containerId: "main",
    //   });
    // });
  };

  console.log("createInvitationQuery", createInvitationQuery);

  const [buttonText, setButtonText] = React.useState("Create Invitation");
  return (
    <div className="flex w-full flex-col gap-6 p-6">
      <div className="flex w-full flex-row justify-between">
        <div className="text-2xl text-gray-700">
          Invite someone to trackflow
        </div>
        <button onClick={close}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
      {!createInvitationQuery.isSuccess ? (
        <>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md shadow-sm bg-white border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="user@example.com"
                aria-describedby="email-description"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="email-description">
              This is needed for the onboarding process.We won't send a link.
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm ring-offset-white bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-zinc-900"
            >
              {buttonText}
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-4">
          {/* <div className="text-gray-700 text-xl">Invitation created!</div> */}
          <div className="text-sm text-gray-500">
            <CopyToClipboard
              text={
                "https://app.trackflow.studio/onboarding/" +
                createInvitationQuery.data?.inviteCode?.substring(11)
              }
              onCopy={() => {
                setCopied(true);
                toast.success("Copied to clipboard!", { containerId: "main" });
              }}
            >
              <div className="group flex cursor-pointer flex-row">
                <div className="w-[32rem] overflow-hidden truncate rounded-l-md border px-2 py-2 bg-white border-gray-200 ">
                  {"https://app.trackflow.studio/onboarding/" +
                    createInvitationQuery.data?.inviteCode.substring(11)}
                </div>
                <div className="overflow-hidden truncate rounded-r-md border-y  border-r px-2 py-2 bg-white text-gray-600 border-gray-200 group-hover:bg-gray-100 ">
                  {copied ? (
                    <ClipboardDocumentCheckIcon className="h-6 w-6" />
                  ) : (
                    <ClipboardDocumentIcon className="h-6 w-6" />
                  )}
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminInviteWorkspaceContent;
