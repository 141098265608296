import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  PlayCircleIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../shared/Utils";

import MenuToggle from "../MenuToggle";

const NotificationListTabsUser = ({ bg, tab, setFilter }) => {
  const [notificationCategories, setNotificationCategories] = useState([
    {
      id: "invitedToWorkspace",
      name: "New invites",
      icon: UserCircleIcon,
      isVisible: true,
    },
    {
      id: "trackAdded",
      name: "New tracks",
      icon: PlusCircleIcon,
      isVisible: true,
    },
    {
      id: "newVersion",
      name: "New versions",
      icon: PlayCircleIcon,
      isVisible: true,
    },

    {
      id: "trackUpdated",
      name: "Track updates",
      icon: ExclamationCircleIcon,
      isVisible: true,
    },
  ]);

  useEffect(() => {
    setFilter(
      notificationCategories
        .filter((category) => category.isVisible)
        .map((category) => category.id)
    );
  }, [notificationCategories]);

  const ref = useRef(null);
  return (
    <div>
      <Menu open={true} as="div" className="relative  text-left">
        <div
          className={classNames(
            tab.current
              ? "text-gray-600 border-gray-600"
              : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-200",
            "toolbar-menu group inline-flex w-full cursor-pointer   justify-center border-b-2 px-2 pb-3 text-sm font-medium focus:outline-none  focus-visible:ring-white  focus-visible:ring-2   focus-visible:ring-opacity-75  "
          )}
        >
          User
          <Menu.Button ref={ref}>
            <span
              className={classNames(
                "text-gray-100 bg-gray-400 ml-2 mt-0.5 flex h-5 rounded-md px-[0.08rem] text-[0.8rem] hover:bg-gray-600 "
              )}
            >
              <div className="my-auto">
                {" "}
                <ChevronDownIcon className=" h-5 w-5 " aria-hidden="true" />
              </div>
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-75 delay-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="bg-white ring-black border-gray-300 divide-gray-100 absolute left-0 mt-2 w-48 origin-top-right divide-y rounded-md border shadow-lg ring-1 ring-opacity-5 focus:outline-none"
          >
            <div className="flex flex-col  overflow-hidden rounded-md">
              {notificationCategories?.map((category) => {
                return (
                  <div key={category.id}>
                    <Menu.Item
                      onClick={() => {
                        // set isVisible to current category
                        setNotificationCategories(
                          notificationCategories.map((c) => {
                            if (c.id === category.id) {
                              return {
                                ...c,
                                isVisible: !c.isVisible,
                              };
                            }
                            return c;
                          })
                        );

                        setTimeout(() => {
                          ref.current?.click();
                        }, 0);

                        // rerender();
                      }}
                    >
                      {({ active }) => (
                        <div
                          className={`${
                            active
                              ? "text-gray-600 bg-gray-50"
                              : "text-gray-600"
                          } ${
                            category.isVisible
                              ? "text-gray-600 bg-gray-100"
                              : "text-gray-600"
                          } group flex w-full select-none items-center px-2 text-xs  hover:bg-opacity-50 dark:hover:bg-opacity-80`}
                        >
                          <label className="flex w-48 cursor-pointer flex-row py-2">
                            <div className=" my-auto  mb-0.5 w-full text-xs font-normal capitalize">
                              <category.icon className="mr-3 mb-0.5 inline h-5 w-5" />
                              {category.name}
                            </div>
                          </label>
                          <MenuToggle enabled={category.isVisible} />
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                );
              })}
              {/* {JSON.stringify(columnSettings)} */}{" "}
              {/* <div className="w-full px-2 py-1 ">
                Updating this saves settings for this project for everyone in
                your team.
              </div> */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default NotificationListTabsUser;
