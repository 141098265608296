import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useCreateArtist() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id, name, avatarUrl, avatarUrlSmall, avatarBlurHash, connectedUsers }) =>
      axiosPrivate
        .post(serverUrl + "/artists/", {
          ...(avatarUrl && { avatarUrl: avatarUrl }),
          ...(avatarUrlSmall && { avatarUrlSmall: avatarUrlSmall }),
          ...(avatarBlurHash && { avatarBlurHash: avatarBlurHash }),
          ...(connectedUsers && { connectedUsers: connectedUsers }),
          id: id,
          name: name,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["all-artists"]);

        queryClient.invalidateQueries(["tracksbyproject", variables.projectid]);
      },
    }
  );
}
