import React from "react";
import useAuth from "../../hooks/useAuth";

const TrackListTrackOverlayNavbarTaskCount = ({ unfinishedTasksCount }) => {
  if (unfinishedTasksCount > 0) {
    return (
      <div className="ml-3 hidden rounded-full py-0.5 px-2.5 text-tiny font-medium bg-white text-gray-800 md:inline-block">
        {unfinishedTasksCount}
      </div>
    );
  }
};

export default TrackListTrackOverlayNavbarTaskCount;
