import React, { useRef, useEffect, useState } from "react";

import NotificationsList from "./NotificationsList";
import { Cog6ToothIcon, EyeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import NotificationsListTabs from "./NotificationListTabs";
import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { classNames } from "../shared/Utils";
import useWorkspace from "../hooks/useWorkspace";
import secureLocalStorage from "react-secure-storage";

import useSettings from "../hooks/useSettings";
import useAuth from "../hooks/useAuth";

const NavBarNotificationsBar = (props) => {
  const { bottomPlayerOpen, setNotificationsBarFixed, notificationsBarFixed } =
    useSettings();

  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  const { auth } = useAuth();

  const workspaceQuery = useWorkspace(auth?.workspace);
  console.log("workspaceQuery", workspaceQuery);
  useEffect(() => {
    if (props.open) {
      console.log("REFETCHING NOTIFICATIONS");
      workspaceQuery.refetch();
    } else {
      setNotificationsBarFixed(false);
    }
  }, [props.open]);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (workspaceQuery?.data?.history) {
      console.log("setNotifications");
      setNotifications(workspaceQuery?.data?.history);
      props.setNotificationsNotSeenByMe(notificationsNotSeenByMe);
    }
  }, [workspaceQuery]);

  const notificationsNotSeenByMe = notifications?.filter(
    (notification) => !notification.seenBy?.includes(auth?.email)
  ).length;

  const [filter, setFilter] = useState([]);

  const filteredNotifications = notifications?.filter((notification) =>
    filter.includes(notification.action)
  );

  return (
    <div
      className={classNames(
        props.open ? "left-16 opacity-100" : "left-[-28rem] ",
        bottomPlayerOpen ? "h-[calc(100vh-70px)]" : "h-screen",
        "fixed  transition-all duration-300"
      )}
      style={
        bottomPlayerOpen ? { marginBottom: "120px" } : { paddingBottom: "0px" }
      }
      ref={wrapperRef}
    >
      <div
        className={classNames(
          notificationsBarFixed ? "shadow-sm" : "shadow-2xl",
          "absolute top-0 flex h-full w-[28rem] flex-col border-r   bg-white text-gray-600 border-gray-300 divide-gray-300  dark:text-zinc-200  dark:border-zinc-600 dark:divide-zinc-700 "
        )}
      >
        <div className="h-full overflow-scroll border-gray-200 ">
          <div className=" sticky top-0 z-[60] flex flex-col self-start bg-white text-gray-800 border-gray-200  ">
            <div className="flex  justify-between">
              <div className="px-6 py-5 text-lg font-medium ">
                Notifications
              </div>
              <div className="my-auto">
                <button
                  type="button"
                  onClick={() => props.setOpen(false)}
                  className="my-auto  mt-0.5 mr-[0.9rem] inline-flex items-center text-sm  font-medium text-gray-400  hover:text-gray-700  hover:border-gray-300 focus:outline-none focus:ring-0 focus:ring-green-500  dark:focus:ring-offset-zinc-900"
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            {props.open && (
              <NotificationsListTabs
                filter={filter}
                setFilter={setFilter}
                setOpen={props.setOpen}
              />
            )}
          </div>
          {props.open && (
            <NotificationsList
              mentions={[]}
              notifications={filteredNotifications}
            />
          )}
        </div>
        {/* <PopoverPrimitive.Arrow className="fill-white dark:fill-zinc-600 " /> */}
      </div>
    </div>
  );
};

export default NavBarNotificationsBar;
