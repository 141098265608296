import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useCreateResetPasswordToken() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({ email, ip }) =>
      axiosPrivate
        .post(serverUrl + "/resetpassword/request", {
          email: email,
          ip: ip,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['resetpasswordtokens']);
      },
    }
  );
}
