import React, { useState } from "react";

import { nanoid } from "nanoid";

import ArtistPhoneInput from "./ArtistPhoneInput";
import { ArtistEmailInput } from "./ArtistEmailInput";
import {
  StarIcon,
  ArrowDownOnSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../shared/Utils";
import ArtistContactTypeInput from "./ArtistContactTypeInput";

const ArtistContactCard = ({
  saveContact,
  dbcontact,
  edit,
  bggray,
  border,
}) => {
  const [contact, setContact] = useState(dbcontact);
  const [name, setName] = useState(dbcontact.name);
  const [email, setEmail] = useState(dbcontact.email);
  const [tel, setTel] = useState(dbcontact?.tel);
  const [changed, setChanged] = useState(false);

  console.log("contact", contact);

  const addEmail = () => {
    if (email)
      setEmail([...email, { id: nanoid(), type: "primary", value: "" }]);
    else setEmail([{ id: nanoid(), type: "primary", value: "" }]);
    setChanged(true);
  };

  const addTel = () => {
    if (tel) setTel([...tel, { id: nanoid(), type: "primary", value: "" }]);
    else setTel([{ id: nanoid(), type: "primary", value: "" }]);
    setChanged(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setChanged(true);
  };

  // edit contact
  const handleEmailChange = (e) => {
    const newState = email.map((email) => {
      if (email.id == e.target.id) {
        return { ...email, value: e.target.value };
      }
      return email;
    });
    console.log("newState", newState);
    setEmail(newState);
    setChanged(true);
  };

  const handleEmailDelete = (e, id) => {
    const newState = email.filter(function (mail) {
      return mail.id !== id;
    });
    setEmail(newState);
    setChanged(true);
  };

  const handleTelChange = (e, id) => {
    const newState = tel.map((tel) => {
      if (tel.id == id) {
        return { ...tel, value: e.target.value };
      }
      return tel;
    });
    setTel(newState);
    setChanged(true);
  };

  const handleTelDelete = (e, id) => {
    const newState = tel.filter(function (tel) {
      return tel.id !== id;
    });
    setTel(newState);
    setChanged(true);
  };
  // save contact
  const handleSave = () => {
    saveContact({ ...dbcontact, email: email, tel: tel, name: name });
    setChanged(false);
  };
  // add contact
  const handleAdd = (e) => {
    console.log("contact", contact);
    dbcontact.addContact(contact);
  };

  if (contact && contact != null)
    return (
      <div className="">
        <div
          className={classNames(
            "  flex w-full flex-col rounded-md  border transition-all border-gray-300 dark:shadow-none  dark:divide-zinc-800 "
          )}
        >
          <div
            className={classNames(
              bggray ? "bg-gray-100 dark:bg-zinc-800 " : "bg-gray-100",
              " flex w-full justify-between   rounded-t-md border-b px-2 py-1 border-gray-300"
            )}
          >
            <div className="flex w-full text-base font-medium text-gray-800">
              <div
                className={classNames(
                  bggray ? "bg-white dark:bg-zinc-800" : "bg-white",
                  "my-auto mr-3 inline-flex h-8 w-8 flex-shrink-0 rounded-full p-1 text-center  align-middle "
                )}
              >
                <div className="  font-base my-auto mx-auto text-sm opacity-50">
                  {name
                    .match(/(\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()}
                </div>
              </div>

              <input
                type="text"
                value={name}
                disabled={!edit}
                onChange={(e) => {
                  handleNameChange(e);
                }}
                placeholder="Name"
                className={classNames(
                  edit ? "border-gray-300" : " border-transparent ",
                  bggray ? "bg-gray-100 dark:bg-zinc-800" : "bg-gray-100",
                  "peer -ml-2 inline-flex w-full rounded  border px-2  py-[0.4rem] text-base  text-gray-800 focus:border-gray-300  "
                )}
              />
            </div>
            <div className="my-auto text-xs font-medium uppercase text-gray-500">
              <ArtistContactTypeInput
                bggray={bggray}
                edit={edit}
                type={contact.type}
              />
            </div>
          </div>
          <div
            className={classNames(
              edit ? "p-4" : "px-2 pt-2",
              bggray ? "bg-gray-100" : "bg-white",
              " grid grid-cols-1 justify-between  gap-1 truncate rounded-b-md transition-all  text-gray-700 "
            )}
          >
            {email?.map((email, i) => (
              <div key={email.id}>
                <ArtistEmailInput
                  bggray={bggray}
                  edit={edit}
                  handleChange={handleEmailChange}
                  handleDelete={handleEmailDelete}
                  email={email}
                  changed={changed}
                />
              </div>
            ))}
            {tel?.map((tel, i) => (
              <ArtistPhoneInput
                bggray={bggray}
                edit={edit}
                handleChange={handleTelChange}
                handleDelete={handleTelDelete}
                tel={tel}
                changed={changed}
                key={i}
              />
            ))}
            {!email && !tel && (
              <div className="w-full text-center text-xs text-gray-400">
                No contact info
              </div>
            )}
            {/* <div>
              <PlusIcon className="text-gray-500 w-4 h-4 inline-flex" />
            </div> */}
            <div className="mt-2 flex justify-between">
              <div
                className={classNames(
                  edit ? "mt-2 flex opacity-100" : "-mt-10 flex   opacity-0   ",
                  " mt-3 justify-start transition-all"
                )}
              >
                {/* {(contact.email || contact.tel) && (
                <div className="ml-3 bg-white hover:bg-gray-100 cursor-pointer text-gray-300 hover:text-gray-400 px-2 py-1 w-fit shadow-sm border rounded text-xs  border-gray-200">
                  <ArrowDownOnSquareIcon className=" w-4 h-4 mb-0.5 inline-flex " />
                </div>
              )} */}
                <div
                  onClick={() => addEmail()}
                  className="mr-3 w-fit cursor-pointer rounded border px-2 py-1 text-xs shadow-sm bg-white text-gray-500 border-gray-300 hover:bg-gray-100"
                >
                  <PlusIcon className="mb-0.5 mr-1 inline-flex h-4 w-4 text-gray-500 " />
                  email
                </div>
                <div
                  onClick={() => addTel()}
                  className="mr-3 w-fit cursor-pointer rounded border px-2 py-1 text-xs shadow-sm bg-white text-gray-500 border-gray-300 hover:bg-gray-100"
                >
                  <PlusIcon className="mb-0.5 mr-1 inline-flex h-4 w-4 text-gray-500 " />
                  phone
                </div>
              </div>
              <div
                className={classNames(
                  changed ? "mt-2 flex opacity-100 " : "flex opacity-0   ",
                  " justify-start transition-all"
                )}
              >
                {edit && (
                  <div
                    onClick={handleSave}
                    className={classNames(
                      changed ? "ml-3 h-8 w-[4.5rem]  border px-2" : "w-0 ",
                      "duration-400 cursor-pointer  rounded py-1 text-xs shadow-sm transition-all  text-white bg-green-500 border-green-500 hover:bg-green-400 "
                    )}
                  >
                    <StarIcon className=" mb-0.5 mr-1 inline-flex h-4 w-4" />
                    Save
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ArtistContactCard;
