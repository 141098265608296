import React from "react";
import {
  ClipboardDocumentCheckIcon,
  FolderIcon,
  Square3Stack3DIcon,
  InformationCircleIcon,
  ClockIcon,
  ShareIcon,
  ChartPieIcon,
  PhotoIcon,
  ChevronDownIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";

import {
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
  FolderIcon as FolderIconSolid,
  Square3Stack3DIcon as Square3Stack3DIconSolid,
  InformationCircleIcon as InformationCircleIconSolid,
  ClockIcon as ClockIconSolid,
  ShareIcon as ShareIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  PresentationChartBarIcon as PresentationChartBarIconSolid,
  PlayIcon,
} from "@heroicons/react/24/solid";

import { classNames } from "../../shared/Utils";
import TrackListStatus from "../TrackListStatus";
import TrackListDatePicker from "../TrackListDatePicker";
import MiniPlayer from "../../MiniPlayer";
import GetSpotifyAvatars from "../../GetSpotifyAvatars";
import { ColorExtractor } from "react-color-extractor";
import { useEffect } from "react";
import TrackListTrackOverlayNavbarShareCount from "./TrackListTrackOverlayNavbarShareCount";
import TrackListTrackOverlayNavbarTaskCount from "./TrackListTrackOverlayNavbarTaskCount";
import TrackMenu from "../../TrackMenu";
import TrackListTrackOverlayNavbarPlaybutton from "./TrackListTrackOverlayNavbarPlaybutton";

const TrackListTrackOverlayNavbar = ({
  currentTab,
  setCurrentTab,
  track,
  tasks,
  unfinishedTasksCount,
  newExternalTrackPlaysCount,
  availableStatuses,
}) => {
  const tabs = [
    {
      title: "Tasks",
      icon: ClipboardDocumentCheckIcon,
      iconSolid: ClipboardDocumentCheckIconSolid,
    },
    {
      title: "Track info",
      icon: InformationCircleIcon,
      iconSolid: InformationCircleIconSolid,
    },
    {
      title: "Versions",
      icon: Square3Stack3DIcon,
      iconSolid: Square3Stack3DIconSolid,
    },

    { title: "Assets", icon: FolderIcon, iconSolid: FolderIconSolid },
    { title: "Splits", icon: ChartPieIcon, iconSolid: ChartPieIconSolid },
    { title: "---" },
    { title: "Share", icon: ShareIcon, iconSolid: ShareIconSolid },
    {
      title: "Share statistics",
      icon: PresentationChartBarIcon,
      iconSolid: PresentationChartBarIconSolid,
    },
    { title: "Activity", icon: ClockIcon, iconSolid: ClockIconSolid },
  ];

  const [images, setImages] = React.useState([]);
  const [colors, setColors] = React.useState([]);

  useEffect(() => {
    if (track) {
      setImages(track?.artists?.map((artist) => artist.avatarUrl));
    }
  }, [track]);

  console.log("overlaynavbar artists", track?.artists);

  return (
    <div
      style={{
        backgroundColor: colors[0] + "00" || "#00000022",
        backgroundBlendMode: "multiply",
      }}
      className="flex h-full flex-col "
    >
      <div className="flex flex-col ">
        {track?.artists?.length > 0 ? (
          <div className="group relative m-4 flex aspect-square flex-row items-end overflow-hidden rounded-md dark:opacity-80">
            <TrackListTrackOverlayNavbarPlaybutton track={track} />
            {images && images.length > 0
              ? images?.map((image, index) => {
                  if (index === 0) {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          index === images.length - 1
                            ? " rounded-md"
                            : "rounded-l-md",
                          " h-full  w-full overflow-hidden  "
                        )}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                          }}
                          className=" top-0 left-0 h-full w-full "
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={image}
                        style={{
                          backgroundColor: colors[0],
                        }}
                        className={classNames(
                          index === images.length - 1 ? "rounded-r-md" : "",
                          "h-full w-full overflow-hidden"
                        )}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPositionX: "center",
                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat",
                            // filter:
                            //   " hue-rotate(" +
                            //   getHueRotate(mainColor) +
                            //   "deg) saturate(1) contrast(1.2)",
                          }}
                          className="h-full w-full opacity-80 bg-blend-screen contrast-125"
                        />
                      </div>
                    );
                  }
                })
              : "no images"}
            {images?.length > 0 && (
              <ColorExtractor
                src={images[0]}
                getColors={(colors) => setColors(colors)}
              />
            )}
          </div>
        ) : (
          <div className="justify-middle  m-4 flex aspect-square flex-col items-center rounded-md p-4 text-gray-400 ">
            <div className="my-auto flex flex-col justify-center gap-2">
              <PhotoIcon className="mx-auto h-6 w-6 text-gray-400" />
              <div className="text-xs">No artwork</div>
            </div>
          </div>
        )}
        <div className="flex flex-row  border-black/10  px-4 pb-4  dark:border-white/10 ">
          <div className="mx-2 my-auto  flex flex-row py-1 text-xl font-medium text-gray-700 dark:text-gray-300">
            {/* <div className="flex-shrink-0 flex-grow-0">
              <MiniPlayer mini track={track} />
            </div> */}
            <div className="w-[12rem] truncate whitespace-normal">
              {track?.title}
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col justify-between divide-y divide-black/10 border-y border-black/10 border-zinc-600 dark:divide-white/10 dark:border-white/10 ">
          <TrackListStatus overlay row={{ original: track }} />{" "}
        </div> */}
        {/* <div className="flex w-full border-y border-gray-200 ">
          <TrackMenu overlay track={track}>
            <button className="group  flex w-full flex-row items-center justify-between py-3 px-6 ring-0 hover:bg-white focus:ring-0">
              <div>Track actions</div>
              <ChevronDownIcon className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500" />
            </button>
          </TrackMenu>
        </div> */}
      </div>
      <div className="flex h-full flex-col justify-between ">
        <div
          className={classNames(
            "flex flex-grow flex-col gap-1 px-4 pb-4 text-sm transition-all"
          )}
        >
          {tabs.map((item) => {
            if (item.title === "---") {
              return (
                <div key={item.title}>
                  <div className="w-full  border-b border-black/10 pt-2  dark:border-white/10 " />
                  <div className="pb-2" />
                </div>
              );
            } else {
              return (
                <div
                  key={item.title}
                  onClick={() => setCurrentTab(item.title)}
                  className={classNames(
                    item.title === currentTab
                      ? "text-black/80 bg-white dark:bg-black/20 dark:text-white/80  "
                      : " hover:bg-white dark:text-white/60 dark:hover:bg-black/20",
                    "group flex h-10 cursor-pointer flex-row items-center rounded-md px-2 py-2 text-sm font-medium"
                  )}
                >
                  {item.title === currentTab ? (
                    <item.iconSolid
                      className={classNames(
                        "text-black/80 dark:text-white/80",

                        "w-65 mr-4 h-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <item.icon
                      className={classNames(
                        "text-black/60 dark:text-white/60 ",
                        "mr-4 h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  )}
                  <div className="flex-grow">{item.title}</div>
                  {item.title === "Tasks" && (
                    <TrackListTrackOverlayNavbarTaskCount
                      unfinishedTasksCount={unfinishedTasksCount}
                    />
                  )}
                  {item.title === "Share statistics" && (
                    <TrackListTrackOverlayNavbarShareCount track={track} />
                  )}
                </div>
              );
            }
          })}
        </div>
        {/* <div className="border-zinc-600 border-t p-4 dark:border-backgrounddark">
          test
        </div> */}
      </div>
    </div>
  );
};

export default TrackListTrackOverlayNavbar;
