import { useContext } from "react";
import NewPlaylistOverlayContext from "../../context/NewPlaylistOverlayProvider";

const useNewPlaylistOverlay = () => {
  const { context } = useContext(NewPlaylistOverlayContext);

  return useContext(NewPlaylistOverlayContext);
};

export default useNewPlaylistOverlay;
