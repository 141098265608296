import React from "react";
import { serverUrl } from "../../hooks/Api";
import LargePlayerAnalytics from "../../LargePlayerAnalytics";
const TrackListTrackOverlayShareStats = ({ track }) => {
  return (
    <div className="">
      <LargePlayerAnalytics
        url={serverUrl + "/files/preview/" + track.previewcode}
        track={track}
        buttonColor={"black"}
        cursorColor={"black"}
        progressColor={"black"}
        // height={90}
      />
    </div>
  );
};

export default TrackListTrackOverlayShareStats;
