import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import CustomHeaderComponent from "./CustomHeaderComponent";

export default Node.create({
  name: "taskHeader",
  draggable: true,
  group: "block",

  content: "text*",

  defining: true,
  addAttributes() {
    return {
      count: {
        default: 0,
      },
      color: {
        default: "#ccc",
      },
    };
  },

  onTransaction({ transaction }) {
    // The editor state has changed.
  },

  parseHTML() {
    return [
      {
        tag: "task-header",
      },
    ];
  },

  // addKeyboardShortcuts() {
  //   return {
  //     Backspace: () =>
  //       this.editor.commands.command(({ tr, state }) => {
  //         const { selection } = state;
  //         const { empty, anchor } = selection;

  //         if (empty) {
  //           this.editor
  //             .chain()
  //             .focus()
  //             .deleteRange(range)
  //             .setNode("paragraph")
  //             .run();
  //         }
  //       }),
  //   };
  // },

  renderHTML({ HTMLAttributes }) {
    return ["task-header", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomHeaderComponent);
  },
});
