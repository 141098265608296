import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackUploadStatus() {
  const axiosPrivate = useAxiosPrivate();
  console.log("useUpdateTrackUploadStatus");
  const queryClient = useQueryClient();
  return useMutation(
    ({ trackid, projectid, uploadStatus }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          uploadStatus: uploadStatus,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("tracks");
        queryClient.invalidateQueries("tracks/" + data.projectid);
      },
    }
  );
}
