import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";
import short from "short-uuid";
import { toast } from "react-toastify";

export default function useCreateInvitation() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      email,
      name,
      workspace,
      invitecode,
      roles,
      inviteByName,
      inviteById,
      type,
      sendEmail,
    }) =>
      axiosPrivate
        .post(serverUrl + "/register/createinvitation", {
          id: short.generate(),
          email: email,
          workspace: workspace,
          inviteCode: invitecode,
          roles: roles,
          inviteByName: inviteByName,
          sendEmail: sendEmail ? sendEmail : true,
          type: type ? type : "user",
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        if (variables.sendEmail) {
          toast.success("Invitation sent!", { containerId: "main" });
        }
        queryClient.invalidateQueries(["all-users"]);
      },
    }
  );
}
