import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import StatusList from "./StatusList";
const basicStatuses = [
  {
    id: "basic-1",
    title: "In progress",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#bbbbbb",
  },

  {
    id: "basic-finished",
    title: "Finished",
    iconName: "RocketLaunchIcon",
    icon: RocketLaunchIcon,
    color: "#22c55e",
  },
];

const creativeStatuses = [
  {
    id: "creative-1",
    title: "In progress",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#bbbbbb",
  },
  {
    id: "creative-2",
    title: "Writing",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#ef4444",
  },
  {
    id: "creative-3",
    title: "Recording",
    iconName: "MicrophoneIcon",
    icon: MicrophoneIcon,
    color: "#eab308",
  },
  {
    id: "creative-4",
    title: "Mastering",
    iconName: "AdjustmentsVerticalIcon",
    icon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
  },

  {
    id: "creative-finished",
    title: "Finished",
    iconName: "RocketLaunchIcon",
    icon: RocketLaunchIcon,
    color: "#22c55e",
  },
];

const advancedStatuses = [
  {
    id: "advanced-1",
    title: "In progress",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#bbbbbb",
  },
  {
    id: "advanced-2",
    title: "Writing",
    iconName: "PencilIcon",
    icon: PencilIcon,
    color: "#ef4444",
  },
  {
    id: "advanced-3",
    title: "Finding feature",
    iconName: "MicrophoneIcon",
    icon: MicrophoneIcon,
    color: "#eab308",
  },
  {
    id: "advanced-4",
    title: "Recording vocals",
    iconName: "MicrophoneIcon",
    icon: MicrophoneIcon,
    color: "#eab308",
  },
  {
    id: "advanced-5",
    title: "Mixing",
    iconName: "AdjustmentsVerticalIcon",
    icon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
  },
  {
    id: "advanced-6",

    title: "Mastering",
    iconName: "AdjustmentsVerticalIcon",
    icon: AdjustmentsVerticalIcon,
    color: "#0ea5e9",
  },
  {
    id: "advanced-7",
    title: "Waiting for assets",
    iconName: "StarIcon",
    icon: StarIcon,
    color: "#22c55e",
  },
  {
    id: "advanced-8",
    title: "Finished",
    iconName: "RocketLaunchIcon",
    icon: RocketLaunchIcon,
    color: "#22c55e",
  },
];
const statusGroups = [
  {
    id: 1,
    title: "Basic",
    description:
      "You want a simple list of songs and see which ones are finished.",
    users: "621 users",
    initStatuses: basicStatuses,
    editable: true,
  },
  {
    id: 2,
    title: "Creative",
    description: "Your workflow consists of writing and finishing songs.",
    users: "1200 users",
    initStatuses: creativeStatuses,
    editable: true,
  },
  {
    id: 3,
    title: "Advanced",
    description: "You want to track all aspects of a song prior to release.",
    users: "2740 users",
    initStatuses: advancedStatuses,
    editable: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StatusGroups({ setStatuses }) {
  const [selectedstatusGroups, setSelectedstatusGroups] = useState(
    statusGroups[0]
  );

  useEffect(() => {
    setStatuses(selectedstatusGroups.initStatuses);
  }, [selectedstatusGroups]);

  return (
    <RadioGroup value={selectedstatusGroups} onChange={setSelectedstatusGroups}>
      {/* <RadioGroup.Label className="text-gray-900 text-base font-medium">
        Select a mailing list
      </RadioGroup.Label> */}

      <div className="mt-4 flex flex-grow-0 flex-wrap gap-6 pt-2 pb-4">
        {statusGroups.map((statusGroup) => (
          <RadioGroup.Option
            key={statusGroup.id}
            value={statusGroup}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-green-500 ring-green-500 ring-2" : "",
                "bg-gray-50 relative flex h-fit w-[19rem] cursor-pointer rounded-lg border shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="text-gray-800 border-gray-200 block border-b px-4 py-4 text-2xl  font-medium"
                    >
                      {statusGroup.title}
                      <RadioGroup.Description
                        as="span"
                        className="text-gray-600 border-gray-200 flex items-center pt-1 text-sm font-normal leading-relaxed"
                      >
                        {statusGroup.description}
                      </RadioGroup.Description>
                    </RadioGroup.Label>

                    <StatusList
                      editable={checked}
                      initStatuses={statusGroup.initStatuses}
                    />
                    {/* <RadioGroup.Description
                      as="span"
                      className="text-gray-900 mt-6 text-sm font-medium"
                    >
                      {statusGroup.users}
                    </RadioGroup.Description> */}
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "right text-green-500 absolute top-6 right-5 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-green-500" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
