import React from "react";

import { classNames } from "./shared/Utils";

import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",

  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUpAndFade },
  '&[data-side="bottom"]': { animationName: slideDownAndFade },
  '&[data-side="left"]': { animationName: slideLeftAndFade },
  '&[data-side="right"]': { animationName: slideRightAndFade },
});

const Prioritypicker = ({
  setExternalOpen,
  selectedPriority,
  setSelectedPriority,
}) => {
  const [open, setOpen] = React.useState(false);

  const priorities = [
    { name: "Low", color: "bg-gray-500" },
    { name: "Medium", color: "bg-yellow-500" },
    { name: "Urgent", color: "bg-red-500" },
  ];

  return (
    <div className="group relative my-auto mx-auto mt-1.5 flex h-7 ">
      <PopoverPrimitive.Root
        onOpenChange={(open) => {
          setOpen(open);
          setExternalOpen(open);
        }}
        open={open}
      >
        <PopoverPrimitive.Trigger>
          <div className="group h-7">
            <div
              className={classNames(
                open
                  ? "text-gray-500 hover:text-gray-600"
                  : "text-gray-400 hover:text-gray-500",
                "  flex h-7 w-7 rounded-full p-1.5 ring-2 ring-white  ",
                "bg-gray-100 hover:bg-gray-200"
              )}
              onClick={() => setOpen(open)}
            >
              {selectedPriority === "Low" && <RiFlag2Line />}
              {selectedPriority === "Medium" && (
                <RiFlag2Fill className="fill-yellow-500" />
              )}
              {selectedPriority === "Urgent" && (
                <RiFlag2Fill className="fill-red-500" />
              )}
            </div>
          </div>
        </PopoverPrimitive.Trigger>
        <StyledContent>
          <div
            className={classNames(
              "  z-50 flex  cursor-pointer flex-col divide-y  overflow-hidden rounded-md   border  shadow-lg border-gray-300   divide-gray-200 "
            )}
          >
            {priorities.map((priority) => (
              <div
                key={priority.name}
                className={classNames(
                  selectedPriority === priority.name
                    ? "bg-gray-100 hover:bg-gray-200"
                    : "bg-white hover:bg-gray-50",
                  " flex flex-row py-2 px-4 "
                )}
                onClick={() => {
                  if (selectedPriority === priority.name) {
                    setSelectedPriority("Low");
                  } else {
                    setSelectedPriority(priority.name);
                  }
                  setOpen(false);
                }}
              >
                <div
                  className={classNames(
                    priority.color,
                    "my-auto  flex h-7 w-7 rounded-full p-1.5"
                  )}
                >
                  {priority.name === "Low" ? (
                    <RiFlag2Line className="mx-auto my-auto fill-white" />
                  ) : (
                    <RiFlag2Fill className="mx-auto my-auto fill-white" />
                  )}
                </div>
                <div
                  className={classNames(
                    selectedPriority === priority.name ? "" : "",
                    "my-auto select-none px-2 text-xs font-medium text-gray-700"
                  )}
                >
                  {priority.name}
                </div>
              </div>
            ))}
          </div>
          <PopoverPrimitive.Arrow className=" fill-gray-300 dark:fill-zinc-600 " />
        </StyledContent>
      </PopoverPrimitive.Root>
    </div>
  );
};

export default Prioritypicker;
