import { Fragment, useEffect, useState } from "react";
import { format, compareAsc } from "date-fns";
import { Dialog, Transition } from "@headlessui/react";

import { forwardRef, useImperativeHandle } from "react";

import "react-tabs/style/react-tabs.css";

import "./TrackListTrackOverlay.css";

import useSettings from "../../hooks/useSettings";
import TrackListTrackOverlayInfo from "./TrackListTrackOverlayInfo";

import WorkingOnThis from "../../WorkingOnThis";

import TrackListTrackOverlaySideBar from "./TrackListTrackOverlaySideBar";
import { classNames } from "../../shared/Utils";
import TrackListTrackOverlayVersions from "./TrackListTrackOverlayVersions";
import TrackListTrackOverlayTasks from "./TrackListTrackOverlayTasks";
import useTrack from "../../hooks/useTrack";
import TrackListTrackOverlayNavbar from "./TrackListTrackOverlayNavbar";
import { useNavigate, useParams } from "react-router-dom";
import TrackListTrackOverlaySplits from "./TrackListTrackOverlaySplits";
import TrackListTrackOverlayShare from "./TrackListTrackOverlayShare";
import TrackListTrackOverlayShareStats from "./TrackListTrackOverlayShareStats";

import TracklistTrackOverlayBreadcrumb from "./TracklistTrackOverlayBreadcrumb";
import { axiosPrivate, serverUrl } from "../../hooks/Api";
import { useQueryClient } from "@tanstack/react-query";

const TrackListTrackOverlay = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const [trackId, setTrackId] = useState(null);
  const [track, setTrack] = useState({});

  const trackQuery = useTrack(trackId);
  const queryClient = useQueryClient();
  console.log("trackQuery", trackQuery.data?.length);

  useEffect(() => {
    if (trackQuery.isSuccess) {
      setTrack(trackQuery.data[0]);
    }
  }, [trackQuery.isSuccess]);

  useEffect(() => {
    trackQuery.refetch();
  }, [trackId]);

  const { workspaceSettings } = useSettings();
  const availableStatuses = workspaceSettings?.statuses;

  const [currentTab, setCurrentTab] = useState("Tasks");

  useImperativeHandle(ref, () => ({
    openDialog(trackid) {
      console.log("Opening track overlay for ", trackid);
      setTrackId(trackid);
      queryClient.removeQueries(["track", trackid]);
      handleOpen();
    },
  }));

  useEffect(() => {
    return () => {
      console.log("closing track overlay");
      setTrack({});
      setCurrentTab("Tasks");
      setOpen(false);
    };
  }, []);

  const projectParams = useParams();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setTrack({});
    setCurrentTab("Tasks");
    if (projectParams.trackid) {
      navigate(
        "/projects/" + projectParams.projectid + "/" + projectParams.projectview
      );
    }
    setCurrentTab("Tasks");
  };

  // useEffect(() => {
  //   if (open) {
  //     console.log("refetching track");
  //     setCurrentTab("Tasks");
  //     // trackQuery.refetch();
  //   }
  // }, [open]);

  const [unfinishedTasksCount, setUnfinishedTasksCount] = useState(0);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        onClose={(e) => {
          handleClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-80 transition-opacity bg-zinc-600 dark:bg-black/70" />
        </Transition.Child>

        <div className="fixed inset-0 z-10  ">
          <div className="flex h-screen items-end justify-center  px-6 text-center sm:block">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              id="track-overlay"
            >
              <Dialog.Panel
                // tabIndex={0}
                // onKeyDown={(e) => {
                //   console.log(e);
                // }}
                className={classNames(
                  "max-w-[90rem] sm:my-8 sm:h-[calc(100vh-60px)] sm:w-[calc(100vw-60px)] ",
                  " relative inline-block w-full  transform overflow-auto rounded-md  text-left align-middle     shadow-2xl transition-all bg-white  border-gray-300 dark:border  dark:drop-shadow-2xl"
                )}
              >
                <div className="flex h-full flex-col ">
                  <TracklistTrackOverlayBreadcrumb
                    track={track}
                    setOpen={setOpen}
                  />
                  <div className="  h-[calc(100%-46px)] w-full ">
                    <div className=" flex h-full w-full flex-row overflow-auto">
                      <div className="sticky top-0 z-30 h-full w-[17rem] flex-shrink-0 flex-grow-0  self-start border-r bg-gray-100 border-gray-200 dark:bg-zinc-950">
                        {/* NAVBAR START */}
                        <TrackListTrackOverlayNavbar
                          track={track}
                          currentTab={currentTab}
                          setCurrentTab={setCurrentTab}
                          availableStatuses={availableStatuses}
                          unfinishedTasksCount={unfinishedTasksCount}
                        />
                        {/* NAVBAR END */}
                      </div>
                      {track?.id ? (
                        <div className="relative w-[calc(100%-17rem)] bg-white">
                          {currentTab === "Tasks" && (
                            <TrackListTrackOverlayTasks
                              track={track}
                              setUnfinishedTasksCount={setUnfinishedTasksCount}
                            />
                          )}
                          {currentTab === "Track info" && (
                            <TrackListTrackOverlayInfo track={track} />
                          )}
                          {currentTab === "Versions" && (
                            <TrackListTrackOverlayVersions track={track} />
                          )}
                          {currentTab === "Assets" && (
                            <WorkingOnThis track={track} />
                          )}
                          {currentTab === "Splits" && (
                            <TrackListTrackOverlaySplits track={track} />
                          )}
                          {currentTab === "Share" && (
                            <TrackListTrackOverlayShare track={track} />
                          )}
                          {currentTab === "Share statistics" && (
                            <TrackListTrackOverlayShareStats track={track} />
                          )}
                          {currentTab === "Activity" && (
                            <TrackListTrackOverlaySideBar track={track} />
                          )}
                        </div>
                      ) : (
                        <div className=" mx-auto flex w-full animate-pulse flex-col">
                          <div className="flex h-[3.7rem] flex-row justify-between border-b px-4 py-2 bg-gray-50 border-gray-200">
                            <div className="my-auto h-4 w-24 rounded-md bg-gray-300 " />
                            <div className="my-auto h-4 w-32 rounded-md bg-gray-300 " />
                          </div>

                          <div className="flex h-full w-full animate-pulse flex-row items-center justify-start space-x-5 pl-6">
                            <div className="flex h-full w-full flex-col space-y-4  py-6">
                              <div className="h-4 w-[40%] rounded-md bg-gray-300 "></div>
                              <div className="h-4 w-[10%] rounded-md bg-gray-300 "></div>
                              <div className="h-4 w-[60%] rounded-md bg-gray-300 "></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default TrackListTrackOverlay;
