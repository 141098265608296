import { useState } from "react";
import { Cog6ToothIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import useSettings from "../hooks/useSettings";

import ToolTip from "../ToolTip";
import NotificationListTabsUser from "./NotificationListTabsUser";

const tabs = [
  { name: "User", href: "#", count: "5", current: true },
  { name: "Mentions", href: "#", count: "3", current: false },

  { name: "All", href: "#", count: "4", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NotificationsListTabs({ setOpen, setFilter, filter }) {
  const { setNotificationsBarFixed, notificationsBarFixed } = useSettings();

  return (
    <div>
      <div className=" flex flex-row justify-between border-b pl-6 pr-2 border-gray-200  ">
        <div className=" ">
          <nav className="-mb-px flex space-x-2" aria-label="Tabs">
            {tabs.map((tab) => (
              <>
                {tab.name === "User" ? (
                  <NotificationListTabsUser
                    setFilter={setFilter}
                    filter={filter}
                    tab={tab}
                  />
                ) : (
                  <a
                    key={tab.name}
                    href="#"
                    className={classNames(
                      tab.current
                        ? "text-gray-600 border-gray-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "flex whitespace-nowrap border-b-2 px-2 pb-3 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                    {tab.count ? (
                      <span
                        className={classNames(
                          tab.current
                            ? "text-zinc-100 bg-zinc-600"
                            : "text-zinc-900 bg-zinc-200",
                          " ml-2  mt-0.5 flex h-5 rounded-md px-2 text-[0.8rem] "
                        )}
                      >
                        <div className="my-auto">{tab.count}</div>
                      </span>
                    ) : null}
                  </a>
                )}
              </>
            ))}
          </nav>
        </div>
        <div
          onClick={() => {
            if (notificationsBarFixed) {
              setOpen(false);
            } else {
              setNotificationsBarFixed(true);
            }
          }}
          className="-mt-1 mr-1"
        >
          <ToolTip
            message={!notificationsBarFixed ? "Pin overlay" : "Close overlay"}
            alignOffset="10"
            sideOffset={4}
          >
            <div
              className={classNames(
                notificationsBarFixed ? "rotate-180" : "",
                "my-auto flex   cursor-pointer flex-col rounded-full p-[3px] transition-all  hover:bg-background dark:border dark:border-zinc-600 dark:hover:bg-zinc-950 "
              )}
            >
              <div className=" ">
                <ChevronRightIcon className="h-5  w-5 text-gray-600" />
              </div>
            </div>
          </ToolTip>
        </div>
      </div>
    </div>
  );
}
