import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import useSettings from "../hooks/useSettings";
import MiniPlayer from "../MiniPlayer";

import useAllTracks from "../hooks/useAllTracks";

import NotificationListWaveForm from "./NotificationListWaveForm";

const NotificationsListItem = ({ item, seen }) => {
  const allTracksQuery = useAllTracks();
  if (item.action === "upload") {
    console.log("uploaditem", item);
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center   text-sm text-gray-800">
          <div className="w-12">
            <MiniPlayer track={item.track} />
          </div>
          <div className="flex-grow font-medium">{item.track?.title}</div>
        </div>
        <div className=" mb-1 w-fit rounded-md p-1 px-3 font-mono text-[0.74rem] text-gray-500 bg-gray-50">
          {item.data.url?.substring(
            item.data.url?.lastIndexOf(":original") + 10
          )}
        </div>
      </div>
    );
  }
  if (item.action === "status") {
    const { workspaceSettings } = useSettings();
    const availableStatuses = workspaceSettings?.statuses;
    return (
      <div className="-ml-1.5 flex flex-col gap-1 py-1 px-3">
        <div className="text-sm font-medium text-gray-800">
          {item.track?.title}
        </div>
        <div className="my-1 flex  text-center text-sm  text-gray-800">
          <div
            style={{
              color: availableStatuses?.find(
                (element) => element.title === item.data.oldStatus
              )
                ? availableStatuses?.find(
                    (element) => element.title === item.data.oldStatus
                  ).color + "aa"
                : "#ccccccaa",

              borderColor: availableStatuses?.find(
                (element) => element.title === item.data.oldStatus
              )
                ? availableStatuses?.find(
                    (element) => element.title === item.data.oldStatus
                  ).color + "aa"
                : "#ccccccaa",
            }}
            className="y-auto flex rounded-sm border py-0.5  px-2  text-xs font-medium text-gray-800"
          >
            <div className="my-auto">{item.data.oldStatus}</div>
          </div>
          <ChevronRightIcon className="my-auto mx-1.5 h-4 w-4 text-gray-800" />
          {item.data.between && (
            <>
              <div
                style={{
                  // failsafe for when the status is deleted
                  color: availableStatuses?.find(
                    (element) => element.title === item.data.between
                  )
                    ? availableStatuses?.find(
                        (element) => element.title === item.data.between
                      ).color + "77"
                    : "#cccccc",

                  borderColor: availableStatuses?.find(
                    (element) => element.title === item.data.between
                  )
                    ? availableStatuses?.find(
                        (element) => element.title === item.data.between
                      ).color + "77"
                    : "#cccccc",
                }}
                className="y-auto flex rounded-sm  border py-0.5  px-2  text-xs font-medium text-gray-800"
              >
                <div className="my-auto">{item.data.between}</div>
              </div>
              <ChevronRightIcon className="my-auto mx-1.5 h-4 w-4 text-gray-800" />
            </>
          )}
          <div
            style={{
              backgroundColor: availableStatuses?.find(
                (element) => element.title === item.data.status
              )
                ? availableStatuses?.find(
                    (element) => element.title === item.data.status
                  ).color
                : "#cccccc",
            }}
            className="y-auto flex rounded-sm py-0.5 px-2  text-xs font-medium text-white"
          >
            <div className="my-auto">{item.data.status}</div>
          </div>
        </div>
      </div>
    );
  }
  if (item.action === "created") {
    return (
      <div className="-ml-1.5 flex flex-col gap-1 py-1 px-3">
        <div className="text-sm font-medium text-gray-800">
          {item.track?.title}
        </div>
      </div>
    );
  }
  if (item.action === "externalPlay") {
    console.log("externalPlay", item);
    return (
      <div className="-ml-1.5 flex flex-col gap-1 py-1 px-3">
        <div className="py-1 text-sm font-medium text-gray-800">
          {item.track?.title}
        </div>
        <div>
          <NotificationListWaveForm
            waveformUrl={item.track.waveformurl}
            data={item.data}
          />
        </div>

        <div className="hidden py-1 text-xs text-gray-600 group-hover:block ">
          listened for {Math.round(item.data.playDuration)} seconds
        </div>
      </div>
    );
  }

  if (item.action === "externalPlaylistPlay") {
    console.log("externalPlaylistPlay", item);
    return (
      <div className="-ml-1.5 flex flex-col gap-1 py-1 px-3">
        {item.data.playlistTitle ? item.data.playlistTitle : "unnamed playlist"}

        <div className="-ml-1.5 flex flex-col gap-1 py-1 px-3">
          {item.data.plays?.map((play) => (
            <>
              <div className="text-sm font-medium text-gray-800">
                {allTracksQuery.isSuccess
                  ? allTracksQuery.data.find(
                      (track) => track.id === play.trackId
                    ).title
                  : "loading"}
              </div>
              <div>
                <NotificationListWaveForm
                  waveformUrl={
                    "https://api.trackflow.studio/files/waveforms/" +
                    play.trackId
                  }
                  data={play}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    );
  }
};

export default NotificationsListItem;
