/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon } from "@heroicons/react/24/solid";

export default function ErrorMessage({ title, message }) {
  return (
    <div className=" p-6 bg-red-50">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title && title}</h3>
          <div className="mt-2 text-sm text-red-700">
            <div role="list" className="list-disc space-y-1">
              <p>{message && message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
