import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { formatDistance } from "date-fns";
import React, { useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "./TrackListDatePicker.css";

import useUpdateTrackDeadline from "../hooks/useUpdateTrackDeadline";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { classNames } from "../shared/Utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { statusOptionColors } from "../shared/Utils";
import TrackListDatePickerStatusSelector from "./TrackListDatePickerStatusSelector";

const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: 5,
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px solid #eaeaea",
  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
});

const TrackListDatePicker = React.memo(
  ({ deadline, row, showText, overlay }) => {
    const [selectedDate, setSelectedDate] = React.useState(
      deadline != "" && deadline != "/" && new Date(deadline)
    );

    const updateTrackDeadlineQuery = useUpdateTrackDeadline();

    const setDeadline = (date) => {
      setOpen(false);
      openChange(false);
      updateTrackDeadlineQuery.mutate({
        projectid: row.original.project,
        trackid: row.original.id,
        deadline: date,
      });

      setSelectedDate(date);
    };

    let daysTillDeadlineStyle = {};
    let pickerDate = "";
    let cellText = "";

    if (
      selectedDate != "/" &&
      selectedDate != "" &&
      selectedDate != null &&
      selectedDate != undefined
    ) {
      const diffInMs = new Date(selectedDate) - new Date();
      let daysTillDeadline = Math.ceil(diffInMs / (1000 * 3600 * 24));
      // style conditional to daysTillDeadline

      if (daysTillDeadline == 0) {
        cellText = "today";
      } else if (daysTillDeadline == -1) {
        cellText = "yesterday";
      } else if (daysTillDeadline == 1) {
        cellText = "tomorrow";
      } else if (daysTillDeadline > 0) {
        cellText = "in " + daysTillDeadline + " days";
      } else if (daysTillDeadline < 0) {
        cellText = Math.abs(daysTillDeadline) + " days ago";
      }

      if (daysTillDeadline < 0) {
        daysTillDeadlineStyle = {
          borderColor: "gray",
          // color: "black",
          textDecoration: "line-through",
          // textDecorationColor: "gray",
        };
      } else if (daysTillDeadline < 3) {
        daysTillDeadlineStyle = {
          // red-400
          borderColor: "#f87171",
          fontWeight: "bold",
          color: "#f87171",
        };
      } else if (daysTillDeadline < 7) {
        daysTillDeadlineStyle = {
          // orange-400
          borderColor: "#fb923c",
          fontWeight: "bold",
          color: "#fb923c",
        };
      } else {
        daysTillDeadlineStyle = {
          //emerald-400
          borderColor: "#34d399",
          fontWeight: "bold",
          color: "#34d399",
        };
      }
    } else {
      cellText = "Pick a date";
      daysTillDeadlineStyle = {
        // border: "1px solid #eaeaea",
        //color: "#eaeaea",
      };
    }

    function openChange(open) {
      setOpen(open);
      console.log("openchange datepicker", open);
      if (open) {
        document
          .getElementById("row-" + row.original.id)
          .classList.add("selected-row");
        document
          .getElementById("deadline-" + row.original.id)
          .classList.add("bg-gray-100");
        const pick = document.getElementById(
          "deadline-pick-" + row.original.id
        );
        if (pick) pick.style.color = "rgb(71,85,105)";
      } else {
        document
          .getElementById("row-" + row.original.id)
          .classList.remove("selected-row");
        document
          .getElementById("deadline-" + row.original.id)
          .classList.remove("bg-gray-100");
        const pick = document.getElementById(
          "deadline-pick-" + row.original.id
        );
        if (pick) pick.style.color = "";
      }
    }

    const quickOptions = [
      {
        label: "Today",
        value: new Date(),
        onClick: () => setDeadline(new Date()),
      },
      {
        label: "Tomorrow",
        value: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        onClick: () =>
          setDeadline(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
      },
      {
        label: "Next week",
        value: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        onClick: () =>
          setDeadline(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)),
      },
      {
        label: "Next Month",
        value: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
        onClick: () =>
          setDeadline(
            new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          ),
      },
    ];

    const [open, setOpen] = React.useState(false);

    return (
      <>
        <PopoverPrimitive.Root
          open={open}
          onOpenChange={(open) => openChange(open)}
        >
          <div
            id={"deadline-" + row.original.id}
            className={classNames(
              overlay ? "py-3 hover:bg-zinc-700 dark:hover:bg-zinc-800" : "",
              "h-full  cursor-pointer hover:bg-gray-100"
            )}
          >
            {selectedDate != "" &&
            selectedDate != null &&
            selectedDate != undefined ? (
              <PopoverPrimitive.Trigger className=" flex h-full">
                <div className="relative mx-auto flex   h-full  w-28 items-start   md:w-32">
                  <div
                    className="mx-auto my-auto flex truncate whitespace-nowrap rounded-md px-2 text-xs  text-gray-800  "
                    style={daysTillDeadlineStyle}
                  >
                    {overlay ? (
                      cellText ? (
                        cellText
                      ) : (
                        <div className="text-left font-normal text-zinc-500 dark:text-zinc-500">
                          no deadline
                        </div>
                      )
                    ) : (
                      cellText
                    )}
                  </div>
                </div>
              </PopoverPrimitive.Trigger>
            ) : (
              <PopoverPrimitive.Trigger
                id={"deadline-pick-" + row.original.id}
                className={classNames(
                  showText ? "text-gray-400 " : "text-transparent",
                  "flex h-full  hover:text-gray-400"
                )}
              >
                <div className="relative mx-auto  my-auto flex  h-full  w-28 items-center  hover:bg-gray-100 md:w-32">
                  <div
                    className="mx-auto flex whitespace-nowrap rounded-md px-2 py-1 text-xs "
                    style={daysTillDeadlineStyle}
                  >
                    Pick a date
                  </div>
                </div>
              </PopoverPrimitive.Trigger>
            )}

            <StyledContent className="rounded-md border bg-white border-white text-gray-600 dark:bg-zinc-950  dark:text-zinc-200  dark:border-zinc-600 ">
              <div className="flex flex-row  divide-x divide-gray-300 dark:divide-zinc-700">
                <div className="flex w-64 flex-col justify-between p-4 ">
                  {/* <div>
                    Deadline for:
                    <TrackListDatePickerStatusSelector />
                  </div> */}
                  <div>
                    {quickOptions.map((option) => (
                      <div
                        key={option.label}
                        onClick={() => option.onClick()}
                        className={classNames(
                          deadline == option.value ? "text-bold" : "",

                          "flex cursor-pointer flex-row items-center justify-between p-2 hover:underline"
                        )}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="grid w-[22rem]  text-sm">
                    <DayPicker
                      mode="single"
                      selected={selectedDate}
                      onSelect={setDeadline}
                    />
                  </div>
                  <ul
                    className="col-span-2  border-t  px-5 py-1 bg-white border-gray-200  hover:bg-gray-100"
                    onClick={() => setDeadline("/")}
                  >
                    <li className="my-2  cursor-pointer rounded-md py-2  text-gray-500 hover:text-gray-600">
                      <div className=" flex w-full ">
                        <XMarkIcon className=" inline-flex h-5 w-5" />
                        <div className=" ml-2 font-medium uppercase">
                          No deadline
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <PopoverPrimitive.Arrow
                width={11}
                height={5}
                offset={58}
                className="fill-white dark:fill-zinc-600 "
              />
            </StyledContent>
          </div>
        </PopoverPrimitive.Root>
      </>
    );
  }
);

export default TrackListDatePicker;
