import React, { useEffect, useRef, useState } from "react";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react";

import MiddleTruncate from "react-middle-truncate/lib/react-middle-truncate";

import axios from "axios";

import { styled } from "@stitches/react";

import * as SliderPrimitive from "@radix-ui/react-slider";

import WaveSurfer from "wavesurfer.js";
import CursorPlugin from "./Wavesurfer/CursorPlugin";

// import CursorPlugin from "wavesurfer.js/src/plugin/cursor/index.js";

import "react-circular-progressbar/dist/styles.css";
import useSettings from "./hooks/useSettings";
import useAddPlay from "./hooks/useAddPlay";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { classNames } from "./shared/Utils";

import WaveFormPlaceholderAnimation from "./WaveFormPlaceholderAnimation";
import { serverUrl } from "./hooks/Api";
import useAuth from "./hooks/useAuth";
import MyMemo from "./MyMemo";

const StyledSlider = styled(SliderPrimitive.Root, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  width: 100,

  '&[data-orientation="horizontal"]': {
    height: 20,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: "column",
    width: 20,
    height: 100,
  },
});

const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: "#e5e7eb",
  position: "relative",
  flexGrow: 1,
  borderRadius: "9999px",

  '&[data-orientation="horizontal"]': { height: 3 },
  '&[data-orientation="vertical"]': { width: 3 },
});

const StyledRange = styled(SliderPrimitive.Range, {
  position: "absolute",
  backgroundColor: "#d1d5db",
  borderRadius: "9999px",
  height: "100%",
});

const StyledThumb = styled(SliderPrimitive.Thumb, {
  all: "unset",
  display: "block",
  width: 16,
  height: 16,
  backgroundColor: "#d1d5db",
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  border: "solid 2px #ffffff ",
  borderRadius: 20,

  "&:hover": { backgroundColor: "#c2c5cc" },
  // "&:focus": { boxShadow: `0 0 0 5px ${blackA.blackA8}` },
});

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function BottomPlayer() {
  const navigate = useNavigate();
  const {
    bottomPlayerOpen,
    bottomPlayerUrl,
    bottomPlayerTrack,
    bottomPlayerSetIsPlaying,
    bottomPlayerIsPlaying,
  } = useSettings();

  console.log("bottomPlayerTrack", bottomPlayerTrack);

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const [volume, setVolume] = useState(1);

  const [loadingPercentage, setLoadingPercentage] = useState(0);

  let time;
  const formWaveSurferOptions = (ref) => ({
    container: ref,

    waveColor: "#9ca3af",
    progressColor: "#22c55e",
    // cursorColor: "rgba(80, 100, 120, 1)",
    cursorColor: "rgba(255, 255, 255, 1)",
    // waveColor: "#ccc",
    // progressColor: "#eee",
    // cursorColor: "#ddd",
    barWidth: 1,
    barRadius: 3,
    barGap: 2,
    responsive: true,
    height: 30,
    hideScrollbar: true,
    hideCursor: false,

    cursorWidth: 2,
    // cursorColor: "#22c55e",

    fillParent: true,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    backend: "MediaElement",

    mediaType: "audio",
    plugins: [
      CursorPlugin.create({
        deferInit: true,
        showTime: true,
        opacity: 0.7,
        style: {
          width: "4px",
        },
        customShowTimeStyle: {
          "background-color": "#1e293b",
          color: "#e2e8f0",
          padding: "4px",
          marginLeft: "4px",
          // marginRight: "8px",
          borderRadius: "4px",
          "font-size": "10px",
        },
      }),
    ],
  });

  const { auth } = useAuth();

  const addPlayQuery = useAddPlay();
  const currentUser = auth?.userid;

  console.log("currentUser", currentUser);
  console.log("bottomPlayerTrack", bottomPlayerTrack);

  const userEmail = auth?.email;

  console.log("Localemail", userEmail);

  useEffect(() => {
    if (
      bottomPlayerTrack?.id &&
      !bottomPlayerTrack.playedBy?.includes(userEmail)
    ) {
      addPlayQuery.mutate({
        trackid: bottomPlayerTrack.id,
        projectid: bottomPlayerTrack.project,
        email: auth?.email,
      });
    } else {
      console.log("already played");
    }
    const options = formWaveSurferOptions(waveformRef.current);

    wavesurfer.current = WaveSurfer.create(options);

    if (bottomPlayerUrl) {
      axios
        .get(serverUrl + "/files/waveforms/" + bottomPlayerTrack.id)
        .then((res) => {
          const peaks = res.data;

          const normalizedPeaks = peaks.filter((peak, index) => {
            return index % 10 === 0;
          });
          wavesurfer.current.load(bottomPlayerUrl, normalizedPeaks);
        });

      wavesurfer.current.song = bottomPlayerUrl;

      console.log("LOADING SONG");
    }
    wavesurfer.current.on("loading", (data) => {
      setLoadingPercentage(data);
    });

    wavesurfer.current.on("finish", () => {
      bottomPlayerSetIsPlaying(false);
    });

    wavesurfer.current.on("ready", function () {
      setLoadingPercentage(100);
      wavesurfer.current.initPlugin("cursor");

      wavesurfer.current.play();
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    // wavesurfer.current.on("play", function () {

    // });

    wavesurfer.current.on("audioprocess", function () {
      if (wavesurfer.current.isPlaying()) {
        var totalTime = wavesurfer.current.getDuration(),
          currentTime = wavesurfer.current.getCurrentTime(),
          remainingTime = totalTime - currentTime;

        document.getElementById("time-total").innerText =
          fancyTimeFormat(totalTime);
        document.getElementById("time-current").innerText =
          fancyTimeFormat(currentTime);
      }
    });
    // if (bottomPlayerIsPlaying && wavesurfer.current.isPlaying()) {
    //   // End of song
    // } else {
    //   bottomPlayerIsPlaying(false);
    // }

    return () => wavesurfer.current.destroy();
  }, [bottomPlayerUrl]);

  wavesurfer.current?.on("play", function () {
    bottomPlayerSetIsPlaying(true);
  });

  wavesurfer.current?.on("pause", function () {
    bottomPlayerSetIsPlaying(false);
  });

  const handleVolumeChange = (e) => {
    if (e == 0) {
      wavesurfer.current.setVolume(0);
      setVolume(0);
    } else {
      wavesurfer.current.setVolume(e / 100 || 1);
      setVolume(e / 100 || 1);
    }
  };

  const handlePlayPause = () => {
    // bottomPlayerSetIsPlaying(!bottomPlayerIsPlaying);
    wavesurfer.current.playPause();
  };

  const handlePause = () => {
    // bottomPlayerSetIsPlaying(false);
    wavesurfer.current.pause();
  };

  const handlePlay = () => {
    // bottomPlayerSetIsPlaying(true);
    wavesurfer.current.play();
  };

  useEffect(() => {
    if (bottomPlayerIsPlaying) {
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  }, [bottomPlayerIsPlaying]);

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <div
      style={{
        boxShadow: "0 -5px 20px -5px rgba(200,200,200,0.3)",
      }}
      className="fixed left-0 bottom-0 z-50 w-full  select-none border-t bg-white  border-gray-300 md:-left-16  md:ml-16 md:pl-16"
    >
      <div
        style={bottomPlayerOpen ? { height: "70px" } : { height: "0px" }}
        className="mx-auto flex max-w-7xl  align-middle transition-all  "
      >
        <div className="my-auto mr-[4.4rem] flex w-full max-w-7xl px-3 md:mx-6 md:mr-20 md:px-[30px]">
          {bottomPlayerTrack && (
            <div className=" flex ">
              {" "}
              <div className=" relative  mr-4 flex justify-between ">
                <CircularProgressbar
                  value={loadingPercentage}
                  className={classNames(
                    loadingPercentage == 100
                      ? "opacity-0"
                      : "opacity-100 dark:opacity-50",
                    "absolute top-1 h-10 w-10 transition-all"
                  )}
                  strokeWidth={16}
                  // text={`${loadingPercentage}%`}
                  styles={buildStyles({
                    rotation: 0,

                    strokeLinecap: "butt",

                    textSize: "16px",

                    pathTransitionDuration: 0,

                    pathColor: "#d1d5db",
                    textColor: "#d1d5db",
                    trailColor: `rgba(0, 0, 0, 0)`,
                    backgroundColor: "#3e98c7",
                  })}
                >
                  %
                </CircularProgressbar>
                <div className=" controls  z-50 my-auto  w-10 flex-none">
                  {!bottomPlayerIsPlaying ? (
                    <Icon
                      className={classNames(
                        loadingPercentage == 100 ? "opacity-100" : "",
                        "opacity-0",
                        " h-12 w-12 cursor-pointer p-1 transition-all text-gray-300 hover:text-gray-400"
                      )}
                      onClick={handlePlayPause}
                      aria-hidden="true"
                      icon="heroicons-solid:play"
                    />
                  ) : (
                    <Icon
                      className={classNames(
                        loadingPercentage == 100 ? "opacity-100" : "",
                        "opacity-0",
                        "h-12 w-12 cursor-pointer p-1 transition-all text-gray-300 hover:text-gray-400"
                      )}
                      onClick={handlePlayPause}
                      aria-hidden="true"
                      icon="heroicons-solid:pause"
                    />
                  )}
                </div>
              </div>
              {/* <div className=" my-auto ml-2 mr-4 h-10 w-10 flex-none rounded-md border fill-slate-400 p-1 bg-gray-50 border-gray-300">
                <svg
                  version="1.1"
                  id="Icons"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                  // style={{ enableBackground: "new 0 0 32 32" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M4,13c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C5,13.4,4.6,13,4,13z" />
                    <path d="M8,11c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C9,11.4,8.6,11,8,11z" />
                    <path d="M12,6c-0.6,0-1,0.4-1,1v18c0,0.6,0.4,1,1,1s1-0.4,1-1V7C13,6.4,12.6,6,12,6z" />
                    <path d="M16,13c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C17,13.4,16.6,13,16,13z" />
                    <path d="M20,9c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1s1-0.4,1-1V10C21,9.4,20.6,9,20,9z" />
                    <path d="M24,6c-0.6,0-1,0.4-1,1v18c0,0.6,0.4,1,1,1s1-0.4,1-1V7C25,6.4,24.6,6,24,6z" />
                    <path d="M28,13c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C29,13.4,28.6,13,28,13z" />
                  </g>
                </svg>
              </div> */}
              <div className="my-auto hidden  w-full flex-grow flex-col  pr-6  text-xs text-gray-600 md:flex">
                <div
                  className="  max-w-[20rem] cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap font-medium hover:underline "
                  onClick={() =>
                    navigate(
                      "/projects/" +
                        bottomPlayerTrack.project +
                        "/list/track/" +
                        bottomPlayerTrack.id
                    )
                  }
                >
                  {bottomPlayerTrack.title}
                </div>
                <div className=" w-full  max-w-[20rem] overflow-hidden overflow-ellipsis  whitespace-nowrap text-tiny">
                  {bottomPlayerTrack.artists
                    ?.map((artist) => artist.name)
                    .join(", ")}
                </div>
              </div>
            </div>
          )}
          <div
            id="time-current"
            className="my-auto w-12 flex-none text-xs font-medium text-gray-500 "
          >
            0:00
          </div>
          <div className="relative my-auto h-[30px] max-h-[30px] w-full">
            <div
              id="waveform"
              className="z-0  h-[30px]  max-h-[30px] w-full cursor-pointer  overflow-hidden opacity-100"
              onClick={handlePlay}
              ref={waveformRef}
            />{" "}
            <div
              className={classNames(
                loadingPercentage != 100 ||
                  (loadingPercentage === 0 && !isPlaying)
                  ? "opacity-100"
                  : "hidden opacity-0",
                " absolute bottom-0 left-0  h-full w-full overflow-hidden"
              )}
            >
              <WaveFormPlaceholderAnimation height={30} width={800} />
            </div>
          </div>{" "}
          <div className="flex w-16   md:w-36">
            <div
              id="time-total"
              className=" my-auto px-4 text-xs font-medium  text-gray-500"
            >
              0:00
            </div>
          </div>
          <div className="my-auto hidden md:flex">
            <StyledSlider
              defaultValue={[100]}
              max={100}
              step={1}
              aria-label="Volume"
              onValueChange={handleVolumeChange}
            >
              <StyledTrack>
                <StyledRange />
              </StyledTrack>
              <StyledThumb />
            </StyledSlider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyMemo(BottomPlayer, ["bottomPlayerUrl", "bottomPlayerOpen"]);
