import React from "react";
import ScaleText from "react-scale-text";
import { Fade } from "react-awesome-reveal";

const ListeningRoomCardSquare = ({ track, isMobile }) => {
  return (
    <div className="card-fade-in flex w-full flex-col justify-between">
      <div className="min-h-[2.2rem]  w-[8rem] leading-[1.3] md:min-h-[2.8rem]   md:w-[12rem]">
        <ScaleText
          minFontSize={isMobile ? 16 : 18}
          maxFontSize={isMobile ? 24 : 32}
        >
          {track.title}
        </ScaleText>
      </div>
      <div className="opacity-70">
        {track.artists.map((artist, artistIdx) => (
          <span key={artist.name}>
            {artistIdx > 0 && ", "}
            {artist.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ListeningRoomCardSquare;
