import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";
import secureLocalStorage from "react-secure-storage";
import useAuth from "./useAuth.js";

export default function useSeeTrackHistory() {
  const { auth } = useAuth();
  const currentuserid = auth?.email;
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ trackid, historyid, projectid }) =>
      axiosPrivate
        .post(
          serverUrl + "/tracks/" + trackid + "/history/" + historyid + "/seen"
        )
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "track-history",
          variables.trackid.toString(),
        ]);
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
        queryClient.invalidateQueries(["assigned_tasks", currentuserid]);
      },
    }
  );
}
