import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useAddTrackToMLQueue() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation(({ trackId }) =>
    axiosPrivate
      .get(serverUrl + "/tracks/addToMLQueue/" + trackId)
      .then((res) => res.data)
  );
}
