import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackStatus() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ status, projectid, trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, { status: status })
        .then((res) => res.data),
    {
      onMutate: async (newTrackStatus) => {
        await queryClient.cancelQueries(["track", newTrackStatus.trackid]);
        const previousTrack = queryClient.getQueryData([
          "track",
          newTrackStatus.trackid,
        ]);
        queryClient.setQueryData(["track", newTrackStatus.trackid], {
          ...previousTrack,
          status: newTrackStatus.status,
        });
        return { previousTrack };
      },
      onError: (err, newTrackStatus, context) => {
        queryClient.setQueryData(
          ["track", newTrackStatus.trackid],
          context.previousTrack
        );
      },
      onSettled: (data, error, newTrackStatus) => {
        queryClient.invalidateQueries([
          "tracksbyproject",
          newTrackStatus.projectid.toString(),
        ]);

        queryClient.invalidateQueries([
          "project-pie-data",
          newTrackStatus.projectid.toString(),
        ]);

        queryClient.invalidateQueries(["all-tracks"]);
        queryClient.invalidateQueries(["recent-tracks"]);
        queryClient.invalidateQueries(["track", newTrackStatus.trackid]);
        queryClient.invalidateQueries([
          "track-history",
          newTrackStatus.trackid,
        ]);
      },
    }
  );
}
