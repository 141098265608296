import { useMutation, useQueryClient } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";
import axios from "axios";

export default function useAddPlaylistVisit({ code }) {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axios
        .post(serverUrl + "/play/" + code + "/visit", data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['all-private-playlists']);
      },
    }
  );
}
