import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useSeeTrackHistory from "../hooks/useSeeTrackHistory";
import { classNames } from "../shared/Utils";

const NotificationsListItemSeen = (props) => {
  const seeTrackHistoryQuery = useSeeTrackHistory();
  const ref = useRef(null);

  console.log("props.item.seenBy", props.item.seenBy);

  const { auth } = useAuth();

  const currentUserEmail = auth.email;

  const [isSeen, setIsSeen] = useState(
    props.item.seenBy.includes(currentUserEmail)
  );

  useEffect(() => {
    if (isSeen) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          props.addItemToSeenArray({
            historyId: props.item.id,
          });
        }
      },
      { threshold: 1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  // random false or true

  // set isSeen to true after 2 seconds
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const timeoutId = setTimeout(() => {
            if (!isSeen) {
              setIsSeen(true);
            }
          }, 4000);
          return () => clearTimeout(timeoutId);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [isSeen]);

  const navigate = useNavigate();
  return (
    <div
      ref={ref}
      onClick={() => {
        navigate(
          `/projects/${props.item.track.project}/list/track/${props.item.track.id}`
        );
      }}
      className={classNames(
        isSeen
          ? "group-hover:bg-gray-50"
          : "bg-green-100 group-hover:bg-green-200 ",
        " rounded-t-md rounded-bl-md py-2 pl-2  transition-all"
      )}
    >
      {/* trackId: {props.trackId} historyId: {props.historyId} */}
      {props.children}
    </div>
  );
};

export default NotificationsListItemSeen;
