import { getAttributes } from "@tiptap/core";
import { MarkType } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";

export function clickHandler(options) {
  return new Plugin({
    key: new PluginKey("handleClickLink"),
    props: {
      handleClick: (view, pos, event) => {
        const attrs = getAttributes(view.state, options.type.name);
        const link = event.target?.closest("a");

        if (link && attrs.href) {
          window.open(attrs.href, attrs.target);

          return true;
        }

        return false;
      },
    },
  });
}
