import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";

export default function useAllProjects() {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(['all-projects'], () =>
    axiosPrivate.get(serverUrl + "/projects/").then((res) => res.data));
}
