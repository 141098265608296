import React from "react";
import "./WaveFormPlaceholderAnimation.css";
const WaveFormPlaceholderAnimation = ({ color, height, width }) => {
  const width2 = width ? width : 200;
  const repeatFor = Math.floor(width2 / 3);

  return (
    <div
      id="loader"
      className="flex justify-center align-middle"
      style={{
        // paddingTop: height ? height / 2 : 50,
        width: width ? width : "400px",
        height: height ? height : "30px",
      }}
    >
      {[...Array(repeatFor)].map((_, i) => {
        return (
          <span
            key={"waveform-" + i}
            className="bg-black/20 dark:bg-white/20"
            style={{
              //   backgroundColor: color ? color : "white",
              animationDelay: i * 10 + "ms",
            }}
          ></span>
        );
      })}
    </div>
  );
};

export default WaveFormPlaceholderAnimation;
