import { createContext, useState } from "react";
import { toast } from "react-toastify";

import shortUUID from "short-uuid";
import useUpdateTrackUrl from "../components/hooks/useUpdateTrackUrl";
import useUpdateTrackUploadStatus from "../components/hooks/useUpdateTrackUploadStatus";

import React from "react";
import Uppy from "@uppy/core";

import Transloadit from "@uppy/transloadit";
import { StatusBar } from "@uppy/react";

import "./UploaderProvider.css";

const UploaderContext = createContext({});

export const UploaderProvider = ({ children }) => {
  const UpdateTrackUrlQuery = useUpdateTrackUrl();
  const UpdateTrackUploadStatusQuery = useUpdateTrackUploadStatus();
  const [uploaderJobs, setUploaderJobs] = useState([]);

  const uploaderAddJob = (job) => {
    console.log("uploaderAddJob", job);
    const newJobId = CreateUploaderJob({ job });
    job.id = newJobId;
    setUploaderJobs([...uploaderJobs, job]);
  };

  const uploaderAddJobs = (jobs) => {
    console.log("uploaderAddJobs", jobs);
    jobs.map((job) => {
      const newJobId = CreateUploaderJobBatch({ job });
      job.id = newJobId;
      setUploaderJobs([...uploaderJobs, job]);
    });
  };

  const CreateUploaderJob = ({ job }) => {
    job.status = "uploading";
    job.id = shortUUID.generate();

    UpdateTrackUploadStatusQuery.mutate({
      trackid: job.trackid,
      projectid: job.projectid,
      uploadStatus: "processing",
    });

    const uppy = new Uppy().use(Transloadit, {
      service: "https://api2.transloadit.com",
      params: {
        auth: { key: "4fe848b9c24a4477a057aac61b170837" },
        steps: {
          ":original": {
            robot: "/upload/handle",
          },
          imported_image: {
            robot: "/http/import",
            url: "https://app.trackflow.studio/images/download-artwork.png",
          },
          preview_mp3: {
            use: ":original",
            robot: "/audio/encode",
            bitrate: 128000,
            preset: "mp3",
            ffmpeg_stack: "v4.3.1",
          },
          download_mp3: {
            use: ":original",
            robot: "/audio/encode",
            bitrate: 320000,
            preset: "mp3",
            ffmpeg_stack: "v4.3.1",
          },
          preview: {
            robot: "/audio/artwork",
            use: {
              steps: [
                {
                  name: "preview_mp3",
                  as: "audio",
                },
                {
                  name: "imported_image",
                  as: "image",
                },
              ],
              bundle_steps: true,
            },
            method: "insert",
            ffmpeg_stack: "v4.3.1",
          },
          mp3: {
            robot: "/audio/artwork",
            use: {
              steps: [
                {
                  name: "download_mp3",
                  as: "audio",
                },
                {
                  name: "imported_image",
                  as: "image",
                },
              ],
              bundle_steps: true,
            },
            method: "insert",
            ffmpeg_stack: "v4.3.1",
          },
          waveform: {
            use: "preview_mp3",
            robot: "/audio/waveform",
            width: 300,
            height: 100,
            format: "json",
          },
          export: {
            use: [":original", "mp3", "preview", "waveform"],
            robot: "/google/store",
            credentials: "firebase",
            path:
              "tracks/" +
              job.workspace +
              "/" +
              job.projectid +
              "/" +
              job.trackid +
              "/${previous_step.name}-${file.url_name}",
          },
        },
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
      fields: {},
      limit: 3,
      locale: {
        strings: {
          encoding: "Processing audio..",
        },
      },
    });

    // create blob from job.file
    const blob = new Blob([job.file[0]], {
      type: "application/octet-stream",
    });

    uppy.addFile({
      name: job.file[0].name, // file name
      type: job.file[0].type, // file type
      data: blob, // file blob
    });
    // delay for the file to be added to the queue

    uppy.upload();

    uppy.on("file-added", (file) => {
      console.log("uppy file-added", file);
      uppy.upload();
    });

    uppy.on("upload", (file) => {
      toast(
        <div className="  flex w-full flex-col gap-2  px-2">
          {/* <div className="">
            Uploading audio for:
            <ArrowUpIcon className="mt-0.5 mr-0.5 inline-flex h-4 w-4 animate-bounce " />
          </div> */}
          <div className=" max-w-[27rem] overflow-hidden  overflow-ellipsis whitespace-nowrap ">
            {job.file[0].name}
          </div>
          <div>
            <StatusBar
              uppy={uppy}
              hideAfterFinish={false}
              showProgressDetails
              // className="w-full"
              hideUploadButton={false}
              // theme="dark"
            />
          </div>
        </div>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "uploads",
          className: "",
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
          toastId: "uploadtoast-" + job.id,
        }
      );
    });

    uppy.on("upload-success", (file, response) => {
      console.log("UPPY upload-success", file, response);
    });

    uppy.on("transloadit:result", (stepName, result) => {
      const file = uppy.getFile(result.localId);
      console.log("uppy transloadit:result", stepName, result, file);
    });

    uppy.on("file-removed", (file, reason) => {
      setTimeout(() => {
        toast.dismiss("uploadtoast-" + job.id);
      }, 2000);
      console.log("uppy cancel", file);

      UpdateTrackUploadStatusQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        uploadStatus: "cancelled",
      });
    });

    uppy.on("transloadit:complete", (assembly) => {
      // Could do something fun with this!
      console.log("uppy transloadit complete", assembly.results);

      const assemblyResults = assembly.results;

      const originalurl = assemblyResults[":original"][0].ssl_url.replace(
        "/gs:/",
        "/"
      );

      const previewurl = assemblyResults.preview[0].ssl_url.replace(
        "/gs:/",
        "/"
      );
      const downloadurl = assemblyResults.mp3[0].ssl_url.replace("/gs:/", "/");
      const waveformurl = assemblyResults.waveform[0].ssl_url.replace(
        "/gs:/",
        "/"
      );

      UpdateTrackUrlQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        originalurl: originalurl,
        waveformurl: waveformurl,
        previewurl: previewurl,
        downloadurl: downloadurl,
        workspace: job.workspace,
        playedBy: [],
      });

      UpdateTrackUploadStatusQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        uploadStatus: "finished",
      });

      setTimeout(() => {
        toast.dismiss("uploadtoast-" + job.id);
      }, 5000);
    });

    return job.id;
  };

  const CreateUploaderJobBatch = ({ job }) => {
    console.log("CreateUploaderJobBatch", job);
    job.status = "uploading";
    job.id = shortUUID.generate();

    UpdateTrackUploadStatusQuery.mutate({
      trackid: job.trackid,
      projectid: job.projectid,
      uploadStatus: "processing",
    });

    const uppy = new Uppy().use(Transloadit, {
      service: "https://api2.transloadit.com",
      params: {
        auth: { key: "4fe848b9c24a4477a057aac61b170837" },
        steps: {
          ":original": {
            robot: "/upload/handle",
          },
          imported_image: {
            robot: "/http/import",
            url: "https://app.trackflow.studio/images/download-artwork.png",
          },
          preview_mp3: {
            use: ":original",
            robot: "/audio/encode",
            bitrate: 128000,
            preset: "mp3",
            ffmpeg_stack: "v4.3.1",
          },
          download_mp3: {
            use: ":original",
            robot: "/audio/encode",
            bitrate: 320000,
            preset: "mp3",
            ffmpeg_stack: "v4.3.1",
          },
          preview: {
            robot: "/audio/artwork",
            use: {
              steps: [
                {
                  name: "preview_mp3",
                  as: "audio",
                },
                {
                  name: "imported_image",
                  as: "image",
                },
              ],
              bundle_steps: true,
            },
            method: "insert",
            ffmpeg_stack: "v4.3.1",
          },
          mp3: {
            robot: "/audio/artwork",
            use: {
              steps: [
                {
                  name: "download_mp3",
                  as: "audio",
                },
                {
                  name: "imported_image",
                  as: "image",
                },
              ],
              bundle_steps: true,
            },
            method: "insert",
            ffmpeg_stack: "v4.3.1",
          },
          waveform: {
            use: "preview_mp3",
            robot: "/audio/waveform",
            width: 300,
            height: 100,
            format: "json",
          },
          export: {
            use: [":original", "mp3", "preview", "waveform"],
            robot: "/google/store",
            credentials: "firebase",
            path:
              "tracks/" +
              job.workspace +
              "/" +
              job.projectid +
              "/" +
              job.trackid +
              "/${previous_step.name}-${file.url_name}",
          },
        },
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
      fields: {},
      limit: 3,
      locale: {
        strings: {
          encoding: "Processing audio..",
        },
      },
    });

    // create blob from job.file
    const blob = new Blob(job.file, {
      type: "application/octet-stream",
    });

    uppy.addFile({
      name: job.file.name, // file name
      type: job.file.type, // file type
      data: blob, // file blob
    });

    // delay for the file to be added to the queue

    uppy.upload();

    uppy.on("file-added", (file) => {
      console.log("uppy file-added", file);
      uppy.upload();
    });

    uppy.on("upload", (file) => {
      toast(
        <div className="  flex w-full flex-col gap-2  px-2">
          <div className=" max-w-[27rem] overflow-hidden  overflow-ellipsis whitespace-nowrap ">
            {job.file[0].name}
          </div>
          <div>
            <StatusBar
              uppy={uppy}
              hideAfterFinish={false}
              showProgressDetails
              hideUploadButton={false}
              // theme="dark"
            />
          </div>
        </div>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "uploads",
          className: "",
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
          toastId: "uploadtoast-" + job.id,
        }
      );
    });

    uppy.on("upload-success", (file, response) => {
      console.log("UPPY upload-success", file, response);
      
    });

    uppy.on("transloadit:result", (stepName, result) => {
      const file = uppy.getFile(result.localId);
      console.log("uppy transloadit:result", stepName, result, file);
    });

    uppy.on("file-removed", (file, reason) => {
      setTimeout(() => {
        toast.dismiss("uploadtoast-" + job.id);
      }, 2000);
      console.log("uppy cancel", file);

      UpdateTrackUploadStatusQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        uploadStatus: "cancelled",
      });
    });

    uppy.on("transloadit:complete", (assembly) => {
      // Could do something fun with this!
      console.log("uppy transloadit complete", assembly.results);

      const assemblyResults = assembly.results;

      const originalurl = assemblyResults[":original"][0].ssl_url.replace(
        "/gs:/",
        "/"
      );

      const previewurl = assemblyResults.preview[0].ssl_url.replace(
        "/gs:/",
        "/"
      );
      const downloadurl = assemblyResults.mp3[0].ssl_url.replace("/gs:/", "/");
      const waveformurl = assemblyResults.waveform[0].ssl_url.replace(
        "/gs:/",
        "/"
      );

      UpdateTrackUrlQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        originalurl: originalurl,
        waveformurl: waveformurl,
        previewurl: previewurl,
        downloadurl: downloadurl,
        workspace: job.workspace,
        playedBy: [],
      });

      UpdateTrackUploadStatusQuery.mutate({
        trackid: job.trackid,
        projectid: job.projectid,
        uploadStatus: "finished",
      });

      setTimeout(() => {
        toast.dismiss("uploadtoast-" + job.id);
      }, 5000);
    });

    return job.id;
  };

  return (
    <UploaderContext.Provider
      value={{
        uploaderAddJob,
        uploaderAddJobs,
      }}
    >
      {children}
    </UploaderContext.Provider>
  );
};

export default UploaderContext;
