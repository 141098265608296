import React, { Fragment } from "react";

import { useState, useEffect } from "react";
import { Reorder } from "framer-motion";

import _ from "lodash";
import shortUUID from "short-uuid";

import { getStatusIcon } from "../../../shared/Utils";

import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/solid";

import ColorPicker from "../../../ColorPicker";
import IconPicker from "../../../IconPicker";
import { PlusIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../shared/Utils";

export default function StatusListSimple({ initStatuses, editable }) {
  const [statuses, setStatuses] = useState(initStatuses);

  const setColor = ({ color, id }) => {
    setStatuses(
      statuses.map((item) =>
        item.id === id ? { ...item, color: color } : item
      )
    );
  };

  const setIcon = ({ iconName, id }) => {
    setStatuses(
      statuses.map((item) =>
        item.id === id ? { ...item, iconName: iconName } : item
      )
    );
  };

  const [error, setError] = useState(null);
  const [addButton, setAddButton] = useState(
    <>
      <PlusIcon className="text-gray-500 mx-auto my-auto mr-2  h-5 w-5 " />
      Add status
    </>
  );

  const handleAddStatus = () => {
    if (statuses[statuses.length - 1]?.title || statuses.length == 0) {
      setStatuses((oldStatuses) => [
        ...oldStatuses,
        {
          id: shortUUID.generate(),
          title: "",
          iconName: "PencilIcon",
          icon: PencilIcon,
          color: "#bbbbbb",
          progress: 30,
        },
      ]);
    } else {
      setAddButton("Please name previous status first");
      setTimeout(() => {
        setAddButton(
          <>
            <PlusIcon className="text-gray-500 mx-auto my-auto mr-2  h-5 w-5 " />
            Add status
          </>
        );
      }, 1000);
    }
  };

  const handleDeleteStatus = (id) => {
    setStatuses((oldStatuses) => oldStatuses.filter((s) => s.id !== id));
  };

  // console.log("statuses", statuses);
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="-my-2 ">
          <div className="inline-block min-w-full py-2 align-middle ">
            <div className="ring-black border-gray-300 overflow-hidden md:rounded-lg">
              <div className="bg-gray-50  min-w-full  overflow-hidden rounded-md  ">
                <div className="  ">
                  <div className="divide-gray-200 py-2">
                    {statuses.map((status) => (
                      <div key={status.id} value={status}>
                        <div
                          key={status.id}
                          className="group bg-gray-50 border-gray-300 relative flex h-12 flex-row  hover:bg-gray-50"
                        >
                          <div
                            className={classNames(
                              "my-auto w-12 flex-shrink-0 whitespace-nowrap pl-3   pr-3  text-sm transition-all "
                            )}
                          >
                            <div
                              style={{
                                color: status.color,
                                // borderColor: status.color,
                              }}
                              className="flex h-8 w-8 p-1 "
                            >
                              {getStatusIcon(status.iconName)}
                            </div>
                          </div>

                          <div className="text-gray-800 bg-gray-50 my-auto ml-2 whitespace-nowrap text-sm font-medium">
                            {status.title}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
