import { HashtagIcon } from "@heroicons/react/24/outline";
import React from "react";
import { CustomPicker } from "react-color";
import { classNames } from "./shared/Utils";

import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { CheckIcon } from "@heroicons/react/20/solid";
import { forwardRef } from "react";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",

  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUpAndFade },
  '&[data-side="bottom"]': { animationName: slideDownAndFade },
  '&[data-side="left"]': { animationName: slideLeftAndFade },
  '&[data-side="right"]': { animationName: slideRightAndFade },
});

class ColorPicker extends React.Component {
  state = {
    open: false,
  };

  handleClick = (e) => {
    this.props.setColor({ color: e.target.id, id: this.props.id });
    this.props.setOpen(false);
    this.setState({ open: false });
  };
  render() {
    const availableColors = [
      "#6b7280",
      "#eab308",
      "#f97316",
      "#ef4444",
      "#d946ef",
      "#8b5cf6",
      "#0ea5e9",
      "#06b6d4",
      "#14b8a6",
      "#22c55e",
    ];
    return (
      <div className="group relative my-auto mx-auto mt-1.5 ">
        <PopoverPrimitive.Root
          onOpenChange={(open) => {
            this.setState({ open: open });
            this.props.setOpen(open);
          }}
          open={this.state.open}
        >
          <PopoverPrimitive.Trigger>
            <div
              className=" h-6 w-6 cursor-pointer rounded-md  border border-transparent hover:border-gray-350 hover:brightness-110"
              style={{
                backgroundColor: this.props.hex ? this.props.hex : "#ccc",
              }}
              onClick={(e) => {
                e.preventDefault;
                this.setState({ open: true });
              }}
            />
          </PopoverPrimitive.Trigger>
          <StyledContent>
            <div
              className={classNames(
                "z-40   grid  h-[7rem] w-[16rem]  cursor-pointer grid-cols-5 grid-rows-2 rounded-md border  p-3 shadow-lg bg-gray-100  border-gray-300 "
              )}
            >
              {availableColors.map((color) => (
                <div
                  className={classNames(
                    color == this.props.hex
                      ? "border-2 opacity-100"
                      : "opacity-100",
                    "m-2 my-auto flex h-8 w-8  flex-shrink-0 rounded-md text-white  border-white  hover:border-2 hover:opacity-100"
                  )}
                  style={{ backgroundColor: color }}
                  onClick={(e) => this.handleClick(e)}
                  key={color}
                  id={color}
                >
                  {color == this.props.hex && (
                    <CheckIcon className="my-auto mx-auto h-4 w-4" />
                  )}
                </div>
              ))}

              {/* <div className="relative col-span-5 mx-2 mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <HashtagIcon
                    className="text-gray-400 h-4 w-4"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="customColor"
                  id="customColor"
                  className="bg-gray-50 border-gray-300 block w-full rounded-md pl-8 text-xs focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  placeholder="custom hex"
                />
              </div> */}
            </div>
            <PopoverPrimitive.Arrow className=" fill-gray-300 dark:fill-zinc-600 " />
          </StyledContent>
        </PopoverPrimitive.Root>
      </div>
    );
  }
}

export default CustomPicker(ColorPicker);
