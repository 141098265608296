const template = [
  {
    id: "releasedate",
    title: "Release date",
    type: "date",
    initialValue: "",
    placeholder: "dd/mm/yyyy",
    validate: (value) => {
      if (!value) {
        return "Required";
      } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return "Must be in dd/mm/yyyy format";
      }
    },
  },
  {
    id: "upc",
    title: "Upc",
    type: "text",
    initialValue: "",
    placeholder: "123456789012",
    validate: (value) => {
      if (!value) {
        return "Required";
      } else if (!/^\d{12}$/.test(value)) {
        return "Must be 12 digits";
      }
    },
  },

  {
    id: "recordlabel",
    title: "Record Label",
    type: "text",
    initialValue: "100 mgmt",
    validate: (value) => {
      if (!value) {
        return "Required";
      }
    },
  },
  {
    id: "catalognumber",
    title: "Catalog Number",
    type: "text",
    initialValue: "",
    validate: (value) => {
      if (!value) {
        return "Required";
      }
    },
  },
  {
    id: "cline",
    title: "C Line",
    type: "text",
    initialValue: "",
    validate: (value) => {
      if (!value) {
        return "Required";
      }
    },
  },
  {
    id: "pline",
    title: "P Line",
    type: "text",
    initialValue: "",
    validate: (value) => {
      if (!value) {
        return "Required";
      }
    },
  },

  {
    id: "isrc",
    title: "ISRC",
    type: "text",
    initialValue: "",
    validate: (value) => {
      return null;
    },
  },
  {
    id: "iswc",
    title: "ISWC",
    type: "text",
    initialValue: "",
    validate: (value) => {
      return null;
    },
  },
];

export default template;
