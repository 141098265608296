import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import useCreateInvitation from "../../hooks/useCreateInvitation";
const AdminInviteUserToCurrentWorkspaceContent = ({ close }) => {
  const createInvitationQuery = useCreateInvitation();

  return (
    <div className="flex w-full flex-col gap-6 p-6">
      <div className="flex w-full flex-row justify-between">
        <div className="text-gray-700 text-2xl">Invite user to workspace</div>
        <button onClick={close}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
      <div>TODO Template?</div>
      <div>
        <button
          type="button"
          className="bg-white text-gray-700 border-gray-300 inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm ring-offset-white hover:bg-gray-50 focus:ring-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:ring-offset-zinc-900"
        >
          Invite
        </button>
      </div>
    </div>
  );
};

export default AdminInviteUserToCurrentWorkspaceContent;
