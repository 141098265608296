import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";
import { toast } from "react-toastify";

export default function useDeleteTrack() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ artists, projectid, trackid }) =>
      axiosPrivate
        .delete(serverUrl + "/tracks/" + trackid)
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        toast.info("Deleted track in project" + variables.projectid.toString());
        queryClient.invalidateQueries(["projects"]);
        queryClient.invalidateQueries(["all-tracks"]);
        queryClient.invalidateQueries(["recent-tracks"]);
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
        queryClient.invalidateQueries([
          "project-pie-data",
          variables.projectid.toString(),
        ]);
      },
    }
  );
}
