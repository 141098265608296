import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "./ArtistPhoneInput.css";
import { classNames } from "../shared/Utils";
import {
  LocationMarkerIcon,
  MapIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

import { getLocalInfo } from "phone-number-to-timezone";
import { ClockIcon, MoonIcon } from "@heroicons/react/24/solid";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ToolTip from "../ToolTip";

const ArtistPhoneInput = ({
  tel,
  handleChange,
  handleDelete,
  changed,
  bggray,
  edit,
}) => {
  const [value, setValue] = React.useState(tel.value);
  const [localTime, setLocalTime] = React.useState({});

  const change = (e) => {
    handleChange(e, tel.id);
    setValue(e.target.value);
  };

  useEffect(() => {
    setLocalTime(getLocalInfo(tel.value));
    console.log("localTime", localTime);
  }, [tel]);

  const CopyToClipboard = (e) => {
    console.log(tel.value);
    navigator.clipboard.writeText(tel.value);
    toast.success("Copied to clipboard", { containerId: "main" });
  };

  return (
    <div
      className={classNames(edit ? "gap-2" : "", "z-10  flex  justify-between")}
    >
      <div
        className={classNames(
          edit ? "ring-1" : "ring-0",
          bggray
            ? "bg-gray-100 hover:bg-gray-200"
            : "bg-white hover:bg-gray-100",
          " group bg-white border-white  relative  flex w-full justify-between overflow-hidden rounded ring-gray-200 focus:border-0 dark:ring-zinc-700"
        )}
        onClick={(e) => {
          if (!edit) {
            e.stopPropagation();
            CopyToClipboard();
          }
        }}
      >
        <PhoneInput
          className={classNames(
            edit ? "" : " ",
            bggray
              ? "bg-gray-100 hover:bg-white group-hover:bg-white"
              : "bg-white hover:bg-gray-100 group-hover:bg-gray-100",
            "  frlex-shrink-0  w-full flex-grow rounded-md focus:border-0"
          )}
          country={"us"}
          disableDropdown={true}
          disableMagnifyingGlassIcon={true}
          enableAreaCodes={true}
          value={value}
          disabled={!edit}
          onChange={(value, country, e, formattedValue) => {
            // setValue(value);
            change(e);
            setLocalTime(getLocalInfo(formattedValue));
          }}
        />
        {localTime?.time?.hour && (
          <div className="absolute right-0  mr-2 w-6">
            {(parseInt(localTime.time.hour) < 8 ||
              parseInt(localTime.time.hour) > 20) && (
              <div className="text-gray-500 flex  h-[2rem]   rounded px-2 py-1 text-xs">
                <ToolTip
                  message={
                    "Local time in " +
                    localTime.location +
                    " is: " +
                    localTime.time.display
                  }
                >
                  <div className=" my-auto h-4 w-4 ">
                    <MoonIcon className="h-4 w-4  " />
                  </div>
                </ToolTip>
              </div>
            )}
          </div>
        )}
      </div>{" "}
      <div className="flex flex-grow-0">
        <XMarkIcon
          onClick={(e) => handleDelete(e, tel.id)}
          className={classNames(
            edit ? "inline" : "hidden",
            "  text-gray-500 my-auto h-5   w-5 cursor-pointer hover:text-red-700"
          )}
        />
      </div>
    </div>
  );
};

export default ArtistPhoneInput;
