import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import {
  StarIcon,
  PhotographIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";

import { ColorExtractor } from "react-color-extractor";

export const getAuth = async () => {
  console.log("spotify auth");
  const clientId = "c307bb17266f4bad89de9502fcb4bef8";
  const clientSecret = "bfe89f6570d84cd5ba2192ec87facf8b";

  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: clientId,
      password: clientSecret,
    },
  };
  const data = {
    grant_type: "client_credentials",
  };

  try {
    const response = await axios.post(
      "https://accounts.spotify.com/api/token",
      qs.stringify(data),
      headers
    );
    console.log("spotify token", response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    console.log("spotify error", error);
  }
  // var spotifyApi = new SpotifyWebApi();

  //
};

const SpotifyAvatar = ({ artistName, setColors, setFound, setImageUrl }) => {
  var spotifyApi = new SpotifyWebApi();
  const [image, setImage] = useState(null);
  const [followers, setFollowers] = useState(null);
  const [genres, setGenres] = useState([]);
  const [spotifyLink, setSpotifyLink] = useState(null);

  useEffect(() => {
    if (artistName) {
      getAuth().then((token) => {
        console.log("spotify auth", token);
        spotifyApi.setAccessToken(token);
        spotifyApi.searchArtists(artistName).then(
          function (data) {
            console.log("spotify data", data);
            setImage(data?.artists?.items[0]?.images[0]?.url);
            setImageUrl(data?.artists?.items[0]?.images[0]?.url);
            setFound(data?.artists?.items[0]?.images[0]?.url ? true : false);
            setFollowers(
              data?.artists?.items[0]?.followers?.total.toLocaleString()
            );
            setGenres(data?.artists?.items[0]?.genres);
            setSpotifyLink(data?.artists?.items[0]?.external_urls.spotify);
          },
          function (err) {
            console.error(err);
          }
        );
      });
    }
  }, [artistName]);

  // upload image to firebase
  const uploadImage = async (image) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`images/${image.name}`);
    const uploadTask = imageRef.put(image);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      }
    );
    uploadTask.then(function (snapshot) {
      // Handle successful uploads
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      imageRef.getDownloadURL().then(function (url) {
        console.log(url);
      });
    });
  };

  return (
    <>
      <div className="">
        <div className="z-20   md:aspect-square md:h-full  md:w-full">
          <ColorExtractor
            src={image}
            getColors={(colors) => setColors(colors)}
          />

          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "lighten",
              backgroundColor: "rgba(244,244,244,0.1)",
            }}
            className="group flex aspect-square w-full flex-col place-items-end overflow-hidden rounded-full border-4 shadow border-white dark:brightness-90   dark:border-zinc-200"
          >
            {!image && (
              <svg
                className="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpotifyAvatar;
