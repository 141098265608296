import React, { useEffect, useState } from "react";
import useUpdateUser from "./hooks/useUpdateUser";

import nightwind from "nightwind/helper";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { classNames } from "./shared/Utils";
import useAuth from "./hooks/useAuth";
import { useAuth0 } from "@auth0/auth0-react";

const DarkModeSwitch = () => {
  const [darkMode, setDarkmode] = useState(false);

  const { auth } = useAuth();

  const { user } = useAuth0();

  const userId = user?.userId;
  console.log("DarkModeSwitchUserId", userId);
  const updateUserQuery = useUpdateUser();

  useEffect(() => {
    nightwind.enable(user?.userPreferences.darkMode);
    setDarkmode(user?.userPreferences.darkMode);
    nightwind.beforeTransition();
  }, [user?.userPreferences.darkMode]);

  const isDarkMode = () => {
    if (auth?.firstLogin) {
      setDarkmode(false);
    } else {
      const nightwindmode = localStorage.getItem("nightwind-mode");
      if (nightwindmode == "dark") {
        setDarkmode(true);
        document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute("content", "#2b2b2f");
      } else {
        setDarkmode(false);
        document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute("content", "#e1e3eb");
      }
    }
  };

  useEffect(() => {
    isDarkMode();
  }, []);

  const toggleDarkMode = () => {
    nightwind.toggle();
    nightwind.beforeTransition();

    const htmlDarkMode = document.documentElement.classList.contains("dark");
    console.log("htmlDarkMode", htmlDarkMode);
    setDarkmode(htmlDarkMode);

    updateUserQuery.mutate({
      userId: userId,
      data: { user_metadata: { darkMode: !darkMode } },
    });
  };

  return (
    <div className="ga- my-auto flex  h-10 w-full cursor-pointer">
      <div
        className={classNames(
          "mx-auto flex h-10 w-10 rounded-md text-zinc-400 hover:bg-zinc-700 dark:hover:bg-zinc-800 "
        )}
        onClick={() => toggleDarkMode()}
      >
        {darkMode ? (
          <SunIcon className="my-auto ml-2 h-6 w-6" aria-hidden="true" />
        ) : (
          <MoonIcon className="my-auto ml-2 h-6 w-6" aria-hidden="true" />
        )}
      </div>
    </div>
  );
};

export default DarkModeSwitch;
