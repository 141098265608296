import React, { useEffect, useState } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export const TrackListMobileArtists = ({ row }) => {
  const [maintags, setMainTags] = useState([]);
  const [collabtags, setCollabTags] = useState([]);

  //load tags
  useEffect(() => {
    let maindata = row.original.artists;
    let collabdata = row.original.collaborators;

    if (maindata) {
      let tempmaintags = maindata.map((artist) => {
        return { label: artist.name, value: artist.id };
      });
      setMainTags(tempmaintags);
    }

    if (collabdata) {
      let tempcollabtags = collabdata.map((artist) => {
        return { label: artist.name, value: artist.id };
      });
      setCollabTags(tempcollabtags);
    }
  }, []);

  return (
    <>
      <div
        id={"artists-" + row.original.id}
        className="truncate cursor-pointer flex flex-wrap gap-y-1  text-gray-500"
      >
        {maintags &&
          maintags.map((artist) => {
            return (
              <div className="inline" key={artist.value}>
                <div className=" flex font-normal px-1 ">{artist.label}</div>
              </div>
            );
          })}

        {collabtags &&
          collabtags.map((artist) => {
            return (
              <div className="inline" key={artist.value}>
                <div className=" flex font-normal px-1  ">{artist.label}</div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default TrackListMobileArtists;
