import React from "react";
import { EnvelopeOpenIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { classNames } from "../shared/Utils";

import { toast } from "react-toastify";

export const ArtistEmailInput = ({
  email,
  handleChange,
  handleDelete,
  changed,
  edit,
  bggray,
}) => {
  const [value, setValue] = React.useState(email.value);
  const change = (e) => {
    handleChange(e);
    console.log("email id", email.id, e.target.value);
    setValue(e.target.value);
  };

  const CopyToClipboard = (e) => {
    console.log(email.value);
    navigator.clipboard.writeText(email.value);
    toast.success("Copied to clipboard", { containerId: "main" });
  };

  return (
    <div className="flex w-full ">
      <div
        className={classNames(
          edit ? "ring-1" : "ring-0",
          bggray ? "bg-gray-100" : "bg-white",
          " group z-10  flex  w-full flex-grow overflow-hidden rounded ring-gray-200 dark:ring-zinc-700"
        )}
      >
        <div
          className={classNames(
            bggray
              ? "bg-gray-100 hover:bg-white"
              : "bg-white hover:bg-gray-100",
            "flex w-full bg-white "
          )}
          onClick={(e) => {
            if (!edit) {
              e.stopPropagation();
              CopyToClipboard();
            }
          }}
        >
          {" "}
          <div className="flex w-10 px-3 py-1 ">
            <div className="my-auto">
              <EnvelopeOpenIcon className=" mb-[0.2rem] inline h-4 w-4 text-gray-500" />{" "}
            </div>
          </div>
          <input
            type="email"
            value={value}
            id={email.id}
            disabled={!edit}
            onChange={(e) => {
              change(e);
            }}
            className={classNames(
              edit ? "" : "hover:bg-gray-100 ",
              bggray ? "hover:bg-white dark:hover:bg-zinc-900" : "",
              "peer peer my-auto w-full rounded border-0 bg-inherit px-2 py-[0.4rem] text-sm  text-gray-800 focus:ring-0 disabled:cursor-copy "
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          edit ? "flex" : "hidden",
          " my-auto ml-2 w-5 flex-grow-0 "
        )}
      >
        <XMarkIcon
          onClick={(e) => handleDelete(e, email.id)}
          className={classNames(
            edit ? "inline" : "hidden",
            "  my-auto h-5  w-5   cursor-pointer text-gray-500 hover:text-red-700"
          )}
        />
      </div>
    </div>
  );
};
