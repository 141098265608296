import React, { useEffect } from "react";
import { useRef } from "react";
import Avatar from "../../Avatar";
import { formatDistanceToNow, formatDistanceStrict } from "date-fns";
import { useInViewport } from "react-in-viewport";

import useDeleteTrackHistory from "../../hooks/useDeleteTrackHistory";
import useUpdateTrackHistory from "../../hooks/useUpdateTrackHistory";

import ReactHtmlParser from "react-html-parser";

import {
  PencilIcon,
  ArrowUturnLeftIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/solid";
import TrackListTrackOverlaySidebarHistoryReply from "./TrackListTrackOverlaySidebarHistoryReply";
import { classNames } from "../../shared/Utils";
import useSeeTrackHistory from "../../hooks/useSeeTrackHistory";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import useAuth from "../../hooks/useAuth";

const TrackListTrackOverlaySidebarHistoryMessage = ({
  activityItem,
  trackid,
  projectid,
  type,
  track,
}) => {
  console.log("activityItem", activityItem);
  const seeTrackHistoryQuery = useSeeTrackHistory();

  const { auth } = useAuth();

  const currentuserid = auth?.userid;

  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);
  const [seen, setSeen] = React.useState(
    activityItem.seenBy?.includes(currentuserid)
  );

  const deleteTrackHistoryQuery = useDeleteTrackHistory();

  const handleDelete = () => {
    console.log("delete history", activityItem._id);
    deleteTrackHistoryQuery.mutate({ trackid, historyid: activityItem._id });
    console.log("deleteTrackHistory", deleteTrackHistoryQuery);
  };

  useEffect(() => {
    if (!seen) {
      console.log("[seenby !seen] ");
      if (inViewport) {
        console.log("[seenby inViewport] ");
        setTimeout(() => {
          console.log("[seenby inViewport] ", inViewport);
          seeTrackHistoryQuery.mutate(
            {
              trackid: trackid,
              projectid: projectid,
              historyid: activityItem.id,
              userid: currentuserid,
              seen: true,
            },
            (data) => {
              console.log("[seen] ", data);
            }
          );
          console.log("[seenby query] ", seeTrackHistoryQuery);

          setSeen(true);
        }, 2000);
      }
    }
  }, [seen, inViewport]);

  if (type === "dashboard") {
    return (
      <div className=" flex w-full flex-col" ref={myRef}>
        <div
          className={classNames(
            seen
              ? "ring-0"
              : "ring-offsett-background duration-400 z-50 rounded-lg ring-2 ring-inset ring-offset-1 bg-green-50  ring-green-500 dark:bg-green-900/30 dark:ring-offset-backgrounddark",
            "group w-full  overflow-hidden transition-all    bg-white   "
          )}
        >
          <div className="p-3">
            <div className="flex items-center ">
              <div className="relative flex w-full justify-between ">
                <div className="flex-grow">
                  <Avatar
                    size="30"
                    withName
                    assignedTo={activityItem.assignedTo}
                    userid={activityItem.actionBy}
                  />
                </div>
                <div className=" float-right mt-[0.24rem] text-xs opacity-0 transition-all text-gray-400 group-hover:opacity-100">
                  {formatDistanceStrict(
                    new Date(activityItem.date),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="w-fit   rounded py-3 px-1 text-base text-gray-700">
              <div>{ReactHtmlParser(activityItem.message)}</div>
            </div>
          </div>

          <div
            className={classNames(
              "grid h-0 w-full cursor-pointer grid-cols-2 divide-x  transition-all delay-150 border-gray-200 divide-gray-200 group-hover:h-12 group-hover:border-t  dark:divide-zinc-600"
            )}
          >
            <div className="p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100">
              <ArrowUturnLeftIcon className="mb-1 mr-2 inline h-4 w-4" />
              Reply
            </div>
            <div className="p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100">
              <HandThumbUpIcon className="mb-1 mr-2 inline h-4 w-4" />
              Mark as done
            </div>
          </div>
        </div>
        {activityItem.replies && (
          <>
            {activityItem.replies.map((reply, replyIdx) => (
              <TrackListTrackOverlaySidebarHistoryReply
                reply={reply}
                replyIdx={replyIdx}
                activityItem={activityItem}
                key={reply._id}
              />
            ))}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="my-2 flex w-full flex-col" ref={myRef}>
        <div
          className={classNames(
            seen
              ? "ring-0"
              : "ring-2 ring-offset-2 ring-offset-white ring-green-500 dark:ring-offset-zinc-900",
            "duration-400 group w-full  overflow-hidden  rounded-lg  border transition-all bg-white border-gray-300  "
          )}
        >
          <div className="p-3">
            <div className="flex items-center">
              <div className="relative w-full ">
                <Avatar
                  size="32"
                  withName
                  assignedTo={activityItem.assignedTo}
                  userid={activityItem.actionBy}
                />
                {/* <span className="bg-white absolute top-5 left-5 rounded-tl-md  p-1 dark:bg-zinc-900 ">
                <ChatBubbleLeftIcon
                  className="text-gray-600 h-4 w-4"
                  aria-hidden="true"
                />
              </span> */}
                <div className="float-right mt-[0.24rem] text-xs text-gray-400">
                  {formatDistanceStrict(
                    new Date(activityItem.date),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                </div>
                <div className="float-right w-10 overflow-hidden py-3 transition-all "></div>
              </div>
            </div>
            <div className="mt-2 w-fit  rounded py-3 px-2 text-base text-gray-700">
              <p>{ReactHtmlParser(activityItem.message)}</p>
            </div>

            <div
              className={classNames(
                activityItem.seenBy && activityItem.seenBy?.length > 0
                  ? "h-4 opacity-100"
                  : "h-0 opacity-0",
                " flex justify-end  text-right transition-all"
              )}
            >
              <div className="flex  pr-2  text-gray-400">
                <EyeIcon className="mt-0.5 inline h-4 w-4" />
              </div>
              {activityItem.seenBy?.map((userid) => (
                <div className=" flex" key={userid}>
                  <Avatar size="18" userid={userid} />
                </div>
              ))}
            </div>
          </div>

          <div
            className={classNames(
              activityItem.actionBy == currentuserid
                ? "grid-cols-4"
                : "grid-cols-3",
              "flex h-0 w-full cursor-pointer justify-between divide-x  transition-all delay-150 border-gray-300 divide-gray-300 group-hover:h-12 group-hover:border-t  dark:divide-zinc-600"
            )}
          >
            {activityItem.actionBy == currentuserid && (
              <div className="w-full p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100">
                <PencilIcon className="mb-1 mr-2 inline h-4 w-4" />
                Edit{" "}
              </div>
            )}
            <div className="w-full p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100">
              <ArrowUturnLeftIcon className="mb-1 mr-2 inline h-4 w-4" />
              Reply
            </div>
            <div className="w-full p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100">
              <HandThumbUpIcon className="mb-1 mr-2 inline h-4 w-4" />
              Done
            </div>
            <div
              onClick={() => handleDelete()}
              className="w-full p-[0.6rem] text-center text-gray-600 bg-gray-50 hover:bg-gray-100"
            >
              <TrashIcon className="mb-1 mr-2 inline h-4 w-4" />
              Delete
            </div>
          </div>
        </div>
        {activityItem.replies && (
          <>
            {activityItem.replies.map((reply, replyIdx) => (
              <TrackHistoryReply
                reply={reply}
                replyIdx={replyIdx}
                activityItem={activityItem}
                key={reply._id}
              />
            ))}
          </>
        )}
      </div>
    );
  }
};

export default TrackListTrackOverlaySidebarHistoryMessage;
