import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import MiniPlayer from "../MiniPlayer";
import { styled, keyframes } from "@stitches/react";
import secureLocalStorage from "react-secure-storage";

import { createRef } from "react";

import Highlighter from "react-highlight-words";

import { toast } from "react-toastify";

import useAddPlay from "../hooks/useAddPlay";

import useUploader from "../hooks/useUploader";

import CreatePrivateLinkOverlay from "../PlayList/CreatePrivateLinkOverlay";

import handleDownload from "../hooks/handleDownload";

import TrackListArtists from "./TrackListArtists";

import TrackListNotification from "./TrackListNotification";

import useResetTrackPreviewCode from "../hooks/useResetTrackPreviewCode";
import useResetTrackDownloadCode from "../hooks/useResetTrackDownloadCode";

import TrackMenu from "../TrackMenu";

import * as HoverCardPrimitive from "@radix-ui/react-hover-card";

import "../../context/UploaderProvider.css";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import {
  ChatBubbleLeftIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  FolderOpenIcon,
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  PlayIcon,
  ClipboardDocumentCheckIcon,
  EnvelopeIcon,
  ArrowsPointingOutIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

import { EnvelopeOpenIcon } from "@heroicons/react/24/solid";

import TrackListStatus from "./TrackListStatus";
import TrackListDatePicker from "./TrackListDatePicker";
import useUpdateTrackTitle from "../hooks/useUpdateTrackTitle";
import UppyUpload from "../FileUpload/UppyUpload";
import { TrackListMobileArtists } from "./TrackListMobileArtists";
import MiniPlayerMobile from "../MiniPlayerMobile";
import DeleteOverlay from "../DeleteOverlay";

import TrackListTrackOverlay from "./TrackOverlay/TrackListTrackOverlay";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import useTrackOverlay from "../hooks/useTrackOverlay";
import useAuth from "../hooks/useAuth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 220,
  // backgroundColor: "inherit",

  borderRadius: 6,
  padding: 5,
  boxShadow:
    "hsl(206 22% 7% / 10%) 0px 0px 10px 3px, hsl(206 22% 7% / 5%) 0px 5px 10px 2px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledHoverCardContent = styled(HoverCardPrimitive.Content, {
  borderRadius: 4,
  // padding: "8px 10px",

  fontSize: 15,
  lineHeight: 1,
  color: "inherit",
  backgroundColor: "inherit",
  boxShadow:
    "hsl(206 22% 7% / 10%) 0px 0px 10px 3px, hsl(206 22% 7% / 5%) 0px 5px 10px 2px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: "unset",
  fontSize: 14,
  lineHeight: 1,
  color: "inherit",
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 36,
  padding: "0 5px",
  position: "relative",
  paddingLeft: 10,
  userSelect: "none",
  cursor: "pointer",
  backgroundColor: "inherit",

  "&[data-disabled]": {
    color: "lightgray",
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: "#22c55e",
    color: "white",
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
});
const StyledTriggerItem = styled(DropdownMenuPrimitive.Trigger, {
  '&[data-state="open"]': {
    backgroundColor: "inherit",
    color: "inherit",
  },
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: "#166534",
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  // backgroundColor: "inherit",
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  // fill: "inherit",
});

export const HoverCard = HoverCardPrimitive.Root;
export const HoverCardTrigger = HoverCardPrimitive.Trigger;
export const HoverCardContent = StyledHoverCardContent;

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuTriggerItem = StyledTriggerItem;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: "gray",
  ":focus > &": { color: "white" },
  "[data-disabled] &": { color: "red" },
});

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex items-baseline gap-x-2 ">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md shadow-sm border-gray-300 focus:ring focus:ring-opacity-50 focus:border-slate-300 focus:ring-slate-200"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function AlertCell({ value, column, row }) {
  if (value === "f-new-version") {
    return (
      <TrackListNotification message={["New version!"]}>
        <div className="  flex h-full w-12 text-sm font-medium ">
          <div className=" relative  my-auto inline-flex items-center whitespace-nowrap">
            <ExclamationCircleIcon
              className="mr-2 h-5 w-5 stroke-yellow-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </TrackListNotification>
    );
  } else if (value === "a-deadline") {
    return (
      <TrackListNotification message={["Deadline is in next 3 days!"]}>
        <div className="  flex h-full w-12 text-sm font-medium  ">
          <div className=" relative my-auto mx-auto inline-flex items-center whitespace-nowrap">
            <ExclamationCircleIcon
              className="ml-2 mr-2 h-5 w-5 stroke-red-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </TrackListNotification>
    );
  } else {
    return (
      <div className=" flex h-full w-12 text-sm font-medium  ">
        <div className="relative my-auto mx-auto inline-flex items-center whitespace-nowrap px-2 text-gray-200">
          No alerts
        </div>
      </div>
    );
  }
}
export function PreviewCell({ row }) {
  if (
    row.original.previewurl != "" &&
    row.original.previewurl != null &&
    row.original.uploadStatus != "processing"
  ) {
    return (
      <div className=" h-full ">
        <MiniPlayer alert={row.original.alert} track={row.original} />
      </div>
    );
  } else if (row.original.uploadStatus == "processing") {
    return (
      <div className="relative ml-1.5 h-full  ">
        <svg className="circular-load-small " viewBox="25 25 50 50">
          <circle
            className="loader-path-small"
            cx="50"
            cy="50"
            r="10"
            fill="none"
            stroke="#d1d5db"
            strokeWidth="5"
          />
        </svg>
      </div>
    );
  } else {
    return (
      <div className="h-full w-10 ">
        <UppyUpload
          trackid={row.original.id}
          projectid={row.original.project}
        />
      </div>
    );
  }
}

export function SentCell({ row }) {
  return (
    <div className=" flex h-full w-full pl-2 text-sm font-medium  ">
      <div className=" relative my-auto inline-flex h-full items-center whitespace-nowrap">
        <EnvelopeIcon
          className="mr-2 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <div className="flex -space-x-5 ">
          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white bg-gray-200 dark:bg-zinc-800 dark:ring-zinc-600">
            <span className="text-xs font-medium leading-none text-white">
              {/* BD */}
            </span>
          </span>

          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white bg-gray-200 dark:bg-zinc-800 dark:ring-zinc-600">
            <span className="text-xs font-medium leading-none text-white">
              {/* RF */}
            </span>
          </span>

          <div className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white text-gray-700 bg-gray-300 dark:ring-zinc-500">
            <div className="text-xs font-medium leading-none text-white  dark:text-zinc-200">
              {" "}
              12
            </div>
          </div>
        </div>
        <EnvelopeOpenIcon
          className="ml-4 mr-2 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <div className="flex -space-x-5 ">
          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white bg-gray-200 dark:bg-zinc-800 dark:ring-zinc-600">
            <span className="text-xs font-medium leading-none text-white">
              {/* BD */}
            </span>
          </span>

          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white bg-gray-200 dark:bg-zinc-800 dark:ring-zinc-600">
            <span className="text-xs font-medium leading-none text-white">
              {/* RF */}
            </span>
          </span>

          <div className="inline-flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-white text-gray-700 bg-gray-300 dark:ring-zinc-500">
            <div className="text-xs font-medium leading-none text-white  dark:text-zinc-200">
              {" "}
              12
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MobilePreviewCell({ row }) {
  if (
    row.original.previewurl != "" &&
    row.original.previewurl != null &&
    row.original.uploadStatus != "processing"
  ) {
    return (
      <div className=" h-full ">
        <MiniPlayerMobile alert={row.original.alert} track={row.original} />
      </div>
    );
  } else if (row.original.uploadStatus == "processing") {
    return (
      <div className="relative ml-1 h-full  w-12  ">
        <svg className="circular-load-small " viewBox="25 25 50 50">
          <circle
            className="loader-path-small"
            cx="50"
            cy="50"
            r="10"
            fill="none"
            stroke="#d1d5db"
            strokeWidth="5"
          />
        </svg>
      </div>
    );
  } else {
    return (
      <div className="h-full ">
        <UppyUpload
          trackid={row.original.id}
          projectid={row.original.project}
        />
      </div>
    );
  }
}

export function MobileTitleCell({ value, row }) {
  const { auth } = useAuth();
  const currentUserEmail = auth?.email;
  const isPlayed =
    row.original.playedBy?.includes(currentUserEmail) ||
    !row.original.previewurl;
  return (
    <div className="truncate  font-medium text-gray-800  ">
      {row.original.title ? (
        <>
          {row.original.title}
          {!isPlayed && (
            <div className="z-20 ml-2 inline-flex  rounded border p-1 text-[0.65rem] text-green-500 border-green-500 ">
              NEW
            </div>
          )}
        </>
      ) : (
        <span className=" text-gray-400">no title</span>
      )}
    </div>
  );
}
export function ChatCell({ original, showTrackSideBar }) {
  const [chatOpen, setChatOpen] = React.useState(false);
  const { auth } = useAuth();
  const currentUserEmail = auth.email;
  const isPlayed =
    original.playedBy?.includes(currentUserEmail) || !original.previewurl;

  // increment comments if row.original.history has replies

  const comments = original.history?.filter(
    (entry) => entry.action === "comment"
  );

  const commentCount = comments?.length;
  const commentAndReplyCount = comments?.reduce(
    (previousValue, comment) => previousValue + comment.replies?.length,
    commentCount
  );

  const currentuserid = auth?.userid;

  const unseenCommentCount = useMemo(
    () =>
      comments?.reduce(
        (previousValue, comment) =>
          previousValue +
          (comments.filter(
            (comment) => !comment.seenBy?.includes(currentuserid)
          )?.length ?? 0),
        0
      ),
    [comments, currentuserid]
  );

  return (
    <div className="  h-full w-12 text-sm font-medium  ">
      {/* <TrackListChatSlideover ref={chatSlideover} /> */}
      <div
        onClick={() => showTrackSideBar({ track: original })}
        className={classNames(
          unseenCommentCount > 0
            ? "opacity-80 text-green-500 hover:opacity-100 "
            : "text-gray-300 hover:text-gray-400",
          "relative flex h-full w-12 cursor-pointer items-start  whitespace-nowrap border-r border-gray-300 hover:bg-gray-100 dark:border-zinc-800"
        )}
      >
        <div className="mx-auto  my-auto">
          {commentAndReplyCount > 0 ? (
            <div className="relative w-6">
              <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23 21.5"
              >
                <path
                  id="svg_1"
                  d="M3.35,.52C5.72,.17,9.11,0,11.5,0,13.93,0,17.32,.18,19.65,.52c1.98,.29,3.35,2.02,3.35,3.97V12.51c0,1.95-1.37,3.68-3.35,3.97-1.94,.28-4.92,.45-6.92,.5-.1,0-.19,.04-.27,.11l-4.18,4.18c-.29,.29-.77,.29-1.06,0-.14-.14-.22-.33-.22-.53v-3.95c-.89-.08-2.77-.19-3.65-.32-1.98-.29-3.35-2.02-3.35-3.97V4.49C0,2.54,1.37,.81,3.35,.52Z"
                  style={{
                    fill: "currentColor",
                    stroke: "currentColor",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    // strokeWidth: "2px",
                  }}
                />
              </svg>
              <div className="absolute top-0 w-6 text-center text-[12px] font-bold text-white">
                {commentAndReplyCount}
              </div>
            </div>
          ) : (
            <ClipboardIcon className="  my-auto h-6 w-6  " />
          )}
        </div>
      </div>
    </div>
  );
}

export const ChatCellMemo = React.memo(ChatCell);

export function TitleCell({ value, row, globalFilterValue, openTrackOverlay }) {
  const updateTrackTitleQuery = useUpdateTrackTitle();

  const updateTrackTitle = async (title) => {
    setTitle(title);
    setTitleEdit(false);
    updateTrackTitleQuery.mutate({
      projectid: row.original.project,
      trackid: row.original.id,
      title: title,
    });
  };

  const [titleEdit, setTitleEdit] = React.useState(false);
  const [title, setTitle] = React.useState(value);

  const { TrackOverlayRef } = useTrackOverlay();

  const handleOnClick = () => {
    TrackOverlayRef.current.openDialog(row.original.id);
    console.log("open track overlay", row.original.id);

    // navigate("/projects/" + row.original.project + "/track/" + row.original.id);
  };

  const { auth } = useAuth();

  const { uploaderAddJob } = useUploader();
  const handleUpload = (e) => {
    e.preventDefault();
    let job = {};
    job.file = e.target.files;
    job.trackid = row.original.id;
    job.projectid = row.original.project;
    job.workspace = auth.workspace;

    uploaderAddJob(job);
    setStatus("uploading");
  };

  const currentUserEmail = auth?.email;

  const isPlayed =
    row.original.playedBy?.includes(currentUserEmail) ||
    !row.original.previewurl;

  return (
    <div className="col-span-3 grid h-full  w-full overflow-hidden md:flex md:flex-row">
      <div className=" h-full  w-full  cursor-pointer">
        <div className="group  flex h-full  w-full   text-base  font-medium  ">
          <div className=" z-10 my-auto -ml-2 flex-grow pl-3">
            {titleEdit ? (
              <>
                <input
                  type="text"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    updateTrackTitle(e.target.value);
                    setTitleEdit(false);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") updateTrackTitle(e.target.value);
                  }}
                  className="w-[17.5rem] rounded-md p-1 py-2  text-base font-medium ring-0 bg-white text-gray-900 border-gray-300 ring-green-500 focus:ring-0 focus:border-gray-600 "
                  defaultValue={title}
                  autoFocus
                />
                <XMarkIcon
                  className="mx-2 mb-1 inline h-5 w-5 cursor-pointer text-gray-300 hover:text-gray-400"
                  onClick={(event) => {
                    event.stopPropagation();
                    setTitleEdit(false);
                  }}
                />
              </>
            ) : (
              <div className="relative flex h-full ring-black focus:ring-0">
                <div
                  className="flex h-full  w-full ring-black focus:ring-0 "
                  onClick={() => handleOnClick()}
                >
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setTitleEdit(true);
                    }}
                    className="inline-flex h-[2.2rem] w-min max-w-[19.6rem] cursor-text truncate rounded-md border p-1 pt-[5px] border-transparent ring-black text-gray-900 hover:border-gray-300 focus:ring-0 group-hover:max-w-[17.5rem]"
                  >
                    {title ? (
                      <div className="h-10 overflow-hidden overflow-ellipsis whitespace-nowrap group-hover:max-w-[16.5rem]">
                        <span> {title}</span>
                      </div>
                    ) : (
                      <span className=" text-gray-400">no title</span>
                    )}
                  </div>{" "}
                  {!isPlayed && (
                    <div className="my-auto inline-flex p-[0.22rem] text-[0.65rem]   text-green-500   ">
                      NEW
                    </div>
                  )}
                </div>

                <div className="absolute -right-1 top-0.5 w-[2.4rem] ">
                  <input
                    type="file"
                    id={"file-upload-" + row.original.id}
                    name={"file-upload-" + row.original.id}
                    onChange={(e) => {
                      handleUpload(e);
                    }}
                    className="sr-only"
                  />
                  <TrackMenu
                    openTrack={() => handleOnClick()}
                    track={row.original}
                  >
                    <button className="group -mt-1 ring-0 focus:ring-0">
                      <EllipsisVerticalIcon
                        //   id={"dots-" + project.id}
                        className="z-10  mx-0 h-10 w-7 rounded-md px-0 py-1   text-gray-900/30 ring-0 ring-black text-gray-300  hover:text-gray-900/60 focus:ring-0 group-hover:visible group-aria-[expanded=true]:bg-gray-900/5 group-aria-[expanded=true]:text-gray-900/50 dark:hover:text-white/80 dark:group-aria-[expanded=true]:bg-white/5 dark:group-aria-[expanded=true]:text-zinc-200"
                      />
                    </button>
                  </TrackMenu>

                  {/* <PencilIcon className="h-4 w-4 mb-1 ml-2 text-gray-300 hover:text-gray-400 hidden group-hover:inline" /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="float-right h-full inline-flex">
        <ExternalLinkIcon className="inline-flex my-auto h-5 w-5 group-hover:text-gray-400 ml-2 text-gray-200 mr-2 " />
      
      </div> */}
      </div>
    </div>
  );
}

const ArtistsCell = ({
  value,
  row,
  directLink,
  showArtistSideBar,
  openArtistOverlay,
}) => {
  return (
    <div className="grid h-full w-full whitespace-normal text-sm font-medium ">
      <TrackListArtists
        showArtistSideBar={showArtistSideBar}
        directLink={directLink}
        openArtistOverlay={openArtistOverlay}
        row={row}
      />
    </div>
  );
};

const MobileArtistsCell = ({ value, row }) => {
  return (
    <div className="grid h-full w-full whitespace-normal  text-sm font-medium ">
      <TrackListMobileArtists row={row} />
    </div>
  );
};

const AssignedCell = React.memo(({ value }) => {
  return (
    <>
      <div className="relative my-auto  mx-auto flex h-full w-full items-center py-3 text-transparent hover:text-gray-400 hover:bg-gray-100">
        <div className="mx-auto flex whitespace-nowrap rounded-md  px-2 py-1 text-xs ">
          Assign team
        </div>
      </div>
    </>
  );
});

export { ArtistsCell, MobileArtistsCell, AssignedCell };
