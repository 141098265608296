import React from "react";

const LoaderAnimation = () => {
  return (
    <div className=" w-24 ">
      {/*?xml version="1.0" encoding="UTF-8" standalone="no"?*/}
      {/* Created with Keyshape */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 1000 1000"
        style={{ whiteSpace: "pre" }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n@keyframes a0_do { 0% { stroke-dashoffset: 1080px; animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 58.8235% { stroke-dashoffset: 0px; } 100% { stroke-dashoffset: 0px; } }\n@keyframes a1_do { 0% { stroke-dashoffset: 1080px; } 11.7647% { stroke-dashoffset: 1080px; animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 70.5882% { stroke-dashoffset: 0px; } 100% { stroke-dashoffset: 0px; } }\n@keyframes a2_do { 0% { stroke-dashoffset: 1080px; } 23.5294% { stroke-dashoffset: 1080px; animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 82.3529% { stroke-dashoffset: 0px; } 100% { stroke-dashoffset: 0px; } }\n@keyframes a3_do { 0% { stroke-dashoffset: 1080px; } 41.1765% { stroke-dashoffset: 1080px; animation-timing-function: cubic-bezier(0.42,0,0.58,1); } 100% { stroke-dashoffset: 0px; } }\n    ",
          }}
        />
        <ellipse
          rx={169}
          ry={169}
          stroke="#22c55e"
          fill="none"
          strokeWidth={70}
          strokeMiterlimit={6}
          strokeDasharray={1080}
          strokeDashoffset={0}
          transform="translate(500,500) rotate(270) scale(2.4,2.4)"
        />
        <ellipse
          rx={169}
          ry={169}
          stroke="#ef4444"
          fill="none"
          strokeWidth={70}
          strokeMiterlimit={6}
          strokeDasharray={1080}
          strokeDashoffset={1080}
          transform="translate(500,500) rotate(270) scale(2.4,2.4)"
          style={{ animation: "1.7s linear infinite both a0_do" }}
        />
        <ellipse
          rx={169}
          ry={169}
          stroke="#eab308"
          fill="none"
          strokeWidth={70}
          strokeMiterlimit={6}
          strokeDasharray={1080}
          strokeDashoffset={1080}
          transform="translate(500,500) rotate(270) scale(2.4,2.4)"
          style={{ animation: "1.7s linear infinite both a1_do" }}
        />
        <ellipse
          rx={169}
          ry={169}
          stroke="#0ea5e9"
          fill="none"
          strokeWidth={70}
          strokeMiterlimit={6}
          strokeDasharray={1080}
          strokeDashoffset={1080}
          transform="translate(500,500) rotate(270) scale(2.4,2.4)"
          style={{ animation: "1.7s linear infinite both a2_do" }}
        />
        <ellipse
          rx={169}
          ry={169}
          stroke="#22c55e"
          fill="none"
          strokeWidth={70}
          strokeMiterlimit={6}
          strokeDasharray={1080}
          strokeDashoffset={1080}
          transform="translate(500,500) rotate(270) scale(2.4,2.4)"
          style={{ animation: "1.7s linear infinite both a3_do" }}
        />
      </svg>
    </div>
  );
};

export default LoaderAnimation;
