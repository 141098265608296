import { useContext } from "react";
import UploaderContext from "../../context/UploaderProvider";

const useUploader = () => {
  const { context } = useContext(UploaderContext);

  return useContext(UploaderContext);
};

export default useUploader;
