import React, { Fragment, useState, Suspense } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

import NavBarNotificationsBar from "./NavBarNotificationsBar";
import secureLocalStorage from "react-secure-storage";
import {
  UsersIcon,
  MagnifyingGlassIcon,
  BellIcon,
  Bars3Icon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  XMarkIcon,
  WalletIcon,
  ArrowsRightLeftIcon,
  ClipboardDocumentCheckIcon,
  PuzzlePieceIcon,
  BuildingLibraryIcon,
  QueueListIcon,
  UserGroupIcon,
  AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";

import {
  UsersIcon as UsersIconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  BellIcon as BellIconSolid,
  Bars3Icon as Bars3IconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  ArrowLeftOnRectangleIcon as ArrowLeftOnRectangleIconSolid,
  XMarkIcon as XMarkIconSolid,
  WalletIcon as WalletIconSolid,
  ArrowsRightLeftIcon as ArrowsRightLeftIconSolid,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
  PuzzlePieceIcon as PuzzlePieceIconSolid,
  QueueListIcon as QueueListIconSolid,
  UserGroupIcon as UserGroupIconSolid,
} from "@heroicons/react/24/solid";

import useLogout from "../hooks/useLogout";

import logoicon from "../../img/trackflow-logo.svg";

import DarkModeSwitch from "../DarkModeSwitch";
import CommandPalette from "../CommandPalette";

import NavBarNotifictionsButton from "./NavBarNotifictionsButton";
import NavBarFeedBack from "./NavBarFeedBack";
import useAuth from "../hooks/useAuth";
import NavBarQuickStartButton from "./NavBarQuickStart/NavBarQuickStartButton";
import NavBarCanny from "./NavBarCanny/NavBarCanny";

import AdminOverlay from "../Admin/AdminOverlay";
import Avatar from "../Avatar";
import TopNavBar from "./TopNavBar";
import useSettings from "../hooks/useSettings";
import MyMemo from "../MyMemo";
import { useAuth0 } from "@auth0/auth0-react";

const sidebarNavigation = [
  {
    name: "Projects",
    href: "/projects",
    icon: WalletIcon,
    solidIcon: WalletIconSolid,
    current: true,
  },
  {
    name: "Playlists",
    href: "/playlists",
    icon: QueueListIcon,
    solidIcon: QueueListIconSolid,
    current: false,
    roles: ["admin"],
  },

  {
    name: "Artists",
    href: "/artists",
    icon: UsersIcon,
    solidIcon: UsersIconSolid,
    current: false,
    roles: ["admin"],
  },
  // { name: "Shared", href: "/shared", icon: ShareIcon, current: false },
  {
    name: "Tasks",
    href: "/tasks",
    icon: ClipboardDocumentCheckIcon,
    solidIcon: ClipboardDocumentCheckIconSolid,
    current: false,
  },
  {
    name: "---",
    href: "#",
    icon: UsersIcon,
    solidIcon: UsersIconSolid,
    current: false,
  },

  // {
  //   name: "Extensions",
  //   href: "/extensions",
  //   icon: PuzzlePieceIcon,
  //   solidIcon: PuzzlePieceIconSolid,
  //   current: false,
  //   roles: ["admin"],
  // },
  {
    name: "Team",
    href: "/team",
    icon: UserGroupIcon,
    solidIcon: UserGroupIconSolid,
    current: false,
    roles: ["admin"],
  },
  {
    name: "Settings",
    href: "/settings",
    icon: Cog6ToothIcon,
    solidIcon: Cog6ToothIconSolid,
    current: false,
    roles: ["admin"],
  },
  // {
  //   name: "Search",
  //   href: "#",
  //   icon: MagnifyingGlassIcon,
  //   solidIcon: MagnifyingGlassIconSolid,
  //   current: false,
  //   // roles: ["admin"],
  // },
];
const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Sign out", href: "/signout" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function NavBar() {
  console.log("render NavBar");
  const navigate = useNavigate();
  const { auth } = useAuth();

  const userRoles = auth.roles;

  let homeLink = "/projects";
  // if (auth.roles?.includes("admin")) {
  //   if (workspaceSettings?.companyType === "label") {
  //     homeLink = "/projects/groups";
  //   }
  // }

  const logout = useLogout();

  const handleLogOut = async () => {
    await logout();
    navigate("/login");
  };

  const [open, setOpen] = useState(false);

  const [notificationsBarOpen, setNotificationsBarOpen] = useState(false);

  const [notificationsNotSeenByMe, setNotificationsNotSeenByMe] =
    React.useState("");

  const toggleNotificationsBarOpen = () => {
    setNotificationsBarOpen(!notificationsBarOpen);
  };

  const [adminOverlayOpen, setAdminOverlayOpen] = useState(false);

  return (
    <>
      <div className=" absolute  z-[60]">
        <NavBarNotificationsBar
          open={notificationsBarOpen}
          setOpen={setNotificationsBarOpen}
          setNotificationsNotSeenByMe={setNotificationsNotSeenByMe}
        />
      </div>

      <div className="block md:hidden">
        <TopNavBar userRoles={userRoles} />
      </div>
      <div className="left-0  top-0 z-[100] flex flex-col  md:absolute md:h-full ">
        {/* Bottom section */}
        <div className=" flex h-full min-h-0 flex-1">
          {/* Narrow sidebar*/}
          <nav
            aria-label="Sidebar"
            className="fixed top-0 z-[100]  hidden h-full w-[4rem] flex-col content-between overflow-visible border-b  shadow-sm shadow-zinc-500  transition-all border-gray-300  md:flex md:bg-zinc-800 dark:md:bg-zinc-900"
          >
            <div className="flex-1  ">
              <div className=" cursor-pointer pt-0.5">
                <div onClick={() => navigate(homeLink)} className="flex">
                  <img
                    className="ml-[1.2rem]  mt-[1.2rem] block h-[1.8rem]   w-auto"
                    src={logoicon}
                    alt="Trackflow"
                  />
                </div>
              </div>
              <div className=" mt-1  flex w-20 flex-col space-y-[0.3rem] p-3 ">
                {sidebarNavigation.map((item) => {
                  if (item.name === "---") {
                    return (
                      <div key={item.name}>
                        <div className="w-[2.5rem]  border-b-2 pt-2 border-zinc-600 dark:border-backgrounddark" />
                        <div className="pb-2" />
                      </div>
                    );
                  }
                  if (item.roles) {
                    if (!userRoles?.some((role) => item.roles.includes(role))) {
                      return null;
                    }
                  }
                  return (
                    <div key={item.name}>
                      {item.name === "---" ? (
                        <>
                          <div className="-ml-4 w-[2rem] border-b pt-1" />
                          <div className="pb-1" />
                        </>
                      ) : item.name === "Search" ? (
                        <div
                          onClick={() => setOpen(true)}
                          className={classNames(
                            window.location.pathname.startsWith(item.href)
                              ? " text-gray-600"
                              : "text-gray-600 ",
                            "justify-left group z-50 inline-flex flex-shrink-0 cursor-pointer items-center  rounded-lg"
                          )}
                        >
                          <span className="sr-only">{item.name}</span>
                          <div
                            className={classNames(
                              window.location.pathname.startsWith(item.href)
                                ? "text-zinc-200 bg-zinc-700 dark:text-zinc-300 dark:bg-zinc-800"
                                : "text-zinc-400 group-hover:bg-zinc-700 dark:group-hover:bg-zinc-800",
                              " flex h-10 w-10 rounded-md"
                            )}
                          >
                            <item.icon
                              className="my-auto ml-2 h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => navigate(item.href)}
                          className={classNames(
                            window.location.pathname.startsWith(item.href)
                              ? "text-gray-600"
                              : "text-gray-600 ",
                            "nav-" + item.name,
                            "justify-left group z-50 inline-flex flex-shrink-0 cursor-pointer items-center  rounded-lg"
                          )}
                          id={"nav-" + item.name}
                        >
                          <span className="sr-only">{item.name}</span>
                          <div
                            className={classNames(
                              window.location.pathname.startsWith(item.href)
                                ? "text-zinc-300 bg-zinc-700 dark:text-zinc-300 dark:bg-zinc-800"
                                : "text-zinc-400 group-hover:bg-zinc-700 dark:group-hover:bg-zinc-800",
                              " flex h-10 w-10 rounded-md"
                            )}
                          >
                            {window.location.pathname.startsWith(item.href) ? (
                              <item.solidIcon
                                className="my-auto ml-2 h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <item.icon
                                className="my-auto ml-2 h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div
                            className={classNames(
                              window.location.pathname.startsWith(item.href)
                                ? "ml-[0.11rem] inline-block"
                                : "ml-1  opacity-0",
                              " w-3 -rotate-90   overflow-hidden transition-all duration-100 group-hover:ml-[0.11rem] group-hover:opacity-100"
                            )}
                          >
                            {/* NAV CHIP START */}
                            <div
                              id="navchip"
                              className={classNames(
                                notificationsBarOpen
                                  ? "bg-white"
                                  : "bg-background dark:bg-backgrounddark",
                                "navshadow mb-[0.1rem] inline-block    group-hover:bg-gray-200   ",
                                " h-2 w-2 origin-bottom-left rotate-45 transform "
                              )}
                            />
                            {/* NAV CHIP END */}
                          </div>
                          <div className="ml-[-0.1rem] hidden  rounded-r-md p-2 text-xs font-medium shadow text-gray-600 bg-gray-200 group-hover:inline dark:text-gray-300 ">
                            {item.name}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="mb-3  flex w-full flex-col">
              {/* <NavBarQuickStartButton /> */}
              {/* <NavBarFeedBack /> */}

              {auth?.email === "netskymusic@me.com" && (
                <div className=" my-auto flex  h-10 w-full cursor-pointer">
                  <AdminOverlay
                    open={adminOverlayOpen}
                    setOpen={setAdminOverlayOpen}
                  />
                  <div
                    className={classNames(
                      "mx-auto flex h-10 w-10 rounded-md text-zinc-400 hover:bg-zinc-700 dark:hover:bg-zinc-800 "
                    )}
                    onClick={() => {
                      setAdminOverlayOpen(true);
                    }}
                  >
                    <BuildingLibraryIcon
                      className="my-auto ml-2 h-6 w-6"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              )}
              {auth?.email === "netskymusic@me.com" && (
                <div className="my-auto mt-2 flex  h-10 w-full cursor-pointer">
                  <div
                    className={classNames(
                      "mx-auto flex h-10 w-10 rounded-md text-zinc-400 hover:bg-zinc-700 dark:hover:bg-zinc-800 "
                    )}
                    onClick={() => {
                      navigate("/test");
                    }}
                  >
                    <AdjustmentsVerticalIcon className="my-auto ml-2 h-6 w-6" />
                  </div>
                </div>
              )}
              {auth.roles?.includes("admin") && (
                <div className="-mb-1">
                  <NavBarNotifictionsButton
                    toggleNotificationsBarOpen={toggleNotificationsBarOpen}
                    notificationsBarOpen={notificationsBarOpen}
                    notificationsNotSeenByMe={notificationsNotSeenByMe}
                  />
                </div>
              )}
              <DarkModeSwitch />
            </div>
            <div className="mx-auto w-full border-b-2 border-zinc-600  dark:border-backgrounddark" />

            <div className="border-background-dark group block h-[68px] flex-shrink-0 overflow-hidden transition-all   border-zinc-600 hover:h-[114px] hover:bg-gray-50 dark:border-backgrounddark dark:border-backgrounddark">
              <div className=" flex h-full  flex-col items-center transition-all">
                <div
                  className="mt-1 flex py-2"
                  onClick={() => navigate("/profile")}
                >
                  <div className="mx-auto my-auto h-8 w-8 cursor-pointer rounded-full border border-transparent bg-gray-200 hover:brightness-110 hover:border-gray-300 dark:bg-zinc-700">
                    <Avatar size="30" email={auth?.email} />
                  </div>
                </div>

                <div
                  onClick={handleLogOut}
                  className=" hidden h-12 w-12 cursor-pointer rounded-md text-xs font-medium text-gray-500 bg-gray-100 hover:bg-white group-hover:flex "
                >
                  <ArrowLeftOnRectangleIcon className="my-auto mx-auto h-6 w-6 text-gray-600" />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default MyMemo(NavBar, []); // <-- here
