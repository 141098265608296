import React from "react";
import IsLoaded from "../IsLoaded";

const LocalStorage = () => {
  window.addEventListener(
    "message",
    function (e) {
      console.log("received localstorage", e);

      if (e.origin === "https://p2.trackflow.studio") {
        localStorage.setItem("p-user", JSON.stringify(e.data));
      }
    },
    false
  );
  return (
    <div>
      <IsLoaded />
    </div>
  );
};

export default LocalStorage;
