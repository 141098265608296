import React from "react";

const ArtistContactTypeInput = ({ edit, type, handleUpdate }) => {
  return (
    <div>
      {edit ? (
        <select className="bg-gray-200 dark:bg-backgrounddark ml-2 p-1 w-24 rounded-md text-tiny border-0 focus:ring-0">
          <option value="primary" className="text-xs">
            Artist
          </option>
          <option value="secondary">Management</option>
          <option value="other">Label</option>
        </select>
      ) : (
        <div className="text-xs">{type}</div>
      )}
    </div>
  );
};

export default ArtistContactTypeInput;
