import React from "react";

import { classNames } from "./shared/Utils";

import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import useAllUsers from "./hooks/useAllUsers";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import Avatar from "./Avatar";
import { Popover } from "@headlessui/react";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",

  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUpAndFade },
  '&[data-side="bottom"]': { animationName: slideDownAndFade },
  '&[data-side="left"]': { animationName: slideLeftAndFade },
  '&[data-side="right"]': { animationName: slideRightAndFade },
});

const UserPicker = ({ setExternalOpen, selectedUsers, setSelectedUsers }) => {
  console.log("selectedUsers", selectedUsers);

  const [users, setUsers] = React.useState([]);
  //   const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const allUsersQuery = useAllUsers();

  console.log("selectedUsers allUsers", users);
  const email = "netskymusic@me.com";

  React.useEffect(() => {
    if (allUsersQuery.data) {
      setUsers(allUsersQuery.data);
    }
  }, [allUsersQuery.data]);

  return (
    <div className="group relative my-auto mx-auto  flex h-7 ">
      <PopoverPrimitive.Root
        onOpenChange={(open) => {
          setOpen(open);
          setExternalOpen(open);
        }}
        open={open}
      >
        <PopoverPrimitive.Trigger>
          <div className="group h-7">
            {selectedUsers?.length === 0 || !selectedUsers ? (
              <div
                className={classNames(
                  open
                    ? "text-gray-500 hover:text-gray-600"
                    : "text-gray-400 hover:text-gray-500",
                  "  flex h-7 w-7 rounded-full p-1.5 ring-2 ring-white  bg-gray-100 hover:bg-gray-200"
                )}
                onClick={() => setOpen(open)}
              >
                <UserPlusIcon />
              </div>
            ) : (
              <div className="flex flex-row ">
                <div className="isolate flex -space-x-1 ">
                  {selectedUsers?.sort().map((email) => (
                    <div key={"selected-users-" + email}>
                      <Avatar size="28" email={email} />
                    </div>
                  ))}
                  <div
                    className={classNames(
                      open
                        ? "text-gray-500 hover:text-gray-600"
                        : "text-gray-400 hover:text-gray-500",
                      " h-7 w-7   rounded-full p-1.5 ring-2 ring-white bg-gray-100 hover:bg-gray-200  "
                    )}
                    onClick={() => setOpen(open)}
                  >
                    <UserPlusIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        </PopoverPrimitive.Trigger>

        <StyledContent>
          <>
            <div
              className={classNames(
                "  z-50 flex  cursor-pointer flex-col divide-y  overflow-hidden rounded-md   border  shadow-lg border-gray-300   divide-gray-200 "
              )}
            >
              {users?.map((user) => (
                <div
                  key={user.email}
                  className={classNames(
                    selectedUsers?.includes(user?.email)
                      ? "bg-gray-100 hover:bg-gray-200"
                      : "bg-white hover:bg-gray-50",
                    " py-2 px-4 "
                  )}
                  onClick={() => {
                    if (selectedUsers?.includes(user?.email)) {
                      setSelectedUsers(
                        selectedUsers?.filter((u) => u !== user?.email)
                      );
                    } else {
                      if (selectedUsers?.length > 0) {
                        setSelectedUsers([...selectedUsers, user?.email]);
                      } else {
                        setSelectedUsers([user?.email]);
                      }
                    }
                  }}
                >
                  <div className="flex flex-row">
                    {user.avatar ? (
                      <img
                        className={classNames(
                          selectedUsers?.includes(user?.email)
                            ? "border-white"
                            : "border-transparent",
                          "inline-block h-8 w-8 rounded-full border-2"
                        )}
                        src={user.avatar}
                        alt=""
                      />
                    ) : (
                      <div
                        className="flex h-8 w-8 rounded-full bg-gray-200"
                        alt=""
                      >
                        <div className="my-auto mx-auto cursor-default select-none text-xs font-medium text-gray-400">
                          {user.name
                            .match(/(\b\S)?/g)
                            .join("")
                            .match(/(^\S|\S$)?/g)
                            .join("")
                            .toUpperCase()}
                        </div>
                      </div>
                    )}
                    <div
                      className={classNames(
                        selectedUsers?.includes(user?.email)
                          ? "font-medium"
                          : "",
                        "my-auto select-none px-2 text-sm text-gray-700 "
                      )}
                    >
                      {user.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <PopoverPrimitive.Arrow className=" fill-gray-300 dark:fill-zinc-600 " />
          </>
        </StyledContent>
      </PopoverPrimitive.Root>
    </div>
  );
};

export default UserPicker;
