import { HashtagIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactDOM from "react-dom";

import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { classNames, getStatusIcon } from "./shared/Utils";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",

  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUpAndFade },
  '&[data-side="bottom"]': { animationName: slideDownAndFade },
  '&[data-side="left"]': { animationName: slideLeftAndFade },
  '&[data-side="right"]': { animationName: slideRightAndFade },
});

import {
  AcademicCapIcon,
  ArrowRightCircleIcon,
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
  RocketLaunchIcon,
  ArrowPathIcon,
  BeakerIcon,
  BellIcon,
  BoltIcon,
  BookOpenIcon,
  ChartBarIcon,
  CameraIcon,
  ChartPieIcon,
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleLeftIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyDollarIcon,
  FingerPrintIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  LockClosedIcon,
  LockOpenIcon,
  PhoneIcon,
  TvIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import TrackHistoryMessage from "./Track/TrackHistoryMessage";

const availableIcons = [
  { name: "AcademicCapIcon", icon: AcademicCapIcon },
  { name: "ArrowRightCircleIcon", icon: ArrowRightCircleIcon },
  { name: "PencilIcon", icon: PencilIcon },
  { name: "AdjustmentsVerticalIcon", icon: AdjustmentsVerticalIcon },
  { name: "StarIcon", icon: StarIcon },
  { name: "MicrophoneIcon", icon: MicrophoneIcon },
  { name: "RocketLaunchIcon", icon: RocketLaunchIcon },
  { name: "ArrowPathIcon", icon: ArrowPathIcon },
  { name: "BeakerIcon", icon: BeakerIcon },
  { name: "BellIcon", icon: BellIcon },
  { name: "BoltIcon", icon: BoltIcon },
  { name: "BookOpenIcon", icon: BookOpenIcon },
  { name: "ChartBarIcon", icon: ChartBarIcon },
  { name: "CameraIcon", icon: CameraIcon },
  { name: "ChartPieIcon", icon: ChartPieIcon },
  { name: "CalendarDaysIcon", icon: CalendarDaysIcon },
  { name: "ChatBubbleLeftRightIcon", icon: ChatBubbleLeftRightIcon },
  { name: "ChatBubbleLeftIcon", icon: ChatBubbleLeftIcon },
  { name: "CloudArrowDownIcon", icon: CloudArrowDownIcon },
  { name: "CloudArrowUpIcon", icon: CloudArrowUpIcon },
  { name: "CurrencyEuroIcon", icon: CurrencyEuroIcon },
  { name: "CurrencyPoundIcon", icon: CurrencyPoundIcon },
  { name: "CurrencyDollarIcon", icon: CurrencyDollarIcon },

  { name: "FingerPrintIcon", icon: FingerPrintIcon },
  { name: "HandThumbDownIcon", icon: HandThumbDownIcon },
  { name: "HandThumbUpIcon", icon: HandThumbUpIcon },
  { name: "HeartIcon", icon: HeartIcon },
  { name: "LockClosedIcon", icon: LockClosedIcon },
  { name: "LockOpenIcon", icon: LockOpenIcon },
  { name: "PhoneIcon", icon: PhoneIcon },

  { name: "TvIcon", icon: TvIcon },
  { name: "XMarkIcon", icon: XMarkIcon },
];

class IconPicker extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    open: false,
  };

  handleClick = (i) => {
    this.props.setIcon({
      iconName: availableIcons[i].name,
      id: this.props.id,
    });

    this.setState({ open: false });
  };
  render() {
    return (
      <div className="group relative my-auto mx-auto">
        <PopoverPrimitive.Root
          onOpenChange={(open) => this.setState({ open: open })}
          open={this.state.open}
        >
          <PopoverPrimitive.Trigger>
            <div
              className=" border-gray-200 flex h-8 w-8    cursor-pointer rounded-md border hover:border-gray-350  hover:brightness-110"
              style={{
                backgroundColor: this.props.hex,
              }}
              onClick={() => this.setState({ open: true })}
            >
              <div className="my-auto mx-auto h-5 w-5">
                {getStatusIcon(this.props.iconName)}
              </div>
            </div>
          </PopoverPrimitive.Trigger>
          <StyledContent>
            <div
              className={classNames(
                // this.state.open ? "grid" : "hidden",
                "bg-gray-100 border-gray-300   z-50 grid  h-[12rem] w-[24rem] cursor-pointer grid-cols-8 grid-rows-4  rounded-md border p-2 shadow-lg "
              )}
            >
              {availableIcons.map((icon, i) => (
                <div
                  className={classNames(
                    icon == this.props.icon
                      ? "border-2 opacity-100"
                      : "opacity-100",
                    ""
                  )}
                  key={i}
                >
                  <div
                    className={classNames(
                      "text-gray-500 m-2 my-auto h-9 w-9  flex-shrink-0 rounded-md  border p-1 border-transparent  hover:border-gray-400 hover:opacity-100"
                    )}
                    id={i}
                    onClick={(e) => this.handleClick(i)}
                  >
                    <icon.icon />
                  </div>
                </div>
              ))}
            </div>
            <PopoverPrimitive.Arrow className=" fill-white dark:fill-zinc-600 " />
          </StyledContent>
        </PopoverPrimitive.Root>
      </div>
    );
  }
}

export default IconPicker;
