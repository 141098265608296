import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";

export default function useOnboarding() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(({ workspace, token }) => {
    console.log("useMutation", workspace, token);
    axiosPrivate
      .post(serverUrl + "/onboarding/" + token, workspace)
      .then((res) => res.data),
      {
        onSuccess: (data, variables) => {
          queryClient.invalidateQueries(['allworkspaces']);
        },
        return(data, variables) {
          return data;
        },
      };
  });
}
