import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import React, { useEffect } from "react";

import { classNames } from "../../shared/Utils";

import ColorPicker from "../../ColorPicker";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
export default (props) => {
  console.log("headerprops", props);
  const docJSON = props.editor.getJSON();

  const setColor = (color) => {
    props.updateAttributes({
      color: color.color,
    });
  };

  // count the number of headers
  const headerCount = docJSON.content.reduce((acc, curr) => {
    if (curr.type === "taskHeader") {
      return acc + 1;
    }
    return acc;
  }, 0);

  // count the number of tasks from this header to the next header
  const taskCount = docJSON.content.reduce((acc, curr) => {
    if (curr.type === "taskHeader") {
      return 0;
    }
    if (curr.type === "taskList") {
      let taskCount = 0;
      curr.content.forEach((task) => {
        if (task.type === "taskItem") {
          taskCount++;
          // check if any of the content is a taskList
          task.content.forEach((taskContent) => {
            if (taskContent.type === "taskList") {
              taskContent.content.forEach((taskContent2) => {
                if (taskContent2.type === "taskItem") {
                  taskCount++;
                }
              });
            }
          });
        }
      });
      return acc + taskCount;
    }
    return acc;
  }, 0);

  const completedTaskCount = docJSON.content.reduce((acc, curr) => {
    if (curr.type === "taskHeader") {
      return 0;
    }
    if (curr.type === "taskList") {
      let taskCount = 0;
      curr.content.forEach((task) => {
        if (task.type === "taskItem") {
          if (task.attrs.checked) {
            taskCount++;
          }
          // check if any of the content is a taskList
          task.content.forEach((taskContent) => {
            if (taskContent.type === "taskList") {
              taskContent.content.forEach((taskContent2) => {
                if (taskContent2.type === "taskItem") {
                  if (taskContent2.attrs.checked) {
                    taskCount++;
                  }
                }
              });
            }
          });
        }
      });
      return acc + taskCount;
    }
    return acc;
  }, 0);

  console.log("headercount", headerCount);
  console.log("headerTaskcount", taskCount);

  //

  const [open, setOpen] = React.useState(false);

  const color = props.node.attrs.color;

  const percentage = Math.round((completedTaskCount / taskCount) * 100);

  return (
    <NodeViewWrapper className="react-component">
      <div
        style={{
          color: color,
        }}
        className="sticky top-[20rem] -ml-6 -mr-6  flex h-14 self-start bg-gray-50"
      >
        <div className=" my-auto ml-[2.3rem] rounded-full  ">
          <CircularProgressbar
            value={percentage}
            animated={true}
            background={true}
            className={classNames(
              percentage == 100
                ? "opacity-100"
                : "opacity-100 dark:opacity-100",
              " h-6 w-6 transition-all"
            )}
            strokeWidth={50}
            // text={`${loadingPercentage}%`}
            styles={buildStyles({
              rotation: 0,

              strokeLinecap: "butt",

              pathTransitionDuration: 0,
              background: false,
              pathColor: color,

              trailColor: `#838383`,
              backgroundColor: "#cccccc",
            })}
          ></CircularProgressbar>
        </div>
        <NodeViewContent
          style={{
            color: color,
          }}
          className="relative my-auto ml-4 text-xl font-medium"
        >
          {props.node.content.size === 0 && (
            <div className="absolute top-0 select-none text-gray-300 ">
              Category
            </div>
          )}
        </NodeViewContent>
        <div contentEditable={false} className="my-auto ml-auto mr-6">
          <div className=" px-2 py-1">
            <ColorPicker
              setOpen={setOpen}
              open={open}
              setColor={setColor}
              color={props.node.attrs.color || "#ccc"}
              contentEditable={false}
            />
          </div>
          {/* <div
            className="drag-handle"
            contenteditable="false"
            draggable="true"
            data-drag-handle
          /> */}
        </div>
      </div>
    </NodeViewWrapper>
  );
};
