import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";

export default function useAllPlaylists() {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(["all-playlists"], () =>
    axiosPrivate.get(serverUrl + "/playlists").then((res) => res.data)
  );
}
