import React from "react";

import Div100vh from "react-div-100vh";

import LargePlayer from "../components/LargePlayer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ArrowDownOnSquareIcon, XCircleIcon } from "@heroicons/react/24/solid";
import fileDownload from "js-file-download";
import { LightenDarkenColor } from "lighten-darken-color";
import SafeArea from "react-safe-area-component";

import { ColorExtractor } from "react-color-extractor";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import IsLoaded from "../IsLoaded";
const storage = getStorage();

import LoaderAnimation from "../components/LoaderAnimation";

import usePlay from "../components/hooks/usePlay";
import useAddPlayVisit from "../components/hooks/useAddPlayVisit";

import useGeoLocation from "react-ipgeolocation";

import logo from "../img/trackflow-logo-with-text-black.svg";
import { useMediaQuery } from "react-responsive";
import GetSpotifyAvatars from "../components/GetSpotifyAvatars";
import { fakeTracks } from "../components/ListeningRoom/FakeTracks";
import { classNames } from "../components/shared/Utils";

function handleDownload(url, filename) {
  axios
    .get(url, {
      headers: {
        "Content-type": "Content-Type: audio/mpeg",
      },
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

const Play = () => {
  const { code } = useParams();
  const playQuery = usePlay({ code });
  const addPlaylistVisitQuery = useAddPlayVisit({ code });
  const visitorLocation = useGeoLocation();

  const [tracks, setTracks] = useState(fakeTracks);
  const [error, setError] = useState("");

  const [images, setImages] = useState(null);
  const [colors, setColors] = useState([]);

  const [trackVisitRecorded, setTrackVisitRecorded] = useState(false);

  GetSpotifyAvatars({ artists: tracks[0].artists, setImages });

  useEffect(() => {
    if (visitorLocation.country && !trackVisitRecorded) {
      setTrackVisitRecorded(true);
      addPlaylistVisitQuery.mutate({ country_code: visitorLocation.country });
    }
  }, [visitorLocation]);

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  if (error) {
    return (
      <>
        <div className="flex h-screen w-screen items-center justify-center">
          <div className="rounded-md p-4 bg-red-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  There was an error retrieving this playlist
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    <li>{error}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IsLoaded />
      </>
    );
  }

  var LightenColor = function (color, percent) {
    var num = parseInt(color.replace("", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1);
  };

  const [mainColor, setMainColor] = useState(null);
  const [mainColorLight, setMainColorLight] = useState(null);
  const [textColor, setTextColor] = useState(null);
  useEffect(() => {
    if (colors.length > 0) {
      setMainColor(LightenDarkenColor(colors[2], 0));
      setMainColorLight(LightenDarkenColor(colors[2], 30));
      setTextColor(LightenDarkenColor(colors[4], 120));
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute(
          "content",
          isMobile
            ? LightenDarkenColor(colors[2], 0)
            : LightenDarkenColor(colors[2], 30)
        );
    }
  }, [colors]);

  return (
    <>
      <SafeArea top />
      {tracks[0].previewurl ? (
        <>
          <div
            style={{
              backgroundColor: isMobile ? mainColor : mainColorLight,
            }}
            className=" relative flex h-full w-full flex-col items-center justify-between     md:p-12 "
          >
            <div className="relative flex h-full w-full flex-row overflow-hidden  md:rounded-t-xl">
              {/* <div
                style={{
                  backgroundColor: isMobile ? colors[0] : colors[0],
                }}
                className="absolute top-0 h-20 w-full opacity-50 bg-green-500 "
              /> */}
              {images?.map((image, index) => (
                <div
                  key={index}
                  className={classNames(
                    index === 0
                      ? "h-full w-full opacity-80 bg-blend-screen contrast-125"
                      : "h-full w-full opacity-80 bg-blend-screen contrast-125"
                  )}
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    //   filter: "blur(10px)",
                  }}
                />
              ))}
              <div
                className="absolute bottom-0 h-32 w-full "
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 1%, ${mainColor} 100%)`,
                }}
              />
            </div>
            <div
              className="flex h-fit w-full flex-shrink-0 flex-col justify-center overflow-hidden md:rounded-b-xl  "
              style={{
                backgroundColor: mainColor,
              }}
            >
              <div className="w-full px-3 pt-10">
                <LargePlayer
                  buttonColor={textColor}
                  cursorColor={textColor}
                  progressColor={textColor}
                  url={tracks[0].previewurl}
                  height={90}
                />
              </div>
              <div className="flex flex-col pt-6 pb-12">
                <div
                  className="px-6 py-2 text-4xl font-medium "
                  style={{
                    color: textColor,
                  }}
                >
                  {tracks[0].title}
                </div>
                <div
                  className="px-6 py-2 text-xl font-medium "
                  style={{
                    color: textColor,
                  }}
                >
                  {tracks[0].artists.map((artist) => artist).join(", ")}
                </div>
              </div>
            </div>
            {images?.length > 0 && (
              <ColorExtractor
                src={images[0]}
                getColors={(colors) => setColors(colors)}
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex h-screen w-screen">
          <div className="my-auto mx-auto ">
            <LoaderAnimation />
          </div>
        </div>
      )}
      <IsLoaded />
      <SafeArea bottom />
    </>
  );
};

export default Play;
