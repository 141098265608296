import React, { useEffect } from "react";
import { CirclePicker } from "react-color";
import logoicon from "../../../img/trackflow-logo.svg";
import DotsLoader from "../../DotsLoader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { app, storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { nanoid } from "nanoid";
import { classNames } from "../../shared/Utils";

const OnboardingStep1 = ({ handleNextStep, current, percentage, formik }) => {
  const [buttonText, setButtonText] = React.useState("next");
  const [progresspercent, setProgresspercent] = React.useState(null);
  const [logoURL, setLogoURL] = React.useState("");

  const workspaceLogoRef = React.createRef();

  const [selectedColor, setSelectedColor] = React.useState("#22c55e");

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleSubmit = (e) => {
    if (current) {
      e.preventDefault();
      console.log("submitted");
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        handleNextStep();
      }, 100);
    }
  };

  const handleSelectColor = (e) => {
    formik.setFieldValue("workspaceColor", e.hex);
    setSelectedColor(e.hex);
  };

  const handleUploadLogo = (e) => {
    // e.preventDefault();
    console.log("e", e);
    const file = e.target?.files[0];
    console.log("file", file);

    if (!file) return;

    const extention = file.name.split(".").pop();

    const storageRef = ref(
      storage,
      `workspaces/logos/${nanoid()}.${extention}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          formik.setFieldValue("workspaceLogo", downloadURL);
          setLogoURL(downloadURL);
          setButtonText("next");
        });
      }
    );
  };

  return (
    <div className=" flex w-full flex-row gap-12">
      <div className="hidden w-20 flex-shrink-0 md:block ">
        <CircularProgressbar
          value={percentage}
          className={" h-20 w-20 transition-all"}
          strokeWidth={18}
          // text={`${loadingPercentage}%`}
          styles={buildStyles({
            rotation: 0,

            strokeLinecap: "butt",

            pathTransitionDuration: 1,
            pathColor: "#22c55e",
            textColor: "#d1d5db",
            trailColor: `#3f3f46`,
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className=" w-full ">
          {" "}
          <div className="block pb-2 text-2xl text-gray-800 sm:text-3xl ">
            Customize your <span className="font-medium">workspace</span>'s{" "}
            <span className="font-medium">avatar</span>
          </div>
          <div className="mt-12 mb-6  flex w-full flex-wrap gap-12 md:flex-row">
            <div
              style={{
                backgroundImage: `url(${logoURL})`,
                backgroundSize: "cover",
              }}
              className={classNames(
                logoURL ? "flex h-32 items-center" : "",
                "flex max-w-lg cursor-pointer justify-center rounded-md border-2 border-dashed px-6 pt-5 pb-6 transition-all border-gray-300 hover:bg-gray-50 "
              )}
              onClick={() => workspaceLogoRef.current.click()}
            >
              {" "}
              <div className="flex w-full flex-col space-y-1 text-center">
                {!logoURL && (
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <div className="flex text-sm text-gray-600">
                  {progresspercent != null ? (
                    <div>
                      <div
                        className={classNames(
                          progresspercent === 100 ? "" : "h-2.5",
                          "mt-2 w-32  rounded-full bg-gray-200 dark:bg-gray-700"
                        )}
                      >
                        <div
                          className={classNames(
                            progresspercent === 100 ? "p-1" : "h-2.5",
                            "rounded-full font-medium transition-all text-white  bg-green-500 dark:text-white"
                          )}
                          style={{
                            width: `${progresspercent}%`,
                          }}
                        >
                          {progresspercent === 100 && "Done!"}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" relative cursor-pointer rounded-md font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500 hover:text-green-500">
                      <span>Upload your brand's logo</span>
                    </div>
                  )}
                  <input
                    ref={workspaceLogoRef}
                    autoFocus={false}
                    id="workspaceLogo"
                    name="workspaceLogo"
                    type="file"
                    className="sr-only"
                    onChange={(e) => handleUploadLogo(e)}
                    accept="image/png, image/jpeg, image/gif, image/svg"
                  />
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                {/* <p className="text-gray-500 text-xs">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>

            <div
              className={classNames(
                logoURL ? "opacity-0" : "opacity-100",
                "flex flex-col flex-wrap gap-12 transition-all md:flex-row"
              )}
            >
              <div className="relative hidden flex-col justify-center md:flex">
                <div className="ml-2  h-8 w-[2px] bg-gray-300  " />
                <div className=" z-10 my-auto py-2 text-lg text-gray-600">
                  or
                </div>{" "}
                <div className="ml-2  h-8 w-[2px] bg-gray-300  " />
              </div>
              <div className="relative flex flex-row  justify-start md:hidden">
                <div className="my-auto ml-2 mr-4 h-[2px] w-20 bg-gray-300  " />
                <div className=" z-10 my-auto text-lg text-gray-600">
                  or
                </div>{" "}
                <div className="my-auto ml-4 h-[2px] w-20 bg-gray-300  " />
              </div>
              <div className="flex flex-row gap-12">
                <div className="flex flex-row">
                  <div
                    style={{
                      backgroundColor: selectedColor,
                    }}
                    className="my-auto flex h-32 w-32 items-center rounded-md text-center text-5xl font-medium transition-all text-white bg-green-500"
                  >
                    <div className="my-auto mx-auto">
                      {formik.values.workspaceName &&
                        formik.values.workspaceName
                          .match(/(\b\S)?/g)
                          .join("")
                          .match(/(^\S|\S$)?/g)
                          .join("")
                          .toUpperCase()}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-auto">
                    <CirclePicker
                      onChange={(e) => handleSelectColor(e)}
                      colors={[
                        "#22c55e",
                        "#27272a",
                        "#f9fafb",
                        "#ef4444",
                        "#f97316",
                        "#eab308",
                        "#14b8a6",
                        "#6366f1",
                        "#8b5cf6",
                        "#0ea5e9",
                        "#ec4899",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            onClick={handleSubmit}
            className="inline-flex h-14  items-center rounded-md  px-5 py-3 text-xl  font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
          >
            {buttonText == "next" ? (
              <div>Next</div>
            ) : buttonText == "loading" ? (
              <div>
                <DotsLoader color="#ffffff" />{" "}
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep1;
