import { serverUrl } from "./Api.js";
import { QueryClient } from "@tanstack/react-query";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";
import useAuth from "./useAuth.js";

export default function useAddPlay() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation(
    ({ trackid, projectid, email }) =>
      axiosPrivate
        .post(serverUrl + "/tracks/" + trackid + "/play", { email: email })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log(
          "useAddPlay Boris",
          variables.trackid.toString(),
          variables.projectid.toString()
        );
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);
        queryClient.invalidateQueries(['all-tracks']);
        queryClient.invalidateQueries(['track'], variables.trackid.toString());
      },
    }
  );
}
