import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Avatar from "../Avatar";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import useAuth from "../hooks/useAuth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TopNavBarUserMenu({ userName }) {
  const logout = useLogout();
  const navigate = useNavigate();

  const { auth } = useAuth();

  const handleLogOut = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md  bg-background px-1.5 py-1.5 font-medium border-transparent text-gray-700 hover:bg-gray-50  hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-background focus:ring-green-500 aria-[expanded=true]:bg-white aria-[expanded=true]:border-gray-300  dark:bg-backgrounddark dark:hover:bg-zinc-900 dark:focus:ring-offset-backgrounddark">
          <Avatar hidetooltip size="28" userid={auth?.userid} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y rounded-md shadow-lg ring-1 ring-opacity-5 ring-black bg-white divide-gray-100 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm text-gray-600">Signed in as</p>
            <p className="truncate text-sm font-medium text-gray-900">
              {auth?.email}
            </p>
          </div>
          {/* <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "text-gray-900 bg-gray-100" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Account settings
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "text-gray-900 bg-gray-100" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Support
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "text-gray-900 bg-gray-100" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  License
                </a>
              )}
            </Menu.Item>
          </div> */}

          <Menu.Item>
            {({ active }) => (
              <div
                onClick={handleLogOut}
                className={classNames(
                  active ? "text-gray-900 bg-gray-100" : "text-gray-700",
                  "block w-full px-4 py-2 text-left text-sm"
                )}
              >
                Sign out
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
