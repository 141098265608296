import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

import TrackListChatSlideoverCommentForm from "./TrackListChatSlideoverCommentForm";
import TrackListTrackOverlaySidebarHistory from "./TrackListTrackOverlaySidebarHistory";

import { Switch } from "@headlessui/react";
import { classNames } from "../../shared/Utils";

import useSettings from "../../hooks/useSettings";

import {
  ChartPieIcon,
  FolderIcon,
  InformationCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";

const TrackListTrackOverlaySideBar = ({ track, closeSidebar, small }) => {
  const {
    setBottomPlayerOpen,
    bottomPlayerSetTrack,
    bottomPlayerUrl,
    bottomPlayerSetUrl,
    bottomPlayerIsPlaying,
    bottomPlayerSetIsPlaying,
  } = useSettings();

  const [tab, setTab] = useState("updates");
  const [showAllUpdates, setShowAllUpdates] = useState(false);

  const tabs = [
    {
      name: "Updates",
      href: "updates",
      icon: ClockIcon,
      current: tab === "updates",
    },
    {
      name: "Info",
      href: "info",
      icon: InformationCircleIcon,
      current: tab === "info" || tab === "" || tab === undefined,
    },
    {
      name: "Artist splits",
      href: "artistsplits",
      icon: ChartPieIcon,
      current: tab === "artistsplits",
    },
    {
      name: "Assets",
      href: "assets",
      icon: FolderIcon,
      current: tab === "assets",
    },
  ];

  if (track) {
    return (
      <div className="h-full pb-6  bg-white ">
        <div
          className={classNames(
            small ? "shadow-xl shadow-gray-500/10" : " shadow-none ",
            " sticky top-0 z-30 self-start transition-all bg-white"
          )}
        >
          <div
            className={classNames(
              tab == "updates" ? "border-b pb-[12px]" : "h-0 ",
              " text-gray-400  bg-gray-50 border-gray-200 flex  justify-end  overflow-hidden px-4 pt-[15px] text-xs  transition-all"
            )}
          >
            <div className="my-auto"> Show all updates</div>
            <Switch
              checked={showAllUpdates}
              onChange={setShowAllUpdates}
              className="group border-red-600 relative my-auto ml-2 inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:ring-green-500 focus:border-0 focus:outline-none focus:ring-0 focus:ring-offset-0"
            >
              <span className="sr-only">Show all updates</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  showAllUpdates ? "bg-green-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  showAllUpdates ? "translate-x-5" : "translate-x-0",
                  "border-gray-200 pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border shadow ring-0 transition-transform duration-200 ease-in-out bg-white"
                )}
              />
            </Switch>
          </div>
        </div>

        <div className="">
          <div className="px-6 py-6">
            <div className="   z-50  self-start ">
              {/* {track?.project && track?.id && (
                  <TrackListChatSlideoverCommentForm
                    projectid={track?.project}
                    trackid={track?.id}
                  />
                )} */}
            </div>
            <div className="">
              <TrackListTrackOverlaySidebarHistory
                showAll={showAllUpdates}
                trackid={track.id}
                projectid={track.project}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "No track selected";
  }
};

export default TrackListTrackOverlaySideBar;
