import { Fragment, useState } from "react";
import short from "short-uuid";
import { useParams } from "react-router-dom";

const validforOptions = [
  { name: "6 hrs", isOption: true },
  { name: "1 days", isOption: true },
  { name: "7 days", isOption: true },
  { name: "1 month", isOption: true },
];

import useCreatePlaylist from "../hooks/useCreatePlaylist";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";

import { forwardRef, useImperativeHandle } from "react";
import Lottie from "react-lottie-player";

import * as linkAnimation from "../../animations/11-link-unlink-morph-flat-edited.json";
import * as doneAnimation from "../../animations/24-approved-checked-flat-edited.json";

import {
  LockClosedIcon,
  ShareIcon,
  LinkIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
} from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CreatePrivateLinkOverlay = forwardRef((props, ref) => {
  const createPlaylistQuery = useCreatePlaylist();

  //   console.log("createPlaylistQuery", createPlaylistQuery);

  const [recipient, setRecipient] = useState("");
  const [allowDownloads, setAllowDownloads] = useState(false);
  const { projectid } = useParams();
  const [open, setOpen] = useState(false);

  console.log(createPlaylistQuery);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [selectedtracks, setSelectedTracks] = useState([]);

  useImperativeHandle(ref, () => ({
    openDialog(selectedrows) {
      console.log("selectedrows", selectedrows);
      handleOpen();
      setSelectedTracks(selectedrows);
    },
  }));
  const [playlisturl, setPlaylistUrl] = useState("");

  const handleSubmit = async (e) => {
    const selectedTrackIds = selectedtracks.map((track) => track.id);
    let selectedTracks = [];
    selectedtracks.map((track, index) => {
      selectedTracks.push({ pos: index, id: track.id });
    });

    const playlistId = short().generate();
    console.log("PLAYLIST ID", playlistId);
    console.log("PLAYLIST recipient", recipient);
    console.log("SELECTED TRACKS 2", selectedTracks);

    console.log("PROJECT ID", props.projectId);

    createPlaylistQuery.mutate({
      id: playlistId,
      recipient: recipient,
      tracks: selectedTracks,
      projectId: projectid,
      allowDownloads: allowDownloads,
    });

    setPlaylistUrl(`https://p.trackflow.studio/${playlistId}`);
  };

  const handleFocus = (event) => event.target.select();
  const [copiedstate, setCopiedState] = useState(false);
  const [duration, setDuration] = useState(validforOptions[2]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[101]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-500" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-screen items-end justify-center  text-center md:block ">
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative m-4 inline-block  w-full transform  overflow-hidden rounded-lg text-left align-middle shadow-xl transition-all bg-white sm:w-full sm:max-w-xl md:m-12">
                <div className="block  justify-between p-6 md:flex md:gap-4">
                  <div className="flex justify-center">
                    {/* <div className="">
                        <Lottie
                          speed={1}
                          loop={false}
                          animationData={doneAnimation}
                          play
                          style={{ width: 120, height: 120 }}
                        />
                      </div> */}

                    <div className="">
                      <Lottie
                        speed={1}
                        direction={-1}
                        loop={false}
                        animationData={linkAnimation}
                        play
                        style={{ width: 120, height: 120 }}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mt-4">
                      <div className="grid  w-full grid-cols-3 gap-x-4">
                        {/* Content */}
                        <main
                          className={classNames(
                            playlisturl ? "h-0 " : "h-full  ",
                            "col-span-3  overflow-hidden  px-4 "
                          )}
                        >
                          <form onSubmit={handleSubmit}>
                            <div className="space-y-6">
                              <div className="min-w-0 flex-1 text-sm">
                                <div>
                                  {selectedtracks.length === 1 ? (
                                    <div>
                                      <label
                                        htmlFor="recipient"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Describe link:
                                      </label>
                                      <div className="mt-2 flex rounded-md shadow-sm">
                                        <div className="inline-flex items-center rounded-l-md border border-r-0 px-3 py-2 text-gray-800 bg-gray-50 border-gray-300 sm:text-sm">
                                          <div className=" mr-2 rounded border px-2 py-1 border-gray-400">
                                            {selectedtracks[0].title}
                                          </div>{" "}
                                          for
                                        </div>
                                        <input
                                          type="text"
                                          name="recipient"
                                          id="recipient"
                                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-x border-r px-3 py-2 border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm "
                                          placeholder="Steve"
                                          autoComplete="off"
                                          onChange={(e) =>
                                            setRecipient(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <label
                                        htmlFor="recipient"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Describe link:
                                      </label>
                                      <div className="mt-2 flex rounded-md shadow-sm">
                                        <div className="inline-flex items-center rounded-l-md border border-r-0 px-3 py-2 text-gray-800 bg-gray-50 border-gray-300 sm:text-sm">
                                          <div className=" mr-2 rounded border px-2 py-1 border-gray-400">
                                            {selectedtracks.length} tracks
                                          </div>{" "}
                                          for
                                        </div>
                                        <input
                                          type="text"
                                          name="recipient"
                                          id="recipient"
                                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-x border-r px-3 py-2 border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm "
                                          placeholder="Steve"
                                          autoComplete="off"
                                          onChange={(e) =>
                                            setRecipient(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="flex items-center justify-between">
                                  <h2 className="text-sm font-medium text-gray-900">
                                    Valid for:
                                  </h2>
                                  {/* <a
                                    href="#"
                                    className="text-sm font-medium text-green-600 hover:text-green-500"
                                  >
                                    The link will become inactive after
                                  </a> */}
                                </div>

                                <RadioGroup
                                  value={duration}
                                  onChange={setDuration}
                                  className="mt-2"
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Choose a durationory option
                                  </RadioGroup.Label>
                                  <div className="grid grid-cols-3 divide-x overflow-hidden rounded-md border sm:grid-cols-4">
                                    {validforOptions.map((option) => (
                                      <RadioGroup.Option
                                        key={option.name}
                                        value={option}
                                        className={({ active, checked }) =>
                                          classNames(
                                            option.isOption
                                              ? "cursor-pointer focus:outline-none"
                                              : "cursor-not-allowed opacity-25",
                                            active ? " " : "",
                                            checked
                                              ? "text-white  bg-green-500 hover:bg-green-600"
                                              : "bg-white  text-gray-900 hover:bg-gray-50",
                                            "  flex items-center justify-center py-3 px-3 text-xs   sm:flex-1"
                                          )
                                        }
                                        disabled={!option.isOption}
                                      >
                                        <RadioGroup.Label as="span">
                                          {option.name}
                                        </RadioGroup.Label>
                                      </RadioGroup.Option>
                                    ))}
                                  </div>
                                </RadioGroup>
                              </div>
                              <fieldset className=" border-gray-200">
                                <legend className="sr-only">
                                  Allow downloads
                                </legend>
                                <div className="divide-y divide-gray-200">
                                  <div className="relative flex items-start pt-4">
                                    <div className="min-w-0 flex-1 text-sm">
                                      <label
                                        htmlFor="downloads"
                                        className="font-medium text-gray-700"
                                      >
                                        Allow downloads
                                      </label>
                                      <p
                                        id="downloads-description"
                                        className="py-2 text-gray-400"
                                      >
                                        Allow the user to download full-quality.
                                      </p>
                                    </div>
                                    <div className="ml-3 flex h-5 items-center">
                                      <input
                                        id="downloads"
                                        aria-describedby="downloads-description"
                                        name="downloads"
                                        type="checkbox"
                                        onChange={(e) =>
                                          setAllowDownloads(
                                            e.target.value === "on"
                                              ? true
                                              : false
                                          )
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </form>
                        </main>
                        {playlisturl ? (
                          <div className="col-span-3 flex w-full flex-col pt-14  ">
                            <div className=" mx-auto -mt-10 w-96"></div>
                            <div className="col-span-3 flex w-full  rounded-md   px-1">
                              <div className="w-full">
                                <input
                                  onFocus={handleFocus}
                                  className="flex h-12 w-full rounded-l-md border-y border-l py-2 pl-3 text-xs bg-gray-100 border-gray-300 ring-green-500  focus:border-gray-500"
                                  type="text"
                                  value={playlisturl}
                                  readOnly
                                />
                              </div>

                              <CopyToClipboard
                                text={playlisturl}
                                onCopy={() => setCopiedState(true)}
                              >
                                <div
                                  className={classNames(
                                    copiedstate ? "w-32" : "w-28",
                                    "my-auto   flex h-12  cursor-pointer select-none  truncate rounded-r-md border-x border-r  text-center text-xs  font-medium transition-all text-white"
                                  )}
                                >
                                  {copiedstate ? (
                                    <div className="my-auto mx-auto flex h-full w-full py-2 pr-3 pl-3 bg-green-500 hover:bg-green-600">
                                      <div className="my-auto">
                                        copied{" "}
                                        <ClipboardDocumentCheckIcon className=" my-auto ml-2 inline-flex h-5 w-5" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="my-auto mx-auto flex h-full w-full py-2 pr-3 pl-3 bg-green-500 hover:bg-green-600">
                                      <div className="my-auto w-full">
                                        copy
                                        <ClipboardIcon className=" my-auto ml-2 inline-flex h-5 w-5" />
                                      </div>
                                    </div>
                                  )}

                                  {/*  */}
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {!playlisturl && (
                  <div className="col-span-3 mt-4 flex justify-end p-6 bg-gray-100 ">
                    <button
                      type="button"
                      onClick={() => {
                        handleClose();
                      }}
                      className="rounded-md border py-2 px-4 text-sm font-medium shadow-sm bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="ml-3 inline-flex justify-center rounded-md border py-2 px-4 text-sm font-medium shadow-sm border-transparent text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      {createPlaylistQuery.isInitialLoading ? (
                        "Saving..."
                      ) : createPlaylistQuery.isError ? (
                        "Error..."
                      ) : createPlaylistQuery.isSuccess ? (
                        "Saved!"
                      ) : (
                        <>
                          <LinkIcon className="mr-2 mt-1 inline h-4 w-4" />{" "}
                          Create link
                        </>
                      )}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default CreatePrivateLinkOverlay;
