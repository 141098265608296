import { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const debouncedResizeHandler = useCallback(
    debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 300),
    []
  );
  useEffect(() => {
    function handleResize() {
      debouncedResizeHandler();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
