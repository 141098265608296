import { createContext, useEffect } from "react";

import Tracker from "@openreplay/tracker/cjs";

import trackerAxios from "@openreplay/tracker-axios";
import trackerAssist from "@openreplay/tracker-assist/cjs";

const OpenReplayContext = createContext({});

export const OpenReplayProvider = ({ children }) => {
  const tracker = new Tracker({
    projectKey: "MmGJJy5TB739arBilbmx",
    onStart: ({ sessionID }) =>
      console.log("OpenReplay tracker started with session: ", sessionID),
  });

  tracker.use(trackerAxios());
  tracker.use(trackerAssist());

  useEffect(() => {
    tracker.start();
  }, []);

  return (
    <OpenReplayContext.Provider value={{ tracker }}>
      {children}
    </OpenReplayContext.Provider>
  );
};

export default OpenReplayContext;
