import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Flipper, Flipped } from "react-flip-toolkit";

import "./AdminOverlay.css";
import {
  CheckIcon,
  FolderPlusIcon,
  UserPlusIcon,
  ArrowPathIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../shared/Utils";
import AdminInviteWorkspaceContent from "./Overlays/AdminInviteWorkspaceContent";
import AdminInviteUserToCurrentWorkspaceContent from "./Overlays/AdminInviteUserToCurrentWorkspaceContent";

const adminTools = [
  {
    id: "inviteWorkspace",
    name: "New Workspace",
    icon: FolderPlusIcon,
    description: "Create invite for new workspace",
    color: "#a855f7",
    content: AdminInviteWorkspaceContent,
  },
  {
    id: "inviteUser",
    name: "New Workspace",
    icon: UserPlusIcon,
    description: "Create invite to current workspace",
    color: "#f43f5e",
    content: AdminInviteUserToCurrentWorkspaceContent,
  },
  {
    id: "resetDemo",
    name: "Reset Demo Account",
    icon: ArrowPathIcon,
    description: "Reset Demo Account",
    color: "#f59e0b",
    content: AdminInviteUserToCurrentWorkspaceContent,
  },
  {
    id: "resetWorkspaceHistory",
    name: "Delete Workspace history",
    icon: MinusCircleIcon,
    description: "Delete Workspace history",
    color: "#84cc16",
    content: AdminInviteUserToCurrentWorkspaceContent,
  },
];

export default function AdminOverlay({ open, setOpen }) {
  const [fullScreen, setFullScreen] = useState([]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 transition-opacity bg-gray-500 dark:bg-black/60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  //   fullScreen.length != 0 ? "h-[40rem]" : "",
                  "relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all  bg-white sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                )}
              >
                <Flipper flipKey={fullScreen}>
                  {/* <span className="text-gray-800 p-6 text-2xl font-medium">
                    Admin tools
                  </span> */}
                  <div className="grid grid-cols-1 px-3 pt-6 pb-6">
                    {adminTools.map((tool) => (
                      <Flipped key={tool.id}>
                        <div
                          className={classNames(
                            fullScreen === tool.id
                              ? "admin-overlay-full-screen-square my-3"
                              : "admin-overlay-square m-2",
                            "flex  h-fit flex-row gap-3  rounded-lg  border  shadow-sm transition-all bg-white text-gray-700  border-gray-200 hover:bg-gray-50 dark:border"
                          )}
                          // style={{ borderColor: tool.color }}
                        >
                          {fullScreen === tool.id ? (
                            <tool.content close={() => setFullScreen([])} />
                          ) : (
                            <div
                              onClick={() => setFullScreen(tool.id)}
                              className="flex w-full cursor-pointer flex-row gap-4  p-4"
                            >
                              <div className="flex h-8 w-8 flex-shrink-0">
                                <tool.icon
                                  style={{
                                    color: tool.color,
                                  }}
                                  className="my-auto"
                                />
                              </div>
                              <div className=" my-auto text-left">
                                {tool.description}
                              </div>
                            </div>
                          )}
                        </div>
                      </Flipped>
                    ))}
                  </div>
                </Flipper>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
