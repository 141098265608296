import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackTasks() {
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();
  return useMutation(
    ({ tasks, tiptapJSON, trackid, projectid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid + "/tasks", {
          tasks: tasks,
          tiptapJSON: tiptapJSON,
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("onSuccess update track tasks", variables.trackid);

        queryClient.invalidateQueries(["track", variables.trackid]);
        queryClient.removeQueries(["track", variables.trackid]);
        queryClient.refetchQueries(["track", variables.trackid]);
        queryClient.invalidateQueries(["all-tracks"]);
      },
    }
  );
}
