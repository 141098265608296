import React, { useEffect, useRef } from "react";
import { useField } from "formik";
import { classNames } from "../shared/Utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { setContext } from "@sentry/react";

const OnboardingTextfield = ({
  label,
  helpText,
  setError,
  current,

  ...props
}) => {
  const [field, meta] = useField(props);

  const ref = useRef(null);

  useEffect(() => {
    if (current) {
      setTimeout(() => {
        current && ref.current.focus();
      }, 500);
    }
  }, [current]);

  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const showFeedback = (!!didFocus && field.value?.length > 2) || meta.touched;
  useEffect(() => {
    setError(meta.error);
  }, [meta]);

  return (
    <div
      className={`form-control ${
        showFeedback ? (meta.error ? "invalid" : "valid") : ""
      }`}
    >
      <input
        {...props}
        {...field}
        ref={ref}
        disabled={!current}
        autoComplete="do-not-autofill"
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={handleFocus}
        className={classNames(
          meta.error ? "border-gray-300" : "border-green-600",
          "block   w-full border-0 border-b-2 px-0  text-xl bg-transparent text-gray-800  focus:ring-0  sm:text-4xl"
        )}
      />{" "}
      <div className="  mt-3 w-full ">
        {showFeedback ? (
          <div
            id={`${props.id}-feedback`}
            aria-live="polite"
            className="feedback text-sm text-gray-700"
          >
            {meta.error && (
              <ExclamationCircleIcon className="mr-2 mb-0.5 inline h-5 w-5 text-gray-600" />
            )}
            {meta.error && meta.error}
          </div>
        ) : null}
      </div>
      {/* <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
        {helpText}
      </div> */}
    </div>
  );
};

export default OnboardingTextfield;
