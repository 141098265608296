import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useWorkspaceSettings(workspaceid) {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(
    ["workspace-settings", workspaceid],
    () =>
      axiosPrivate
        .get(serverUrl + "/workspaces/" + workspaceid + "/settings")
        .then((res) => res.data),
    {
      // refetchInterval: 60000,
    }
  );
}
