import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Banner from "./Banner";

const PushTokenChecker = () => {
  const { user, IsLoaded } = useAuth0();
  const [showBanner, setShowBanner] = useState(false);

  //   console.log("PushTokenChecker: ", user);

  useEffect(() => {
    // if (!IsLoaded) return;
    if (user) {
      if (user?.userInfo?.pushToken) {
        console.log("pushtoken exists");
      } else {
        console.log("pushtoken does not exist");
        setShowBanner(true);
      }
    }
  }, [user, IsLoaded]);

  if (showBanner) {
    return (
      <Banner
        message="Download the mobile app and allow push notifications to get live updates."
        action={() => {
          window.open("https://testflight.apple.com/join/6RZ37DLV", "_blank");
        }}
        dismiss={() => {
          console.log("dismissed");
          setShowBanner(false);
        }}
      />
    );
  }
};

export default PushTokenChecker;
