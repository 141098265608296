/* This example requires Tailwind CSS v2.0+ */
import { useState, useMemo } from "react";
import React from "react";
import { styled, keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { classNames, getStatusIcon } from ".././shared/Utils";

import "./TrackListStatus.css";

import useSettings from "../hooks/useSettings";
import useUpdateTrackStatus from "../hooks/useUpdateTrackStatus";
import useAuth from "../hooks/useAuth";

const slideDown = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideUp = keyframes({
  "0%": { opacity: 0, transform: "translateY(10px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  animationDuration: "0.6s",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  animationFillMode: "forwards",
  '&[data-side="top"]': { animationName: slideUp },
  '&[data-side="bottom"]': { animationName: slideDown },
});

const TrackListStatus = ({ row, openEditStatusesOverlay, overlay }) => {
  console.log("render TrackListStatus");

  const { workspaceSettings } = useSettings();
  const availableStatuses = workspaceSettings.statuses;

  const [currentStatus, setCurrentStatus] = useState(row.original.status);
  const updateTrackStatusQuery = useUpdateTrackStatus();

  const SetStatus = (status) => {
    updateTrackStatusQuery.mutate({
      projectid: row.original.project,
      trackid: row.original.id,
      status: status,
    });

    setCurrentStatus(status);
  };

  let currentStatusColor = "";
  let currentStatusIconName = "";

  const getCurrentStatusColor = () => {
    if (availableStatuses?.find((element) => element.title === currentStatus)) {
      currentStatusColor = availableStatuses.find(
        (element) => element.title === currentStatus
      ).color;
    } else {
      currentStatusColor = "#cccccc";
    }
  };
  getCurrentStatusColor();

  const getCurrentStatusIconName = () => {
    if (availableStatuses?.find((element) => element.title === currentStatus)) {
      currentStatusIconName = availableStatuses.find(
        (element) => element.title === currentStatus
      ).iconName
        ? availableStatuses.find((element) => element.title === currentStatus)
            .iconName
        : "QuestionMarkCircleIcon";
    } else {
      currentStatusIconName = "QuestionMarkCircleIcon";
    }
  };
  getCurrentStatusIconName();

  const { auth } = useAuth();

  const [open, setOpen] = useState(false);

  return (
    <>
      <PopoverPrimitive.Root onOpenChange={(open) => setOpen(open)}>
        <PopoverPrimitive.Trigger className="h-full w-full text-black">
          <div
            id={"status-" + row.original.id}
            style={{
              color: currentStatusColor,
            }}
            className={classNames(
              overlay
                ? "py-3 hover:bg-white dark:hover:bg-zinc-800"
                : "hover:brightness-[0.92] hover:bg-gray-50   group-hover:bg-gray-50",
              open ? "status-folded bg-gray-50" : "status-fold",
              "relative flex h-full   w-full cursor-pointer  select-none overflow-hidden border-0 text-center  dark:hover:brightness-110 md:px-2"
            )}
          >
            <div
              style={{
                backgroundColor: currentStatusColor,
              }}
              className={classNames(
                overlay ? "opacity-0" : "opacity-100",
                "  absolute left-0 z-0 h-full w-full transition-all dark:opacity-0"
              )}
            />
            <div
              className={classNames(
                open ? "status-folded " : "status-fold",
                "  z-10 flex w-full"
              )}
            >
              <div
                className={classNames(
                  overlay ? "mx-4" : "mx-auto",
                  "  flex flex-row items-center justify-center   text-xs font-bold uppercase "
                )}
              >
                <div
                  className={classNames(
                    overlay ? "text-inherit" : "",
                    currentStatus.length > 15
                      ? "mr-1 h-[1rem] w-[1rem]"
                      : "mr-2 h-[1.1rem] w-[1.1rem]",

                    "flex-shrink-0   text-white dark:text-inherit "
                  )}
                >
                  {getStatusIcon(currentStatusIconName)}
                </div>

                <div
                  className={classNames(
                    overlay ? "text-inherit" : "max-w-[140px]",
                    currentStatus.length > 15 && !overlay
                      ? "text-[0.74rem]"
                      : "text-xs",
                    "truncate whitespace-pre text-white  dark:text-inherit"
                  )}
                >
                  {currentStatus}
                </div>
              </div>
            </div>
          </div>
        </PopoverPrimitive.Trigger>

        <StyledContent className=" rounded-md border bg-white  border-white dark:bg-zinc-950  dark:border-zinc-600 ">
          <PopoverPrimitive.Close>
            <div>
              <div className="flex flex-col p-4  text-sm">
                {availableStatuses?.map((option) => (
                  <div
                    key={option.title}
                    onClick={() => {
                      SetStatus(option.title);
                    }}
                    className={classNames(
                      currentStatus === option.title
                        ? "text-gray-800"
                        : "text-gray-800",
                      " my-0.5 flex cursor-pointer rounded-md border-2 py-2 px-2   outline-2 border-transparent text-gray-800  hover:bg-gray-100  dark:hover:bg-white/10"
                    )}
                    style={{
                      borderColor:
                        currentStatus === option.title && option.color,
                    }}
                  >
                    <div
                      className="inline-flex h-7 w-7 rounded-md bg-white/30 p-1 text-white dark:bg-black/30 dark:text-white"
                      style={{
                        background: option.color,
                      }}
                    >
                      {getStatusIcon(option.iconName)}
                    </div>
                    <div className="inline-block pl-3">
                      <div className=" my-auto pt-[0.06rem] text-left font-medium uppercase ">
                        {option.title}
                      </div>
                      {/* <div className=" font-normal text-gray-500 dark:text-zinc-500  ">
                          {option.description}
                        </div> */}
                    </div>
                  </div>
                ))}
              </div>
              {auth.roles?.includes("admin") && (
                <div
                  onClick={openEditStatusesOverlay}
                  className="col-span-2  w-full rounded-b-md border-t px-3 py-4 font-medium uppercase text-gray-500 border-gray-300 hover:bg-gray-100"
                >
                  Add/Edit statuses
                </div>
              )}
            </div>
          </PopoverPrimitive.Close>

          <PopoverPrimitive.Arrow
            width={11}
            height={5}
            offset={80}
            className="fill-gray-50 dark:fill-gray-600"
          />
        </StyledContent>
      </PopoverPrimitive.Root>
    </>
  );
};

export default TrackListStatus;
