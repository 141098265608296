import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useTrack(trackid) {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(
    ["track", trackid],
    () =>
      axiosPrivate
        .get(serverUrl + "/tracks/" + trackid)
        .then((res) => res.data),
    {
      enabled: !!trackid,
    }
  );
}
