import React from "react";

const FullDonut = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // shapeRendering="geometricPrecision"
      // textRendering="geometricPrecision"
      viewBox="0 0 1000 1000"
      // style={{ whiteSpace: "pre" }}
      className="animation"
    >
      <ellipse
        rx={149}
        ry={149}
        fill="none"
        stroke="#22c55e"
        strokeWidth={70}
        strokeDashoffset={1000}
        strokeMiterlimit={6}
        strokeDasharray={1000}
        transform="translate(500,500)"
        style={{
          animation:
            "3s linear both a0_t, 3s linear both a0_sw, 3s linear both a0_do",
        }}
      />
      <ellipse
        rx="118.5"
        ry="118.5"
        stroke="none"
        fill="#22c55e"
        strokeWidth={70}
        strokeMiterlimit={6}
        transform="translate(500,500)"
      />
      <ellipse
        rx={136}
        ry={136}
        stroke="none"
        fill="#d9dae1"
        strokeWidth={70}
        strokeMiterlimit={6}
        transform="translate(497.071,497.071)"
        style={{ animation: "3s linear both a1_t" }}
      />
    </svg>
  );
};

export default FullDonut;
