import React from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import useUploader from "../../hooks/useUploader";

import { toast } from "react-toastify";

import handleDownload from "../../hooks/handleDownload";

import { createRef } from "react";
import DeleteOverlay from "../../DeleteOverlay";

import useUpdateTrackTitle from "../../hooks/useUpdateTrackTitle";

import useTrack from "../../hooks/useTrack";
import { ArrowUpOnSquareIcon, ShareIcon } from "@heroicons/react/24/solid";

import useAllUsers from "../../hooks/useAllUsers";

import TrackListGenre from "../TrackListGenre";
import TrackListTags from "../TrackListTags";

import { BoltIcon } from "@heroicons/react/24/solid";

import {
  XMarkIcon,
  ArrowDownOnSquareIcon,
  PlayIcon,
  PauseIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

import Avatar from "../../Avatar";
import TrackListStatus from "../TrackListStatus";
import TrackListDatePicker from "../TrackListDatePicker";
import { TitleCell } from "../TrackListCells";
import TrackListArtists from "../TrackListArtists";
import secureLocalStorage from "react-secure-storage";
import useAuth from "../../hooks/useAuth";
import TrackInfo from "../../TrackInfo/TrackInfo";

const storage = getStorage();

const TrackListTrackOverlayInfo = ({ track }) => {
  const [url, setUrl] = React.useState(null);

  const PrivateLinkOverlayRef = createRef();

  getDownloadURL(
    ref(
      storage,
      "tracks/100mgmt/hmCLKZEd8cDBPYDHXiQfsZ/8STN1eowa8wXyxgga3i3rq/mp3-gin-juice.mp3"
    )
  )
    .then((url) => {
      setUrl(url);
    })
    .catch((error) => {
      console.log("firebase download error", error);
    });

  const { auth } = useAuth();

  const { uploaderAddJob } = useUploader();
  const handleUpload = (e) => {
    e.preventDefault();
    let job = {};
    job.file = e.target.files;
    job.trackid = track?.id;
    job.projectid = track?.project;
    job.workspace = auth.workspace;

    uploaderAddJob(job);
  };

  const deleteOverlayRef = createRef();

  const deleteRow = async (row, projectid) => {
    console.log("delete row", row, projectid);
    deleteOverlayRef.current.openDialog(row, projectid);
  };

  const updateTrackTitleQuery = useUpdateTrackTitle();

  const updateTrackTitle = async (title) => {
    updateTrackTitleQuery.mutate({
      projectid: track?.project,
      trackid: track?.id,
      title: title,
    });
  };

  const usersQuery = useAllUsers();

  console.log("usersQuery", usersQuery);

  const handleCopyDownloadLink = () => {
    const downloadUrl = "https://p2.trackflow.studio/" + track.downloadcode;
    navigator.clipboard.writeText(downloadUrl);
    toast.success("Download link copied to clipboard", {
      containerId: "main",
    });
  };

  const handleCopyPreviewLink = () => {
    const downloadUrl = "https://p2.trackflow.studio/" + track.previewcode;
    navigator.clipboard.writeText(downloadUrl);
    toast.success("Preview link copied to clipboard", {
      containerId: "main",
    });
  };

  return (
    <div className="">
      <div className="  border-b bg-gray-50 border-gray-200">
        <div className="flex justify-end ">
          <div className="hidden flex-row gap-3 px-3 py-1 lg:flex ">
            <div className="flex cursor-pointer select-none flex-row rounded-tr-md px-3 py-3 opacity-100 text-gray-700  border-gray-300 hover:bg-white">
              <BoltIcon className="my-auto h-5 w-5" />
              <div className="my-auto select-none pl-1 text-xs font-medium">
                templates
              </div>{" "}
            </div>
          </div>
          <div className="hidden flex-row gap-3 px-3 py-1 lg:flex ">
            <div className="flex cursor-pointer select-none flex-row rounded-tr-md px-3 py-3 opacity-100 text-gray-700  border-gray-300 hover:bg-white">
              <ArrowDownOnSquareIcon className="my-auto h-5 w-5" />
              <div className="my-auto select-none pl-1 text-xs font-medium">
                PDF
              </div>{" "}
            </div>
          </div>
          <div className="hidden flex-row gap-3 px-3 py-1 lg:flex ">
            <div className="flex cursor-pointer select-none flex-row rounded-tr-md px-3 py-3 opacity-100 text-gray-700  border-gray-300 hover:bg-white">
              <ShareIcon className="my-auto h-5 w-5" />
              <div className="my-auto select-none pl-1 text-xs font-medium">
                Share
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="file-upload"
        name="file-upload"
        onChange={(e) => {
          handleUpload(e);
        }}
        className="sr-only"
      />
      {/* <h2 className=" text-lg text-gray-700 font-medium p-5">Track</h2> */}
      <div className="grid grid-cols-1 divide-y border-b border-gray-300 divide-gray-300 dark:divide-zinc-700 md:grid-cols-2   ">
        {/* <div className="md:col-span-2"></div> */}
        <div className="group flex flex-shrink-0 md:col-span-2">
          <div className=" my-auto w-28 px-6 text-xs   font-medium uppercase text-gray-500 md:w-48">
            title
          </div>{" "}
          <div>
            <div className="my-auto h-14 flex-grow py-1   ">
              <>
                <input
                  type="text"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onBlur={() => {
                    setTitleEdit(false);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") updateTrackTitle(e.target.value);
                  }}
                  className=" h-full w-[20rem] rounded-md border px-2 py-[0.6rem] ring-0 border-transparent bg-white text-gray-800 ring-green-500 focus:ring-0 focus:border-gray-300 group-hover:border-gray-300 "
                  defaultValue={track.title}
                />
              </>
            </div>
          </div>
        </div>
        <div className="flex  md:col-span-2">
          <div className="my-auto flex w-28 flex-shrink-0 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
            artists
          </div>{" "}
          <div className=" flex h-14 w-full flex-grow flex-wrap">
            <TrackListArtists row={{ original: track }} />
            {/* <TrackArtists track={track} /> */}
          </div>
        </div>
        <div className="flex flex-shrink-0 md:col-span-2 ">
          <div className="my-auto w-28 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
            status
          </div>{" "}
          <div className=" flex h-14 ">
            <TrackListStatus overlay row={{ original: track }} />
            {/* <TrackStatus track={track} /> */}
          </div>
        </div>
        <div className="flex flex-shrink-0 md:col-span-2 ">
          <div className="my-auto w-28 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
            deadline
          </div>{" "}
          <div className="flex h-14 flex-grow ">
            <TrackListDatePicker row={{ original: track }} />
            {/* <TrackDatePicker track={track} /> */}
          </div>
        </div>
        <div className="flex flex-shrink-0 md:col-span-2 ">
          <div className="my-auto w-28 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
            genre
          </div>{" "}
          <div className="flex h-14 flex-grow ">
            <TrackListGenre row={{ original: track }} />
            {/* <TrackDatePicker track={track} /> */}
          </div>
        </div>
        <div className="flex flex-shrink-0 md:col-span-2 ">
          <div className="my-auto w-28 px-6 text-xs font-medium uppercase text-gray-500 md:w-48">
            tags
          </div>{" "}
          <div className="flex h-14 flex-grow ">
            <TrackListTags row={{ original: track }} />
            {/* <TrackDatePicker track={track} /> */}
          </div>
        </div>
      </div>
      <TrackInfo track={track} />
    </div>
  );
};

export default TrackListTrackOverlayInfo;
