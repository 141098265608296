import React from "react";

import {
  FaceSmileIcon,
  FireIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  RocketLaunchIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { ReactionBarSelector } from "@charkour/react-reactions";

const ListeningRoomReactionSelector = ({ iconSize, color }) => {
  const availableReactions = [
    {
      label: "Rocket",
      node: (
        <div>
          <RocketLaunchIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </div>
      ),
      key: "rocket",
    },
    {
      label: "Smile",
      node: (
        <div>
          <FaceSmileIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </div>
      ),
      key: "smile",
    },
    {
      label: "Love",
      node: (
        <div>
          <HeartIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </div>
      ),
      key: "love",
    },
    {
      label: "Like",
      node: (
        <div>
          <HandThumbUpIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </div>
      ),
      key: "like",
    },
    {
      label: "Dislike",
      node: (
        <div>
          <HandThumbDownIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </div>
      ),
      key: "dislike",
    },
  ];

  return (
    <div className=" w-full">
      <div
        className="text-gray-600 absolute bottom-[8rem] "
        style={{
          left: "-" + (availableReactions.length * iconSize + 18) / 2 + "px",
        }}
      >
        <ReactionBarSelector
          iconSize={iconSize}
          style={{
            backgroundColor: "white",
            // color: color,
            color: color,
            shadow: "0 0 0 0",
            boxShadow: "0 0 0 0",
            width: "100%",
            zIndex: 20,
            padding: "0.4rem",
          }}
          reactions={availableReactions}
        />{" "}
        <div className="pointer -z-10  mt-3  " />
      </div>
    </div>
  );
};

export default ListeningRoomReactionSelector;
