import useAxiosPrivate from "./useAxiosPrivate.js";
import { serverUrl } from "./Api.js";
import { useQuery } from "@tanstack/react-query";

export default function useOnboardingCheckCode(token) {
  console.log("useOnboardingCheckCode");
  const axiosPrivate = useAxiosPrivate();

  return useQuery(["onboarding", token], () =>
    axiosPrivate.get(serverUrl + "/onboarding/" + token).then((res) => res.data)
  );
}
