import useAxiosPrivate from "./useAxiosPrivate.js";
import { useQuery } from "@tanstack/react-query";

import { serverUrl } from "./Api.js";

export default function useAllArtists() {
  const axiosPrivate = useAxiosPrivate();

  return useQuery(["all-artists"], () =>
    axiosPrivate.get(serverUrl + "/artists/").then((res) => res.data)
  );
}
