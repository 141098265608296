import React, { useCallback, useEffect } from 'react'


const KeyboardShortcuts = () => {
    const handleKeyPress = useCallback((event) => {
        console.log(`Key pressed: ${event}`);
      }, []);
    
      useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);
    
        // remove the event listener
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, [handleKeyPress]);

}

export default KeyboardShortcuts