import { mergeAttributes, Node, nodePasteRule } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import CustomSpotifyComponent from "./CustomSpotifyComponent";

export default Node.create({
  name: "customSpotifyItem",

  group: "block",

  draggable: true,
  inline: false,

  // content() {
  //   return "block*";
  // },
  // defining: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-spotify-link] iframe",
      },
    ];
  },
  addPasteRules() {
    return [
      nodePasteRule({
        find: /^(https?:\/\/)?(www\.|open\.)?(spotify\.com|spotify\.be)(.+)?$/g,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["custom-spotify-item", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomSpotifyComponent);
  },
});
