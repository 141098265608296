import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate.js";

import { serverUrl } from "./Api.js";

export default function useUpdateTrackGenre() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ genre, predictedGenres, projectid, trackid }) =>
      axiosPrivate
        .patch(serverUrl + "/tracks/" + trackid, {
          ...(genre && { genre: genre }),
          ...(predictedGenres && { predictedGenres: predictedGenres }),
        })
        .then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        console.log("variables ", variables);
        console.log("tracksbyproject", variables.projectid.toString());
        queryClient.invalidateQueries([
          "tracksbyproject",
          variables.projectid.toString(),
        ]);

        queryClient.invalidateQueries(['all-tracks']);
        queryClient.invalidateQueries(['recent-tracks']);
        queryClient.invalidateQueries(["track", variables.trackid]);
        queryClient.invalidateQueries(["track-history", variables.trackid]);
      },
    }
  );
}
