import React, { useEffect, useRef, useState } from "react";
import IsLoaded from "../IsLoaded";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import FullDonut from "../components/FullDonutAnimation";
import FullDonutDark from "../components/FullDonutAnimationDark";
import {
  ArrowRightOnRectangleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import DotsLoader from "../components/DotsLoader";

import logo from "../img/trackflow-logo-with-text-white.svg";
import useCreateResetPasswordToken from "../components/hooks/useCreateResetPasswordToken";
import axios from "axios";
import Lottie from "react-lottie-player";

import checkAnimation from "../animations/check.json";

const ResetPasswordRequest = () => {
  const [email, setEmail] = React.useState("");
  const [errMsg, setErrMsg] = useState("");

  const [emailSent, setEmailSent] = useState(false);
  const emailRef = useRef();
  const errRef = useRef();

  //creating IP state
  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const createResetPasswordTokenQuery = useCreateResetPasswordToken();

  const handleResetPasswordRequest = (e) => {
    e.preventDefault();
    createResetPasswordTokenQuery.mutate({
      email: emailRef.current.value,
      ip: ip,
    });
  };

  useEffect(() => {
    if (createResetPasswordTokenQuery.isSuccess) {
      setEmailSent(true);
    }
  }, [createResetPasswordTokenQuery.isSuccess]);

  const [buttonText, setButtonText] = useState("signin");

  useEffect(() => {
    document.body.classList.add("body-login");
    return () => {
      document.body.classList.remove("body-login");
    };
  }, []);
  return (
    <div>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Ld7ev8iAAAAAIfCd9-W7P6KKYoeBE5F-GPu4Chv"
        language="english"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
        container={{
          element: "app",
          parameters: {
            // badge: "bottomleft", // optional, default undefined
            theme: "dark", // optional, default undefined
          },
        }}
      >
        <div className="h-screen p-4 bg-green-500">
          {!emailSent ? (
            <div
              className=" flex min-h-full flex-col items-center justify-center
        py-12  transition  duration-300 sm:px-6 lg:px-8"
            >
              <div className="mx-auto pb-3">
                <img
                  src={logo}
                  alt="Trackflow"
                  className="h-14 dark:opacity-60 dark:invert"
                />
              </div>
              <p className="inline-flex py-4 px-6 text-center text-sm font-bold text-white md:hidden">
                {" "}
                Trackflow is a desktop-first app, you'll have limited
                functionality on a smartphone
              </p>

              <div className="mt-8 w-full sm:max-w-sm lg:max-w-md xl:max-w-md">
                <div className="rounded-lg py-8 px-4 shadow-md bg-white dark:bg-[#134f26] sm:px-10">
                  <form
                    onSubmit={handleResetPasswordRequest}
                    className="space-y-6"
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          ref={emailRef}
                          autoComplete="off"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          className="block  w-full appearance-none rounded-md border px-3 py-2 shadow-sm bg-white text-gray-800 border-gray-300 placeholder-gray-400 focus:outline-none focus:border-green-500 focus:ring-green-500 dark:border-white/30 dark:bg-black/40 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex h-12 w-full justify-center rounded-md border py-2 px-4 text-base font-medium  shadow-sm border-transparent text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:hover:bg-green-600"
                      >
                        <div className="my-auto mx-auto">
                          {buttonText == "signin" ? (
                            <>
                              <ArrowRightOnRectangleIcon className="mr-1 inline-flex h-5 w-5" />
                              Send reset link
                            </>
                          ) : buttonText == "loading" ? (
                            <div>
                              <DotsLoader color="#ffffff" />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </button>
                    </div>
                  </form>
                  <p
                    className="py-2 text-red-500"
                    ref={errRef}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                </div>
              </div>

              <p className="my-2 pt-6 text-center text-sm text-white/70 dark:text-black/50 md:my-6">
                dev / beta
              </p>
            </div>
          ) : (
            <div
              className=" flex min-h-full flex-col items-center justify-center
        py-12 transition  duration-300 sm:px-6 lg:px-8"
            >
              <div className="mt-8 w-full sm:max-w-sm lg:max-w-md xl:max-w-md">
                <div className="flex flex-col rounded-lg py-8 px-4 shadow-md bg-white dark:bg-[#134f26] sm:px-10">
                  <div className="mx-auto">
                    <Lottie
                      speed={1}
                      loop={false}
                      animationData={checkAnimation}
                      style={{ width: 100, height: 100 }}
                      play={true}
                    />
                  </div>
                  <div className="pt-4 text-center text-lg text-gray-800">
                    {" "}
                    Check your inbox!{" "}
                  </div>
                  <div className="pt-1 text-center text-xs opacity-50 text-gray-800">
                    (you can close this window)
                  </div>
                </div>
              </div>
            </div>
          )}
          <IsLoaded />
        </div>
      </GoogleReCaptchaProvider>
      <IsLoaded />
    </div>
  );
};

export default ResetPasswordRequest;
