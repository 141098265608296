import React from "react";
// import "@uppy/robodog/dist/robodog.css";
import {
  ArrowSmUpIcon,
  StarIcon,
  XMarkIcon,
  CloudArrowUpOnSquareIcon,
} from "@heroicons/react/24/solid";
import secureLocalStorage from "react-secure-storage";

import useUpdateTrackUrl from "../hooks/useUpdateTrackUrl";

import useUploader from "../hooks/useUploader";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import useAuth from "../hooks/useAuth";

const UppyUpload = ({ trackid, projectid }) => {
  const { uploaderAddJob } = useUploader();

  const { auth } = useAuth();

  const UpdateTrackUrlQuery = useUpdateTrackUrl();
  const [status, setStatus] = React.useState("idle");

  const handleSubmit = (e) => {
    e.preventDefault();
    let job = {};
    job.file = e.target.files;
    job.trackid = trackid;
    job.projectid = projectid;
    job.workspace = auth.workspace;

    uploaderAddJob(job);
    setStatus("uploading");
  };

  return (
    <div className="ml-[0.1rem] h-full">
      <form className="h-full">
        {status === "idle" ? (
          <label
            htmlFor={"file-upload" + trackid}
            className=" relative h-full cursor-pointer  rounded-md font-medium text-gray-300 hover:text-gray-500 "
          >
            <div className="relative my-auto  mx-auto flex  h-full w-full items-center hover:text-gray-400 hover:bg-gray-100">
              <div className="mx-auto flex whitespace-nowrap rounded-md px-2 py-2 text-xs ">
                <ArrowUpOnSquareIcon className="mt-0.5 mr-0.5 inline-flex h-6 w-6 pb-1 " />
              </div>
            </div>
            <input
              id={"file-upload" + trackid}
              name={"file-upload" + trackid}
              type="file"
              className="sr-only"
              onChange={handleSubmit}
              accept=".mp3,.wav,.flac,.aiff,.ogg,.m4a"
            />
          </label>
        ) : status === "uploading" ? (
          <div className="relative my-auto   mx-auto flex h-full w-full items-center">
            <div className="mx-auto flex whitespace-nowrap  rounded-md px-2 py-1 text-xs text-gray-600 ">
              <ArrowUpOnSquareIcon className="mt-0.5 mr-2 inline-flex h-4 w-4 animate-bounce pb-1" />
            </div>
          </div>
        ) : status === "finished" ? (
          <div className="relative my-auto   mx-auto flex h-full w-full items-center">
            <div className="mx-auto flex whitespace-nowrap rounded-md px-2 py-1 text-xs text-green-600 ">
              <StarIcon className="mt-0.5 mr-2 inline-flex h-4 w-4 pb-1" />
            </div>
          </div>
        ) : (
          <div className="relative my-auto   mx-auto flex h-full w-full items-center">
            <div className="mx-auto flex whitespace-nowrap rounded-md px-2 py-1 text-xs text-red-600 ">
              <XMarkIcon className="mt-0.5 mr-2 inline-flex h-4 w-4 pb-1" />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default UppyUpload;
