import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import CustomAssignToComponent from "../CustomAssignToComponent/CustomAssignToComponent";

export const inputRegex = /^\s*(\[([( |x])?\])\s$/;

export const ListItem = Node.create({
  name: "taskItem",

  addOptions() {
    return {
      nested: true,
      HTMLAttributes: {},
      color: "test",
    };
  },
  defining: true,
  content() {
    return this.options.nested ? "block*" : "paragraph+";
  },

  addStorage() {
    return {
      dateUpdated: new Date(),
      dateCreated: new Date(),
    };
  },
  onUpdate() {
    this.storage.dateUpdated = new Date();
  },

  defining: false,
  selectable: true,

  addAttributes() {
    return {
      checked: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute("data-checked") === "true",
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked,
        }),
      },
      priority: {
        default: "Low",
      },
      assignedTo: {
        default: [],
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "li",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomAssignToComponent);
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      "Shift-Tab": () => this.editor.commands.liftListItem(this.name),
      // Backspace: () => this.editor.commands.liftListItem(this.name),
    };
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => ({
          checked: match[match.length - 1] === "x",
        }),
      }),
    ];
  },
});

export default ListItem;
