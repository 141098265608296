import React, { useEffect } from "react";

import formatDistanceToNow from "date-fns/formatDistanceToNow";

import useArtistById from "./hooks/useArtistByIdHoverCard";
import useTracksByArtist from "./hooks/useTracksByArtistHoverCard";

const ArtistHoverCardContent = ({ artistId, artistName, hoverCardOpen }) => {
  const artistByIdQuery = useArtistById(artistId);
  const tracksByArtistQuery = useTracksByArtist(artistId);
  console.log(
    "tracksByArtistQuery hovercard",
    artistId,
    artistName,
    tracksByArtistQuery.data
  );

  useEffect(() => {
    if (!tracksByArtistQuery.data) tracksByArtistQuery.refetch();
    if (!artistByIdQuery.data) artistByIdQuery.refetch();
  }, [hoverCardOpen]);

  return (
    <div className="flex flex-col justify-between  pl-3">
      <div>
        <div className="text-gray-800 font-medium">{artistName}</div>
        {tracksByArtistQuery.isSuccess ? (
          <div className="text-gray-600 align-middle ">
            {tracksByArtistQuery.data.length}{" "}
            {tracksByArtistQuery.data.length === 1 ? "track" : "tracks"}
          </div>
        ) : (
          <div className="bg-gray-200 mt-1 h-4 w-16 animate-pulse rounded-full" />
        )}
      </div>
      {artistByIdQuery.isSuccess ? (
        <div className="text-gray-400 text-tiny">
          Created{" "}
          {artistByIdQuery?.data[0]?.createdAt &&
            formatDistanceToNow(new Date(artistByIdQuery?.data[0]?.createdAt), {
              addSuffix: true,
            })}
        </div>
      ) : (
        <div className="bg-gray-100 h-3  w-36 animate-pulse rounded-full" />
      )}
    </div>
  );
};

export default ArtistHoverCardContent;
