import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import logo from "../../../img/trackflow-logo-with-white-text.svg";

import DotsLoader from "../../DotsLoader";

const OnboardingWelcome = ({ handleNextStep, current, percentage }) => {
  const [buttonText, setButtonText] = React.useState("next");

  useEffect(() => {
    if (current) {
      setButtonText("next");
    }
  }, [current]);

  const handleSubmit = (e) => {
    if (current) {
      e.preventDefault();
      console.log("submitted");
      setButtonText("loading");
      setTimeout(() => {
        console.log("submitted");
        setButtonText("Get started");
        handleNextStep();
      }, 100);
    }
  };
  return (
    <div className="flex h-screen w-full flex-row gap-12">
      {/* mt-[calc(50vh-10rem)]  */}

      <div className="my-auto flex w-full flex-col gap-6 pb-12">
        <input type="text" className="h-0 opacity-0" />
        <div className="w-full">
          <div className="max-w-[24rem]">
            <img src={logo} alt="Trackflow" />
          </div>
          <Fade delay={100} triggerOnce>
            <label className="block pt-8 pb-3 text-2xl text-gray-800 sm:text-3xl ">
              Let's set up your <span className="font-medium">account</span>!
            </label>
          </Fade>
        </div>

        <div>
          <Fade delay={300} triggerOnce>
            <button
              type="button"
              onClick={handleSubmit}
              className="inline-flex h-14 items-center  rounded-md border px-5 py-3 text-xl font-medium shadow-sm text-white border-gray-300 bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:ring-offset-backgrounddark"
            >
              <div>Get started</div>
            </button>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWelcome;
